import { ApplicationState } from "../store/index";
import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

class NotFoundPage extends React.PureComponent<any, any>{
    render() {
        return <div>
            <Helmet title={"Not found"} />
            <div className="page login">
                <div className="container container-content">
                    <div className="panel">
                        <div className="panel-body">
                            <h4 className="text-center">{"Not found"}</h4>
                        </div>
                    </div>
                </div>
            </div>                        
        </div>
    }
}

export default connect(
    (state: ApplicationState) => ({}),
)(NotFoundPage);
