import { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";

import { ApplicationPaths } from "../enums/Common/ApplicationPaths";
import Result from "../models/Common/Result";
import Globals from "../Globals";
import { LoginStore } from "../store/LoginStore";

let refreshTokenPromise: Promise<any> | null = null;

export const useServiceBase = () => {
  const dispatch = useDispatch();
  const { accessToken, expiresAt } = useSelector((state: any) => state.login);
  const [isLoading, setIsLoading] = useState(false);

  const executeAuthenticatedRequest = useCallback(
    async (url: string, options: RequestInit = {}): Promise<Response> => {
      setIsLoading(true);
      let initPromise: Promise<void> = refreshTokenPromise || Promise.resolve();
      const isTokenExpired = expiresAt * 1000 < new Date().getTime();

      if (isTokenExpired && refreshTokenPromise == null) {
        console.log(`Token expired for: ${url}`);
        initPromise = dispatch(
          LoginStore.boundActions.refreshToken() as any
        ).then(() => {
          refreshTokenPromise = null;
        });
        refreshTokenPromise = initPromise;
      }

      return initPromise
        .then(() => {
          options.headers = {
            ...options.headers,
            "Accept-Language": Globals.currentActiveCulture,
            Authorization: `Bearer ${accessToken}`,
          };

          return fetch(url, options);
        })
        .finally(() => setIsLoading(false));
    },
    [accessToken, expiresAt, dispatch]
  );

  const handleError = useCallback(
    async (result: Response): Promise<Result<any>> => {
      switch (result.status) {
        case 403:
        case 401:
          window.location.href = ApplicationPaths.Unauthorized;
          return new Result(null);
        case 400:
        case 409:
        case 422:
        case 500:
          const response = await result.json();
          notification.error({
            message: "Server Error",
            description: JSON.stringify(response.errors),
          });
          return new Result(null, response);
        case 404:
          return new Result({});
        default:
          notification.error({
            message: "General Error",
            description: String(result),
          });
          throw new Error(result.statusText);
      }
    },
    []
  );

  const authenticatedFetch = useCallback(
    async <T>(url: string, options: RequestInit = {}): Promise<Result<T>> => {
      try {
        const response = await executeAuthenticatedRequest(url, options);
        if (!response.ok) {
          return handleError(response);
        }
        const data = await response.json();
        return new Result(data);
      } catch (error) {
        return handleError(error as Response);
      }
    },
    [executeAuthenticatedRequest, handleError]
  );

  return { authenticatedFetch, isLoading };
};
