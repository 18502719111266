// DateInput.tsx
import React from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";

import { IInputProps } from "./FormInput";

const DateInput: React.FC<IInputProps> = ({
  label,
  value,
  className,
  errors,
  name,
  readOnly,
  onChange,
}) => {
  const initialValue = !value
    ? ""
    : dayjs(new Date(value)).format("YYYY-MM-DD HH:mm").replace("T", " ");

  const handleDateChange = (date: Date) => {
    if (!readOnly) {
      onChange(
        name,
        dayjs(new Date(date)).format("YYYY-MM-DD HH:mm").replace(" ", "T")
      );
    }
  };

  return (
    <div className="form-item">
      {label && <label>{label}</label>}
      <br />
      <DatePicker
        className={`${className} ${readOnly ? "readonly-date" : ""}`}
        name={name}
        showTimeSelect={true}
        value={initialValue}
        selected={new Date(initialValue)}
        timeFormat="HH:mm"
        dateFormat="dd-MM-yyyy HH:mm"
        onChangeRaw={(e) => {
          e.preventDefault();
        }}
        disabled={readOnly}
        onChange={handleDateChange}
      />
      {errors.map((err: string) => (
        <span key={err} className="field-validation-error">
          {err}
        </span>
      ))}
    </div>
  );
};

export default DateInput;
