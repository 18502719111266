import React from "react";
import General from "../../resources/AdminUI/General";
import LocalizationLanguages from "../../resources/AdminUI/LocalizationLanguages"

interface IProps {
    descriptionEn: string
    descriptionEs: string
    descriptionPt: string
}

interface IState {

}

export class DescriptionTab extends React.Component<IProps, IState>{

    constructor(props) {
        super(props);
    }

    render() {

        return <div>
            <p className="header first">{LocalizationLanguages.Resources.english}</p>
            <p>{this.props.descriptionEn ? this.props.descriptionEn : General.Resources.notCompletedYet}</p>
            <p className="header">{LocalizationLanguages.Resources.spanish}</p>
            <p>{this.props.descriptionEs ? this.props.descriptionEs : General.Resources.notCompletedYet}</p>
            <p className="header">{LocalizationLanguages.Resources.portuguese}</p>
            <p>{this.props.descriptionPt ? this.props.descriptionPt : General.Resources.notCompletedYet}</p>
        </div>
    }
}
