import React from "react";
import { Helmet } from "react-helmet";
import { Dayjs } from "dayjs";

import { withRouter } from "../../components/Shared/withRouter";
import RoomBookingsGridWrapper from "../../components/RoomBookings/RoomBookingsGridWrapper";
import ActionsSection from "../../components/RoomBookings/ActionsSection";
import FiltersSection from "../../components/RoomBookings/FiltersSection";
import { DefaultFilterValues } from "../../enums/Common/DefaultFilterValues";
import { useRoomBookingsPage } from "../../hooks/RoomBookings/useRoomBookingsPage";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import { getRoomBookingsColumns } from "../../components/RoomBookings/RoomBookingsTableColumns";
import { useRoomBookingsContext } from "../../providers/RoomBookingsProvider";
import { useRoomBookingsQueries } from "../../hooks/RoomBookings/useRoomBookingsQueries";

const RoomBookingsGridPage = () => {
  const {
    searchTerm,
    singleSelectedStatus,
    singleSelectedCountry,
    singleSelectedCity,
    startDateSearchTemplate,
    endDateSearchTemplate,
    itemsPerPage,
    numberOfRoomBookings,
    lastIndexFromPage,
    firstIndexFromPage,
    pageIndexArray,
    setSearchTerm,
    setSingleSelectedStatus,
    setSingleSelectedCity,
    setSingleSelectedCountry,
    setDateRange,
  } = useRoomBookingsContext();

  const {
    countriesData,
    citiesData,
    filters,
    skip,
    getAllRoomStatuses,
    changeCurrentPage,
    reloadRoomBookingsData,
  } = useRoomBookingsPage();

  const { getRoomBookings } = useRoomBookingsQueries();
  const { data, error, isLoading } = getRoomBookings(
    itemsPerPage,
    skip,
    filters
  );

  return (
    <>
      <Helmet title={RoomBookingsGrid.Resources.roomsLogs} />
      <div className="page-top-container">
        <div className="page-title">{RoomBookingsGrid.Resources.roomsLogs}</div>
        <ActionsSection filters={filters} />
      </div>
      <FiltersSection
        countries={countriesData}
        cities={citiesData}
        singleSelectedCountry={singleSelectedCountry}
        singleSelectedCity={singleSelectedCity}
        singleSelectedStatus={singleSelectedStatus}
        searchTerm={searchTerm}
        startDateSearchTemplate={startDateSearchTemplate}
        endDateSearchTemplate={endDateSearchTemplate}
        dateFormat={"DD-MM-YYYY"}
        onSearchFilterChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
          setSearchTerm(e.target.value)
        }
        onCountryFilterChange={(value: string): void => {
          setSingleSelectedCountry(value);
          setSingleSelectedCity(DefaultFilterValues.All.toString());
        }}
        onCityFilterChange={(value: string): void =>
          setSingleSelectedCity(value)
        }
        setSingleSelectedStatus={setSingleSelectedStatus}
        onDateChange={(dates: [Dayjs | null, Dayjs | null]): void => {
          if (!dates) {
            setDateRange(null, null);
            return;
          }
          setDateRange(dates[0]?.unix(), dates[1]?.unix());
        }}
        getAllRoomStatuses={getAllRoomStatuses}
      />
      <RoomBookingsGridWrapper
        roomBookingsColumns={getRoomBookingsColumns(true)}
        itemsPerPage={itemsPerPage}
        data={data}
        isLoading={isLoading}
        pagination={{
          totalItems: numberOfRoomBookings,
          firstIndexFromPage: firstIndexFromPage,
          lastIndexFromPage: lastIndexFromPage,
          pageIndexArray: pageIndexArray,
          maxPagesDisplayed: 3,
          pageNeighbours: 1,
          reload: reloadRoomBookingsData,
          changeCurrentPage: changeCurrentPage,
        }}
      />
    </>
  );
};

export default withRouter(RoomBookingsGridPage);
