import { AppSettings } from "../utils/settings";

export class UnauthorizedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "UnauthorizedError";
  }
}

export default class AccountService {
  static async sendAuthChallenge(email: string): Promise<void> {
    const url = `${AppSettings.CommonSettings.ApiUrl}/auth/send-auth-challenge`;
    const formData = new FormData();
    formData.append("email", email);

    return fetch(url, { method: "POST", body: formData }).then(
      async (result) => {
        if (!result.ok) {
          throw new Error(await result.text());
        }
      }
    );
  }

  static async validateAuthChallenge(
    email: string,
    challenge: string
  ): Promise<{ refreshToken: string; accessToken: string }> {
    const url = `${AppSettings.CommonSettings.ApiUrl}/auth/validate-auth-challenge`;
    const formData = new FormData();
    formData.append("email", email);
    formData.append("challenge", challenge);

    return fetch(url, { method: "POST", body: formData }).then(
      async (result) => {
        if (!result.ok) {
          if (result.status === 401) {
            throw new UnauthorizedError(await result.text());
          }
          throw new Error(await result.text());
        } else {
          return result.json();
        }
      }
    );
  }

  static async refreshToken(
    refreshToken: string
  ): Promise<{ refreshToken: string; accessToken: string }> {
    const url = `${AppSettings.CommonSettings.ApiUrl}/auth/token`;
    const formData = new FormData();
    formData.append("refreshToken", refreshToken);

    return fetch(url, { method: "POST", body: formData }).then(
      async (result) => {
        if (!result.ok) {
          if (result.status === 401) {
            throw new UnauthorizedError(await result.text());
          }
          throw new Error(await result.text());
        } else {
          return result.json();
        }
      }
    );
  }
}
