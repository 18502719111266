import React from "react";
import ISpaceGroup from "../../../models/ManageAccounts/ISpaceGroup";
import ManageAccounts from "../../../resources/AdminUI/ManageAccounts";
import { String_Format } from "../../../utils/utils";
import bind from "bind-decorator";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { ManageAccountStore } from "../../../store/ManageAccountsStore";

interface IProps {
    spaceGroup: ISpaceGroup,
    changeSelectedSpaceGroup?: (selectedSpaceGroup: ISpaceGroup) => void
}

class SpaceGroupCard extends React.PureComponent<IProps, any> {
    constructor(props) {
        super(props)
    }

    @bind
    loadSpaceGroupAccounts() {
        this.props.changeSelectedSpaceGroup(this.props.spaceGroup);
    }

    render() {
        return <div onClick={this.loadSpaceGroupAccounts} className="accounts-list-item">
            <h2 className="title">{this.props.spaceGroup.name}</h2>
            <div className="status">
                {
                    this.props.spaceGroup.hasCredentials ?
                        <span className="status-affirmative">{ManageAccounts.Resources.hasCredentialsLabel}</span>
                        : <span className="status-negative">{ManageAccounts.Resources.noCredentialsLabel}</span>
                }
            </div>
            <div className="spaces-list">
                {
                    this.props.spaceGroup.noOfWorkspaces == 0 ?
                        <span>{ManageAccounts.Resources.noSpaceLabel}</span>
                        : <span className="spaces-number">
                            {this.props.spaceGroup.noOfWorkspaces > 1 ?
                                String_Format(ManageAccounts.Resources.spacesLabel, this.props.spaceGroup.noOfWorkspaces)
                                : String_Format(ManageAccounts.Resources.spaceLabel, this.props.spaceGroup.noOfWorkspaces)}
                        </span>
                }
                {
                    this.props.spaceGroup.workspacesList?.join(', ')
                }
            </div>
        </div>
    }
}

export default connect(
    (state: ApplicationState, ownProps: IProps) => {
        return {
            ...ownProps
        }
    },
    {
        changeSelectedSpaceGroup: ManageAccountStore.actionCreators.changeSelectedSpaceGroup
    }
)(SpaceGroupCard as any)