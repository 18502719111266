import React from "react";
import ClientDetails from "../../../resources/AdminUI/Clients/ClientDetails";
import { IClientDetails } from "../../../models/Clients/Interfaces/IClientDetails";
import General from "../../../resources/AdminUI/General";

interface IProps {
    client: IClientDetails
}

export default class ContactInfoTabContent extends React.PureComponent<IProps, any> {
    constructor(props) {
        super(props)
    }

    render() {
        return <div>
            <div className="header first" >{ClientDetails.Resources.customerContactInfoLabel}</div>
            <div>
                <p>{this.props.client.contactPhone || General.Resources.notCompletedYet}</p>
                <p>{this.props.client.contactEmail || General.Resources.notCompletedYet}</p>
            </div>
            <div className="header">{ClientDetails.Resources.pluriaAccountManagerLabel}</div>
            <div>
                <p>{this.props.client.accountManagerEmail || General.Resources.notCompletedYet}</p>
            </div>
            <div className="header">{ClientDetails.Resources.pluriaSalesPersonLabel}</div>
            <div>
                <p>{this.props.client.salesPersonEmail || General.Resources.notCompletedYet}</p>
            </div>
        </div>
    }
}