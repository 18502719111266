import React from "react";
import Helmet from 'react-helmet';
import CountriesAndCities from "../../resources/AdminUI/CountriesAndCities";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { CountriesAndCitiesStore } from "../../store/CountriesAndCitiesStore";
import { ICountryItem } from "../../models/CountriesAndCities/ICountryItem";
import CountriesContainer from "../../components/CountriesAndCities/CountriesContainer";
import CitiesContainer from "../../components/CountriesAndCities/CitiesContainer";

interface IProps {
    countries: ICountryItem[],
    initializePage: (id: number) => {}
}

class CountriesAndCitiesPage extends React.PureComponent<IProps, any> {
    constructor(props) {
        super(props)

        this.props.initializePage(1);
    }

    render() {
            return <div>
                <div className="page-top-container">
                    <div className="page-title">Manage countries & cities</div>
                </div>

                <Helmet title={CountriesAndCities.Resources.countriesAndCitiesHeaderLabel} />

                <div className="lists-container countries-page">
                    <div className="left-list-container">
                        <CountriesContainer />
                    </div>
                    <div className="right-list-container">
                        <CitiesContainer />
                    </div>
                </div>
            </div>
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            countries: state.countriesAndCities.countriesList
        }
    },
    {
        initializePage: CountriesAndCitiesStore.actionCreators.initializePage
    }
)(CountriesAndCitiesPage as any)