import Globals from "./../../Globals";

export default class RenewalTypesList {
       public day: string = `day(s)`;
       public month: string = `month(s)`;
       public week: string = `week(s)`;
       public year: string = `year(s)`;

		public static get Resources(): RenewalTypesList{
			switch (Globals.currentActiveLanguage) {
				default:
				return new RenewalTypesList();
		}
   	}
}
