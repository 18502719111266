import React from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import General from "../../resources/AdminUI/General";
import { withRouter } from "../../components/Shared/withRouter";
import EditSpacePrice from "../../resources/AdminUI/Spaces/EditSpacePrice";
import EditSpacePriceForm from "../../components/Rooms/EditSpacePriceForm";
import { ChangeHotdeskBookingPricesDTO } from "../../models/Spaces/Interfaces/ChangeHotdeskBookingPricesDTO";
import { ChangeHotdeskBookingPricesStore } from "../../store/UpdateSpacePrices";
import Loading from "../../components/Shared/Loading";

interface IProps {
  loading: boolean;
  hasServerSideErrors: boolean;
  erros: string;
  onSave?;
}

interface IState extends ChangeHotdeskBookingPricesDTO {
  workspaceId?: number;
}

class EditSpacePricePage extends React.PureComponent<IProps, IState> {
  render() {
    return (
      <div>
        <Helmet title={EditSpacePrice.Resources.title} />

        <div className="page-top-container">
          <div className="page-title">{EditSpacePrice.Resources.title}</div>
        </div>

        {this.props.loading ? (
          <div>
            <Loading />
          </div>
        ) : (
          <div>
            <EditSpacePriceForm
              onSave={this.props.onSave}
              hasServerSideErrors={this.props.hasServerSideErrors}
              errors={this.props.erros}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: ApplicationState) => {
      return {
        loading: state.addOrEditHotdeskBooking.loading,
        hasServerSideErrors: state.addOrEditRoomBooking.hasServerSideErrors,
        errors: state.addOrEditRoomBooking.errors,
      };
    },
    {
      onSave: ChangeHotdeskBookingPricesStore.actionCreators.onSave,
    }
  )(EditSpacePricePage as any)
);
