import React, { FC } from "react";
import { connect } from "react-redux";

import { ApplicationState } from "../../../store";
import DnDGrid from "../DnDGrid";
import Gallery from "../../../resources/AdminUI/Gallery";
import { AppSettings } from "../../../utils/settings";
import { useImageGallery } from "./useImageGalleryUpload";

interface IProps {
  images?: string[];
  onSaveCallback?: (newOrderedImages: string[]) => void;
}
const ImageGalleryUpload: FC<IProps> = ({ images, onSaveCallback }) => {
  const {
    currentImages,
    errors,
    uploadImagesWithInput,
    uploadImagesWithDragDrop,
    removeImage,
    changeImageOrder,
  } = useImageGallery(images || [], onSaveCallback || (() => {}));

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className="i-gallery" draggable="true">
      <div
        className="i-gallery-upload"
        onDrop={uploadImagesWithDragDrop}
        onDragOver={handleDrag}
      >
        <div className="i-upload-label">
          {Gallery.Resources.dropYourImage}
          <span className="i-upload-btn">
            <span className="i-upload-btn-label">
              {Gallery.Resources.browse}
            </span>
            <input
              type="file"
              onChange={uploadImagesWithInput}
              data-multiple-caption="{count} files selected"
              multiple
            />
          </span>
        </div>
        <div className="i-upload-info">
          {Gallery.Resources.supportsJPGandPNGfiles}
        </div>
      </div>

      <div className="i-gallery-title">{Gallery.Resources.photoOrder}</div>
      <div className="i-gallery-detail">
        {Gallery.Resources.dragAndDropPhotosPlaceInTheOrderYouLikeThemToAppear}
      </div>

      <div style={{ color: "red" }}>{errors}</div>

      <div className="i-gallery-list">
        <DnDGrid
          items={currentImages}
          onChange={changeImageOrder}
          getItemId={(img) => currentImages.indexOf(img) + 1}
          getGridElementInnerContent={(image, propsForHandle) => (
            <div className="i-gallery-item">
              <div className="i-gallery-photo">
                <div {...propsForHandle}>
                  <img
                    src={AppSettings.CommonSettings.ImageBaseUrl + image}
                    alt=""
                  />
                </div>
                <a
                  className="i-gallery-delete-btn"
                  style={{ cursor: "pointer" }}
                  onClick={() => removeImage(image)}
                >
                  <span className="material-symbols-outlined">delete</span>
                </a>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default ImageGalleryUpload;

const mapStateToProps = (state: ApplicationState) => ({
  images: [],
});

export const ImageGalleryUploadComponent = connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(ImageGalleryUpload);