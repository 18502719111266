import Globals from "./../../Globals";

export default class LocalizationLanguages {
       public english: string = `English`;
       public portuguese: string = `Portuguese`;
       public spanish: string = `Spanish`;

		public static get Resources(): LocalizationLanguages{
			switch (Globals.currentActiveLanguage) {
				default:
				return new LocalizationLanguages();
		}
   	}
}
