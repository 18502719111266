import React from 'react'
import UserDetails from "../../../resources/AdminUI/Users/UserDetails";
import { String_Format, firstLetterToLowerCase, getArrayAsCommaSeparatedString } from "../../../utils/utils";
import { IUserDetails } from '../../../models/Users/Interfaces/IUserDetails';
import { UserStates } from '../../../enums/Users/UserStates';
import { PlanTypes } from '../../../enums/Common/PlanTypes';
import UsersGrid from '../../../resources/AdminUI/Users/UsersGrid';
import UserStateTypes from '../../../resources/AdminUI/Users/UserStateTypes';
import { formatDate } from '../../../utils/date';

interface IProps {
    user: IUserDetails
}

export class UserDetailsCard extends React.Component<IProps, any> {
    constructor(props) {
        super(props)
    }

    render() {
        return (

            <div className="two-columns-container">
                <section className="container">
                    <h2 className="item-name">{this.props.user.firstName} {this.props.user.lastName}</h2>
                    <div className="db_info">
                        <p>
                            <span className={`status ${firstLetterToLowerCase(UserStates[this.props.user.status])}`}>
                                {UserStateTypes.Resources[firstLetterToLowerCase(UserStates[this.props.user.status])]}
                            </span>
                        </p>
                        <div className="id_created_on">
                            | {String_Format(UserDetails.Resources.idLabel, this.props.user.id)}
                            | {String_Format(UserDetails.Resources.accountCretedOnLabel, formatDate(this.props.user.createdAt))}
                        </div>
                    </div>

                    <div className="header">{UserDetails.Resources.desksActivityLabel}</div>
                    <p>{String_Format(UserDetails.Resources.currentMonthBookings, this.props.user.hotdeskCurrentMonthBookings || 0, this.props.user.hotdeskCurrentMonthCheckins || 0)}</p>
                    <p>{String_Format(UserDetails.Resources.totalBookings, this.props.user.hotdeskTotalBookings || 0, this.props.user.hotdeskTotalCheckins || 0)}</p>

                    <div className="header">{UserDetails.Resources.roomsActivityLabel}</div>
                    <p>{String_Format(UserDetails.Resources.rommBookingsLabel, this.props.user.roomBookings || 0)}</p>

                </section>

                <section className="container">

                    <div className="header first">{UserDetails.Resources.planLabel}</div>
                    <p>{PlanTypes[PlanTypes.ENTERPRISE]}</p>
                    <div className="user-info-row">
                        <span className="material-symbols-outlined">store</span>
                        <p className="text">{this.props.user.companyName} | {String_Format(UserDetails.Resources.idLabel, this.props.user.companyId)}</p>
                    </div>

                    <div className="header">{UserDetails.Resources.roleLabel}</div>
                    <div>
                        {this.props.user.isAccountOwner ? UsersGrid.Resources.accountOwnerLabel : ''}
                        {this.props.user.isBookingManager ? UsersGrid.Resources.bookingManagerLabel : ''}
                        {this.props.user.isAccountOwner == false && this.props.user.isBookingManager == false && UsersGrid.Resources.noRoleLabel}
                    </div>

                    <div className="header">{UserDetails.Resources.contactInfoLabel}</div>
                    <div className="user-info-row">
                        <span className="material-symbols-outlined">phone</span>
                        <p className="text">{this.props.user.phone}</p>
                    </div>
                    <div className="user-info-row">
                        <span className="material-symbols-outlined">email</span>
                        <p className="text">{this.props.user.email}</p>
                    </div>

                    <div className="header">{UserDetails.Resources.preferredChannelsLabel}</div>
                    <div>
                        {
                            getArrayAsCommaSeparatedString(this.props.user.marketingPreferences)
                            || UsersGrid.Resources.noCommunicationChannels
                        }
                    </div>
                </section>
            </div>

        );
    }
}