import React from "react";
import Helmet from "react-helmet";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { LookupStore } from "../../store/LookupStore";
import { ApplicationPaths } from "../../enums/Common/ApplicationPaths";
import General from "../../resources/AdminUI/General";
import AddOrEditOfficeForm from "../../components/Spaces/AddOrEditOfficeForm";
import ErrorMessages from "../../resources/Common/ErrorMessages";
import bind from "bind-decorator";
import { AddOrEditOfficeStore } from "../../store/AddOrEditOfficeStore";
import AddOrEditOffice from "../../resources/AdminUI/Spaces/AddOrEditOffice";
import { IAntdSelectListItem } from "../../models/Common/IAntdSelectListItem";
import { Modal } from "antd";
import { NavigateFunction } from "react-router-dom";
import { withRouter } from "../../components/Shared/withRouter";
import Loading from "../../components/Shared/Loading";

interface IProps {
  navigate?: NavigateFunction;
  params: URLSearchParams;

  countries?: IAntdSelectListItem[];
  cities?: IAntdSelectListItem[];
  languages?: IAntdSelectListItem[];
  loading: boolean;

  getCountriesWithCities?: (addAllOption?: boolean) => void;
  getCities?: (addAllOption?: boolean) => void;
  getLanguages?: () => void;
  getOfficeAmenities?: () => void;
  initializeCreatePage?: () => void;
  initializeEditPage?: (id: number) => void;
}

interface IState {
  isEditMode: boolean;
  officeId: number;
  companyId: number;
}

class AddOrEditOfficePage extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);

    if (window.location.pathname == ApplicationPaths.AddOffice) {
      if (props.params.has("customerId")) {
        const clientId = parseInt(props.params.get("customerId"));
        this.state = { isEditMode: false, officeId: null, companyId: clientId };
        this.props.initializeCreatePage();
      } else {
        this.props.navigate(ApplicationPaths.NotFound);
      }
    } else if (window.location.pathname == ApplicationPaths.EditOffice) {
      if (props.params.has("id")) {
        const id = parseInt(props.params.get("id"));
        this.state = { isEditMode: true, officeId: id, companyId: null };
        this.props.initializeEditPage(id);
      } else {
        this.props.navigate(ApplicationPaths.NotFound);
      }
    }

    this.props.getOfficeAmenities();
    this.props.getCountriesWithCities(false);
    this.props.getCities(false);
    this.props.getLanguages();
  }

  @bind
  showInvalidArgumentModal() {
    Modal.error({
      title: ErrorMessages.Resources.error,
      content: ErrorMessages.Resources.invalidValueForArgumentId,
    });
  }

  render() {
    return (
      <div>
        <Helmet title={AddOrEditOffice.Resources.office} />

        <div className="details-page-top">
          <div className="page-navigation">
            <span
              className="material-symbols-outlined"
              onClick={() => this.props.navigate(-1)}
            >
              arrow_back
            </span>
            <div className="page-title">
              {this.state.isEditMode
                ? AddOrEditOffice.Resources.editOffice
                : AddOrEditOffice.Resources.addOffice}
            </div>
          </div>
        </div>

        {!this.props.countries ||
        !this.props.cities ||
        !this.props.languages ||
        this.props.loading ? (
          <div>
            <Loading />
          </div>
        ) : (
          <div>
            <AddOrEditOfficeForm companyId={this.state.companyId} />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: ApplicationState) => {
      return {
        countries: state.lookup.countries,
        cities: state.lookup.cities,
        languages: state.lookup.languages,
        loading: state.addOrEditOffice.loading,
      };
    },
    {
      getCountriesWithCities: LookupStore.actionCreators.getCountriesWithCities,
      getCities: LookupStore.actionCreators.getCities,
      getLanguages: LookupStore.actionCreators.getLanguages,
      getOfficeAmenities:
        AddOrEditOfficeStore.actionCreators.getOfficeAmenities,
      initializeCreatePage:
        AddOrEditOfficeStore.actionCreators.initializeCreatePage,
      initializeEditPage:
        AddOrEditOfficeStore.actionCreators.initializeEditPage,
    }
  )(AddOrEditOfficePage as any)
);
