import Globals from "./../../../Globals";

export default class RoomBookingTypeListForAddOrEdit {
       public dailyInterval: string = `Daily (interval - multiple days)`;
       public dailyOneDay: string = `Daily (one day)`;
       public hourly: string = `Hourly`;
       public monthly: string = `Monthly`;

		public static get Resources(): RoomBookingTypeListForAddOrEdit{
			switch (Globals.currentActiveLanguage) {
				default:
				return new RoomBookingTypeListForAddOrEdit();
		}
   	}
}
