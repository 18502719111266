import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { IAdminLogItem } from "../models/AccountAndLogs/Interfaces/IAdminLogItem";
import IPageIndex from "../models/Pagination/IPageIndex";
import AccountsAndLogsService from "../services/AccountsAndLogsService";
import update from "immutability-helper";
import { DefaultFilterValues } from "../enums/Common/DefaultFilterValues";

export namespace AdminLogsStore {

    export interface IState {
        numberOfAdminLogs?: number;
        adminLogs?: IAdminLogItem[];
        isAdminLogsListHidden?: boolean[];
        loading?: boolean;
        filters?: string;
        selectedUser?: string;

        itemsPerPage: number;
        currentPage: number;
        firstIndexFromPage: number;
        lastIndexFromPage: number;
        pageIndexArray: IPageIndex[];
    }

    const initialState: IState = {
        itemsPerPage: 10,
        currentPage: 1,
        firstIndexFromPage: 1,
        lastIndexFromPage: 1,
        pageIndexArray: [],
        selectedUser: DefaultFilterValues.All.toString()
    }

    export enum Actions {
        ToggleLoading = "ADMIN_LOGS_TOGGLE_LOADING",
        Initialize = "ADMIN_LOGS_INITIALIZE",
        InitializeHiddenList = "ADMIN_LOGS_INITIALIZE_HIDDEN_LIST",
        ReloadData = "ADMIN_LOGS_RELOAD_DATA",
        RecalculatePageArray = "ADMIN_LOGS_RECALCULATE_PAGE_ARRAY",
        RecalculateIndexes = "ADMIN_LOGS_RECALCULATE_INDEXES",
        ChangeCurrentPage = "ADMIN_LOGS_CHANGE_CURRENT_PAGE",
        SetFilters = "ADMIN_LOGS_SET_FILTERS",
        ChangeSelectedUser = "ADMIN_LOGS_CHANGE_SELECTED_USER",
    }

    interface IInitialize {
        type: Actions.Initialize;
        itemsPerPage: number,
    }

    interface IInitializeHiddenList {
        type: Actions.InitializeHiddenList,
    }

    interface IToggleLoading {
        type: Actions.ToggleLoading;
        loading: boolean;
    }

    interface IReloadData {
        type: Actions.ReloadData,
        adminlogs: IAdminLogItem[],
        numberOfAdminLogs: number,
    }

    interface IRecalculatePageArray {
        type: Actions.RecalculatePageArray,
    }

    interface IRecalculateIndexes {
        type: Actions.RecalculateIndexes,
    }

    interface IChangeCurrentPage {
        type: Actions.ChangeCurrentPage,
        currentPage: number,
    }

    interface ISetFilters {
        type: Actions.SetFilters,
        filters: string,
    }

    interface IChangeSelectedUser {
        type: Actions.ChangeSelectedUser,
        selectedUser: string
    }

    type KnownAction = IToggleLoading |
        IInitialize | IInitializeHiddenList | IReloadData | IRecalculatePageArray | IRecalculateIndexes
        | IChangeCurrentPage | ISetFilters | IChangeSelectedUser;

    export const actionCreators = {
        initialize: (defaultSelectedItemsPerPageOption: number, filters: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {

            dispatch({ type: Actions.Initialize, itemsPerPage: defaultSelectedItemsPerPageOption });
            dispatch({ type: Actions.ToggleLoading, loading: true });

            const adminLogs = await AccountsAndLogsService.getAdminLogs(defaultSelectedItemsPerPageOption, 0, filters).then(res => res.value);
            const adminLogsMapped = AccountsAndLogsService.additionalMapping(adminLogs);

            if (adminLogs.entities.length != 0) {
                dispatch({ type: Actions.ReloadData, adminlogs: adminLogsMapped.entities, numberOfAdminLogs: adminLogsMapped.numberOfEntities });
            } else {
                dispatch({ type: Actions.ReloadData, adminlogs: [], numberOfAdminLogs: 0 });
            }

            dispatch({ type: Actions.SetFilters, filters: filters });
            dispatch({ type: Actions.InitializeHiddenList });
            dispatch({ type: Actions.ChangeCurrentPage, currentPage: 1 });
            dispatch({ type: Actions.RecalculatePageArray });
            dispatch({ type: Actions.RecalculateIndexes });
            dispatch({ type: Actions.ToggleLoading, loading: false });
        },
        reload: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {

            dispatch({ type: Actions.ToggleLoading, loading: true });
            const take = getState().adminLogs.itemsPerPage;
            const skip = (getState().adminLogs.currentPage - 1) * getState().adminLogs.itemsPerPage;
            const filters = getState().adminLogs.filters;

            const adminLogs = await AccountsAndLogsService.getAdminLogs(take, skip, filters).then(res => res.value);
            const adminLogsMapped = AccountsAndLogsService.additionalMapping(adminLogs);

            dispatch({ type: Actions.ReloadData, adminlogs: adminLogsMapped.entities, numberOfAdminLogs: adminLogsMapped.numberOfEntities });
            dispatch({ type: Actions.InitializeHiddenList });
            dispatch({ type: Actions.ToggleLoading, loading: false });
        },
        changeCurrentPage: (currentPage: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ChangeCurrentPage, currentPage });
            dispatch({ type: Actions.RecalculatePageArray });
            dispatch({ type: Actions.RecalculateIndexes });
        },
        setFilters: (filters: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.SetFilters, filters: filters });
        },
        setSelectedUser: (selectedUser: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ChangeSelectedUser, selectedUser: selectedUser });
        },
    }

    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case Actions.Initialize:
                return update(initialState,
                    {
                        itemsPerPage: { $set: action.itemsPerPage }
                    }
                );
            case Actions.InitializeHiddenList:
                const hiddenList = new Array(currentState.itemsPerPage).fill(undefined).map((e) => true);
                return update(currentState,
                    {
                        isAdminLogsListHidden: { $set: hiddenList }
                    }
                );
            case Actions.ToggleLoading:
                return update(currentState,
                    {
                        loading: { $set: action.loading }
                    }
                );
            case Actions.ReloadData:
                return update(currentState,
                    {
                        adminLogs: { $set: action.adminlogs },
                        numberOfAdminLogs: { $set: action.numberOfAdminLogs }
                    }
                );
            case Actions.ChangeCurrentPage:
                return update(currentState,
                    {
                        currentPage: { $set: action.currentPage }
                    }
                );
            case Actions.RecalculatePageArray:
                const totalPages = Math.floor((currentState.numberOfAdminLogs - 1) / currentState.itemsPerPage) + 1;
                const pageArray = [];
                pageArray.push({ pageNumber: 1, isActive: currentState.currentPage === 1 } as IPageIndex);
                for (let i = 2; i <= totalPages; i++) {
                    pageArray.push({ pageNumber: i, isActive: currentState.currentPage === i });
                }
                return update(currentState,
                    {
                        pageIndexArray: { $set: pageArray }
                    }
                );
            case Actions.RecalculateIndexes: {
                const firstIndex = (currentState.currentPage - 1) * currentState.itemsPerPage + 1;
                const totalPages = currentState.pageIndexArray.length;
                let lastIndex: number;

                if (totalPages == currentState.currentPage)
                    lastIndex = Math.min(currentState.numberOfAdminLogs, currentState.itemsPerPage * totalPages);
                else
                    lastIndex = currentState.currentPage * currentState.itemsPerPage;
                return update(currentState,
                    {
                        firstIndexFromPage: { $set: firstIndex },
                        lastIndexFromPage: { $set: lastIndex },
                    }
                );
            }
            case Actions.SetFilters:
                return update(currentState,
                    {
                        filters: { $set: action.filters }
                    }
                );
            case Actions.ChangeSelectedUser:
                return update(currentState,
                    {
                        selectedUser: { $set: action.selectedUser }
                    }
                );
            default:
                return currentState || initialState;
        }
    }
}