import Globals from "./../../../Globals";

export default class UserDetails {
  public accountCretedOnLabel: string = `Account created on {0}`;
  public activateUserOptionLabel: string = `Activate User`;
  public addUserInfoDesc: string = `Complete user's first name, last name, phone number or email address. Make sure the user's email address is valid, in order to authenticate in the mobile app.`;
  public addUserInfoHeader: string = `Add user information`;
  public contactInfoLabel: string = `Contact info`;
  public currentMonthBookings: string = `{0} booking(s) this month, {1} check-in(s)`;
  public deactivateUserDescLabel: string = `By continuing the user will still be able to access his account , but will not be able to create bookings, check-in in any working space, and all his active bookings will be canceled.`;
  public deactivateUserOptionLabel: string = `Deactivate user`;
  public deleteUserDescLabel: string = `By continuing the user will no longer access the application and all his upcoming bookings are canceled. User profile information is anonymized.`;
  public deleteUserOptionLabel: string = `Delete user`;
  public desksActivityLabel: string = `Desks activity`;
  public editUserInfoDescLabel: string = `Change user's first name, last name, phone number or email address. Make sure the user's email address is valid, in order to authenticate in the mobile app.`;
  public editUserInfoLabel: string = `Edit user info`;
  public emailLabel: string = `Email address`;
  public firstNameLabel: string = `First name`;
  public idLabel: string = `ID: #{0}`;
  public lastNameLabel: string = `Last name`;
  public phoneLabel: string = `Phone number`;
  public cityLabel: string = `City`;
  public departmentLabel: string = `Department`;
  public teamLeaderLabel: string = `Team leader`;
  public planLabel: string = `Plan`;
  public preferredChannelsLabel: string = `Preferred channels `;
  public roleLabel: string = `Role`;
  public rommBookingsLabel: string = `{0} room(s) booked by now`;
  public roomsActivityLabel: string = `Rooms activity`;
  public saveUserInfoButtonLabel: string = `Save user info`;
  public totalBookings: string = `{0} booking(s) until now, {1} check-in(s)`;
  public userDetailsHeader: string = `User details`;

  public static get Resources(): UserDetails {
    switch (Globals.currentActiveLanguage) {
      default:
        return new UserDetails();
    }
  }
}
