// RoomBookingsGrid.tsx
import React, { FC } from "react";

import { IRoomBookingsItem } from "../../models/RoomBookings/Interfaces/IRoomBookingsItem";
import General from "../../resources/AdminUI/General";
import Loading from "../Shared/Loading";
import PaginationBar from "../Shared/PaginationBar";
import TanstackTable from "../Shared/TanstackTable";
import ExpandedContent from "./RowExpandedContent";
import { IEntitiesList } from "../../models/Common/IEntitiesList";
import { IPaginationBar } from "../../models/Pagination/IPaginationBar";

interface IProps {
  data: IEntitiesList<IRoomBookingsItem> | undefined;
  isLoading: boolean;
  itemsPerPage: number;
  roomBookingsColumns: any;
  pagination: IPaginationBar;
}

const RoomBookingsGridWrapper: FC<IProps> = ({
  roomBookingsColumns,
  itemsPerPage,
  data,
  isLoading,
  pagination,
}) => {
  if (isLoading || !data) {
    return <Loading />;
  }

  const expandedContent = (row: IRoomBookingsItem) => (
    <ExpandedContent row={row} />
  );

  return (
    <div>
      {data && data?.entities?.length > 0 && (
        <TanstackTable
          data={data?.entities}
          columns={roomBookingsColumns}
          pageCount={Math.ceil(data.numberOfEntities / itemsPerPage)}
          expandedContent={expandedContent}
        />
      )}
      {data && data?.entities?.length === 0 && (
        <div className="no-elements">{General.Resources.noElements}</div>
      )}
      <PaginationBar {...pagination} />
    </div>
  );
};

export default RoomBookingsGridWrapper;
