import { Action, Reducer } from "redux";
import { AppThunkAction, ApplicationState, StateDictionary } from ".";
import IPageIndex from "../models/Pagination/IPageIndex";
import { IUserItem } from "../models/Users/Interfaces/IUserItem";
import UsersService from "../services/UsersService";
import update from "immutability-helper";

export namespace UsersGridStore {
  export interface IState {
    users: IUserItem[];
    numberOfUsers: number;
    loading: boolean;
    filters: string;
    selectedStateId: string;
    searchedTerm: string;
    itemsPerPage: number;
    currentPage: number;
    firstIndexFromPage: number;
    lastIndexFromPage: number;
    pageIndexArray: IPageIndex[];
  }

  const initialState: IState = {
    users: [],
    numberOfUsers: 0,
    loading: false,
    filters: "",
    searchedTerm: "",
    selectedStateId: "1",
    itemsPerPage: 15,
    currentPage: 1,
    firstIndexFromPage: 1,
    lastIndexFromPage: 1,
    pageIndexArray: [],
  };

  export enum Actions {
    Initialize = "USERS_INITIALIZE",
    ToggleLoading = "USERS_TOGGLE_LOADING",
    ReloadData = "USERS_RELOAD_DATA",
    ChangeCurrentPage = "USERS_CHANGE_CURRENT_PAGE",
    RecalculatePageArray = "USERS_RECALCULATE_PAGE_ARRAY",
    RecalculateIndexes = "USERS_RECALCULATE_INDEXES",
    SetFilter = "USERS_SET_FILTER",
    SetSearchedTerm = "USERS_SET_SEARCHED_TERM",
    SetSelectedStateId = "USERS_SET_SELECTED_STATE_ID",
  }

  interface IInitialize {
    componentId: string;
    type: Actions.Initialize;
    itemsPerPage: number;
  }

  interface IToggleLoading {
    componentId: string;
    type: Actions.ToggleLoading;
    loading: boolean;
  }

  interface IReloadData {
    componentId: string;
    type: Actions.ReloadData;
    users: IUserItem[];
    numberOfUsers: number;
  }

  interface IChangeCurrentPage {
    componentId: string;
    type: Actions.ChangeCurrentPage;
    currentPage: number;
  }

  interface IRecalculatePageArray {
    componentId: string;
    type: Actions.RecalculatePageArray;
  }

  interface IRecalculateIndexes {
    componentId: string;
    type: Actions.RecalculateIndexes;
  }

  interface ISetFilter {
    componentId: string;
    type: Actions.SetFilter;
    filter: string;
  }

  interface ISetSearchedTerm {
    componentId: string;
    type: Actions.SetSearchedTerm;
    filterValue: string;
  }

  interface ISetSelectedStateId {
    componentId: string;
    type: Actions.SetSelectedStateId;
    filterValue: string;
  }

  type KnownAction =
    | IInitialize
    | IToggleLoading
    | IReloadData
    | IChangeCurrentPage
    | IRecalculatePageArray
    | IRecalculateIndexes
    | ISetFilter
    | ISetSearchedTerm
    | ISetSelectedStateId;

  export const reloadAll =
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      Object.keys(getState().users).forEach((key) => {
        getActionCreators(key).reload()(dispatch, getState);
      });
    };

  export const getActionCreators = (componentId: string) => ({
    initialize:
      (
        defaultSelectedItemsPerPageOption: number,
        filters?: string
      ): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        var state: IState = getState().users[componentId];
        var searchedTerm =
          state && state.searchedTerm ? state.searchedTerm : "";
        var selectedStateId =
          state && state.selectedStateId ? state.selectedStateId : "";
        dispatch({
          componentId,
          type: Actions.Initialize,
          itemsPerPage: defaultSelectedItemsPerPageOption,
        });
        dispatch({ componentId, type: Actions.ToggleLoading, loading: true });

        dispatch({ componentId, type: Actions.SetFilter, filter: filters });

        var users = await UsersService.getUsers(
          defaultSelectedItemsPerPageOption,
          0,
          filters,
          searchedTerm
        ).then((res) => res.value);
        var usersMapped = UsersService.additionalMapping(users);

        if (usersMapped.entities.length != 0) {
          dispatch({
            componentId,
            type: Actions.ReloadData,
            users: usersMapped.entities,
            numberOfUsers: usersMapped.numberOfEntities,
          });
        } else {
          dispatch({
            componentId,
            type: Actions.ReloadData,
            users: [],
            numberOfUsers: 0,
          });
        }
        dispatch({
          componentId,
          type: Actions.SetSearchedTerm,
          filterValue: searchedTerm,
        });
        dispatch({
          componentId,
          type: Actions.SetSelectedStateId,
          filterValue: selectedStateId,
        });
        dispatch({
          componentId,
          type: Actions.ChangeCurrentPage,
          currentPage: 1,
        });
        dispatch({ componentId, type: Actions.RecalculatePageArray });
        dispatch({ componentId, type: Actions.RecalculateIndexes });
        dispatch({ componentId, type: Actions.ToggleLoading, loading: false });
      },
    changeCurrentPage:
      (currentPage: number): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({
          componentId,
          type: Actions.ChangeCurrentPage,
          currentPage: currentPage,
        });
        dispatch({ componentId, type: Actions.RecalculatePageArray });
        dispatch({ componentId, type: Actions.RecalculateIndexes });
      },
    setFilter:
      (filter: string): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({ componentId, type: Actions.SetFilter, filter: filter });
      },
    reload: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ componentId, type: Actions.ToggleLoading, loading: true });

      const componentState: IState = getState().users[componentId];

      var toTake = componentState.itemsPerPage;
      var toSkip =
        (componentState.currentPage - 1) * componentState.itemsPerPage;
      var filters = componentState.filters;
      let searchedTerm = componentState.searchedTerm;
      var users = await UsersService.getUsers(
        toTake,
        toSkip,
        filters,
        searchedTerm
      ).then((res) => res.value);
      var usersMapped = UsersService.additionalMapping(users);

      if (usersMapped.entities.length != 0) {
        dispatch({
          componentId,
          type: Actions.ReloadData,
          users: usersMapped.entities,
          numberOfUsers: usersMapped.numberOfEntities,
        });
      } else {
        dispatch({
          componentId,
          type: Actions.ReloadData,
          users: [],
          numberOfUsers: 0,
        });
      }

      dispatch({ componentId, type: Actions.ToggleLoading, loading: false });
    },
    setSearchedTerm:
      (searchedTerm: string): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({
          componentId,
          type: Actions.SetSearchedTerm,
          filterValue: searchedTerm,
        });
      },
    setSelectedStateId:
      (selectedStateId: string): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({
          componentId,
          type: Actions.SetSelectedStateId,
          filterValue: selectedStateId,
        });
      },
  });

  export const reducer: Reducer<StateDictionary<IState>> = (
    currentState: StateDictionary<IState>,
    incomingAction: Action
  ) => {
    const action = incomingAction as KnownAction;

    const componentId = action.componentId;
    const componentState = currentState?.[componentId];

    switch (action.type) {
      case Actions.Initialize:
        return { ...currentState, [componentId]: initialState };
      case Actions.ToggleLoading:
        return update(currentState, {
          [componentId]: {
            loading: { $set: action.loading },
          },
        });
      case Actions.ReloadData:
        return update(currentState, {
          [componentId]: {
            users: { $set: action.users },
            numberOfUsers: { $set: action.numberOfUsers },
          },
        });
      case Actions.ChangeCurrentPage:
        return update(currentState, {
          [componentId]: {
            currentPage: { $set: action.currentPage },
          },
        });
      case Actions.RecalculatePageArray:
        var totalPages =
          Math.floor(
            (componentState.numberOfUsers - 1) / componentState.itemsPerPage
          ) + 1;
        var pageArray = [];
        pageArray.push({
          pageNumber: 1,
          isActive: componentState.currentPage === 1,
        } as IPageIndex);
        for (let i = 2; i <= totalPages; i++) {
          pageArray.push({
            pageNumber: i,
            isActive: componentState.currentPage === i,
          });
        }

        return update(currentState, {
          [componentId]: {
            pageIndexArray: { $set: pageArray },
          },
        });
      case Actions.RecalculateIndexes:
        var firstIndex =
          (componentState.currentPage - 1) * componentState.itemsPerPage + 1;
        var totalPages = componentState.pageIndexArray.length;
        var lastIndex: number;

        if (totalPages == componentState.currentPage) {
          lastIndex = Math.min(
            componentState.numberOfUsers,
            componentState.itemsPerPage * totalPages
          );
        } else {
          lastIndex = componentState.currentPage * componentState.itemsPerPage;
        }
        return update(currentState, {
          [componentId]: {
            firstIndexFromPage: { $set: firstIndex },
            lastIndexFromPage: { $set: lastIndex },
          },
        });
      case Actions.SetFilter:
        return update(currentState, {
          [componentId]: {
            filters: { $set: action.filter },
          },
        });
      case Actions.SetSearchedTerm:
        return update(currentState, {
          [componentId]: {
            searchedTerm: { $set: action.filterValue },
          },
        });
      case Actions.SetSelectedStateId:
        return update(currentState, {
          [componentId]: {
            selectedStateId: { $set: action.filterValue },
          },
        });
      default:
        return currentState || {};
    }
  };
}
