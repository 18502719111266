import { IServiceUser } from "./models/Common/IServiceUser";
import { IPublicSession } from "./models/Common/IPublicSession";
import { AppSettings } from "./utils/settings";

/**
 * Contains global isomorphic session.
 */
// TODO: this can probably be safely removed
export default class Globals {

    public static isInitialized: boolean = false;

    private static session: IPublicSession = {};


    public static reset(): void {
        this.isInitialized = false;
        this.session = {};
    }

    public static init(session: IPublicSession): void {
        if (this.isInitialized) {
            throw Error("Globals is already initialized.");
        }
        
        var language = localStorage.getItem("language");

        var publicSession = {};

        if (language) {
            publicSession["language"] = language;
        }

        this.session = publicSession as IPublicSession;
        this.isInitialized = true;
    }

    private static throwIfNotInitialized() {
        if (!this.isInitialized) {
            throw Error("'Globals' is not initialized. You have to call 'Globals.init' before.");
        }
    }

    public static getSession(): IPublicSession {
        this.throwIfNotInitialized();
        return this.session;
    }

    public static setSession(session: IPublicSession) {
        this.throwIfNotInitialized();
        // Update session object by the new data.
        this.session = { ...this.session, ...session };
    }

    public static get currentActiveLanguage(): string {
        let currentSession = this.getSession();
        if (currentSession) {
            let publicSession = currentSession;
            if (publicSession) {
                return publicSession.language ? publicSession.language : AppSettings.LanguageSettings.DefaultLanguage;
            } else {
                throw Error("Globals: public session was not initialized.")
            }
        } else {
            throw Error("Globals: current session was not initialized.")
        }
    }

    public static set currentActiveLanguage(language: string) {
        localStorage.setItem("language", language);
        this.setSession({ ...this.session, language });
    }

    public static get currentActiveCulture() {
        return AppSettings.LanguageSettings.SupportedLanguages
            .find(e => e.Code == Globals.currentActiveLanguage).CultureName
    }
}