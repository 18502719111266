import React from "react";
import Helmet from "react-helmet";
import Clients from "../../resources/AdminUI/Clients/Clients";
import bind from "bind-decorator";
import ClientsTable from "../../components/Clients/Tables/ClientsTable";
import {
  String_Format,
  firstLetterToLowerCase,
  getPromiseFromAction,
} from "../../utils/utils";
import { LookupStore } from "../../store/LookupStore";
import { ClientsGridStore } from "../../store/ClientsGridStore";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { DefaultFilterValues } from "../../enums/Common/DefaultFilterValues";
import { ClientState } from "../../enums/Clients/ClientState";
import ClientStateList from "../../resources/AdminUI/Clients/ClientStateList";
import RegularExpressions from "../../resources/Common/RegularExpressions";
import { nameof } from "../../utils/utils";
import { ApplicationPaths } from "../../enums/Common/ApplicationPaths";
import ClientsService from "../../services/ClientsService";
import { LocalDateTime } from "@js-joda/core";
import { Link } from "react-router-dom";
import { Select } from "antd";
import General from "../../resources/AdminUI/General";
import Loading from "../../components/Shared/Loading";

export class ClientsGridPage extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);

    this.props.getCountriesWithCities(true);
  }

  nameSearchTemplate = `contains(tolower(Name), tolower('{0}'))`;
  idSearchTemplate = ` or Id eq {0}`;
  countryFilterTemplate = `CountryId eq {0}`;
  stateFilterTemplate = `Deactivated eq {0}`;

  @bind
  rebuildODataAndGetData() {
    var filters = [];

    if (this.props.searchTerm) {
      var searchFilter = String_Format(
        this.nameSearchTemplate,
        this.props.searchTerm
      );
      var pattern = RegularExpressions.Resources.onlyNumbers;
      if (RegExp(pattern).test(this.props.searchTerm)) {
        searchFilter = searchFilter.concat(
          String_Format(this.idSearchTemplate, this.props.searchTerm)
        );
      }
      filters.push(`(${searchFilter})`);
    }

    if (this.props.selectedState != ClientState.All.toString()) {
      filters.push(
        `(${String_Format(
          this.stateFilterTemplate,
          !Boolean(Number(this.props.selectedState))
        )})`
      );
    }

    if (
      this.props.selectedCountry &&
      this.props.selectedCountry != DefaultFilterValues.All.toString()
    ) {
      filters.push(
        `(${String_Format(
          this.countryFilterTemplate,
          this.props.selectedCountry
        )})`
      );
    }

    var oDataFilter = filters.join(" and ");
    this.props.setFilter(oDataFilter);
    this.props.changeCurrentPage(1);
    getPromiseFromAction(this.props.reload()).then(() =>
      this.props.changeCurrentPage(1)
    );
  }

  @bind
  onSearchFilterChange(e: React.FormEvent<HTMLInputElement>) {
    let searchTerm = e.currentTarget.value;
    if (this.props.searchTerm != searchTerm) {
      getPromiseFromAction(this.props.setSearchTerm(searchTerm)).then(() => {
        this.rebuildODataAndGetData();
      });
    }
  }

  @bind
  onCountryFilterChange(value: string, option: any) {
    getPromiseFromAction(this.props.setSelectedCountry(value)).then(() => {
      this.rebuildODataAndGetData();
    });
  }

  @bind
  getClientsState() {
    var options = [];

    let keys = Object.keys(ClientState).filter(
      (k) => typeof ClientState[k as any] === "number"
    );

    keys.forEach((key) => {
      let value = ClientState[key as any].toString();
      options.push({
        value: value,
        label: ClientStateList.Resources[firstLetterToLowerCase(key)],
      });
    });
    return options;
  }

  @bind
  onStateFilterChange(value: string, option: any) {
    getPromiseFromAction(this.props.setSelectedState(value)).then(() => {
      this.rebuildODataAndGetData();
    });
  }

  @bind
  async exportClients() {
    await ClientsService.getClientsForExport(this.props.filters).then((res) => {
      var entities = res.value
        ? res.value.entities
            .map((e) =>
              Object.values(e)
                .map((value) =>
                  typeof value === "string" ? value.replaceAll(",", " ") : value
                )
                .join(",")
            )
            .join("\n")
        : "";
      const csvContent =
        Clients.Resources.exportCustomersHeader + "\n" + entities;

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        Clients.Resources.customersLabel +
          "_" +
          LocalDateTime.now() +
          Clients.Resources.csvExtension
      );

      document.body.appendChild(link);

      link.click();
    });
  }

  render() {
    if (!this.props.countries) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    return (
      <div>
        <Helmet title={Clients.Resources.customersLabel}></Helmet>

        <div className="page-top-container">
          <div className="page-title">{Clients.Resources.customersLabel}</div>
          <div className="page-actions">
            <div className="btn-big btn-secondary" onClick={this.exportClients}>
              <p className="btn-secondary-text">
                {Clients.Resources.exportCustomersCsv}
              </p>
            </div>

            <Link
              className="btn-big btn-primary"
              to={ApplicationPaths.AddCustomer}
            >
              <span className="material-symbols-outlined">add_circle</span>
              <p className="btn-primary-text">
                {Clients.Resources.createNewCustomer}
              </p>
            </Link>
          </div>
        </div>

        <div className="filter-bar">
          <div className="filter-search">
            <div className="input-group">
              <span className="icon material-symbols-outlined">search</span>
              <input
                type="text"
                className="form-control"
                placeholder={Clients.Resources.customersGridSearchPlaceholder}
                onChange={this.onSearchFilterChange}
                name={nameof((e) => e.searchTerm)}
                value={this.props.searchTerm}
              />
            </div>
          </div>

          <div className="filter-item">
            <div className="input-group">
              <Select
                options={this.props.countries}
                onChange={this.onCountryFilterChange}
                defaultValue={this.props.countries.filter(
                  (elem) => elem.selected
                )}
                value={this.props.selectedCountry}
                showSearch
                optionFilterProp="label"
              ></Select>
            </div>
          </div>
          <div className="filter-item">
            <div className="input-group">
              <Select
                options={this.getClientsState()}
                onChange={this.onStateFilterChange}
                value={this.props.selectedState}
              ></Select>
            </div>
          </div>
        </div>
        <ClientsTable />
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState) => {
    return {
      countries: state.lookup.countries,
      filters: state.clients.filters,
      searchTerm: state.clients.searchTerm,
      selectedCountry: state.clients.selectedCountry,
      selectedState: state.clients.selectedState,
    };
  },
  {
    getCountriesWithCities: LookupStore.actionCreators.getCountriesWithCities,
    initialize: ClientsGridStore.actionCreators.initialize,
    changeCurrentPage: ClientsGridStore.actionCreators.changeCurrentPage,
    reload: ClientsGridStore.actionCreators.reload,
    setFilter: ClientsGridStore.actionCreators.setFilter,
    setSearchTerm: ClientsGridStore.actionCreators.setSearchTerm,
    setSelectedCountry: ClientsGridStore.actionCreators.setSelectedCountry,
    setSelectedState: ClientsGridStore.actionCreators.setSelectedState,
  }
)(ClientsGridPage as any);
