import React from "react";
import AddOrEditClient from "../../../resources/AdminUI/Clients/AddOrEditClient";
import bind from "bind-decorator";
import { firstLetterToLowerCase, nameof } from "../../../utils/utils";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { ClientsIndustries } from "../../../enums/Clients/ClientsIndustries";
import { IAddOrEditClient } from "../../../models/Clients/Interfaces/IAddOrEditClient";
import { EntityFieldInputType } from "../../../utils/reactUtils";
import { ValidatedComponent } from "../../FormValidations/Base/ValidatedComponent";
import { AddOrEditClientFormValidator } from "../../FormValidations/Validators/AddOrEditClientFormValidator";
import { AddOrEditClientStore } from "../../../store/AddOrEditClientStore";
import { PlanTypes } from "../../../enums/Common/PlanTypes";
import { DatePicker, Select } from "antd";
import { ApplicationPathBuilder } from "../../../enums/Common/ApplicationPaths";
import { IAntdSelectListItem } from "../../../models/Common/IAntdSelectListItem";
import { NavigateFunction } from "react-router";
import { withRouter } from "../../Shared/withRouter";
import TagButton from "../../Shared/TagButton/TagButton";
import { RenewalTypes } from "../../../enums/Clients/RenewalTypes";
import RenewalTypesList from "../../../resources/AdminUI/RenewalTypesList";
import General from "../../../resources/AdminUI/General";
import dayjs from "dayjs";
import Validations from "../../../resources/Common/Validations";
import FormInput from "../../Shared/Fields/FormInput";
import LogoUpload from "../LogoUpload/LogoUpload";

interface IProps {
  countries?: IAntdSelectListItem[];
  currencies?: IAntdSelectListItem[];
  clientDetails?: IAddOrEditClient;
  editingMode?: boolean;
  hasServerSideErrors?: boolean;
  errors?: string;
  saveCompleted?: boolean;
  role?: string;
  languages?: IAntdSelectListItem[];
  navigate?: NavigateFunction;
  setLogo: (image: string) => void;
  setAltLogo: (image: string) => void;
  saveClient?: (clientDetails: IAddOrEditClient) => void;
  reset?: () => void;
}
interface IState {
  clientDetails: IAddOrEditClient;
}
export class AddOrEditClientForm extends ValidatedComponent<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      clientDetails: this.props.clientDetails,
    };
  }

  @bind
  getPlanRecurence() {
    let planRecurenceOptions: IAntdSelectListItem[] = [];

    const keys = Object.keys(RenewalTypes).filter(
      (k) => typeof RenewalTypes[k] === "number"
    );

    keys.forEach((key) => {
      let value = RenewalTypes[key].toString();
      planRecurenceOptions.push({
        value: value,
        label: RenewalTypesList.Resources[firstLetterToLowerCase(key)],
      } as IAntdSelectListItem);
    });

    return planRecurenceOptions;
  }

  @bind
  getIndustiesList() {
    let industriesOptions = [];

    const keys = Object.keys(ClientsIndustries).filter(
      (k) => typeof ClientsIndustries[k] === "number"
    );

    keys.forEach((key) => {
      let value = ClientsIndustries[key].toString();
      industriesOptions.push({
        value: value,
        label: key,
      } as IAntdSelectListItem);
    });

    return industriesOptions;
  }

  @bind
  handleInputChange(name: string, data: any) {
    this.setState({
      clientDetails: {
        ...this.state.clientDetails,
        [name]: data,
      },
    });
  }

  @bind
  handlePriceInputChange(name: string, data: any) {
    this.setState({
      clientDetails: {
        ...this.state.clientDetails,
        [name]: data ? data * 100 : null,
      },
    });
  }

  @bind
  handleSubscriptionMetadataPriceChange(name: string, data: any) {
    this.setState({
      clientDetails: {
        ...this.state.clientDetails,
        subscriptionMetadata: {
          ...this.state.clientDetails.subscriptionMetadata,
          [name]: data ? data * 100 : null,
        },
      },
    });
  }
  @bind
  handleSubscriptionMetadataChange(name: string, data: any) {
    this.setState({
      clientDetails: {
        ...this.state.clientDetails,
        subscriptionMetadata: {
          ...this.state.clientDetails.subscriptionMetadata,
          [name]: data * 1,
        },
      },
    });
  }

  @bind
  handleTextareInputChange(e) {
    this.setState({
      clientDetails: {
        ...this.state.clientDetails,
        [e.target.name]: e.target.value,
      },
    });
  }

  @bind
  handleOnPaymentUnitChange(name: string, data: number) {
    this.setState({
      clientDetails: {
        ...this.state.clientDetails,
        [name]: data,
      },
    });
  }

  @bind
  handleOnDomainListChange(list: string[]) {
    this.setState({
      clientDetails: {
        ...this.state.clientDetails,
        emailDomains: list,
      },
    });
  }

  @bind
  handelIndustryOnChange(name: string, data: any) {
    this.setState({
      clientDetails: {
        ...this.state.clientDetails,
        [name]: ClientsIndustries[data].toString(),
      },
    });
  }

  @bind
  onKeyDownForNumberInput(e: any) {
    if (["e", "E", "+", "-", "."].includes(e.key)) {
      e.preventDefault();
    }
  }

  @bind
  onKeyDownForPriceInput(e: any) {
    if (["e", "E", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  }

  @bind
  handleOnDateChange(value, name) {
    this.setState({
      clientDetails: {
        ...this.state.clientDetails,
        [name]: value?.toISOString(),
      },
    });
  }

  @bind
  saveClient() {
    this.props.reset();
    this.validate(() => {
      this.props.saveClient(this.state.clientDetails);
    });
  }

  @bind
  goToClientDetailsPage() {
    this.props.navigate(
      ApplicationPathBuilder.ViewCustomerDetails(this.props.clientDetails.id)
    );
  }

  componentDidUpdate(
    prevProps: Readonly<IProps & IProps>,
    prevState: Readonly<IState & IState>,
    snapshot?: any
  ): void {
    if (this.props.saveCompleted) {
      this.goToClientDetailsPage();
    }
  }

  render() {
    return (
      <div className="form-container">
        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditClient.Resources.customerNameText}
              <span className="mandatory">*</span>
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={AddOrEditClient.Resources.customerNameText}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              value={this.state.clientDetails.name}
              name={nameof((s) => s.name)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.name))}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditClient.Resources.countryLabel}
              <span className="mandatory">*</span>
            </div>
          </div>
          <div className="line-action">
            <div className="input-group form-select">
              <Select
                options={this.props.countries}
                onChange={(value, option) => {
                  this.handleInputChange(
                    nameof((e) => e.countryId),
                    parseInt(value)
                  );
                }}
                placeholder={AddOrEditClient.Resources.selectCountryPlaceholder}
                value={this.state.clientDetails.countryId?.toString()}
                showSearch
                optionFilterProp="label"
              ></Select>
            </div>
            {this.getFieldErrors(nameof((s) => s.countryId)).map((err) => (
              <span key={err} className="field-validation-error">
                {err}
              </span>
            ))}
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditClient.Resources.emailDomainsLabel}
            </div>
            <div className="description">
              {AddOrEditClient.Resources.emailDomainsDesc}
            </div>
          </div>
          <div className="line-action">
            <TagButton
              buttonText={AddOrEditClient.Resources.addEmailDomainText}
              tags={this.state.clientDetails.emailDomains}
              onListChange={this.handleOnDomainListChange}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditClient.Resources.contactInfoLabel}
              <span className="mandatory">*</span>
            </div>
            <div className="description">
              {AddOrEditClient.Resources.contactInfoDesc}
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={AddOrEditClient.Resources.emailAddressPlaceholder}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              name={nameof((s) => s.contactEmail)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.contactEmail))}
              value={this.state.clientDetails.contactEmail}
            />
            <FormInput
              placeholder={AddOrEditClient.Resources.phoneNumberPlaceholder}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              name={nameof((s) => s.contactPhone)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.contactPhone))}
              onKeyDown={this.onKeyDownForNumberInput}
              value={this.state.clientDetails.contactPhone}
            />
          </div>
        </div>

        {!this.props.editingMode && (
          <div>
            <h2 className="form-divider">
              {AddOrEditClient.Resources.pricingPlanDetailsSectionLabel}
            </h2>

            <div className="form-line">
              <div className="line-info">
                <div className="title">
                  {AddOrEditClient.Resources.typeOfPlanLabel}
                  <span className="mandatory">*</span>
                </div>
                <div className="description">
                  {AddOrEditClient.Resources.typeOfPlanDesc}
                </div>
              </div>
              <div className="line-action">
                <div className="add-client-type">
                  {PlanTypes[PlanTypes.ENTERPRISE]}
                </div>
              </div>
            </div>

            <div className="form-line">
              <div className="line-info">
                <div className="title">
                  {AddOrEditClient.Resources.currencyLabel}
                  <span className="mandatory">*</span>
                </div>
              </div>
              <div className="line-action">
                <div className="input-group form-select">
                  <Select
                    options={this.props.currencies}
                    onChange={(value, option) => {
                      this.handleInputChange(
                        nameof((e) => e.currency),
                        value
                      );
                    }}
                    value={this.state.clientDetails.currency}
                    placeholder={"Select currency"}
                    showSearch
                    optionFilterProp="label"
                  ></Select>
                </div>
                {this.getFieldErrors(nameof((s) => s.currency)).map((err) => (
                  <span key={err} className="field-validation-error">
                    {err}
                  </span>
                ))}
              </div>
            </div>

            <div className="form-line">
              <div className="line-info">
                <div className="title">
                  {AddOrEditClient.Resources.pricingPlanLabel}
                  <span className="mandatory">*</span>
                </div>
              </div>
              <div className="line-action">
                <FormInput
                  placeholder={AddOrEditClient.Resources.pricingPlanLabel}
                  inputType={EntityFieldInputType.Text}
                  className={"form-control"}
                  value={this.state.clientDetails.subscriptionName}
                  name={nameof((s) => s.subscriptionName)}
                  onChange={this.handleInputChange}
                  errors={this.getFieldErrors(
                    nameof((s) => s.subscriptionName)
                  )}
                />
              </div>
            </div>

            <div className="form-line">
              <div className="line-info">
                <div className="title">
                  {AddOrEditClient.Resources.pricingPlanAccessFee}
                  <span className="mandatory">*</span>
                </div>
              </div>
              <div className="line-action">
                <FormInput
                  placeholder={AddOrEditClient.Resources.pricingPlanAccessFee}
                  inputType={EntityFieldInputType.Number}
                  className={"form-control"}
                  value={
                    this.state.clientDetails.subscriptionMetadata
                      .platformAccessFee == null
                      ? null
                      : this.state.clientDetails.subscriptionMetadata
                          .platformAccessFee / 100
                  }
                  name={nameof((s) => s.platformAccessFee)}
                  onChange={this.handleSubscriptionMetadataPriceChange}
                  errors={this.getFieldErrors(
                    nameof((s) => s.platformAccessFee)
                  )}
                  onKeyDown={this.onKeyDownForPriceInput}
                />
              </div>
            </div>

            <div className="form-line">
              <div className="line-info">
                <div className="title">
                  {AddOrEditClient.Resources.pricingPlanPrepaid}
                  <span className="mandatory">*</span>
                </div>
              </div>
              <div className="line-action">
                <FormInput
                  placeholder={AddOrEditClient.Resources.pricingPlanPrepaid}
                  inputType={EntityFieldInputType.Number}
                  className={"form-control"}
                  value={
                    this.state.clientDetails.subscriptionMetadata.prepaid ==
                    null
                      ? null
                      : this.state.clientDetails.subscriptionMetadata.prepaid /
                        100
                  }
                  name={nameof((s) => s.prepaid)}
                  onChange={this.handleSubscriptionMetadataPriceChange}
                  errors={this.getFieldErrors(nameof((s) => s.prepaid))}
                  onKeyDown={this.onKeyDownForPriceInput}
                />
              </div>
            </div>

            <div className="form-line">
              <div className="line-info">
                <div className="title">
                  {AddOrEditClient.Resources.pricingPlanMininumSpend}
                  <span className="mandatory">*</span>
                </div>
              </div>
              <div className="line-action">
                <FormInput
                  placeholder={
                    AddOrEditClient.Resources.pricingPlanMininumSpend
                  }
                  inputType={EntityFieldInputType.Number}
                  className={"form-control"}
                  value={
                    this.state.clientDetails.subscriptionMetadata
                      .minimumSpend == null
                      ? null
                      : this.state.clientDetails.subscriptionMetadata
                          .minimumSpend / 100
                  }
                  name={nameof((s) => s.minimumSpend)}
                  onChange={this.handleSubscriptionMetadataPriceChange}
                  errors={this.getFieldErrors(nameof((s) => s.minimumSpend))}
                  onKeyDown={this.onKeyDownForPriceInput}
                />
              </div>
            </div>

            <div className="form-line">
              <div className="line-info">
                <div className="title">
                  {AddOrEditClient.Resources.pricingPlanVAT}
                  <span className="mandatory">*</span>
                </div>
              </div>
              <div className="line-action">
                <FormInput
                  placeholder={AddOrEditClient.Resources.pricingPlanVAT}
                  inputType={EntityFieldInputType.Number}
                  className={"form-control"}
                  value={this.state.clientDetails.subscriptionMetadata.vat}
                  name={nameof((s) => s.vat)}
                  onChange={this.handleSubscriptionMetadataChange}
                  errors={this.getFieldErrors(nameof((s) => s.vat))}
                  onKeyDown={this.onKeyDownForPriceInput}
                />
              </div>
            </div>

            <div className="form-line">
              <div className="line-info">
                <div className="title">
                  {AddOrEditClient.Resources.pricingPlanStartDateLabel}
                  <span className="mandatory">*</span>
                </div>
              </div>
              <div className="line-action">
                <DatePicker
                  showTime={{ format: "HH:mm" }}
                  format="YYYY-MM-DD HH:mm"
                  value={
                    this.state.clientDetails.subscriptionStartDate
                      ? dayjs(this.state.clientDetails.subscriptionStartDate)
                      : null
                  }
                  onChange={(value, name) =>
                    this.handleOnDateChange(
                      value,
                      nameof((s) => s.subscriptionStartDate)
                    )
                  }
                />
                {this.getFieldErrors(
                  nameof((s) => s.subscriptionStartDate)
                ).map((err) => (
                  <span key={err} className="field-validation-error">
                    {err}
                  </span>
                ))}
              </div>
            </div>

            <h2 className="form-divider">
              {AddOrEditClient.Resources.paymentRenewal}
            </h2>

            <div className="form-line">
              <div className="line-info">
                <div className="title">
                  {AddOrEditClient.Resources.paymentRenewalTypeText}
                  <span className="mandatory">*</span> &{" "}
                  {General.Resources.value}
                  <span className="mandatory">*</span>
                </div>
              </div>

              <div className="line-action inline-inputs">
                <div className="input-group">
                  <span className="input-group-text">
                    {General.Resources.every}
                  </span>
                  <input
                    type={EntityFieldInputType.Number}
                    placeholder={
                      AddOrEditClient.Resources.paymentRenewalValueText
                    }
                    className={`form-control`}
                    onChange={(e) =>
                      this.handleInputChange(
                        e.currentTarget.name,
                        e.currentTarget.value
                      )
                    }
                    name={nameof((s) => s.paymentRecurrenceValue)}
                    onKeyDown={this.onKeyDownForNumberInput}
                    value={
                      this.state.clientDetails.paymentRecurrenceValue || ""
                    }
                  />
                </div>

                <div className="input-group form-select">
                  <Select
                    options={this.getPlanRecurence()}
                    onChange={(value: any, option: any) => {
                      this.handleOnPaymentUnitChange(
                        nameof((s) => s.paymentRecurrenceUnit),
                        value
                      );
                    }}
                    value={this.state.clientDetails.paymentRecurrenceUnit?.toString()}
                    placeholder={
                      AddOrEditClient.Resources.selectRenewalTypePlaceholder
                    }
                  ></Select>
                </div>

                <div className="break" />

                {this.getFieldErrors(
                  nameof((s) => s.paymentRecurrenceValue)
                ).map((err) => (
                  <span key={err} className="field-validation-error">
                    {err}
                  </span>
                ))}

                {this.getFieldErrors(
                  nameof((s) => s.paymentRecurrenceUnit)
                ).map((err) => (
                  <span key={err} className="field-validation-error">
                    {err}
                  </span>
                ))}
              </div>
            </div>
          </div>
        )}

        <h2 className="form-divider">
          {AddOrEditClient.Resources.plurialAccountManagerLabel}
        </h2>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditClient.Resources.plurialAccountManagerLabel}
              <span className="mandatory">*</span>
            </div>
            <div className="description">
              {AddOrEditClient.Resources.pluriaAccountManagerDesc}
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={AddOrEditClient.Resources.emailAddressPlaceholder}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              name={nameof((s) => s.accountManagerEmail)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.accountManagerEmail))}
              value={this.state.clientDetails.accountManagerEmail}
            />
          </div>
        </div>

        <h2 className="form-divider">
          {AddOrEditClient.Resources.pluriaSalesPersonLabel}
        </h2>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditClient.Resources.pluriaSalesPersonLabel}
              <span className="mandatory">*</span>
            </div>
            <div className="description">
              {AddOrEditClient.Resources.pluriaSalesPersonDesc}
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={AddOrEditClient.Resources.emailAddressPlaceholder}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              name={nameof((s) => s.salesPersonEmail)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.salesPersonEmail))}
              value={this.state.clientDetails.salesPersonEmail}
            />
          </div>
        </div>
        <h2 className="form-divider">
          {AddOrEditClient.Resources.automatedEmails}
        </h2>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditClient.Resources.emailLanguage}
              <span className="mandatory">*</span>
            </div>
            <div className="description">
              {
                AddOrEditClient.Resources
                  .chooseInWhichLanguageTheEmailsShouldBeSent
              }
            </div>
          </div>
          <div className="line-action">
            <div className="input-group form-select">
              <Select
                options={this.props.languages}
                onChange={(value: string, option: any) => {
                  this.handleInputChange(
                    nameof((s) => s.languageId),
                    value
                  );
                }}
                value={
                  this.state.clientDetails?.languageId
                    ? this.state.clientDetails?.languageId.toString()
                    : null
                }
                placeholder={General.Resources.select}
              ></Select>
            </div>
            {this.getFieldErrors(nameof((s) => s.languageId)).map((err) => (
              <span key={err} className="field-validation-error">
                {err}
              </span>
            ))}
          </div>
        </div>

        <h2 className="form-divider">
          {AddOrEditClient.Resources.metadataSectionLabel}
        </h2>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditClient.Resources.legalCustomerNameLabel}
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={AddOrEditClient.Resources.legalCustomerNameLabel}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              name={nameof((s) => s.legalClientName)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.legalClientName))}
              value={this.state.clientDetails.legalClientName}
            />
          </div>
        </div>

        {this.props.role === "superadmin" && (
          <>
            <div className="form-line">
              <div className="line-info">
                <div className="title">{AddOrEditClient.Resources.logo}</div>
                <div className="description">
                  {AddOrEditClient.Resources.acceptedFormatLogo}
                </div>
              </div>

              <div className="line-action">
                {this.getFieldErrors(nameof((s) => s.images)).map((err) => (
                  <span key={err} className="field-validation-error">
                    {err}
                  </span>
                ))}
                <LogoUpload
                  image={this.props.clientDetails.logo}
                  onSaveCallback={(newImage) => {
                    this.props.setLogo(newImage);
                  }}
                />
              </div>
            </div>

            <div className="form-line">
              <div className="line-info">
                <div className="title">{AddOrEditClient.Resources.altLogo}</div>
                <div className="description">
                  {AddOrEditClient.Resources.altLogo}
                </div>
              </div>

              <div className="line-action">
                {this.getFieldErrors(nameof((s) => s.images)).map((err) => (
                  <span key={err} className="field-validation-error">
                    {err}
                  </span>
                ))}
                <LogoUpload
                  image={this.props.clientDetails.altLogo}
                  onSaveCallback={(newImage) => {
                    this.props.setAltLogo(newImage);
                  }}
                />
              </div>
            </div>
          </>
        )}
        <div className="form-line">
          <div className="line-info">
            <div className="title">{AddOrEditClient.Resources.cuiLabel}</div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={AddOrEditClient.Resources.cuiLabel}
              inputType={EntityFieldInputType.Text}
              className={"form-control"}
              name={nameof((s) => s.cui)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.cui))}
              value={this.state.clientDetails.cui}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditClient.Resources.industryLabel}
            </div>
          </div>
          <div className="line-action">
            <div className="input-group form-select">
              <Select
                options={this.getIndustiesList()}
                onChange={(value: any, option: any) => {
                  this.handelIndustryOnChange(
                    nameof((s) => s.industry),
                    value
                  );
                }}
                value={this.state.clientDetails.industry?.toString()}
                placeholder={
                  AddOrEditClient.Resources.selectAnIndustryPlaceholder
                }
              ></Select>
            </div>
            {this.getFieldErrors(nameof((s) => s.industry)).map((err) => (
              <span key={err} className="field-validation-error">
                {err}
              </span>
            ))}
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">
              {AddOrEditClient.Resources.noEmployeesLabel}
            </div>
          </div>
          <div className="line-action">
            <FormInput
              placeholder={AddOrEditClient.Resources.noEmployeesLabel}
              inputType={EntityFieldInputType.Number}
              className={"form-control"}
              name={nameof((s) => s.noEmployees)}
              onChange={this.handleInputChange}
              errors={this.getFieldErrors(nameof((s) => s.noEmployees))}
              value={this.state.clientDetails.noEmployees}
              onKeyDown={this.onKeyDownForNumberInput}
            />
          </div>
        </div>

        <div className="form-line">
          <div className="line-info">
            <div className="title">{AddOrEditClient.Resources.notesLabel}</div>
            <div className="description">
              {AddOrEditClient.Resources.notesDesc}
            </div>
          </div>
          <div className="line-action">
            <textarea
              className="form-control"
              placeholder={AddOrEditClient.Resources.notesLabel}
              name={nameof((s) => s.details)}
              value={this.state.clientDetails.details || ""}
              onChange={(e) => this.handleTextareInputChange(e)}
            />
          </div>
        </div>

        {this.props.hasServerSideErrors ? (
          <div>
            <span className="field-validation-error" style={{ color: "red" }}>
              {this.props.errors}
            </span>
          </div>
        ) : (
          <></>
        )}

        {this.state.errors && Object.keys(this.state.errors).length ? (
          <div>
            <div>
              <span className="field-validation-error" style={{ color: "red" }}>
                {Validations.Resources.notAllFieldsAreValid}
              </span>
            </div>
            {Object.values(this.state.errors).map((errorValues) => {
              return (errorValues as any).map((err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              ));
            })}
          </div>
        ) : (
          <></>
        )}

        <div className="form-line form-bottom-actions position-sticky">
          <button className="btn-big btn-primary" onClick={this.saveClient}>
            {this.props.editingMode
              ? AddOrEditClient.Resources.saveChangesOnEditText
              : AddOrEditClient.Resources.addCustomerText}
          </button>
        </div>
      </div>
    );
  }
}
export default withRouter(
  connect(
    (state: ApplicationState, ownProps: IProps) => {
      return {
        validator: AddOrEditClientFormValidator,
        countries: state.lookup.allCountries,
        currencies: state.lookup.currencies,
        clientDetails: state.addOrEditClient.clientDetails,
        hasServerSideErrors: state.addOrEditClient.hasServerSideErrors,
        errors: state.addOrEditClient.errors,
        saveCompleted: state.addOrEditClient.saveCompleted,
        role: state.login.role,
        languages: (state.lookup.languages ?? []).map((op) => {
          op.selected =
            op.value == state?.addOrEditClient?.clientDetails?.languageId;
          return op;
        }),
        ownProps,
      };
    },
    {
      saveClient: AddOrEditClientStore.actionCreators.saveClient,
      setLogo: AddOrEditClientStore.actionCreators.setLogo,
      setAltLogo: AddOrEditClientStore.actionCreators.setAltLogo,
      reset: AddOrEditClientStore.actionCreators.resetError,
    }
  )(AddOrEditClientForm as any)
);
