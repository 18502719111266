import Result from "../models/Common/Result";
import { SelectListItem } from "../models/Common/SelectListitem";
import { ApplicationPaths } from "../enums/Common/ApplicationPaths";
import { PayloadTypes } from "../enums/Common/PayloadTypes";
import { NotificationStore } from "../store/NotificationStore";
import { LoginStore } from "../store/LoginStore";
import Globals from "../Globals";
import { notification } from "antd";
import { store } from "..";

export interface IRequestOptions {
  url: string;
  data?: any;
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  onError?: (error: any) => any;
  responseType?: "ANY" | "STRING" | "BLOB" | "ARRAYBUFFER" | "FORMDATA";
}

export interface ISendFormDataOptions {
  url: string;
  data: FormData | any;
  method: "POST" | "PUT" | "PATCH" | "DELETE";
  responseType?: "ANY" | "STRING" | "BLOB" | "ARRAYBUFFER" | "FORMDATA";
}

let refreshTokenPromise: Promise<any> | null = null;

export abstract class ServiceBase {
  public static async requestJsonFetch<T>(
    opts: IRequestOptions
  ): Promise<Result<T>> {
    const processQuery = (url: string, data: any): string => {
      if (data) {
        var args = new URLSearchParams(data);
        return `${url}?${args.toString()}`;
      }
      return url;
    };

    return this.executeAuthenticatedRequest(processQuery(opts.url, opts.data), {
      method: opts.method,
    })
      .then(async (result) => {
        if (!result.ok) {
          return await this.handleError(result);
        } else {
          return this.getResultByResponseType(result, opts);
        }
      })
      .catch((error) => {
        if (opts.onError) {
          try {
            return opts.onError(error);
          } catch {
            this.handleError(error);
          }
        } else {
          this.handleError(error);
        }
      });
  }

  public static async sendData<T>(
    opts: ISendFormDataOptions,
    payloadType?: PayloadTypes
  ): Promise<Result<T>> {
    const header: HeadersInit = {};

    switch (payloadType) {
      case PayloadTypes.Json:
        header["Content-Type"] = "application/json";
        header["Accept"] = "application/json";
        break;
      case PayloadTypes.MultipartFormData:
        // header['Content-Type'] = "multipart/form-data ";
        break;
      case PayloadTypes.FormData:
      default:
        break;
    }

    return this.executeAuthenticatedRequest(opts.url, {
      method: opts.method,
      headers: header,
      body: opts.data,
    })
      .then(async (result) => {
        if (!result.ok) {
          return await this.handleError(result);
        } else {
          return this.getResultByResponseType(result, opts);
        }
      })
      .catch((error) => {
        this.handleError(error);
      }) as Promise<Result<T>>;
  }

  public static async executeAuthenticatedRequest<T>(
    url: string,
    options?: RequestInit
  ): Promise<Response> {
    let initPromise: Promise<void> = refreshTokenPromise || Promise.resolve();
    const isTokenExpired =
      store.getState().login.expiresAt * 1000 < new Date().getTime();
    if (isTokenExpired && refreshTokenPromise == null) {
      initPromise = LoginStore.boundActions.refreshToken().then(() => {
        refreshTokenPromise = null;
      });
      refreshTokenPromise = initPromise;
    }

    return initPromise.then(() => {
      options = options || {};
      options.headers = options.headers || {};
      options.headers["Accept-Language"] = Globals.currentActiveCulture;
      options.headers["Authorization"] =
        "Bearer " + store.getState().login.accessToken;

      return fetch(url, options);
    });
  }

  public static async getResultByResponseType(
    response: Response,
    opts: IRequestOptions
  ): Promise<Result<any>> {
    switch (opts.responseType) {
      case "STRING":
        return new Result(await response.text());
      case "ARRAYBUFFER":
        return new Result(await response.arrayBuffer());
      case "BLOB":
        return new Result(await response.blob());
      case "FORMDATA":
        return new Result(await response.formData());
      case "ANY":
      default:
        return new Result(await response.json());
    }
  }

  private static async handleError(result: any) {
    switch (result.status) {
      case 403:
        window.location.href = ApplicationPaths.Unauthorized;
        return;
      case 401:
        window.location.href = ApplicationPaths.Unauthorized;
        // LoginStore.boundActions.loginRequest(location.pathname + location.search);
        return;
      case 400:
      case 409:
      case 422:
      case 500:
        NotificationStore.boundActions.showErrorNotification();
        var response = await result.json();

        // TODO: Temporary solution for error message
        notification.error({
          message: "Server Error",
          description: JSON.stringify(response.errors),
        });

        return new Result(null, response);
      case 404:
        return new Result({});
      default:
        // TODO: Temporary solution for error message
        notification.error({
          message: "General Error",
          description: String(result),
        });

        if (!result?.status) {
          throw new Error(
            result.ToString ? result.ToString() : JSON.stringify(result)
          );
        } else {
          throw result;
        }
    }
  }
}
