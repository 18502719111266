import { Link } from "react-router-dom";
import { PlanTypes } from "../../enums/Common/PlanTypes";
import { UserMarketingPreferencesTypes } from "../../enums/Users/UserMarketingPreferencesTypes";
import { UserStates } from "../../enums/Users/UserStates";
import UsersGrid from "../../resources/AdminUI/Users/UsersGrid";
import { BaseEntity } from "../../utils/reactUtils";
import { String_Format, firstLetterToLowerCase, getArrayAsCommaSeparatedString } from "../../utils/utils";
import { IUserItem } from "./Interfaces/IUserItem";
import { ApplicationPathBuilder } from "../../enums/Common/ApplicationPaths";

export class UserItem extends BaseEntity implements IUserItem {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    status: number;
    deactivated: boolean;
    createdAt: string;
    onBoardingComplete: boolean;
    companyId: number;
    companyName: number;
    teamId: number;
    teamName: string;
    isPhonePreferredChannel: boolean;
    isSmsPreferredChannel: boolean;
    isEmailPreferredChannel: boolean;
    isAccountOwner: boolean;
    isBookingManager: boolean;
    currentMonthCheckins: number;
    totalCheckins: number;
    marketingPreferences: string[]

    constructor(user: IUserItem) {
        super();
        this.additionalConstructor(this, user);

        this.marketingPreferences = [];
        this.marketingPreferences.push(
            this.isPhonePreferredChannel && this.isPhonePreferredChannel.valueOf() ? UserMarketingPreferencesTypes[UserMarketingPreferencesTypes.Phone] :  null,
            this.isSmsPreferredChannel && this.isSmsPreferredChannel.valueOf() ? UserMarketingPreferencesTypes[UserMarketingPreferencesTypes.SMS] : null,
            this.isEmailPreferredChannel && this.isEmailPreferredChannel.valueOf() ? UserMarketingPreferencesTypes[UserMarketingPreferencesTypes.Email] : null
        )

        this.ForProperty(p => "firstName")
            .SetDisplayName(UsersGrid.Resources.nameGridHeader)
            .Show(_self => <div className="column-large">
                <p className="table-item-name">{this.firstName} {this.lastName}</p>
                <p><span className={`table-item-status ${firstLetterToLowerCase(UserStates[this.status])}`}>{[(UserStates[this.status])]} </span> | <span className="table-item-id">{String_Format(UsersGrid.Resources.idLabel, this.id)}</span></p>
            </div>);

        this.ForProperty(p => "email")
            .SetDisplayName(UsersGrid.Resources.contactInfoGridHeader)
            .Show(_self =>
                <div className="column-xlarge">
                    <div className="table-item-icon-info">
                        <span className="material-symbols-outlined">phone</span>
                        <p>{this.phone || '-'}</p>
                    </div>
                    <div className="table-item-icon-info">
                        <span className="material-symbols-outlined">email</span>
                        <p>{this.email || '-'}</p>
                    </div>
                 </div>
           );

        this.ForProperty(p => "companyName")
            .SetDisplayName(UsersGrid.Resources.planGridHeader)
            .Show(_self => <div className="column-large">
                <p className="table-item-highlight-row plan-name">{PlanTypes[PlanTypes.ENTERPRISE]}</p>
                <Link className="table-item-link" to={ApplicationPathBuilder.ViewCustomerDetails(this.companyId)}>{this.companyName} | {String_Format(UsersGrid.Resources.idLabel, this.companyId)}</Link>
            </div>);

        this.ForProperty(p => "isAccountOwner")
            .SetDisplayName(UsersGrid.Resources.roleGridHeader)
            .Show(_self => <div className="column-medium">
                <p>{this.isAccountOwner.valueOf() ? UsersGrid.Resources.accountOwnerLabel : ''}</p>
                <p>{this.isBookingManager.valueOf() ? UsersGrid.Resources.bookingManagerLabel : ''}</p>
                <p>
                    {this.isAccountOwner.valueOf() == false
                        && this.isBookingManager.valueOf() == false
                        && UsersGrid.Resources.noRoleLabel}
                </p>
            </div>);

        this.ForProperty(p => "isPhonePreferredChannel")
            .SetDisplayName(UsersGrid.Resources.marketingPreferencesGridHeader)
            .Show(_self => <div className="column-small">
                {
                    getArrayAsCommaSeparatedString(this.marketingPreferences)
                        || UsersGrid.Resources.noCommunicationChannels
                }
            </div>);

        this.ForProperty(p => "currentMonthCheckins")
            .SetDisplayName(UsersGrid.Resources.activityGridHeader)
            .Show(_self => <div className="column-medium">
                {
                    this.totalCheckins == 0 ?
                        <div>
                            {UsersGrid.Resources.noCheckinsYet}
                        </div>
                        : <div>
                            {
                                this.currentMonthCheckins == 0 ?
                                    <div>
                                        <p>{UsersGrid.Resources.noCheckinsThisMonth}</p>
                                        <p>{String_Format(UsersGrid.Resources.totalCheckins, this.totalCheckins)}</p>
                                    </div>
                                    : <div>
                                        <p>{String_Format(UsersGrid.Resources.checkinsThisMonth, this.currentMonthCheckins)}</p>
                                        <p>{String_Format(UsersGrid.Resources.totalCheckins, this.totalCheckins)}</p>
                                    </div>
                            }
                        </div>
                }
            </div>);
    }
}