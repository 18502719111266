import React from "react";
import Workspaces from "../../../resources/AdminUI/Spaces/Workspaces";
import General from "../../../resources/AdminUI/General";
import LocalizationLanguages from "../../../resources/AdminUI/LocalizationLanguages";

interface IProps {
    wifiName: string,
    wifiPassword: string,
    accessInstructionsEn: string,
    accessInstructionsEs: string,
    accessInstructionsPt: string,
}

interface IState {

}

export class WIFISetupAndAccess extends React.Component<IProps, IState>{

    constructor(props) {
        super(props);
    }

    render() {

        return <div>
            <p className="header first">{Workspaces.Resources.wifiName}</p>
            <p>{this.props.wifiName ? this.props.wifiName : General.Resources.notCompletedYet}</p>
            <p className="header">{Workspaces.Resources.wifiPassword}</p>
            <p>{this.props.wifiPassword ? this.props.wifiPassword : General.Resources.notCompletedYet}</p>
            <p className="header">{Workspaces.Resources.buildingAccessInformation}</p>
            <p className="header">{LocalizationLanguages.Resources.english}</p>
            <p>{this.props.accessInstructionsEn ? this.props.accessInstructionsEn : General.Resources.notCompletedYet}</p>
            <p className="header">{LocalizationLanguages.Resources.spanish}</p>
            <p >{this.props.accessInstructionsEs ? this.props.accessInstructionsEs : General.Resources.notCompletedYet}</p>
            <p className="header">{LocalizationLanguages.Resources.portuguese}</p>
            <p>{this.props.accessInstructionsPt ? this.props.accessInstructionsPt : General.Resources.notCompletedYet}</p>
        </div>
    }
}