import React from "react";
import bind from "bind-decorator";
import { ITeam } from "../../../models/Teams/Interfaces/ITeam";
import { String_Format, firstLetterToLowerCase, getPromiseFromAction, getServerSideErrors } from "../../../utils/utils";
import { SpaceCategories } from "../../../enums/Spaces/SpaceCategories";
import SpaceCategoryList from "../../../resources/AdminUI/Spaces/SpaceCategoryList";
import { DaysOfWeek } from "../../../enums/Common/DaysOfWeek";
import WeekDays from "../../../resources/AdminUI/WeekDays";
import AddOrEditTeam from "../../../resources/AdminUI/Teams/AddOrEditTeam";
import UsersTable from "../../Users/Tables/UsersTable";
import { BookingRestrictionTypes } from "../../../enums/Teams/BookingRestrictionTypes";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { TeamsStore } from "../../../store/TeamsStore";
import { BenefitTypes } from "../../../enums/Benefits/BenefitTypes";
import BenefitTypeList from "../../../resources/AdminUI/Benefits/BenefitTypeList";
import General from "../../../resources/AdminUI/General";
import { Modal } from "antd";
import AddOrEditUserForm from "../../Users/Forms/AddOrEditUserForm";
import { ExclamationCircleOutlined } from "@ant-design/icons"
import Benefits from "../../../resources/AdminUI/Benefits/Benefits";
import { withRouter } from "../../Shared/withRouter";
import { NavigateFunction } from "react-router";
import { ApplicationPathBuilder } from "../../../enums/Common/ApplicationPaths";
import ModalDialog from "../../Shared/ModalDialog";
import { formatDate } from "../../../utils/date";
import Validations from "../../../resources/Common/Validations";
import ClientsService from "../../../services/ClientsService";
import ErrorMessages from "../../../resources/Common/ErrorMessages";
import ClientDetails from "../../../resources/AdminUI/Clients/ClientDetails";
import { UsersGridStore } from "../../../store/UsersGridStore";
import { bindActionCreators } from "redux";

const { confirm } = Modal;
interface IProps {
    team: ITeam;
    hasServerSideErrors?: boolean;
    serverSideErrors?: string;

    navigate?: NavigateFunction;

    editTeam: (team: ITeam) => any;
    deleteTeamBenefit?: (benefitId: number, teamId: number) => any;
    reloadUsersTable?: () => void;
}
interface IState {
    isAddUserModalOpen: boolean;
    isImportUsersModalOpen: boolean;
    inputFile: any;
}

class TeamsAndUsersTabComponent extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isAddUserModalOpen: false,
            isImportUsersModalOpen: false,
            inputFile: null
        }
    }

    tableFilterTemplate = `CompanyId eq {0} and TeamId eq {1}`;
    allowedExtensions = ["csv"];

    @bind
    showAddUserModal() {
        this.setState({ isAddUserModalOpen: true });
    }

    @bind
    hideAddUserModal() {
        this.setState({ isAddUserModalOpen: false });
    }

    @bind
    showImportUsersModal() {
        this.setState({ isImportUsersModalOpen: true });
    }

    @bind
    hideImportUsersModal() {
        this.setState({ isImportUsersModalOpen: false/*, inputFile: null*/ });
    }


    @bind
    getRestrictedSpaceTypes() {
        var days = [];
        this.props.team.spaceTypeIds.forEach(e => {
            var type = SpaceCategories[e]
            var typeName = SpaceCategoryList.Resources[firstLetterToLowerCase(type)];
            days.push(typeName);
        })

        return days.join(', ');
    }

    @bind
    getRestrictedWeekDays() {
        var days = [];
        this.props.team.weekDayIds.forEach(e => {
            var type = DaysOfWeek[e]
            var typeName = WeekDays.Resources[firstLetterToLowerCase(type)];
            days.push(typeName);
        })

        return days.join(', ');
    }

    @bind
    removeBenefit(id: number) {
        confirm({
            title: General.Resources.areYouSureModalLabel,
            icon: <ExclamationCircleOutlined />,
            content: <div>
                <p>{Benefits.Resources.deleteServiceConfirmation}</p>
            </div>,
            okText: Benefits.Resources.deleteService,
            okType: "danger",
            cancelText: General.Resources.cancelButtonLabel,
            onOk: () => {
                this.props.deleteTeamBenefit(id, this.props.team.id);
            },
            onCancel() {
            },
        });

    }

    @bind
    getBenefitTypeName(benefitType) {
        var type = BenefitTypes[benefitType];
        var typeName = BenefitTypeList.Resources[firstLetterToLowerCase(type)];

        return typeName;
    }


    @bind
    handleImportUsersFromFile(e) {
        if (e.target.files.length) {
            const inputFile = e.target.files[0];
            this.setState({ inputFile: inputFile });
        }
    }

    @bind
    importUsers() {
        const fileExtension = this.state.inputFile?.type.split("/")[1];
        if (!this.allowedExtensions.includes(fileExtension)) {
            Modal.error({
                title: Validations.Resources.pleaseInputCsvFile,
            });
            return;
        }

        getPromiseFromAction(ClientsService.importUsers(this.state.inputFile, this.props.team.companyId, this.props.team.id) as any).then(
            (res) => {
                var response = res as any;
                if (!response) {
                    Modal.error({
                        title: ErrorMessages.Resources.generalErrorMessage,
                    });
                } else {
                    var errors = getServerSideErrors(response);
                    var hasServerSideErrors = !errors ? false : true;
                    if (hasServerSideErrors) {
                        Modal.error({
                            title: errors,
                        });
                    } else if(response.value.length > 0){
                        Modal.error({
                            title: String_Format(ErrorMessages.Resources.errorForFollowingEmails, response.value.join(", ")),
                        });
                    }
                }
                
                this.props.reloadUsersTable();
                this.hideImportUsersModal();
            });

    }

    render() {
        return <div>
            <div className="teams-section-info-container">

                <div className="info-section">
                    <div className="top-section">
                        <p className="section-title">{AddOrEditTeam.Resources.details}</p>
                        <div className="btn-small-light" onClick={/*this.showEditTeamModal}*/ () => this.props.navigate(ApplicationPathBuilder.EditTeam(this.props.team.id))}>
                            <span className="material-symbols-outlined">edit</span>
                        </div>
                    </div>

                    <p className="team-name">{this.props.team.name != null && this.props.team.name != '' ? this.props.team.name : General.Resources.defaultTeamName}</p>
                    <p className="team-description">{this.props.team.description}</p>
                    <p className="team-created-at">{formatDate(this.props.team.createdAt)}</p>
                </div>

                <div className="info-section">

                    <div className="top-section">
                        <p className="section-title">{AddOrEditTeam.Resources.assignedServices}</p>
                    </div>

                    {
                        this.props.team.benefits.length > 0 ?
                            this.props.team.benefits.map(e => {
                                return <div key={e.id}>
                                    <div className="benefit-item">
                                        <div onClick={() => this.removeBenefit(e.id)}>
                                            <span className="material-symbols-outlined">close</span>
                                        </div>
                                        <div>{String_Format(AddOrEditTeam.Resources.nameAndId, this.getBenefitTypeName(e.featureId), e.id)}</div>
                                    </div>
                                </div>
                            })
                            : <div>{AddOrEditTeam.Resources.noServicesAssigned}</div>
                    }
                    <div>
                        {this.props.hasServerSideErrors ? <span className="field-validation-error" style={{ color: "red" }}>{this.props.serverSideErrors}</span>
                            : <></>}
                    </div>
                </div>

                <div className="info-section">

                    <div className="top-section">
                        <p className="section-title">{AddOrEditTeam.Resources.restrictions}</p>
                        <div className="btn-small-light" onClick={/*this.showEditTeamModal*/ () => this.props.navigate(ApplicationPathBuilder.EditTeam(this.props.team.id))}>
                            <span className="material-symbols-outlined">edit</span>
                        </div>
                    </div>

                    {this.props.team.spaceTypeRestrictionsType == BookingRestrictionTypes.Allow && this.props.team.spaceTypeIds.length > 0 ?
                        <div> {String_Format(AddOrEditTeam.Resources.allowType, this.getRestrictedSpaceTypes())}</div>
                        : null}

                    {this.props.team.spaceTypeRestrictionsType == BookingRestrictionTypes.Deny && this.props.team.spaceTypeIds.length > 0 ?
                        <div> {String_Format(AddOrEditTeam.Resources.denyType, this.getRestrictedSpaceTypes())}</div>
                        : null}

                    {this.props.team.countryRestrictionsType == BookingRestrictionTypes.Allow && this.props.team.countryIds.length > 0 ?
                        <div> {String_Format(AddOrEditTeam.Resources.allowCountry, this.props.team.countryIds.join(", "))}</div>
                        : null}

                    {this.props.team.countryRestrictionsType == BookingRestrictionTypes.Deny && this.props.team.countryIds.length > 0 ?
                        <div> {String_Format(AddOrEditTeam.Resources.denyCountry, this.props.team.countryIds.join(", "))}</div>
                        : null}

                    {this.props.team.cityRestrictionsType == BookingRestrictionTypes.Allow && this.props.team.cityIds.length > 0 ?
                        <div> {String_Format(AddOrEditTeam.Resources.allowCity, this.props.team.cityIds.join(", "))}</div>
                        : null}

                    {this.props.team.cityRestrictionsType == BookingRestrictionTypes.Deny && this.props.team.cityIds.length > 0 ?
                        <div> {String_Format(AddOrEditTeam.Resources.denyCity, this.props.team.cityIds.join(", "))}</div>
                        : null}

                    {this.props.team.spaceRestrictionsType == BookingRestrictionTypes.Allow && this.props.team.spaceIds.length > 0 ?
                        <div> {String_Format(AddOrEditTeam.Resources.allowSpace, this.props.team.spaceIds.join(", "))}</div>
                        : null}

                    {this.props.team.spaceRestrictionsType == BookingRestrictionTypes.Deny && this.props.team.spaceIds.length > 0 ?
                        <div> {String_Format(AddOrEditTeam.Resources.denySpace, this.props.team.spaceIds.join(", "))}</div>
                        : null}

                    {this.props.team.weekDayRestrictionsType == BookingRestrictionTypes.Allow && this.props.team.weekDayIds.length > 0 ?
                        <div> {String_Format(AddOrEditTeam.Resources.allowWeekDay, this.getRestrictedWeekDays())}</div>
                        : null}

                    {this.props.team.weekDayRestrictionsType == BookingRestrictionTypes.Deny && this.props.team.weekDayIds.length > 0 ?
                        <div> {String_Format(AddOrEditTeam.Resources.denyWeekDay, this.getRestrictedWeekDays())}</div>
                        : null}

                    {this.props.team.maxDailyCheckins &&
                        <div>{String_Format(AddOrEditTeam.Resources.maxNrOfDailyChecinsRestriction, this.props.team.maxDailyCheckins)}</div>
                    }
                </div>
            </div>

            <div className="form-line form-bottom-actions">
                <button className="btn-big btn-primary" onClick={this.showImportUsersModal}>{ClientDetails.Resources.importUsersFromFileActionLabel}</button>
                <button className="btn-big btn-primary" onClick={this.showAddUserModal}>{AddOrEditTeam.Resources.addUserText}</button>
            </div>

            <div>
                <ModalDialog
                    title={ClientDetails.Resources.importUsersFromFileActionLabel}
                    open={this.state.isImportUsersModalOpen}
                    footer={null}
                    onCancel={this.hideImportUsersModal}>
                    <div>
                        <div>
                            <input type="file" className="form-control" onChange={this.handleImportUsersFromFile}></input>
                        </div>
                        <div className="form-line form-bottom-actions">
                            <button className="btn-big btn-primary" onClick={this.hideImportUsersModal}>{General.Resources.closeLabel}</button>
                            <button className="btn-big btn-primary" onClick={this.importUsers}>{General.Resources.save}</button>
                        </div>
                    </div>

                </ModalDialog>
            </div>
            <div>
                <ModalDialog
                    title=""
                    open={this.state.isAddUserModalOpen}
                    onCancel={this.hideAddUserModal}>
                    <AddOrEditUserForm
                        teamId={this.props.team.id}
                        companyId={this.props.team.companyId}
                        onCancelCallback={this.hideAddUserModal}
                    />
                </ModalDialog>
            </div>

            <div>
                <UsersTable
                    componentId={"team-users-" + this.props.team.id + "-" + this.props.team.companyId}
                    hiddenColumns={["teamName", "createdAt", "deactivated", "id", "lastName", "phone", "status", "onBoardingComplete",
                        "companyId", "companyName", "isPhonePreferredChannel", "isSmsPreferredChannel", "isEmailPreferredChannel", "totalCheckins",
                        "isBookingManager", "isAccountOwner", "marketingPreferences", "teamId"]}
                    filters={String_Format(this.tableFilterTemplate, this.props.team.companyId, this.props.team.id)}
                />
            </div>


        </div>
    }
}


export default withRouter(connect(
    (state: ApplicationState, ownProps: IProps) => {
        return {
            hasServerSideErrors: state.teams.hasServerSideErrors,
            serverSideErrors: state.teams.errors,
            ...ownProps
        }
    },
    (dispatch, ownProps: IProps) => bindActionCreators({
        deleteTeamBenefit: TeamsStore.actionCreators.deleteTeamBenefit,
        reloadUsersTable: UsersGridStore.getActionCreators("team-users-" + ownProps.team.id + "-" + ownProps.team.companyId).reload
    }, dispatch),
)(TeamsAndUsersTabComponent as any));