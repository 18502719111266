import { Select, DatePicker } from "antd";
import dayjs from "dayjs";
import { FC, useMemo } from "react";

import { DefaultFilterValues } from "../../enums/Common/DefaultFilterValues";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import General from "../../resources/AdminUI/General";
import { IAntdSelectListItem } from "../../models/Common/IAntdSelectListItem";

const { RangePicker } = DatePicker;

interface IFiltersSectionProps {
  countries: IAntdSelectListItem[];
  cities: IAntdSelectListItem[];
  singleSelectedCountry: string;
  singleSelectedCity: string;
  singleSelectedStatus: string;
  searchTerm: string;
  startDateSearchTemplate: any;
  endDateSearchTemplate: any;
  dateFormat: string;
  onSearchFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCountryFilterChange: (value: string) => void;
  onCityFilterChange: (value: string) => void;
  setSingleSelectedStatus: (value: string) => void;
  onDateChange: (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null]) => void;
  getAllRoomStatuses: () => any[];
}

const FiltersSection: FC<IFiltersSectionProps> = ({
  countries,
  cities,
  singleSelectedCity,
  singleSelectedCountry,
  singleSelectedStatus,
  searchTerm,
  startDateSearchTemplate,
  endDateSearchTemplate,
  dateFormat,
  onCityFilterChange,
  onCountryFilterChange,
  onSearchFilterChange,
  onDateChange,
  getAllRoomStatuses,
  setSingleSelectedStatus,
}) => {
  // Memoize countriesWithAllOption to prevent recalculating on every render
  const countriesWithAllOption = useMemo(() => {
    if (!countries) {
      return [];
    }
    let newCountries = [
      {
        label: General.Resources.allCountries,
        value: DefaultFilterValues.All.toString(),
        group: DefaultFilterValues.All,
      },
      ...countries,
    ];
    return newCountries;
  }, [countries]);

  const citiesWithAllOption = useMemo(() => {
    if (!cities) {
      return [];
    }
    let newCities = [
      {
        label: General.Resources.allCities,
        value: DefaultFilterValues.All.toString(),
        group: DefaultFilterValues.All.toString(),
      },
      ...cities,
    ];

    return newCities;
  }, [cities]);

  return (
    <div className="filter-bar">
      <div className="filter-search">
        <div className="input-group">
          <span className="icon material-symbols-outlined">search</span>
          <input
            type="text"
            className="form-control"
            placeholder={
              RoomBookingsGrid.Resources.searchByLogIdUserNameOrCustomerName
            }
            onChange={onSearchFilterChange}
            name="searchTerm"
            value={searchTerm}
          />
        </div>
      </div>

      <div className="filter-item">
        <div className="input-group">
          <Select
            options={countriesWithAllOption}
            onChange={onCountryFilterChange}
            value={singleSelectedCountry}
            showSearch
            optionFilterProp="label"
          />
        </div>
      </div>
      <div className="filter-item">
        <div className="input-group">
          <Select
            options={
              singleSelectedCountry !== DefaultFilterValues.All.toString()
                ? citiesWithAllOption.filter(
                    (e) =>
                      e.group === singleSelectedCountry ||
                      e.value === DefaultFilterValues.All.toString()
                  )
                : citiesWithAllOption
            }
            onChange={onCityFilterChange}
            value={singleSelectedCity}
            showSearch
            optionFilterProp="label"
          />
        </div>
      </div>
      <div className="filter-item">
        <div className="input-group">
          <Select
            options={getAllRoomStatuses()}
            onChange={(value) => setSingleSelectedStatus(value)}
            value={singleSelectedStatus}
          />
        </div>
      </div>

      <RangePicker
        value={[
          startDateSearchTemplate
            ? dayjs.unix(startDateSearchTemplate)
            : startDateSearchTemplate,
          endDateSearchTemplate
            ? dayjs.unix(endDateSearchTemplate)
            : endDateSearchTemplate,
        ]}
        format={dateFormat}
        onChange={onDateChange}
      />
    </div>
  );
};

export default FiltersSection;
