import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";

import { SpaceStatuses } from "../../enums/Spaces/SpaceStatuses";
import { getPromiseFromAction } from "../../utils/utils";
import Workspaces from "../../resources/AdminUI/Spaces/Workspaces";
import General from "../../resources/AdminUI/General";
import ErrorMessages from "../../resources/Common/ErrorMessages";
import IPageIndex from "../../models/Pagination/IPageIndex";
import { IEntitiesList } from "../../models/Common/IEntitiesList";
import { IRoomBookingsItem } from "../../models/RoomBookings/Interfaces/IRoomBookingsItem";
import { useRoomBookingsQueries } from "../RoomBookings/useRoomBookingsQueries";
import { useRoomBookingsHelpers } from "../RoomBookings/useRoomBookingsHelpers";
import { ApplicationState } from "../../store";

const { confirm } = Modal;

export const useSpaceDetails = (
  spaceId: number,
  changeStatusAction: (workspaceId: number, statusId: number) => any
) => {
  const itemsPerPage = 15;

  const [currentPage, setCurrentPage] = useState(1);
  const [firstIndexFromPage, setFirstIndexFromPage] = useState(1);
  const [lastIndexFromPage, setLastIndexFromPage] = useState(1);
  const [pageIndexArray, setPageIndexArray] = useState<IPageIndex[]>([]);
  const [filters, setFilters] = useState("");
  const [numberOfRoomBookings, setNumberOfRoomBookings] = useState(0);

  const hasServerSideErrors = useSelector(
    (state: ApplicationState) => state.spaceDetails.hasServerSideErrors
  );

  const { recalculatePageArray, recalculateIndexes, rebuildODataFilters } =
    useRoomBookingsHelpers(itemsPerPage, numberOfRoomBookings, currentPage);
  const queryClient = useQueryClient();

  const skip = useMemo(
    () => (currentPage - 1) * itemsPerPage,
    [currentPage, itemsPerPage]
  );

  useEffect(() => {
    const pageArray = recalculatePageArray();
    setPageIndexArray(pageArray);
    const { firstIndex, lastIndex } = recalculateIndexes();
    setFirstIndexFromPage(firstIndex);
    setLastIndexFromPage(lastIndex);
  }, [
    numberOfRoomBookings,
    currentPage,
    recalculatePageArray,
    recalculateIndexes,
  ]);

  const changeCurrentPage = useCallback((newPage: number) => {
    setCurrentPage(newPage);
  }, []);

  const rebuildODataFiltersWithCustomLogic = useCallback(
    (toTake: number, toSkip: number): string => {
      const filters: any = {};

      if (spaceId) {
        filters.WorkspaceId = { eq: spaceId };
      }

      return rebuildODataFilters(toTake, toSkip, filters);
    },
    [spaceId, rebuildODataFilters]
  );

  const oDataFilter = useMemo(
    () => rebuildODataFiltersWithCustomLogic(itemsPerPage, skip),
    [itemsPerPage, skip, rebuildODataFiltersWithCustomLogic]
  );

  useEffect(() => {
    setFilters(oDataFilter);
  }, [oDataFilter]);

  const reloadRoomBookingsData = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ["roomBookings"] });
  }, [queryClient]);

  const changeSpaceStatus = useCallback(
    async (statusId: number) => {
      try {
        await getPromiseFromAction(changeStatusAction(spaceId, statusId));

        if (hasServerSideErrors) {
          Modal.error({
            title: ErrorMessages.Resources.generalErrorMessage,
          });
        }
      } catch (error) {
        console.error("Error changing status:", error);
        Modal.error({
          title: ErrorMessages.Resources.generalErrorMessage,
        });
      }
    },
    [spaceId, changeStatusAction, hasServerSideErrors]
  );

  const showDraftModal = useCallback(() => {
    confirm({
      title: Workspaces.Resources.areYouSure,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>{Workspaces.Resources.draftConfirmationMessage}</p>
        </div>
      ),
      okText: Workspaces.Resources.changeStatusToDraft,
      okType: "primary",
      cancelText: General.Resources.cancelButtonLabel,
      onOk: () => {
        changeSpaceStatus(SpaceStatuses.Draft);
      },
      onCancel() {},
    });
  }, [changeSpaceStatus]);

  const showUnavailableModal = useCallback(() => {
    confirm({
      title: Workspaces.Resources.areYouSure,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>{Workspaces.Resources.unavailableConfirmationMessage}</p>
        </div>
      ),
      okText: Workspaces.Resources.changeStatusToUnavailable,
      okType: "primary",
      cancelText: General.Resources.cancelButtonLabel,
      onOk: () => {
        changeSpaceStatus(SpaceStatuses.Unavailable);
      },
      onCancel() {},
    });
  }, [changeSpaceStatus]);

  const showActiveModal = useCallback(() => {
    confirm({
      title: Workspaces.Resources.areYouSure,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>{Workspaces.Resources.activeConfirmationMessage}</p>
        </div>
      ),
      okText: Workspaces.Resources.activateSpace,
      okType: "primary",
      cancelText: General.Resources.cancelButtonLabel,
      onOk: () => {
        changeSpaceStatus(SpaceStatuses.Available);
      },
      onCancel() {},
    });
  }, [changeSpaceStatus]);

  const showDeactivatedModal = useCallback(() => {
    confirm({
      title: Workspaces.Resources.areYouSure,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>{Workspaces.Resources.deactivatedConfirmationMessage}</p>
        </div>
      ),
      okText: Workspaces.Resources.deactivateSpace,
      okType: "danger",
      cancelText: General.Resources.cancelButtonLabel,
      onOk: () => {
        changeSpaceStatus(SpaceStatuses.Deactivated);
      },
      onCancel() {},
    });
  }, [changeSpaceStatus]);

  return {
    loading: false, // Since loading is handled by the component
    numberOfRoomBookings,
    itemsPerPage,
    currentPage,
    firstIndexFromPage,
    lastIndexFromPage,
    pageIndexArray,
    filters,
    skip,
    changeCurrentPage,
    reloadRoomBookingsData,
    showDraftModal,
    showUnavailableModal,
    showActiveModal,
    showDeactivatedModal,
  };
};
