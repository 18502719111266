import { RenewalTypes } from "../../enums/Clients/RenewalTypes";
import General from "../../resources/AdminUI/General";
import RenewalTypesList from "../../resources/AdminUI/RenewalTypesList";
import Subscriptions from "../../resources/AdminUI/Subscriptions/Subscriptions";
import { DATE_FORMAT, formatDate } from "../../utils/date";
import { BaseEntity } from "../../utils/reactUtils";
import { String_Format, firstLetterToLowerCase } from "../../utils/utils";
import { ISubscription } from "./Interfaces/ISubscription";

export default class SubscriptionItem extends BaseEntity implements ISubscription {
    id: number;
    name: string;
    price: number;
    currency: string;
    startsAt: string;
    renewIntervalUnit: number;
    renewIntervalValue: number;
    lastRenewDate: string;
    nextRenewDate: string;
    companyId: number;
    metadata: {
      vat: number;
      platformAccessFee: number;
      prepaid: number;
      minimumSpend: number;
    }

    constructor(subscription: ISubscription) {
        super();

        this.additionalConstructor(this, subscription);

        this.ForProperty(p => "name")
            .SetDisplayName(Subscriptions.Resources.pricingPlanNameTableHeader)
            .Show(_self => <div className="column-medium">
                <p className="table-item-bold-text">{subscription.name}</p>
            </div>);

        this.ForProperty(p => "renewIntervalUnit")
            .SetDisplayName(Subscriptions.Resources.paymentRenewalTableHeader)
            .Show(_self => <div className="column-small">
                {General.Resources.every} {subscription.renewIntervalValue} {RenewalTypesList.Resources[firstLetterToLowerCase(RenewalTypes[subscription.renewIntervalUnit])]}
            </div>);

        this.ForProperty(p => "startsAt")
            .SetDisplayName(Subscriptions.Resources.pricingPlanStartDateTableHeader)
            .Show(_self => <div className="column-medium">
                {formatDate(subscription.startsAt, DATE_FORMAT)}
            </div>);

        this.ForProperty(p => "lastRenewDate")
            .SetDisplayName(Subscriptions.Resources.renewDatesTableHeader)
            .Show(_self => <div className="column-medium">
                <p>
                    {
                        subscription.lastRenewDate &&
                        String_Format(Subscriptions.Resources.lastRenewedOn, formatDate(subscription.lastRenewDate, DATE_FORMAT))
                    }
                </p>
                <p>
                    {
                        subscription.nextRenewDate &&
                        String_Format(Subscriptions.Resources.nextRenewedOn, formatDate(subscription.nextRenewDate, DATE_FORMAT))
                    }
                </p>
            </div>);
    }
}