import React from "react";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import CountriesAndCities from "../../resources/AdminUI/CountriesAndCities";
import { ICountryItem } from "../../models/CountriesAndCities/ICountryItem";
import bind from "bind-decorator";
import { CountriesAndCitiesStore } from "../../store/CountriesAndCitiesStore";
import { CountryCard } from "./Cards/CountryCard";
import General from "../../resources/AdminUI/General";

interface IProps {
    loading: boolean,
    countries: ICountryItem[],
    onChangeCountry: (country: ICountryItem) => void
}

class CountriesContainer extends React.PureComponent<IProps, any> {
    constructor(props) {
        super(props)
    }

    @bind
    handleOnCountryClick(country: ICountryItem) {
        this.props.onChangeCountry(country);
    }

    render() {
        if (this.props.loading) {
            return <div>
                {General.Resources.loading}
            </div>
        } else {
            return <div className="countries-scrollable-list">

                <h3 className="title">{CountriesAndCities.Resources.countriesLabel}</h3>

                <div className="countries-list-item">
                    <div>
                        <ul style={{ listStyle: 'none' }}>
                            {
                                this.props.countries.map(country =>
                                    <li key={country.id}
                                        onClick={e => this.handleOnCountryClick(country)}
                                        style={{ cursor: 'pointer' }}>
                                        <CountryCard country={country} />
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
            </div>
        }
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            loading: state.countriesAndCities.loading,
            countries: state.countriesAndCities.countriesList
        }
    },
    {
        onChangeCountry: CountriesAndCitiesStore.actionCreators.changeSelectedCountry
    }
)(CountriesContainer as any)