import { PlanTypes } from "../../enums/Common/PlanTypes";
import ClientStateList from "../../resources/AdminUI/Clients/ClientStateList";
import Clients from "../../resources/AdminUI/Clients/Clients";
import { BaseEntity } from "../../utils/reactUtils";
import { String_Format, firstLetterToLowerCase } from "../../utils/utils";
import { IClientItem } from "./Interfaces/IClientItem";
import { formatDate } from "../../utils/date";
import General from "../../resources/AdminUI/General";
import RenewalTypesList from "../../resources/AdminUI/RenewalTypesList";
import { RenewalTypes } from "../../enums/Clients/RenewalTypes";

export class ClientItem extends BaseEntity implements IClientItem {
  id: number;
  name: string;
  deactivated: boolean;
  numberOfEmployees: number;
  countryId: number;
  countryName: string;
  contactPhone: string;
  contactEmail: string;
  numberOfUsers: number;
  emailDoamins: string;
  price: number;
  currency: string;
  renewalIntervalValue: number;
  renewalIntervalUnit: number;
  lastRenewalDate: string;
  nextRenewalDate: string;
  paymentProviderId: number;
  paymentProvider: string;

  constructor(client: IClientItem) {
    super();

    this.additionalConstructor(this, client);

    this.ForProperty((p) => "name")
      .SetDisplayName(Clients.Resources.nameGridColumnLabel)
      .Show((_self) => (
        <div className="column-medium">
          <p className="table-item-name">{this.name ? this.name : " - "}</p>
          <p>
            <span
              className={`table-item-status ${
                this.deactivated.valueOf() ? "deactivated" : ""
              }`}
            >
              {this.deactivated.valueOf()
                ? ClientStateList.Resources.deactivated
                : ClientStateList.Resources.active}
            </span>{" "}
            |{" "}
            <span className="table-item-id">
              {" "}
              {String_Format(Clients.Resources.idLabel, this.id)}
            </span>
          </p>
        </div>
      ));

    this.ForProperty((p) => "price")
      .SetDisplayName(Clients.Resources.priceGridColumnLabel)
      .Show((_self) => (
        <div className="column-large">
          <p className="table-item-highlight-row plan-name">
            {PlanTypes[PlanTypes.ENTERPRISE]}
          </p>
          {
            <>
              {this.price ? (
                <p>
                  {this.price / 100} {this.currency}
                  <span> / </span>
                  {this.renewalIntervalValue ? (
                    <span>
                      {General.Resources.every} {this.renewalIntervalValue}{" "}
                      {
                        RenewalTypesList.Resources[
                          firstLetterToLowerCase(
                            RenewalTypes[this.renewalIntervalUnit]
                          )
                        ]
                      }
                    </span>
                  ) : (
                    <span> - </span>
                  )}
                </p>
              ) : (
                <p> - </p>
              )}
            </>
          }
        </div>
      ));

    this.ForProperty((p) => "countryName")
      .SetDisplayName(Clients.Resources.countryGridColumnLabel)
      .Show((_self) => <div className="column-small">{this.countryName}</div>);

    this.ForProperty((p) => "contactEmail")
      .SetDisplayName(Clients.Resources.contactInfoGridColumnLabel)
      .Show((_self) => (
        <div className="column-large">
          <div className="table-item-icon-info">
            <span className="material-symbols-outlined">phone</span>
            <p>{this.contactPhone || " - "}</p>
          </div>

          <div className="table-item-icon-info">
            <span className="material-symbols-outlined">email</span>
            <p>{this.contactEmail || " - "}</p>
          </div>
        </div>
      ));

    this.ForProperty((p) => "nextRenewalDate")
      .SetDisplayName(Clients.Resources.renewalDatesGridColumnLabel)
      .Show((_self) => (
        <div className="column-medium">
          <p>
            {this.lastRenewalDate
              ? String_Format(
                  Clients.Resources.lastRenewalDateLabel,
                  formatDate(this.lastRenewalDate)
                )
              : " - "}
          </p>
          <p>
            {this.nextRenewalDate
              ? String_Format(
                  Clients.Resources.nextRenewalDateLabel,
                  formatDate(this.nextRenewalDate)
                )
              : " - "}
          </p>
        </div>
      ));
  }
}
