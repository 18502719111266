import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import update from "immutability-helper";
import LookupService from "../services/LookupService";
import { DefaultFilterValues } from "../enums/Common/DefaultFilterValues";
import General from "../resources/AdminUI/General";
import { IAntdSelectListItem } from "../models/Common/IAntdSelectListItem";

export namespace LookupStore {

    export interface IState {
        countries: IAntdSelectListItem[];
        allCountries: IAntdSelectListItem[];
        cities: IAntdSelectListItem[];
        currencies: IAntdSelectListItem[];
        workspaceGroups: IAntdSelectListItem[];
        languages: IAntdSelectListItem[];
        adminUsers: IAntdSelectListItem[];
    }

    export enum Actions {
        GetCountries = "LOOKUP_GET_COUNTRIES",
        GetCountriesWithCities = "LOOKUP_GET_COUNTRIES_WITH_CITIES",
        GetCities = "LOOKUP_GET_CITIES",
        GetCurrencies = "LOOKUP_GET_CURRENCIES",
        GetWorkspaceGroups = "LOOKUP_GET_WORKSPACE_GROUPS",
        GetLanguages = "LOOKUP_GET_LANGUAGES",
        GetAdminUsers = "LOOKUP_GET_ADMIN_USERS"
    }

    interface IGetCountries {
        type: Actions.GetCountries,
        allCountries: IAntdSelectListItem[]
    }

    interface IGetCountriesWithCities {
        type: Actions.GetCountriesWithCities,
        countries: IAntdSelectListItem[]
    }

    interface IGetCities {
        type: Actions.GetCities,
        cities: IAntdSelectListItem[]
    }

    interface IGetCurrencies {
        type: Actions.GetCurrencies,
        currencies: IAntdSelectListItem[]
    }

    interface IGetWorkspaceGroups {
        type: Actions.GetWorkspaceGroups,
        workspaceGroups: IAntdSelectListItem[]
    }

    interface IGetLanguages {
        type: Actions.GetLanguages,
        languages: IAntdSelectListItem[]
    }

    interface IGetAdminUsers {
        type: Actions.GetAdminUsers,
        adminUsers: IAntdSelectListItem[]
    }

    type KnownAction = IGetCountries
        | IGetCountriesWithCities
        | IGetCities
        | IGetCurrencies
        | IGetWorkspaceGroups
        | IGetLanguages
        | IGetAdminUsers;

    export const actionCreators = {

        getCountries: (addAllOption?: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            var countries = await LookupService.getAllCountries().then(res => res.value);
            if (addAllOption) {
                countries.unshift({
                    label: General.Resources.allCountries,
                    value: DefaultFilterValues.All.toString(),
                    selected: true
                } as IAntdSelectListItem)
            }
            dispatch({ type: Actions.GetCountries, allCountries: countries });
        },
        getCountriesWithCities: (addAllOption?: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            var countries = await LookupService.getAllCountriesWithCities().then(res => res.value);
            if (addAllOption) {
                countries.unshift({
                    label: General.Resources.allCountries,
                    value: DefaultFilterValues.All.toString(),
                    selected: true
                } as IAntdSelectListItem)
            }
            dispatch({ type: Actions.GetCountriesWithCities, countries: countries });
        },
        getCities: (addAllOption?: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            var cities = await LookupService.getAllCities().then(res => res.value);
            if (addAllOption) {
                cities.unshift({
                    label: General.Resources.allCities,
                    value: DefaultFilterValues.All.toString(),
                    selected: true
                } as IAntdSelectListItem)
            }
            dispatch({ type: Actions.GetCities, cities: cities });
        },
        getCurrencies: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            var currencies = await LookupService.getAllCurrencies().then(res => res.value);
            dispatch({ type: Actions.GetCurrencies, currencies: currencies });
        },
        getWorkspaceGroups: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            var workspaceGroups = await LookupService.getAllWorkspaceGroups().then(res => res.value);
            dispatch({ type: Actions.GetWorkspaceGroups, workspaceGroups: workspaceGroups });
        },
        getLanguages: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            var languages = await LookupService.getAllLanguages().then(res => res.value);
            dispatch({ type: Actions.GetLanguages, languages: languages });
        },
        getAdminUsers: (addAllOption?: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            var adminUsers = await LookupService.getAllAdminUsers().then(res => res.value);
            if (addAllOption) {
                adminUsers.unshift({
                    label: General.Resources.anyUser,
                    value: DefaultFilterValues.All.toString(),
                    selected: true
                } as IAntdSelectListItem)
            }
            dispatch({ type: Actions.GetAdminUsers, adminUsers: adminUsers });
        },
    }

    const initialState: IState = {
        countries: null,
        allCountries: null,
        cities: null,
        currencies: null,
        workspaceGroups: null,
        languages: null,
        adminUsers: null
    }

    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case Actions.GetCountries:
                return update(currentState,
                    {
                        allCountries: { $set: action.allCountries }
                    });
            case Actions.GetCountriesWithCities:
                return update(currentState,
                    {
                        countries: { $set: action.countries }
                    });
            case Actions.GetCities:
                return update(currentState,
                    {
                        cities: { $set: action.cities }
                    });
            case Actions.GetCurrencies:
                return update(currentState,
                    {
                        currencies: { $set: action.currencies }
                    });
            case Actions.GetWorkspaceGroups:
                return update(currentState,
                    {
                        workspaceGroups: { $set: action.workspaceGroups }
                    });
            case Actions.GetLanguages:
                return update(currentState,
                    {
                        languages: { $set: action.languages }
                    });
            case Actions.GetAdminUsers:
                return update(currentState,
                    {
                        adminUsers: { $set: action.adminUsers }
                    });
            default:
                return currentState || initialState;
        }
    }
}