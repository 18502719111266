import { EntitiesList, IEntitiesList } from "../models/Common/IEntitiesList";
import Result from "../models/Common/Result";
import { IUserItem } from "../models/Users/Interfaces/IUserItem";
import { AppSettings } from "../utils/settings";
import { ServiceBase } from "./ServiceBase";
import { UserItem } from "../models/Users/UserItem";
import { IEditUser } from "../models/Users/Interfaces/IEditUser";
import { PayloadTypes } from "../enums/Common/PayloadTypes";

export default class UsersService extends ServiceBase {
  private static getOrderByValue(searchedTerm: string): string {
    return `(firstName eq '${searchedTerm}' or lastName eq '${searchedTerm}' or Email eq '${searchedTerm}') desc,
      (startswith(tolower(firstName), tolower('${searchedTerm}')) or startswith(tolower(lastName), tolower('${searchedTerm}')) or startswith(tolower(Email), tolower('${searchedTerm}'))) desc,
      Id desc`;
  }

  static getUsers = (
    toTake: number,
    toSkip: number,
    filters?: string,
    searchedTerm?: string
  ): Promise<Result<IEntitiesList<IUserItem>>> => {
    let url = `/odata/users?$top=${toTake}&$skip=${toSkip}`;

    if (filters && filters.length > 0) {
      url = `${url}&$filter=${filters}`;
    }

    if (searchedTerm && searchedTerm.length > 0) {
      url = `${url}&$orderby=${this.getOrderByValue(searchedTerm)}`;
    } else {
      url = `${url}&$orderby=Id desc`;
    }

    try {
      let result = UsersService.requestJsonFetch<IEntitiesList<IUserItem>>({
        method: "GET",
        url: `${AppSettings.CommonSettings.ApiUrl}${url}&$count=true`,
      });
      return result;
    } catch (error) {}

    return new Promise<Result<IEntitiesList<IUserItem>>>(null);
  };

  static additionalMapping = (
    entitiesList: IEntitiesList<IUserItem>
  ): EntitiesList<IUserItem> => {
    let newEntities = new EntitiesList<IUserItem>();
    newEntities.entities = entitiesList.entities.map((e) => new UserItem(e));
    newEntities.numberOfEntities = entitiesList.numberOfEntities;
    return newEntities;
  };

  static getUserDetails = (userId: number): Promise<Result<any>> => {
    let result;
    try {
      result = UsersService.requestJsonFetch<any>({
        method: "GET",
        url: `${AppSettings.CommonSettings.ApiUrl}/users/${userId}`,
      });
    } catch (error) {}

    return result;
  };

  static updateUserDetails = (
    userToUpdate: IEditUser
  ): Promise<Result<IEditUser>> => {
    let result = UsersService.sendData<any>(
      {
        method: "PUT",
        url: `${AppSettings.CommonSettings.ApiUrl}/users/${userToUpdate.id}`,
        data: JSON.stringify(userToUpdate),
      },
      PayloadTypes.Json
    );

    return result;
  };

  static activateUser = (userId: number): Promise<Result<any>> => {
    let result = UsersService.sendData<any>(
      {
        method: "PUT",
        url: `${AppSettings.CommonSettings.ApiUrl}/users/${userId}/activate`,
        data: null,
      },
      PayloadTypes.Json
    );

    return result;
  };

  static deactivateUser = (userId: number): Promise<Result<any>> => {
    let result = UsersService.sendData<any>(
      {
        method: "PUT",
        url: `${AppSettings.CommonSettings.ApiUrl}/users/${userId}/deactivate`,
        data: null,
      },
      PayloadTypes.Json
    );

    return result;
  };

  static deleteUser = (userId: number): Promise<Result<any>> => {
    let result = UsersService.sendData<any>(
      {
        method: "DELETE",
        url: `${AppSettings.CommonSettings.ApiUrl}/users/${userId}`,
        data: null,
      },
      PayloadTypes.Json
    );

    return result;
  };

  static getUsersForExport = (
    filters?: string
  ): Promise<Result<IEntitiesList<IUserItem>>> => {
    let url = `/odata/users?orderby=Id desc`;

    if (filters && filters.length > 0) {
      url = `${url}&$filter=${filters}`;
    }

    try {
      let result = UsersService.requestJsonFetch<IEntitiesList<IUserItem>>({
        method: "GET",
        url: `${AppSettings.CommonSettings.ApiUrl}${url}`,
      });
      return result;
    } catch (error) {}

    return new Promise<Result<IEntitiesList<IUserItem>>>(null);
  };

  static addUser = (user: IEditUser): Promise<Result<IEditUser>> => {
    let result = UsersService.sendData<IEditUser>(
      {
        method: "POST",
        url: `${AppSettings.CommonSettings.ApiUrl}/users`,
        data: JSON.stringify(user),
      },
      PayloadTypes.Json
    );

    return result;
  };

  static getUserBenefits = (userId: number): Promise<Result<any>> => {
    let result;
    try {
      result = UsersService.requestJsonFetch<any>({
        method: "GET",
        url: `${AppSettings.CommonSettings.ApiUrl}/users/${userId}/benefits`,
      });
    } catch (error) {}

    return result;
  };
}
