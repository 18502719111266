import React from "react";
import { String_Format, firstLetterToLowerCase } from "../../utils/utils";
import Workspaces from "../../resources/AdminUI/Spaces/Workspaces";
import bind from "bind-decorator";
import { RoomCategories } from "../../enums/Rooms/RoomCategories";
import RoomTypes from "../../resources/AdminUI/Rooms/RoomTypes";
import Rooms from "../../resources/AdminUI/Rooms/Rooms";
import General from "../../resources/AdminUI/General";
import { AppSettings } from "../../utils/settings";
import RoomDetails from "./RoomDetails";
import { ApplicationPathBuilder } from "../../enums/Common/ApplicationPaths";
import { NavigateFunction } from "react-router";
import { withRouter } from "../Shared/withRouter";
import ModalDialog from "../Shared/ModalDialog";

interface IProps {
    currency: string;
    slide: any;

    navigate?: NavigateFunction;
}

interface IState {
    isDeactivated: boolean;
    isMoreDetailsModalOpen: boolean;
}

class RoomCard extends React.PureComponent<IProps, IState>{
    constructor(props) {
        super(props);
        this.state = {
            isDeactivated: this.props.slide.deactivated,
            isMoreDetailsModalOpen: false
        }
    }

    @bind
    showMoreDetailsModal() {
        this.setState({ isMoreDetailsModalOpen: true });
    }

    @bind
    goToEditRoom(roomId) {
        this.props.navigate(ApplicationPathBuilder.EditRoom(roomId));
    }


    @bind
    hideMoreDetailsModal() {
        this.setState({ isMoreDetailsModalOpen: false });
    }

    @bind
    getRoomTypeName() {
        let value = RoomCategories[this.props.slide.roomTypeId].toString();
        let text = RoomTypes.Resources[firstLetterToLowerCase(value)];
        return text;
    }

    @bind
    changeIsDeactivated(value:boolean)
    {
        this.setState({ isDeactivated: value });
    }

    render() {
        return <div className="list-item">
            <div className="item-info">
                <div className="type">{this.getRoomTypeName()}</div>
                <div className="title"> {this.props.slide.name}</div>

                <div className="db_info">
                    {/* <div className="status">{!this.props.slide.deactivated ? General.Resources.active : General.Resources.notActive}</div> */}
                    <div className="status">{!this.state.isDeactivated ? General.Resources.active : General.Resources.notActive}</div>
                    <div className="id"> | {String_Format(Workspaces.Resources.idLabel, this.props.slide.id)} </div>
                </div>

                <div className="created_on">{String_Format(Workspaces.Resources.createdOn, this.props.slide.createdAt)}</div>

                <div className="info-row">
                    <div className="icon">
                        <span className="material-symbols-outlined">group</span>
                    </div>
                    <div className="info">{this.props.slide.maxGuests ? String_Format(Rooms.Resources.maxGuests, this.props.slide.maxGuests) : null}</div>
                </div>
                <div className="info-row">
                    <div className="material-symbols-outlined">payments</div>

                    {this.props.slide.hourPrice || this.props.slide.dayPrice ?
                        <div className="info">
                            {this.props.slide.hourPrice ? String_Format(Rooms.Resources.priceHourly, parseInt(this.props.slide.hourPrice) / 100, this.props.currency) : null}
                            {this.props.slide.hourPrice && this.props.slide.dayPrice ? <> &bull; </> : null}
                            {this.props.slide.dayPrice ? String_Format(Rooms.Resources.priceDaily, parseInt(this.props.slide.dayPrice) / 100, this.props.currency) : null}
                        </div>
                        :
                        <div>{General.Resources.notCompletedYet}</div>
                    }

                </div>
                <div><button className="btn_text" onClick={() => this.showMoreDetailsModal()}>{Rooms.Resources.moreDetails}</button></div>

                <div><button className="btn-table-action btn-room-card" onClick={() => this.goToEditRoom(this.props.slide.id)}>
                    <div className="material-symbols-outlined">edit</div>
                </button></div>

                <ModalDialog
                    title={null}
                    open={this.state.isMoreDetailsModalOpen}
                    onCancel={this.hideMoreDetailsModal}
                    width='60vw'
                >
                    <RoomDetails
                        details={this.props.slide}
                        isDeactivated={this.state.isDeactivated}
                        currency={this.props.currency}
                        changeIsDeactivated={this.changeIsDeactivated}
                        onOpenChangeActivationModalCallback={this.hideMoreDetailsModal}
                        onCloseChangeActivationModalCallback={this.showMoreDetailsModal}
                    />
                </ModalDialog>
            </div>

            <div className="item-photo">
                {this.props.slide.images.length > 0 ? <img src={AppSettings.CommonSettings.ImageBaseUrl + this.props.slide.images[0]} alt="room-photo" /> : <></>}
            </div>
        </div>
    }
}

export default withRouter(RoomCard);