import { PayloadTypes } from "../enums/Common/PayloadTypes";
import { AdminLogItem } from "../models/AccountAndLogs/AdminLogItem";
import { IAdminLogItem } from "../models/AccountAndLogs/Interfaces/IAdminLogItem";
import { EntitiesList, IEntitiesList } from "../models/Common/IEntitiesList";
import Result from "../models/Common/Result";
import { AppSettings } from "../utils/settings";
import { ServiceBase } from "./ServiceBase";

export default class AccountsAndLogsService extends ServiceBase {
  static getAdminAccounts = (): Promise<Result<any[]>> => {
    try {
      var result = AccountsAndLogsService.requestJsonFetch<any[]>({
        method: "GET",
        url: `${AppSettings.CommonSettings.ApiUrl}/admin-accounts`,
      });
      return result;
    } catch (error) {}
  };

  static addAdminAccount = (adminDetails: any): Promise<Result<any>> => {
    var result = AccountsAndLogsService.sendData<any>(
      {
        method: "POST",
        url: `${AppSettings.CommonSettings.ApiUrl}/admin-accounts`,
        data: JSON.stringify(adminDetails),
      },
      PayloadTypes.Json
    );

    return result;
  };

  static editAdminAccount = (
    id: number,
    adminDetails: any
  ): Promise<Result<any>> => {
    var result = AccountsAndLogsService.sendData<any>(
      {
        method: "PUT",
        url: `${AppSettings.CommonSettings.ApiUrl}/admin-accounts/${id}`,
        data: JSON.stringify(adminDetails),
      },
      PayloadTypes.Json
    );

    return result;
  };

  static deactivateAdminAccount = (id: number): Promise<Result<any>> => {
    var result = AccountsAndLogsService.sendData<any>(
      {
        method: "PUT",
        url: `${AppSettings.CommonSettings.ApiUrl}/admin-accounts/${id}/deactivate`,
        data: null,
      },
      PayloadTypes.Json
    );

    return result;
  };

  static activateAdminAccount = (id: number): Promise<Result<any>> => {
    var result = AccountsAndLogsService.sendData<any>(
      {
        method: "PUT",
        url: `${AppSettings.CommonSettings.ApiUrl}/admin-accounts/${id}/activate`,
        data: null,
      },
      PayloadTypes.Json
    );

    return result;
  };

  static getAdminLogs = (
    toTake: number,
    toSkip: number,
    filters: string
  ): Promise<Result<IEntitiesList<IAdminLogItem>>> => {
    var url = `/odata/admin-logs/?$top=${toTake}&$skip=${toSkip}`;

    if (filters && filters.length > 0) {
      url = `${url}&$filter=${filters}`;
    }

    try {
      var result = AccountsAndLogsService.requestJsonFetch<
        IEntitiesList<IAdminLogItem>
      >({
        method: "GET",
        url: `${AppSettings.CommonSettings.ApiUrl}${url}&$orderby=Id desc&$count=true`,
      });
      return result;
    } catch (error) {}

    return new Promise<Result<IEntitiesList<IAdminLogItem>>>(null);
  };

  static additionalMapping = (
    entitiesList: IEntitiesList<IAdminLogItem>
  ): EntitiesList<IAdminLogItem> => {
    var newEntities = new EntitiesList<IAdminLogItem>();
    newEntities.entities = entitiesList.entities.map(
      (e) => new AdminLogItem(e)
    );
    newEntities.numberOfEntities = entitiesList.numberOfEntities;
    return newEntities;
  };
}
