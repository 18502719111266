import { BenefitTypes } from "../../enums/Benefits/BenefitTypes";
import { RenewalTypes } from "../../enums/Clients/RenewalTypes";
import BenefitTypeList from "../../resources/AdminUI/Benefits/BenefitTypeList";
import Benefits from "../../resources/AdminUI/Benefits/Benefits";
import General from "../../resources/AdminUI/General";
import RenewalTypesList from "../../resources/AdminUI/RenewalTypesList";
import { DATE_FORMAT, formatDate } from "../../utils/date";
import { BaseEntity } from "../../utils/reactUtils";
import { String_Format, firstLetterToLowerCase } from "../../utils/utils";
import { IBenefit } from "./Interfaces/IBenefit";

export class BenefitItem extends BaseEntity implements IBenefit {

    id: number;
    name: string;
    description: string;
    featureId: number;
    isUnlimited: boolean;
    value: number;
    currency: string;
    userUsageLimit: number;
    renewMaxCycles: number;
    currentUsage: number;
    renewIntervalUnitId: number;
    renewIntervalValue: number;
    lastRenewAt: string;
    nextRenewAt: string;
    companyId: number;
    subscriptionId: number;
    subscriptionName: string;
    voucherId: number;
    hasUserLimit: boolean;
    costPerEntry: string;

    constructor(benefit: IBenefit) {
        super();

        this.additionalConstructor(this, benefit);

        this.ForProperty(p => "name")
            .SetDisplayName(Benefits.Resources.titleColumnHeader)
            .Show(_self => <div className="column-medium">
                <p className="table-item-bold-text">{benefit.name}</p>
                <p className="table-item-subheading-row">{String_Format(Benefits.Resources.serviceIdText, benefit.id)}</p>
            </div>);

        this.ForProperty(p => "description")
            .SetDisplayName(Benefits.Resources.descriptionColumnHeader)
            .Show(_self => <div className="column-large">
                {benefit.description}
            </div>);

        this.ForProperty(p => "featureId")
            .SetDisplayName(Benefits.Resources.typeAndQuatityColumnHeader)
            .Show(_self => <div className="column-xlarge">
                <p>{BenefitTypeList.Resources[firstLetterToLowerCase(BenefitTypes[benefit.featureId])]}</p>
                <div className="display-flex">
                    {
                        benefit.isUnlimited ? Benefits.Resources.unlimitedLabel :
                            benefit.currency ?
                                <p>{benefit.value / 100} {benefit.currency}</p>
                                : <p>{benefit.value} {Benefits.Resources.entriesText}</p>

                    }
                    <div className="inline-divider"> | </div>
                    {
                        String_Format(Benefits.Resources.userLimitLabel,
                            benefit.hasUserLimit ?
                                benefit.currency ? benefit.userUsageLimit / 100 : benefit.userUsageLimit + " entries"
                                : Benefits.Resources.unlimitedLabel
                        )
                    }
                </div>
                {
                    benefit.featureId == BenefitTypes.DeskEntries && benefit.costPerEntry &&
                    <p>{Benefits.Resources.costPerEntry}: <span>{benefit.costPerEntry} </span></p>
                }
            </div>);

        this.ForProperty(p => "currentUsage")
            .SetDisplayName(Benefits.Resources.usageColumnHeader)
            .Show(_self => <div className="column-medium">
                {
                    benefit.currency ?
                        <p>{benefit.currentUsage / 100} / {benefit.isUnlimited ? Benefits.Resources.unlimitedLabel : benefit.value / 100}</p>
                        : <p>{benefit.currentUsage || 0} / {benefit.isUnlimited ? Benefits.Resources.unlimitedLabel : benefit.value}</p>
                }

            </div>);

        this.ForProperty(p => "renewIntervalValue")
            .SetDisplayName(Benefits.Resources.renewalTypeValueText)
            .Show(_self => <div className="column-large">
                {General.Resources.every} {benefit.renewIntervalValue} {RenewalTypesList.Resources[firstLetterToLowerCase(RenewalTypes[benefit.renewIntervalUnitId])]}
            </div>);

        this.ForProperty(p => "lastRenewAt")
            .SetDisplayName(Benefits.Resources.renewDatesColumnHeader)
            .Show(_self => <div className="column-large">
                {
                    benefit.lastRenewAt &&
                    <p>{String_Format(General.Resources.lastOnLabel, formatDate(benefit.lastRenewAt, DATE_FORMAT))}</p>
                }
                {
                    benefit.nextRenewAt &&
                    <p>{String_Format(General.Resources.nextOnLabel, formatDate(benefit.nextRenewAt, DATE_FORMAT))}</p>
                }

            </div>);

        this.ForProperty(p => "subscriptionId")
            .SetDisplayName(Benefits.Resources.sourceColumnHeader)
            .Show(_self => <div className="column-xlarge">
                {
                    benefit.subscriptionId &&
                    <div>
                        <p>{Benefits.Resources.pricingPlanSource}</p>
                        <p>{benefit.subscriptionName}</p>
                    </div>

                }
            </div>);
    }
}