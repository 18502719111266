// ImageInput.tsx
import React from "react";

import { IInputProps } from "./FormInput";

const ImageInput: React.FC<IInputProps> = ({
  label,
  value,
  placeholder,
  errors,
  name,
  readOnly,
  hidden,
  onChange,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!readOnly && onChange && e.target.files?.length) {
      const fileUrl = URL.createObjectURL(e.target.files[0]);
      const uploadedImage = {
        formFile: e.target.files[0],
        fileUrl: fileUrl,
      };
      onChange(name, uploadedImage, null);
    }
  };

  const handleRemove = () => {
    onChange(name, { formFile: null, fileUrl: "" }, null);
  };

  const imageUrl =
    value.formFile && value.formFile.name.trim() !== ""
      ? URL.createObjectURL(value.formFile)
      : value.fileUrl;

  return (
    <div className="form-item">
      {label && <label>{label}</label>}
      <div className="form-upload">
        {imageUrl ? (
          <div>
            <img width="250px" src={imageUrl} alt="" />
            <span onClick={handleRemove}>X</span>
          </div>
        ) : (
          <div>
            <input
              type="file"
              placeholder={placeholder}
              className={`${errors.length > 0 ? "input-validation-error" : ""}`}
              onChange={handleChange}
              name={name}
              readOnly={readOnly}
              hidden={hidden}
            />
            <div className="form-upload-wrapper">
              <label>
                {value.formFile && value.formFile.name.trim() !== ""
                  ? value.formFile.name
                  : "No File Chosen"}
              </label>
            </div>
          </div>
        )}
      </div>
      {errors.map((err: string) => (
        <span key={err} className="field-validation-error">
          {err}
        </span>
      ))}
    </div>
  );
};

export default ImageInput;
