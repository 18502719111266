import Globals from "./../../../Globals";

export default class AccountsAndLogs {
       public accountsAndLogsHeader: string = `Accounts & logs`;
       public actionHeaderLabel: string = `Action`;
       public activateAdminDesc: string = `Are you sure you want to activate this user? The user will be able to login into the Pluria Super Admin.`;
       public activateUser: string = `Activate user`;
       public activeLabel: string = `Active`;
       public addCredentialsLabel: string = `Add credentials`;
       public addNewCredentialsDesc: string = `Create new admin credentials by completing name, email address & Password. Please make sure you retain the password because it wont't be displayed anywhere. In case of losing it, reset the password and create a new one.`;
       public addNewCredentialsHeader: string = `Add new admin credentials`;
       public adminLogsLabel: string = `Admin logs`;
       public argumentsHeaderLabel: string = `Arguments`;
       public changeNameAndEmailDesc: string = `Change the name and email by entering a new one.`;
       public changeNameAndEmailHeader: string = `Change admin name and email`;
       public changeNameAndEmailLabel: string = `Change name & email`;
       public changePasswordDesc: string = `Change the password by entering a new one. Please make sure you retain the password, it won't appear anywhere. In case of losing it, you must reset the password.`;
       public changePasswordLabel: string = `Change password`;
       public controllerHeaderLabel: string = `Controller`;
       public createdAtHeaderLabel: string = `Date`;
       public deactivateAdminDesc: string = `Are you sure you want to deactivate this user? The user will no longer be able to login into the Pluria Super Admin.`;
       public deactivatedLabel: string = `Deactivated`;
       public deactivateUser: string = `Deactivate user`;
       public emailAddressLabel: string = `Email Address`;
       public nameLabel: string = `Name`;
       public normalAdminLabel: string = `Normal admin`;
       public passwordLabel: string = `Password`;
       public payloadLabel: string = `Payload`;
       public roleLabel: string = `Role`;
       public superAdminAccountsLabel: string = `Super admin accounts`;
       public superAdminLabel: string = `Super admin`;
       public userHeaderLabel: string = `User`;

		public static get Resources(): AccountsAndLogs{
			switch (Globals.currentActiveLanguage) {
				default:
				return new AccountsAndLogs();
		}
   	}
}
