import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { BenefitHistoryItem } from "../models/Benefits/BenefitHistoryItem";
import { IBenefitHistory } from "../models/Benefits/Interfaces/IBenefitHistory";
import BenefitService from "../services/BenefitsService";
import update from "immutability-helper";

export namespace BenefitHistoryStore {

    export interface IState {
        benefitHistory: BenefitHistoryItem[];
        loading: boolean;
    }

    export enum Actions {
        ToggleLoadingHistory = "BENEFIT_HISTORY_TOGGLE_LOADING_HISTORY",
        SetBenefitHistory = "BENEFIT_HISTORY_SET_BENEFIT_HISTORY"
    }
    
    interface ISetBenefitHistory {
        type: Actions.SetBenefitHistory,
        benefitHistory: BenefitHistoryItem[]
    }

    interface IToggleLoadingBenefitHistory {
        type: Actions.ToggleLoadingHistory;
        loading: boolean;
    }

    type KnownAction = ISetBenefitHistory | IToggleLoadingBenefitHistory;

    export const actionCreators = {
        getBenefitHistory: (benefitId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ToggleLoadingHistory, loading: true });

            var result = await BenefitService.getBenefitHistory(benefitId).then(response => response.value);
            var benefitHistoryItems = BenefitService.mapBenefitHistoryToBenefitHistoryItemsList(result);

            dispatch({ type: Actions.SetBenefitHistory, benefitHistory: benefitHistoryItems })
            
            dispatch({ type: Actions.ToggleLoadingHistory, loading: false });
        }
    }

    const initialState: IState = {
        loading: false,
        benefitHistory: []
    }

    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case Actions.SetBenefitHistory:
                return update(currentState,
                    {
                        benefitHistory: { $set: action.benefitHistory }
                    });
            case Actions.ToggleLoadingHistory:
                return { ...currentState, loading: action.loading }
            default:
                return currentState || initialState;
        }
    }
}