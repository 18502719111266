import Globals from "./../../Globals";

export default class ManageAccounts {
       public createCredentialsButtonText: string = `CREATE CREDENTIALS`;
       public createCredentialsDialogDesc: string = `Create new space dashboard credentials by completing space email address & password. Please make sure you retain the password, it won't be displayed anywhere. In case of losing it, reset the password and create a new one.`;
       public createCredentialsDialogHeader: string = `Create new space dashboard credentials`;
       public currentEmailAddressLabel: string = `Current email address: `;
       public editEmailAddressDialogDesc: string = `Change the space email address by entering a new one. With the email address space gains access to space dashboard.`;
       public editEmailAddressDialogHeader: string = `Edit email address`;
       public editEmailButtonText: string = `EDIT EMAIL ADDRESS`;
       public emailAddressLabel: string = `Email address`;
       public hasCredentialsLabel: string = `✓ Has credentials`;
       public manageAccountsHeader: string = `Manage accounts`;
       public newEmailAddressLabel: string = `New email address: `;
       public newPasswordLabel: string = `New password`;
       public noCredentialsLabel: string = `✕ No credentials set`;
       public noSpaceLabel: string = `No spaces yet`;
       public noPrivateOfficesLabel: string = `No company offices yet`;
       public passwordLabel: string = `Password`;
       public resetPasswordDialogDesc: string = `Reset the password by entering a new one. Please make sure you retain the password, it won't appear anywhere. In case of losing it, you must reset the password.`;
       public resetPasswordDialogHeader: string = `Reset password`;
       public searchBarPlaceholder: string = `Search by group name`;
       public spaceLabel: string = `{0} space: `;
       public spacesLabel: string = `{0} spaces: `;
       public privateOfficeLabel: string = `{0} company office: `;
       public privateOfficesLabel: string = `{0} company offices: `;

		public static get Resources(): ManageAccounts{
			switch (Globals.currentActiveLanguage) {
				default:
				return new ManageAccounts();
		}
   	}
}
