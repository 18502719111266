import Globals from "./../../../Globals";

export default class Clients {
       public contactInfoGridColumnLabel: string = `Customer contact info`;
       public countryGridColumnLabel: string = `Country`;
       public createNewCustomer: string = `Add customer`;
       public csvExtension: string = `.csv`;
       public customersGridSearchPlaceholder: string = `Search by customer name or ID`;
       public customersLabel: string = `Customers`;
       public exportCustomersCsv: string = `Export Customers.csv`;
       public exportCustomersHeader: string = `Id,Name,Deactivated,Number Of Employees,Number Of Users,Email Doamins,Price,Currency,Country Id,Country Name,Contact Phone,Contact Email,Renewal Interval Value,Renewal Interval Unit,Last Renewal Date,Next Renewal Date,ayment Provider Id,Payment Provider`;
       public idLabel: string = `ID: #{0}`;
       public lastRenewalDateLabel: string = `Last on {0}`;
       public manuallyPaymentProvider: string = `Paying manually`;
       public nameGridColumnLabel: string = `Customer name`;
       public nextRenewalDateLabel: string = `Next on {0}`;
       public paymentProviderLabel: string = `Paying with {0}`;
       public planGridColumnLabel: string = `Plan name`;
       public priceGridColumnLabel: string = `Price`;
       public renewalDatesGridColumnLabel: string = `Renewal dates`;
       public renewalIntervalGridColumnLabel: string = `Renewal interval`;
       public userIdLabel: string = `User ID: #{0}`;

		public static get Resources(): Clients{
			switch (Globals.currentActiveLanguage) {
				default:
				return new Clients();
		}
   	}
}
