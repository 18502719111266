import ErrorMessages from "../resources/Common/ErrorMessages";
import { ApplicationState } from "../store/index";
import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { LoginStore } from "../store/LoginStore";
import General from "../resources/AdminUI/General";
import bind from "bind-decorator";

class UnauthorizedPage extends React.PureComponent<any, any>{

    @bind
    reload() {
        window.location.href = '/';
    }

    render() {
        return <div>
            <Helmet title={ErrorMessages.Resources.unauthorizedErrorMessage} />
            <div className="container container-content">
                <div className="panel">
                    <div className="panel-body">
                        <h4 className="text-center">{ErrorMessages.Resources.unauthorizedErrorMessage}</h4>
                        <section>
                            <button onClick={() => this.reload()}>{General.Resources.refresh}</button>
                            <button onClick={() => this.props.logout()}>{General.Resources.logout}</button>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    }
}
export default connect(
    (state: ApplicationState) => ({}),
    {
        logout: LoginStore.actionCreators.logout
    }
)(UnauthorizedPage as any);