import Globals from "./../../../Globals";

export default class AddOrEditWorkspaceRoomForm {
  public acceptedFormats: string = `Accepted formats: .png or .jpg, maxim 2MB each`;
  public addRoom: string = `Add Room`;
  public bookingRequirements: string = `BOOKING REQUIREMENTS`;
  public contactInformation: string = `Contact information`;
  public dailyPrice: string = `Daily price`;
  public editRoom: string = `Edit Room`;
  public emailAddress: string = `Email address`;
  public fullDescription: string = `Full description`;
  public hourlyPrice: string = `Hourly price`;
  public ifRequiredEnterAMinimumBookingTimeForDailyBookings: string = `If space requires, enter a minimum booking time for daily bookings. Ex: "2" for 2 hours minimum booking`;
  public ifRequiredEnterAMinimumBookingTimeForHourlyBookings: string = `If space requires, enter a minimum booking time for hourly bookings. Ex: "2" for 2 hours minimum booking`;
  public maximumNoOfGuests: string = `Maximum no. of guests`;
  public minimumDailyBookingTime: string = `Minimum daily booking time`;
  public minimumHourlyBookingTime: string = `Minimum hourly booking time`;
  public monthlyPrice: string = `Monthly price`;
  public nameWillBeVisibleInMobileApplication: string = `Name will be visible in mobile application`;
  public phoneNumber: string = `Phone number`;
  public pleaseCompleteEverydaysProgramIfTheRoomIsClosed: string = `Please complete everyday's program. If the room is closed, check the 'Closed' option.`;
  public pleaseSelectAllAvailableAmenitiesHighlightImportantAmenitiesAndTheyWillAppearOnTheMainSpaceDetailsScreen: string = `Please select all available room amenities. Highlight important amenities by checking the HIGHLIGHTED button and they will appear on the main Space Details screen.`;
  public presentationDetails: string = `PRESENTATION DETAILS`;
  public recommendBetween200And400Characters: string = `We recommend a description between 200 and 400 characters`;
  public roomAmenities: string = `Room amenities`;
  public roomCanBeBookedDaily: string = `Room can be booked daily`;
  public roomCanBeBookedHourly: string = `Room can be booked hourly`;
  public roomCanBeBookedMonthly: string = `Room can be booked monthly`;
  public roomName: string = `Room name`;
  public roomOpeningHours: string = `Room opening hours`;
  public roomPhoneNumberAndEmailAddressWillBeDisplayedInAppForUsers: string = `Room phone number and email address will be displayed in application for users`;
  public roomPhotographs: string = `Room photographs`;
  public roomPrices: string = `ROOM PRICES`;
  public selectFromTheListAndHighlightTheImportantOnes: string = `Select from the list all room amenities and highlight important ones`;
  public selectRoomAmenities: string = `Select room amenities`;
  public selectRoomSchedule: string = `Select room schedule`;
  public typeOfRoom: string = `Type of room`;
  public useWorkspaceSchedule: string = `Use workspace schedule`;
  public externalCalendarId: string = `External Calendar ID`;

  public static get Resources(): AddOrEditWorkspaceRoomForm {
    switch (Globals.currentActiveLanguage) {
      default:
        return new AddOrEditWorkspaceRoomForm();
    }
  }
}
