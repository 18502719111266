import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { TabStore } from "../../store/TabStore";
import { ApplicationState } from "../../store";
import TabsHeader from "../Tabs/TabsHeader";
import { ITabItem, ITabTitleItem } from "../../utils/reactUtils";
import { DescriptionTab } from "../Tabs/DescriptionTab";
import { AmenitiesTab } from "../Tabs/AmenitiesTab";
import { ScheduleTab } from "../Tabs/ScheduleTab";
import { ContactInfoTab } from "../Tabs/ContactInfoTab";
import General from "../../resources/AdminUI/General";
import { DetailsTypes } from "../../enums/Common/DetailsTypes";
import Rooms from "../../resources/AdminUI/Rooms/Rooms";

class RoomDetailsTabsComponent extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    var tabs = [
      {
        tabId: DetailsTypes.Description.toString(),
        title: Rooms.Resources.description,
        class: "btn",
        content: () => (
          <DescriptionTab
            descriptionEn={this.props.descriptionEn}
            descriptionEs={this.props.descriptionEs}
            descriptionPt={this.props.descriptionPt}
          />
        ),
      } as ITabItem,
      {
        tabId: DetailsTypes.Amenities.toString(),
        title: Rooms.Resources.amenities,
        class: "btn",
        content: () => <AmenitiesTab amenities={this.props.amenities} />,
      } as ITabItem,
      {
        tabId: DetailsTypes.Schedule.toString(),
        title: Rooms.Resources.schedule,
        class: "btn",
        content: () => <ScheduleTab schedule={this.props.schedule} />,
      } as ITabItem,
      {
        tabId: DetailsTypes.ContactInfo.toString(),
        title: Rooms.Resources.publicContactInfo,
        class: "btn",
        content: () => (
          <ContactInfoTab email={this.props.email} phone={this.props.phone} />
        ),
      } as ITabItem,
    ];

    this.props.initializeTabPage(
      tabs,
      DetailsTypes.Description.toString(),
      null
    );
  }

  render() {
    if (!this.props?.tabState) {
      return (
        <div>
          <h2 className="text-center">{General.Resources.loading}</h2>
        </div>
      );
    }

    let activeTab = this.props.tabState.tabs.filter(
      (t) => t.tabId == this.props.tabState.activeTabId
    )[0];
    return (
      <div>
        <TabsHeader
          onTabClick={this.props.changeActiveTab}
          activeTabId={activeTab.tabId}
          className={"page-tabs"}
          tabs={this.props.tabState.tabs.map((t) => {
            return {
              title: t.title,
              tabId: t.tabId,
              class: t.class,
            } as ITabTitleItem;
          })}
        />
        <div className="tab-content">{activeTab.content()}</div>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState, ownProps: any) => {
    return { tabState: state.tab[ownProps.componentId] };
  },
  (dispatch, ownProps: any) =>
    bindActionCreators(
      {
        ...TabStore.getActionCreators(ownProps.componentId),
      },
      dispatch
    )
)(RoomDetailsTabsComponent);
