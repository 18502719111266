import React, { useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";
import { ChevronDown, ChevronRight } from "react-feather";

interface TableProps<T> {
  data: T[];
  columns: ColumnDef<T>[];
  pageCount: number;
  expandedContent: (row: T) => React.ReactNode;
}

function Table<T extends { id: number }>({
  data,
  columns,
  pageCount,
  expandedContent,
}: Readonly<TableProps<T>>) {
  const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>({});

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount,
  });

  const toggleRowExpanded = (rowId: number) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  return (
    <div className="overflow-x-auto table-wrapper">
      <table className="table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              <th className="uppercase tracking-wider">
                {/* Empty header for expand column */}
              </th>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="uppercase tracking-wider">
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <React.Fragment key={row.id}>
              <tr>
                <td>
                  <button onClick={() => toggleRowExpanded(row.original.id)}>
                    {expandedRows[row.original.id] ? (
                      <ChevronDown size={20} />
                    ) : (
                      <ChevronRight size={20} />
                    )}
                  </button>
                </td>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="px-6 py-4 whitespace-nowrap">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
              {expandedRows[row.original.id] && (
                <tr className="colspan-row">
                  <td colSpan={columns.length + 1}>
                    {expandedContent(row.original)}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
