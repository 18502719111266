import bind from "bind-decorator";
import Validations from "../../../resources/Common/Validations";
import { String_Format, nameof } from "../../../utils/utils";
import { BaseValidator } from "../Base/BaseValidator";
import Benefits from "../../../resources/AdminUI/Benefits/Benefits";
import { BenefitTypes } from "../../../enums/Benefits/BenefitTypes";

export class AddOrEditBenefitValidator extends BaseValidator {

    constructor() {
        super();
    }

    @bind
    validate(model: any, props: any) {

        this.check(() => model.benefit.name != '' && model.benefit.name != null, nameof(e => e.name), String_Format(Validations.Resources.required, Benefits.Resources.serviceTitle));
        // this.check(() => !model.benefit.name || model.benefit.name.length <= 50, nameof(e => e.name), String_Format(Validations.Resources.maxLength, 50));
       
        // this.check(() => !model.benefit.description || model.benefit.description.length <= 500, nameof(e => e.description), String_Format(Validations.Resources.maxLength, 500));
        
        this.check(() => model.benefit.isUnlimited || (model.benefit.value != '' && model.benefit.value != null), nameof(e => e.value), String_Format(Validations.Resources.required, Benefits.Resources.serviceQuanity));
        
        this.check(() => model.benefit.featureId != BenefitTypes.RoomBudget || ( model.benefit.currency != '' && model.benefit.currency != null), nameof(e => e.currency), String_Format(Validations.Resources.required, Benefits.Resources.serviceCurrency));

        this.check(() => model.benefit.renewIntervalUnitId != '' && model.benefit.renewIntervalUnitId != null, nameof(e => e.renewIntervalUnitId), String_Format(Validations.Resources.required, Benefits.Resources.renewalTypeLabel));
        this.check(() => model.benefit.renewIntervalValue != '' && model.benefit.renewIntervalValue != null, nameof(e => e.renewIntervalValue), String_Format(Validations.Resources.required, Benefits.Resources.renewalValueLabel));

        return this.getErrorsDictionary();
    }
}