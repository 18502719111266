import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { ApplicationState } from "../../../store";
import { TabStore } from "../../../store/TabStore";
import TabsHeader from "../../Tabs/TabsHeader";
import General from "../../../resources/AdminUI/General";
import { ITabItem, ITabTitleItem } from "../../../utils/reactUtils";
import TeamsAndUsersTabComponent from "./TeamsAndUsersTabContent";

class TeamsAndUsersTabs extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);

    if (this.props.teams.length != 0) {
      var tabs = this.props.teams.map((e) => {
        return {
          tabId: e.id,
          title:
            e.name != null && e.name != ""
              ? e.name
              : General.Resources.defaultTeamName,
          class: "",
          content: () => (
            <TeamsAndUsersTabComponent
              editTeam={this.props.editTeam}
              team={e}
            />
          ),
        } as ITabItem;
      });

      this.props.initializeTabPage(tabs, tabs[0].tabId, null);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    if (this.props.teams != prevProps.teams) {
      var tabs = this.props.teams.map((e) => {
        return {
          tabId: e.id,
          title:
            e.name != null && e.name != ""
              ? e.name
              : General.Resources.defaultTeamName,
          class: "",
          content: () => (
            <TeamsAndUsersTabComponent
              editTeam={this.props.editTeam}
              team={e}
            />
          ),
        } as ITabItem;
      });

      this.props.initializeTabPage(tabs, tabs[0].tabId, null);
    }
  }

  render() {
    {
      if (this.props.tabState == undefined) {
        return (
          <div>
            <h2 className="text-center">{General.Resources.loading}</h2>
          </div>
        );
      }
    }
    var activeTab = this.props.tabState.tabs.filter(
      (t) => t.tabId == this.props.tabState.activeTabId
    )[0];

    return (
      <div>
        <TabsHeader
          onTabClick={this.props.changeActiveTab}
          activeTabId={activeTab.tabId}
          className={"section-table-tabs"}
          tabs={this.props.tabState.tabs.map((t) => {
            return {
              title: t.title,
              tabId: t.tabId,
              class: t.class,
            } as ITabTitleItem;
          })}
        />
        <div>{activeTab.content()}</div>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState, ownProps: any) => {
    return { tabState: state.tab[ownProps.componentId] };
  },
  (dispatch, ownProps: any) =>
    bindActionCreators(
      {
        ...TabStore.getActionCreators(ownProps.componentId),
      },
      dispatch
    )
)(TeamsAndUsersTabs);
