import Result from "../models/Common/Result";
import { AppSettings } from "../utils/settings";
import { ServiceBase } from "./ServiceBase";
import ISpaceGroup from "../models/ManageAccounts/ISpaceGroup";
import { IEntitiesList } from "../models/Common/IEntitiesList";
import { ICompanyAccount } from "../models/ManageAccounts/ICompanyAccount";
import { ISpaceGroupAccount } from "../models/ManageAccounts/ISpaceGroupAccount";
import { PayloadTypes } from "../enums/Common/PayloadTypes";
import ICompany from "../models/ManageAccounts/ICompany";

export default class ManageAccountsService extends ServiceBase {

    static getSpaceGroupsList = (filters?: string): Promise<Result<IEntitiesList<ISpaceGroup>>> => {
        var url = `${AppSettings.CommonSettings.ApiUrl}/odata/workspace-groups`

        if (filters) {
            url = `${url}?$filter=${filters}`;
        }

        try {
            var result = ManageAccountsService.requestJsonFetch<IEntitiesList<ISpaceGroup>>({
                method: "GET",
                url: url
            });
            return result;
        } catch (error) { }

        return new Promise<Result<IEntitiesList<ISpaceGroup>>>(null);
    }

    static getCompaniesList = (filters?: string): Promise<Result<IEntitiesList<ICompany>>> => {
        var url = `${AppSettings.CommonSettings.ApiUrl}/odata/companies-for-manage-accounts`

        if (filters) {
            url = `${url}?$filter=${filters}`;
        }

        try {
            var result = ManageAccountsService.requestJsonFetch<IEntitiesList<ICompany>>({
                method: "GET",
                url: url
            });
            return result;
        } catch (error) { }

        return new Promise<Result<IEntitiesList<ICompany>>>(null);
    }

    static getSpaceGroupAccounts = (id: number): Promise<Result<ISpaceGroupAccount[]>> => {
        try {
            var result = ManageAccountsService.requestJsonFetch<ISpaceGroupAccount[]>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}/manage-accounts/workspace-groups/${id}/accounts`
            });
            return result;
        } catch (error) { }
    }

    static getCompanyAccounts = (id: number): Promise<Result<ICompanyAccount[]>> => {
      try {
          var result = ManageAccountsService.requestJsonFetch<ICompanyAccount[]>({
              method: "GET",
              url: `${AppSettings.CommonSettings.ApiUrl}/manage-accounts/company/${id}/accounts`
          });
          return result;
      } catch (error) { }
  }

    static addSpaceGroupAccount = (account: ISpaceGroupAccount): Promise<Result<ISpaceGroupAccount>> => {

        var result = ManageAccountsService.sendData<any>({
            method: "POST",
            url: `${AppSettings.CommonSettings.ApiUrl}/manage-accounts/workspace-groups/accounts`,
            data: JSON.stringify(account)
        }, PayloadTypes.Json);

        return result;
    }

    static addCompanyAccount = (account: ICompanyAccount): Promise<Result<ICompanyAccount>> => {

      var result = ManageAccountsService.sendData<any>({
          method: "POST",
          url: `${AppSettings.CommonSettings.ApiUrl}/manage-accounts/company/accounts`,
          data: JSON.stringify(account)
      }, PayloadTypes.Json);

      return result;
  }

    static updateSpaceGroupAccount = (id: number, account: ISpaceGroupAccount): Promise<Result<ISpaceGroupAccount>> => {

        var result = ManageAccountsService.sendData<any>({
            method: "PUT",
            url: `${AppSettings.CommonSettings.ApiUrl}/manage-accounts/workspace-groups/accounts/${id}`,
            data: JSON.stringify(account)
        }, PayloadTypes.Json);

        return result;
    }
    static updateCompanyAccount = (id: number, account: ICompanyAccount): Promise<Result<ICompanyAccount>> => {

      var result = ManageAccountsService.sendData<any>({
          method: "PUT",
          url: `${AppSettings.CommonSettings.ApiUrl}/manage-accounts/company/accounts/${id}`,
          data: JSON.stringify(account)
      }, PayloadTypes.Json);

      return result;
  }
}