import Globals from "./../../../Globals";

export default class UserBenefits {
       public noBenefitsText: string = `This user does not have services yet.`;
       public userServicesText: string = `Services used`;

		public static get Resources(): UserBenefits{
			switch (Globals.currentActiveLanguage) {
				default:
				return new UserBenefits();
		}
   	}
}
