import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { ITeam } from "../models/Teams/Interfaces/ITeam";
import update from "immutability-helper";
import { getServerSideErrors } from "../utils/utils";
import TeamsService from "../services/TeamsService";
import { BookingRestrictionTypes } from "../enums/Teams/BookingRestrictionTypes";

export namespace AddOrEditTeamStore {
  export interface IState {
    loading: boolean;
    team: ITeam;
    hasServerSideErrors: boolean;
    errors: string;
  }

  export enum Actions {
    ToggleLoading = "ADD_OR_EDIT_TEAM_TOGGLE_LOADING",
    Initialize = "ADD_OR_EDIT_TEAM_INITIALIZE",
    SetCompanyId = "ADD_OR_EDIT_TEAM_SET_COMPANY_ID",
    SetTeamDetails = "ADD_OR_EDIT_TEAM_SET_TEAM_DETAILS",
    SetErrors = "ADD_OR_EDIT_TEAM_SET_ERRORS",
  }

  interface IToggleLoading {
    type: Actions.ToggleLoading;
    loading: boolean;
  }

  interface IInitialize {
    type: Actions.Initialize;
  }

  interface ISetCompanyId {
    type: Actions.SetCompanyId;
    companyId: number;
  }

  interface ISetTeamDetails {
    type: Actions.SetTeamDetails;
    teamDetails: ITeam;
  }

  interface ISetErrors {
    type: Actions.SetErrors;
    hasServerSideErrors: boolean;
    errors: string;
  }

  type KnownAction =
    | IToggleLoading
    | IInitialize
    | ISetCompanyId
    | ISetTeamDetails
    | ISetErrors;

  export const actionCreators = {
    initializeCreatePage:
      (companyId: number): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({ type: Actions.Initialize });
        dispatch({ type: Actions.SetCompanyId, companyId: companyId });
      },

    initializeEditPage:
      (id: number): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({ type: Actions.ToggleLoading, loading: true });

        var details = await TeamsService.getTeamDetailsForEdit(id).then(
          (response) => response.value
        );

        dispatch({
          type: Actions.SetTeamDetails,
          teamDetails: details,
        });
        dispatch({ type: Actions.ToggleLoading, loading: false });
      },

    addOrEditTeam:
      (team: ITeam): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({ type: Actions.ToggleLoading, loading: true });

        var teamDTO = {
          id: team.id,
          name: team.name,
          defaultTeam: team.defaultTeam,
          description: team.description,
          companyId: team.companyId,
          workspaceBookingRestrictions: {
            countries: {
              type: team.countryRestrictionsType,
              ids: team.countryIds,
            },
            cities: {
              type: team.cityRestrictionsType,
              ids: team.cityIds,
            },
            categories: {
              type: team.spaceTypeRestrictionsType,
              ids: team.spaceTypeIds,
            },
            workspaces: {
              type: team.spaceRestrictionsType,
              ids: team.spaceIds,
            },
            weekDays: {
              type: team.weekDayRestrictionsType,
              ids: team.weekDayIds,
            },
            maxDailyCheckins: team.maxDailyCheckins,
          },
        };

        dispatch({
          type: Actions.SetTeamDetails,
          teamDetails: team,
        });

        if (team.id) {
          var serverResponse = await TeamsService.editTeam(teamDTO).then(
            (response) => response
          );
        } else {
          var serverResponse = await TeamsService.addTeam(teamDTO).then(
            (response) => response
          );
        }

        var errors = getServerSideErrors(serverResponse);
        var hasServerSideErrors = errors ? true : false;

        if (hasServerSideErrors) {
          dispatch({
            type: Actions.SetErrors,
            hasServerSideErrors: hasServerSideErrors,
            errors: errors,
          });
        }
      },
  };

  const initialState: IState = {
    loading: false,
    team: {
      id: null,
      name: "",
      defaultTeam: false,
      description: "",
      companyId: null,
      createdAt: "",
      spaceTypeRestrictionsType: BookingRestrictionTypes.None,
      countryRestrictionsType: BookingRestrictionTypes.None,
      cityRestrictionsType: BookingRestrictionTypes.None,
      weekDayRestrictionsType: BookingRestrictionTypes.None,
      spaceRestrictionsType: BookingRestrictionTypes.None,
      countryIds: [],
      cityIds: [],
      spaceTypeIds: [],
      weekDayIds: [],
      spaceIds: [],
      benefits: [],
      maxDailyCheckins: 1,
    },
    hasServerSideErrors: false,
    errors: "",
  };

  export const reducer: Reducer<IState> = (
    currentState: IState,
    incomingAction: Action
  ) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
      case Actions.ToggleLoading:
        return { ...currentState, loading: action.loading };
      case Actions.Initialize:
        return initialState;
      case Actions.SetCompanyId:
        return update(currentState, {
          team: {
            companyId: { $set: action.companyId },
          },
        });
      case Actions.SetTeamDetails:
        return update(currentState, {
          team: {
            id: { $set: action.teamDetails.id },
            name: { $set: action.teamDetails.name },
            defaultTeam: { $set: action.teamDetails.defaultTeam },
            description: { $set: action.teamDetails.description },
            companyId: { $set: action.teamDetails.companyId },
            createdAt: { $set: action.teamDetails.createdAt },
            spaceTypeRestrictionsType: {
              $set: action.teamDetails.spaceTypeRestrictionsType,
            },
            countryRestrictionsType: {
              $set: action.teamDetails.countryRestrictionsType,
            },
            cityRestrictionsType: {
              $set: action.teamDetails.cityRestrictionsType,
            },
            weekDayRestrictionsType: {
              $set: action.teamDetails.weekDayRestrictionsType,
            },
            spaceRestrictionsType: {
              $set: action.teamDetails.spaceRestrictionsType,
            },
            countryIds: { $set: action.teamDetails.countryIds },
            cityIds: { $set: action.teamDetails.cityIds },
            spaceTypeIds: { $set: action.teamDetails.spaceTypeIds },
            weekDayIds: { $set: action.teamDetails.weekDayIds },
            spaceIds: { $set: action.teamDetails.spaceIds },
            benefits: { $set: action.teamDetails.benefits },
            maxDailyCheckins: { $set: action.teamDetails.maxDailyCheckins },
          },
        });
      case Actions.SetErrors:
        return update(currentState, {
          hasServerSideErrors: { $set: action.hasServerSideErrors },
          errors: { $set: action.errors },
        });
      default:
        return currentState || initialState;
    }
  };
}
