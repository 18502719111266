import React from "react";

import { formatDate } from "../../utils/date";
import { RoomBookingStatuses } from "../../enums/RoomBookings/RoomBookingStatuses";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import { IRoomBookingsItem } from "../../models/RoomBookings/Interfaces/IRoomBookingsItem";

interface IProps {
  row: IRoomBookingsItem;
}

const ExpandedContent: React.FC<IProps> = ({ row }) => {
  return (
    <div>
      <div className="span-details-row">
        {RoomBookingsGrid.Resources.emailAddress} <span>{row.userEmail}</span>
      </div>

      <div className="span-details-row">
        {RoomBookingsGrid.Resources.phoneNumber} <span>{row.userPhone}</span>
      </div>

      <div className="span-details-row">
        {row.statusId === RoomBookingStatuses.Cancelled && (
          <div className="span-details-row">
            {RoomBookingsGrid.Resources.cancelledAt}
            <span className="timestamp">{formatDate(row.cancelledAt)}</span>
          </div>
        )}
        {row.statusId === RoomBookingStatuses.Expired && (
          <div className="span-details-row">
            {RoomBookingsGrid.Resources.expiredAt}
            <span className="timestamp">{formatDate(row.expiresAt)}</span>
          </div>
        )}
        {row.statusId === RoomBookingStatuses.Rejected && (
          <div className="span-details-row">
            {RoomBookingsGrid.Resources.rejectedAt}
            <span className="timestamp">{formatDate(row.rejectedAt)}</span>
          </div>
        )}
      </div>

      <div className="span-details-row">
        {RoomBookingsGrid.Resources.internalNotes} <span>{row.userNotes}</span>
      </div>
    </div>
  );
};

export default ExpandedContent;
