import { ServiceBase } from "./ServiceBase";
import Result from "../models/Common/Result";
import { AppSettings } from "../utils/settings";
import { ISelectListItemForAmenity } from "../models/Common/ISelectListItemForAmenity";
import { IAntdSelectListItem } from "../models/Common/IAntdSelectListItem";

export default class LookupService extends ServiceBase {

    static getAllCountries = (): Promise<Result<IAntdSelectListItem[]>> => {
        
        try{
            var result = LookupService.requestJsonFetch<IAntdSelectListItem[]>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}/lookup/countries`
            });
            return result;
        }catch (error) {  
        }

        return new Promise<Result<IAntdSelectListItem[]>>(null);
    }

    static getAllCountriesWithCities = (): Promise<Result<IAntdSelectListItem[]>> => {
        
        try{
            var result = LookupService.requestJsonFetch<IAntdSelectListItem[]>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}/lookup/countries-with-cities`
            });
            return result;
        }catch (error) {  
        }

        return new Promise<Result<IAntdSelectListItem[]>>(null);
    }

    static getAllCities = (): Promise<Result<IAntdSelectListItem[]>> => {
        
        try{
            var result = LookupService.requestJsonFetch<IAntdSelectListItem[]>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}/lookup/cities`
            });
            return result;
        }catch (error) {  
        }

        return new Promise<Result<IAntdSelectListItem[]>>(null);
    }
    
    static getAllCurrencies = (): Promise<Result<IAntdSelectListItem[]>> => {
        
        try{
            var result = LookupService.requestJsonFetch<IAntdSelectListItem[]>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}/lookup/currencies`
            });
            return result;
        }catch (error) {  
        }

        return new Promise<Result<IAntdSelectListItem[]>>(null);
    }
    
    static getAllWorkspaceGroups = (): Promise<Result<IAntdSelectListItem[]>> => {
        
        try{
            var result = LookupService.requestJsonFetch<IAntdSelectListItem[]>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}/lookup/workspace-groups`
            });
            return result;
        }catch (error) {  
        }

        return new Promise<Result<IAntdSelectListItem[]>>(null);
    }
    
    
    static getAllLanguages = (): Promise<Result<IAntdSelectListItem[]>> => {
        
        try{
            var result = LookupService.requestJsonFetch<IAntdSelectListItem[]>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}/lookup/languages`
            });
            return result;
        }catch (error) {  
        }

        return new Promise<Result<IAntdSelectListItem[]>>(null);
    }
    
    
    static getAllAmenities = (): Promise<Result<ISelectListItemForAmenity[]>> => {
        
        try{
            var result = LookupService.requestJsonFetch<ISelectListItemForAmenity[]>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}/lookup/amenities`
            });
            return result;
        }catch (error) {  
        }

        return new Promise<Result<ISelectListItemForAmenity[]>>(null);
    }
 
    
    static getAllAdminUsers = (): Promise<Result<IAntdSelectListItem[]>> => {
        
        try{
            var result = LookupService.requestJsonFetch<IAntdSelectListItem[]>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}/lookup/admin-users`
            });
            return result;
        }catch (error) {  
        }

        return new Promise<Result<IAntdSelectListItem[]>>(null);
    }

}