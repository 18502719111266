import Globals from "./../../../Globals";

export default class AssignBenefitToTeamOrUser {
       public applyFromWhen: string = `Apply from when?`;
       public assignServiceDesc: string = `Assign services for teams or users by selecting one or multiple teams or users from the list.`;
       public assignServiceToTeamOrUserTitle: string = `Assign service to team or user`;
       public beneficiaries: string = `Beneficiaries`;
       public chooseBeneficiaries: string = `Choose beneficiaries`;
       public fromWhomDoYouAssign: string = `From whom do you assign?`;
       public serviceId: string = `Service Id`;

		public static get Resources(): AssignBenefitToTeamOrUser{
			switch (Globals.currentActiveLanguage) {
				default:
				return new AssignBenefitToTeamOrUser();
		}
   	}
}
