import React from "react";
import { ICityItem } from "../../../models/CountriesAndCities/ICityItem";
import CountriesAndCities from "../../../resources/AdminUI/CountriesAndCities";
import General from "../../../resources/AdminUI/General";
import bind from "bind-decorator";
import AddOrEditCityForm from "../AddOrEditCityForm";
import ModalDialog from "../../Shared/ModalDialog";

interface IProps {
    city: ICityItem;
    countryId?: number;
    onSaveCityCallback?: (city: ICityItem) => void;
    onResetCallback?: () => void;
}

interface IState {
    isEditCityModalOpen: boolean;
}

export class CityCard extends React.PureComponent<IProps, IState> {
    constructor(props) {
        super(props)
        this.state = {
            isEditCityModalOpen: false
        }
    }

    @bind
    showEditCityModal() {
        this.setState({ isEditCityModalOpen: true });
    }

    @bind
    hideEditCityModal() {
        this.setState({ isEditCityModalOpen: false });
    }

    render() {
        return <div className="city-list-item">

            <div className="info-container">
                <h2 className="city-name">{this.props.city.name}</h2>
                <div className="coordinates">{CountriesAndCities.Resources.cityLatitudeLabel}: <span>{this.props.city.lat}</span></div>
                <div className="coordinates">{CountriesAndCities.Resources.cityLongitudeLabel}: <span>{this.props.city.lng}</span></div>
                <div className="timezone">{CountriesAndCities.Resources.cityTimeZone}: <span>{this.props.city.timezone}</span> </div>
            </div>

            <div>
                <button className="btn-big btn-secondary"
                    onClick={() => this.showEditCityModal()}>
                    {General.Resources.edit}
                </button>
                <ModalDialog
                    title={CountriesAndCities.Resources.addANewCityLabel}
                    open={this.state.isEditCityModalOpen}
                    onCancel={this.hideEditCityModal}>
                    <AddOrEditCityForm
                        city={this.props.city}
                        countryId={this.props.countryId}
                        onCancelCallback={this.hideEditCityModal}
                        onSaveCityCallback={this.props.onSaveCityCallback}
                        onResetCallback={this.props.onResetCallback} />,
                </ModalDialog>
            </div>

        </div>
    }
}
