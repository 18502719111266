import React from "react";
import ManageAccounts from "../../../resources/AdminUI/ManageAccounts";
import { ISpaceGroupAccount } from "../../../models/ManageAccounts/ISpaceGroupAccount";
import bind from "bind-decorator";
import EditEmailAddressDialog from "../Dialogs/EditEmailAddressDialog";
import ResetPasswordDialog from "../Dialogs/ResetPasswordDialog";
import ModalDialog from "../../Shared/ModalDialog";

interface IProps {
    account: ISpaceGroupAccount,
    onSaveAccountCallback: (account: ISpaceGroupAccount) => void
}

interface IState {
    isResetPasswordModalOpen: boolean;
    isEditEmailAddressModalOpen: boolean;
}

export class SpaceGroupAccountCard extends React.PureComponent<IProps, IState> {
    constructor(props) {
        super(props)
        this.state = {
            isResetPasswordModalOpen: false,
            isEditEmailAddressModalOpen: false
        }
    }

    @bind
    showResetPasswordModal() {
        this.setState({ isResetPasswordModalOpen: true });
    }

    @bind
    hideResetPasswordModal() {
        this.setState({ isResetPasswordModalOpen: false });
    }

    @bind
    showEditEmailAddressModal() {
        this.setState({ isEditEmailAddressModalOpen: true });
    }

    @bind
    hideEditEmailAddressModal() {
        this.setState({ isEditEmailAddressModalOpen: false });
    }

    render() {
        return <div className="item">
            <div className="email-edit-container">
                <div onClick={this.showEditEmailAddressModal} className="btn-small">
                    <span className="material-symbols-outlined">edit</span>
                </div>
                <span>{this.props.account.email}</span>
            </div>
            <ModalDialog
                title={""}
                open={this.state.isEditEmailAddressModalOpen}
                onCancel={this.hideEditEmailAddressModal}>
                <EditEmailAddressDialog
                    account={{...this.props.account, companyId: null}}
                    onCancelCallback={this.hideEditEmailAddressModal}
                    onSaveCallback={this.props.onSaveAccountCallback} />
            </ModalDialog>

            <div className="reset-password-container">
                <button className="btn-big btn-secondary"
                    onClick={this.showResetPasswordModal}>
                    {ManageAccounts.Resources.resetPasswordDialogHeader}
                </button>
            </div>
            <ModalDialog
                title={""}
                open={this.state.isResetPasswordModalOpen}
                onCancel={this.hideResetPasswordModal}>
                <ResetPasswordDialog
                    account={{...this.props.account, companyId: null}}
                    onCancelCallback={this.hideResetPasswordModal}
                    onSaveCallback={this.props.onSaveAccountCallback} />
            </ModalDialog>

        </div>
    }
}