import Globals from "./../../Globals";

export default class CountriesAndCities {
       public addANewCityLabel: string = `Add a new city`;
       public addCityButtonLabel: string = `ADD NEW CITY`;
       public addCityDesc: string = `Add or edit cities by completing the following information. For timezone, enter the highlighted link, search for the city and copy-paste the timezone displayed in the right section.`;
       public citiesLabel: string = `Cities In {0}`;
       public cityLatitudeLabel: string = `Latitude`;
       public cityLongitudeLabel: string = `Longitude`;
       public cityNameLabel: string = `City Name`;
       public cityTimeZone: string = `Timezone`;
       public countriesAndCitiesHeaderLabel: string = `Countries and cities`;
       public countriesLabel: string = `Select Country`;
       public thereIsNoCityForThisCountry: string = `There is no city for this country`;
       public timezoneLink: string = `https://www.zeitverschiebung.net/en/`;
       public timezoneNoteText: string = `To choose the correct timezone, check the following`;

		public static get Resources(): CountriesAndCities{
			switch (Globals.currentActiveLanguage) {
				default:
				return new CountriesAndCities();
		}
   	}
}
