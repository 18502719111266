import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { BookingLogTypes } from "../../../enums/Common/BookingLogTypes";
import General from "../../../resources/AdminUI/General";
import HotdeskBookingsGrid from "../../../resources/AdminUI/HotdeskBookings/HotdeskBookingsGrid";
import RoomBookingsGrid from "../../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import { ApplicationState } from "../../../store";
import { TabStore } from "../../../store/TabStore";
import { ITabItem, ITabTitleItem } from "../../../utils/reactUtils";
import HotdeskBookingsTable from "../../HotdeskBookings/HotdeskBookingsTable";
import RoomBookingsGridWrapper from "../../RoomBookings/RoomBookingsGridWrapper";
import TabsHeader from "../../Tabs/TabsHeader";

const SpaceDetailsBookingsTablesTabComponent = (props: any) => {
  const {
    tabState,
    initializeTabPage,
    spaceId,
    showActionsColumn,
    hotdeskBookingsTableFilters,
    hotdeskBookingsTableHiddenColumns,
    hotdeskBookingsTableExpandableColumns,
    roomBookingsTableProps,
    changeActiveTab,
  } = props;

  const activeTabId =
    tabState?.activeTabId || BookingLogTypes.HotdeskLogs.toString();

  // Memoize tabs array to avoid unnecessary re-renders
  const tabs = useMemo(
    () => [
      {
        tabId: BookingLogTypes.HotdeskLogs.toString(),
        title: HotdeskBookingsGrid.Resources.desksLogs,
        class: "",
        content: () => (
          <HotdeskBookingsTable
            componentId={`space-hotdesk-bookings-${spaceId}`}
            showActionsColumn={showActionsColumn}
            filters={hotdeskBookingsTableFilters}
            hiddenColumns={hotdeskBookingsTableHiddenColumns}
            expandableColumns={hotdeskBookingsTableExpandableColumns}
          />
        ),
      } as ITabItem,
      {
        tabId: BookingLogTypes.RoomLogs.toString(),
        title: RoomBookingsGrid.Resources.roomLogs,
        class: "",
        content: () => <RoomBookingsGridWrapper {...roomBookingsTableProps} />,
      } as ITabItem,
    ],
    [
      spaceId,
      showActionsColumn,
      hotdeskBookingsTableFilters,
      hotdeskBookingsTableHiddenColumns,
      hotdeskBookingsTableExpandableColumns,
      roomBookingsTableProps,
    ]
  );

  useEffect(() => {
    initializeTabPage(tabs, activeTabId, null);
  }, [tabs, activeTabId, initializeTabPage]);

  if (!tabState) {
    return (
      <div>
        <h2 className="text-center">{General.Resources.loading}</h2>
      </div>
    );
  }

  const activeTab = tabState.tabs.find(
    (t: any) => t.tabId === tabState.activeTabId
  );

  return (
    <div>
      <TabsHeader
        onTabClick={(tabId) => changeActiveTab(tabId)}
        activeTabId={activeTab.tabId}
        className={"section-table-tabs"}
        tabs={tabState.tabs.map(
          (t: ITabItem) =>
            ({
              title: t.title,
              tabId: t.tabId,
              class: t.class,
            } as ITabTitleItem)
        )}
      />
      <div>{activeTab.content()}</div>
    </div>
  );
};

export default connect(
  (state: ApplicationState, ownProps: any) => ({
    tabState: state.tab[ownProps.componentId],
  }),
  (dispatch, ownProps: any) =>
    bindActionCreators(
      {
        ...TabStore.getActionCreators(ownProps.componentId),
      },
      dispatch
    )
)(SpaceDetailsBookingsTablesTabComponent);
