import Globals from "./../../../Globals";

export default class RoomTypes {
       public meetingRoom: string = `Meeting Room`;
       public privateOffice: string = `Private Office`;

		public static get Resources(): RoomTypes{
			switch (Globals.currentActiveLanguage) {
				default:
				return new RoomTypes();
		}
   	}
}
