import { SpaceStatuses } from "../../enums/Spaces/SpaceStatuses";
import SpaceStatusesList from "../../resources/AdminUI/Spaces/SpaceStatusesList";
import Workspaces from "../../resources/AdminUI/Spaces/Workspaces";
import { BaseEntity } from "../../utils/reactUtils";
import { String_Format, firstLetterToLowerCase } from "../../utils/utils";
import { ISpaceItem } from "./Interfaces/ISpaceItem";

export class SpaceItem extends BaseEntity implements ISpaceItem {

    constructor(e: ISpaceItem) {
        super();
        this.additionalConstructor(this, e);

        this.ForProperty(p => "name")
            .SetDisplayName(Workspaces.Resources.nameGridTitle)
            .Show(_self => <div className="column-medium">
                <p className="table-item-name">{this.name}</p>
                <p><span className={`table-item-status ${firstLetterToLowerCase(SpaceStatuses[this.statusId])}`}>{SpaceStatusesList.Resources[firstLetterToLowerCase(SpaceStatuses[this.statusId])]} </span> | <span className="table-item-id">{String_Format(Workspaces.Resources.idLabel, this.id)}</span></p>
            </div>);

        this.ForProperty(p => "address")
            .SetDisplayName(Workspaces.Resources.addressGridTitle)
            .Show(_self => <div className="column-large">
                <p className="table-item-highlight-row address-rules">{this.address}</p>
                <p className="table-item-subheading-row">{this.countryName}, {this.cityName}</p>
            </div>);

        this.ForProperty(p => "numberOfMeetingRooms")
            .SetDisplayName(Workspaces.Resources.featuresGridTitle)
            .Show(_self => <div className="column-large">
                {this.maxSeats == 0 && this.numberOfMeetingRooms == 0 && this.numberOfPrivateOffices == 0 || !this.maxSeats && !this.numberOfMeetingRooms && !this.numberOfPrivateOffices?
                    <>
                        <p>-</p>
                    </> :
                    <>
                        <p>{this.maxSeats == 0 ? null : Workspaces.Resources.hotDesks}</p> 
                        <p>{this.numberOfMeetingRooms.valueOf() ? String_Format(Workspaces.Resources.meetingRoomsLabel, this.numberOfMeetingRooms.toString()) : null}</p>
                        <p>{this.numberOfPrivateOffices.valueOf() ? String_Format(Workspaces.Resources.privateOfficeLabel, this.numberOfPrivateOffices.toString()) : null}</p>
                    </>
                }</div>);

        this.ForProperty(p => "email")
            .SetDisplayName(Workspaces.Resources.publicInformationGridTitle)
            .Show(_self => 
                <div className="column-xlarge">
                    <div className="table-item-icon-info">
                        <span className="material-symbols-outlined">phone</span>
                        <p>{this.phone ? this.phone : '-'}</p>
                    </div>
                    <div className="table-item-icon-info">
                        <span className="material-symbols-outlined">email</span>
                        <p>{this.email ? this.email : '-'}</p>
                    </div>
                 </div>
            );
                
    }

    id: number;
    name: string;
    address: string;
    cityName: string;
    cityId: number;
    countryName: string;
    countryId: number;
    maxSeats: number;
    createdAt: string;
    lastModifiedAt: string;
    numberOfMeetingRooms: number;
    numberOfPrivateOffices: number;
    requireBooking: boolean;
    categoryName: string;
    categoryId: number;
    email: string;
    phone: string;
    statusId: number;
    statusName: string;
    mealIncluded: boolean;
    
}