import bind from "bind-decorator";
import { BaseValidator } from "../Base/BaseValidator";
import Validations from "../../../resources/Common/Validations";
import { isEmail, nameof } from "../../../utils/utils";

export class AddOrEditSpaceCredentialsValidator extends BaseValidator {

    constructor() {
        super();
    }

    @bind
    validate(model: any, props: any) {

        this.check(() => model.account.email && isEmail(model.account.email), nameof(e => e.email), Validations.Resources.emailNotValid);
        this.check(() => model.account.password && model.account.password.length >= 8, nameof(e => e.password), Validations.Resources.passwordNotValid)
        
        return this.getErrorsDictionary();
    }
}