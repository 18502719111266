import bind from "bind-decorator";
import { BaseValidator } from "../Base/BaseValidator";
import { String_Format, nameof } from "../../../utils/utils";
import Validations from "../../../resources/Common/Validations";
import AddOrEditTeam from "../../../resources/AdminUI/Teams/AddOrEditTeam";

export class AddOrEditTeamValidator extends BaseValidator {

    constructor() {
        super();
    }

    @bind
    validate(model: any, props: any) {

        this.check(() => model.team.name != '' && model.team.name != null, nameof(e => e.name), String_Format(Validations.Resources.required, AddOrEditTeam.Resources.teamTitle));
        // this.check(() => !model.team.name || model.team.name.length <= 50, nameof(e => e.name), String_Format(Validations.Resources.maxLength, 50));
        // this.check(() => !model.team.description || model.team.description.length <= 100, nameof(e => e.description), String_Format(Validations.Resources.maxLength, 100));
        
        this.check(() => model.team.maxDailyCheckins != '' && model.team.maxDailyCheckins != null, nameof(e => e.maxDailyCheckins), String_Format(Validations.Resources.required, AddOrEditTeam.Resources.maxNrOfDailyCheckins));
        this.check(() => !model.team.maxDailyCheckins || model.team.maxDailyCheckins >= 1, nameof(e => e.maxDailyCheckins), String_Format(Validations.Resources.minValue, 1));
        
        return this.getErrorsDictionary();
    }
}