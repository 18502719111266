import React, { useCallback, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import Helmet from "react-helmet";
import { LocalDateTime } from "@js-joda/core";
import { Modal } from "antd";

import { firstLetterToLowerCase } from "../../utils/utils";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import { RoomBookingsItem } from "../../models/RoomBookings/RoomBookingsItem";
import ErrorMessages from "../../resources/Common/ErrorMessages";
import { RoomBookingRejectReasons } from "../../enums/RoomBookings/RoomBookingRejectReasons";
import RoomBookingRejectReasonList from "../../resources/AdminUI/RoomBookings/RoomBookingRejectReasonList";
import { getPendingRoomBookingsColumns } from "../../components/RoomBookings/PendingRoomBookingsColumns";
import { useRoomBookingsQueries } from "../../hooks/RoomBookings/useRoomBookingsQueries";
import General from "../../resources/AdminUI/General";
import { usePendingRoomsRequestsPage } from "../../hooks/RoomBookings/usePendingRoomsRequestsPage";
import { usePendingBookingsContext } from "../../providers/PendingBookingsProvider";
import RoomBookingsGridWrapper from "../../components/RoomBookings/RoomBookingsGridWrapper";

const { confirm } = Modal;

const PendingRoomRequestsPage = () => {
  const pendingStatus = "1";

  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState<boolean>(false);
  const [selectedRoomBooking, setSelectedRoomBooking] = useState<
    RoomBookingsItem | undefined
  >(undefined);

  const queryClient = useQueryClient();
  const {
    itemsPerPage,
    numberOfRoomBookings,
    lastIndexFromPage,
    firstIndexFromPage,
    pageIndexArray,
  } = usePendingBookingsContext();
  const { filters, skip, changeCurrentPage, reloadRoomBookingsData } =
    usePendingRoomsRequestsPage(pendingStatus);
  const {
    acceptRoomBooking: acceptRoomBookingRequest,
    getRoomBookingsForExport,
    getRoomBookings,
  } = useRoomBookingsQueries();

  const { mutate: acceptRoomBookingMutate } = acceptRoomBookingRequest();
  const { data, isLoading, error } = getRoomBookingsForExport(
    filters.split("&$count")[0]
  );

  const { data: roomBookingsData } = getRoomBookings(
    itemsPerPage,
    skip,
    filters
  );
  const hideDeclineRoomBookingModal = useCallback(() => {
    setIsDeclineModalOpen(false);
  }, []);

  const showDeclineRoomBookingModal = useCallback(
    (booking: RoomBookingsItem) => {
      setIsDeclineModalOpen(true);
      setSelectedRoomBooking(booking);
    },
    []
  );

  const getDeclineReasonsList = useCallback(() => {
    const options = Object.keys(RoomBookingRejectReasons)
      .filter((key) => typeof RoomBookingRejectReasons[key as any] === "number")
      .map((key) => ({
        value: RoomBookingRejectReasons[key as any].toString(),
        label:
          RoomBookingRejectReasonList.Resources[firstLetterToLowerCase(key)],
      }));
    return options;
  }, []);

  const acceptRoomBooking = useCallback(
    (id: number) => {
      acceptRoomBookingMutate(id, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["roomBookings"] });
        },
        onError: () => {
          Modal.error({
            title: ErrorMessages.Resources.generalErrorMessage,
          });
        },
      });
    },
    [acceptRoomBookingMutate]
  );

  const showAcceptRoomBookingModal = useCallback(
    (id: number) => {
      confirm({
        title: RoomBookingsGrid.Resources.acceptRoomRequest,
        content: (
          <div>
            <p>
              {RoomBookingsGrid.Resources.acceptRoomRequestConfirmationMessage}
            </p>
          </div>
        ),
        okText: RoomBookingsGrid.Resources.accept,
        okType: "primary",
        cancelText: General.Resources.cancelButtonLabel,
        onOk: () => acceptRoomBooking(id),
      });
    },
    [acceptRoomBooking]
  );

  const exportLogs = useCallback(async () => {
    if (isLoading) {
      console.log("Loading data...");
      return;
    }

    if (error) {
      console.error("Error fetching room bookings:", error);
      return;
    }

    if (data?.value) {
      const entities = data.value
        ? data.value.entities
            .map((e) =>
              Object.entries(e)
                .filter(
                  ([key]) =>
                    key !== "unixStartsAtWithTimezone" &&
                    key !== "timezone" &&
                    key !== "workspaceCountryFlag"
                )
                .map(([key, value]) =>
                  typeof value === "string" ? value.replaceAll(",", " ") : value
                )
                .join(",")
            )
            .join("\n")
        : "";
      const csvContent =
        RoomBookingsGrid.Resources.exportRoomBookingLogsHeader +
        "\n" +
        entities;

      const blob = new Blob([csvContent], {
        type: "text/csv;charset=utf-8;",
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `${
          RoomBookingsGrid.Resources.pendingRoomsRequest
        }_${LocalDateTime.now()}${RoomBookingsGrid.Resources.csvExtension}`
      );
      document.body.appendChild(link);
      link.click();
    }
  }, [data, error, isLoading]);

  const pendingRoomBookingColumns = getPendingRoomBookingsColumns({
    isDeclineModalOpen,
    selectedRoomBooking,
    showDeclineModal: showDeclineRoomBookingModal,
    hideDeclineModal: hideDeclineRoomBookingModal,
    getDeclineReasonsList,
    showAcceptRoomBookingModal,
  });

  const pagination = {
    totalItems: numberOfRoomBookings,
    firstIndexFromPage,
    lastIndexFromPage,
    pageIndexArray,
    maxPagesDisplayed: 3,
    pageNeighbours: 1,
    reload: reloadRoomBookingsData,
    changeCurrentPage,
  };

  return (
    <div className="space-list-page">
      <Helmet title={RoomBookingsGrid.Resources.pendingRoomsRequest} />
      <div className="page-top-container">
        <div className="page-title">
          {RoomBookingsGrid.Resources.pendingRoomsRequest}
        </div>
        <div className="page-actions">
          <div className="btn-big btn-secondary" onClick={exportLogs}>
            <p className="btn-secondary-text">
              {RoomBookingsGrid.Resources.exportRoomsLogsCsv}
            </p>
          </div>
        </div>
      </div>
      <div>
        <RoomBookingsGridWrapper
          data={roomBookingsData}
          isLoading={isLoading}
          itemsPerPage={itemsPerPage}
          roomBookingsColumns={pendingRoomBookingColumns}
          pagination={pagination}
        />
      </div>
    </div>
  );
};

export default PendingRoomRequestsPage;
