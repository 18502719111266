import bind from "bind-decorator";
import Validations from "../../../resources/Common/Validations";
import { BaseValidator } from "../Base/BaseValidator";

export class ScheduleFormValidtor extends BaseValidator {
  constructor() {
    super();
  }

  @bind
  validate(model: any, props: any) {
    model.schedule?.forEach((e, index) => {
      this.check(
        () =>
          e.isClosed
            ? true
            : e.openHour != null && e.closeHour != null
            ? true
            : false,
        index.toString(),
        Validations.Resources.generalRequired
      );
    });

    return this.getErrorsDictionary();
  }
}
