import { Action, Reducer } from "redux";
import ISpaceGroup from "../models/ManageAccounts/ISpaceGroup";
import { AppThunkAction } from ".";
import update from "immutability-helper";
import ManageAccountsService from "../services/ManageAccountsService";
import { ISpaceGroupAccount } from "../models/ManageAccounts/ISpaceGroupAccount";
import { ICompanyAccount } from "../models/ManageAccounts/ICompanyAccount";
import { getServerSideErrors } from "../utils/utils";
import ICompany from "../models/ManageAccounts/ICompany";

export namespace ManageAccountStore {
  export interface IState {
    loading: boolean;
    spaceGroups: ISpaceGroup[];
    companies: ICompany[]
    filters: string;
    selectedSpaceGroup: ISpaceGroup;
    selectedCompany: ICompany;
    spaceGroupAccounts: ISpaceGroupAccount[];
    companyAccounts: ICompanyAccount[];
    hasServerSideErrors: boolean;
    errors: string;
    saveCompleted: boolean;
  }

  export enum Actions {
    ToggleLoading = "MANAGE_ACCOUNTS_TOGGLE_LOADING",
    SetSpaceGroupsList = "MANAGE_ACCOUNTS_SET_SPACE_GROUPS",
    SetCompanyList = "MANAGE_ACCOUNTS_SET_COMPANIES",
    SetFilters = "MANAGE_ACCOUNTS_SET_FILTERS",
    SetSelectedSpaceGroup = "MANAGE_ACCOUNTS_SET_SELECTED_SPACE_GROUP",
    SetSelectedCompany = "MANAGE_ACCOUNTS_SET_SELECTED_COMPANY",
    SetSpaceGroupAccountsList = "MANAGE_ACCOUNTS_SPACE_GROUP_SET_ACCOUNTS",
    SetCompanyAccountsList = "MANAGE_ACCOUNTS_COMPANY_SET_ACCOUNTS",
    SetErrors = "COUNTRIES_AND_CITIES_SET_ERRORS",
    SaveCompleted = "COUNTRIES_AND_CITIES_SAVE_COMPLETED",
  }

  export interface IToggleLoading {
    type: Actions.ToggleLoading,
    loading: boolean
  }

  export interface ISetSpaceGroupsList {
    type: Actions.SetSpaceGroupsList,
    spaceGroups: ISpaceGroup[]
  }

  export interface ISetCompaniesList {
    type: Actions.SetCompanyList,
    companies: ICompany[]
  }

  export interface ISetSpaceGroupAccounts {
    type: Actions.SetSpaceGroupAccountsList,
    spaceGroupAccounts: ISpaceGroupAccount[]
  }

  export interface ISetCompanyAccounts {
    type: Actions.SetCompanyAccountsList,
    companyAccounts: ICompanyAccount[]
  }

  export interface ISetFilters {
    type: Actions.SetFilters,
    filters: string
  }

  export interface ISetSelectedSpaceGroup {
    type: Actions.SetSelectedSpaceGroup,
    selectedSpaceGroup: ISpaceGroup
  }

  export interface ISetSelectedCompany {
    type: Actions.SetSelectedCompany,
    selectedCompany: ICompany
  }

  interface ISetErrors {
    type: Actions.SetErrors,
    hasServerSideErrors: boolean,
    errors: string
  }

  interface ISaveCompleted {
    type: Actions.SaveCompleted,
    saveCompleted: boolean,
  }

  export const actionCreators = {
    initializeSpaceGroupPage: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: Actions.ToggleLoading, loading: true });

      var entitiesList = await ManageAccountsService.getSpaceGroupsList().then(response => response.value);
      var spaceGroups = entitiesList.entities || [];
      var selectedSpaceGroup = spaceGroups[0];

      dispatch({ type: Actions.SetSpaceGroupsList, spaceGroups: spaceGroups });

      dispatch({ type: Actions.SetSelectedSpaceGroup, selectedSpaceGroup: selectedSpaceGroup });

      dispatch({ type: Actions.SetSpaceGroupAccountsList, spaceGroupAccounts: selectedSpaceGroup.accountsList });

      dispatch({ type: Actions.ToggleLoading, loading: false });
    },
    initializeCompaniesPage: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: Actions.ToggleLoading, loading: true });
      var entitiesList = await ManageAccountsService.getCompaniesList().then(response => response.value);
      var companies = entitiesList.entities || [];
      var selectedCompany = companies[0];

      dispatch({ type: Actions.SetCompanyList, companies: companies });

      dispatch({ type: Actions.SetSelectedCompany, selectedCompany: selectedCompany });

      dispatch({ type: Actions.SetCompanyAccountsList, companyAccounts: selectedCompany.accountsList });

      dispatch({ type: Actions.ToggleLoading, loading: false });
    },
    reloadSpaceGroupsList: (filters?: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: Actions.ToggleLoading, loading: true });

      dispatch({ type: Actions.SetFilters, filters: filters });

      var entitiesList = await ManageAccountsService.getSpaceGroupsList(filters).then(response => response.value);
      var spaceGroups = entitiesList.entities;
      var selectedSpaceGroup = spaceGroups[0];

      dispatch({ type: Actions.SetSpaceGroupsList, spaceGroups: spaceGroups });

      dispatch({ type: Actions.SetSelectedSpaceGroup, selectedSpaceGroup: selectedSpaceGroup });

      dispatch({ type: Actions.SetSpaceGroupAccountsList, spaceGroupAccounts: selectedSpaceGroup ? selectedSpaceGroup.accountsList : null });

      dispatch({ type: Actions.ToggleLoading, loading: false });
    },
    reloadCompaniesList: (filters?: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: Actions.ToggleLoading, loading: true });

      dispatch({ type: Actions.SetFilters, filters: filters });

      var entitiesList = await ManageAccountsService.getCompaniesList(filters).then(response => response.value);
      var companies = entitiesList.entities;
      var selectedCompany = companies[0];

      dispatch({ type: Actions.SetCompanyList, companies: companies });

      dispatch({ type: Actions.SetSelectedCompany, selectedCompany: selectedCompany });

      dispatch({ type: Actions.SetCompanyAccountsList, companyAccounts: selectedCompany ? selectedCompany.accountsList : null });

      dispatch({ type: Actions.ToggleLoading, loading: false });
    },
    changeSelectedSpaceGroup: (selectedSpaceGroup: ISpaceGroup): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: Actions.SetSelectedSpaceGroup, selectedSpaceGroup: selectedSpaceGroup })

      dispatch({ type: Actions.SetSpaceGroupAccountsList, spaceGroupAccounts: selectedSpaceGroup.accountsList });
    },

    changeSelectedCompany: (selectedCompany: ICompany): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: Actions.SetSelectedCompany, selectedCompany: selectedCompany })

      dispatch({ type: Actions.SetCompanyAccountsList, companyAccounts: selectedCompany.accountsList });
    },
    saveSpaceGroupAccount: (account: ISpaceGroupAccount): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      var serverResponse;
      if (account.id == 0) {
        serverResponse = await ManageAccountsService.addSpaceGroupAccount(account).then(response => response);
      }
      else {
        serverResponse = await ManageAccountsService.updateSpaceGroupAccount(account.id, account).then(response => response);
      }

      var errors = getServerSideErrors(serverResponse);
      var hasServerSideErrors = errors ? true : false;

      dispatch({ type: Actions.SetErrors, hasServerSideErrors: hasServerSideErrors, errors: errors });

      if (!hasServerSideErrors) {
        dispatch({ type: Actions.SaveCompleted, saveCompleted: true });

        var spaceId = getState().manageAccounts.selectedSpaceGroup.id;
        var spaceGroupAccounts = await ManageAccountsService.getSpaceGroupAccounts(spaceId).then(response => response.value);

        dispatch({ type: Actions.SetSpaceGroupAccountsList, spaceGroupAccounts: spaceGroupAccounts });
      }
    },
    saveCompanyAccount: (companyAccount: ICompanyAccount): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      var serverResponse;
      if (companyAccount.id == 0) {
        serverResponse = await ManageAccountsService.addCompanyAccount(companyAccount).then(response => response);
      }
      else {
        serverResponse = await ManageAccountsService.updateCompanyAccount(companyAccount.id, companyAccount).then(response => response);
      }

      var errors = getServerSideErrors(serverResponse);
      var hasServerSideErrors = errors ? true : false;

      dispatch({ type: Actions.SetErrors, hasServerSideErrors: hasServerSideErrors, errors: errors });

      if (!hasServerSideErrors) {
        dispatch({ type: Actions.SaveCompleted, saveCompleted: true });

        var companyId = getState().manageAccounts.selectedCompany.id;
        var companyAccounts = await ManageAccountsService.getCompanyAccounts(companyId).then(response => response.value);

        dispatch({ type: Actions.SetCompanyAccountsList, companyAccounts: companyAccounts });
      }
    },
    reset: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      dispatch({ type: Actions.SetErrors, hasServerSideErrors: false, errors: '' });
      dispatch({ type: Actions.SaveCompleted, saveCompleted: false });
    }
  }

  const initialState: IState = {
    loading: false,
    spaceGroups: [],
    companies: [],
    filters: '',
    selectedSpaceGroup: null,
    selectedCompany: null,
    spaceGroupAccounts: [],
    companyAccounts: [],
    hasServerSideErrors: false,
    errors: "",
    saveCompleted: false
  }

  type KnownAction = IToggleLoading | ISetSpaceGroupsList | ISetSpaceGroupAccounts | ISetFilters | ISetSelectedSpaceGroup | ISetErrors | ISaveCompleted | ISetCompaniesList | ISetSelectedCompany | ISetCompanyAccounts;

  export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
      case Actions.ToggleLoading:
        return update(currentState,
          {
            loading: { $set: action.loading }
          });
      case Actions.SetSpaceGroupsList:
        return update(currentState,
          {
            spaceGroups: { $set: action.spaceGroups }
          });
      case Actions.SetCompanyList:
        return update(currentState,
          {
            companies: { $set: action.companies }
          });
      case Actions.SetSpaceGroupAccountsList:
        return update(currentState,
          {
            spaceGroupAccounts: { $set: action.spaceGroupAccounts }
          });
      case Actions.SetCompanyAccountsList:
        return update(currentState,
          {
            companyAccounts: { $set: action.companyAccounts }
          });
      case Actions.SetFilters:
        return update(currentState,
          {
            filters: { $set: action.filters }
          });
      case Actions.SetSelectedSpaceGroup:
        return update(currentState,
          {
            selectedSpaceGroup: { $set: action.selectedSpaceGroup }
          });
      case Actions.SetSelectedCompany:
        return update(currentState,
          {
            selectedCompany: { $set: action.selectedCompany }
          });
      case Actions.SetErrors:
        return update(currentState,
          {
            hasServerSideErrors: { $set: action.hasServerSideErrors },
            errors: { $set: action.errors },
          });
      case Actions.SaveCompleted:
        return update(currentState, {
          saveCompleted: { $set: action.saveCompleted }
        });
      default:
        return currentState || initialState;
    }
  }
}