import { ServiceBase } from "./ServiceBase";
import Result from "../models/Common/Result";
import { AppSettings } from "../utils/settings";
import { PayloadTypes } from "../enums/Common/PayloadTypes";

export default class RoomsService extends ServiceBase {

    static getRoomAmenities = (id: number): Promise<Result<any>> => {
        var result = RoomsService.requestJsonFetch<any>({
            method: "GET",
            url: `${AppSettings.CommonSettings.ApiUrl}/rooms/${id}/amenities`
        });

        return result;
    };

    static getRoomDetailsForEdit = (id: number): Promise<Result<any>> => {
        var result = RoomsService.requestJsonFetch<any>({
            method: "GET",
            url: `${AppSettings.CommonSettings.ApiUrl}/rooms/${id}/form-details`
        });

        return result;
    };

    
    static addRoom = (roomDetails: any):  Promise<Result<any>> => {
        
        var result = RoomsService.sendData<any>({
            method: "POST",
            url: `${AppSettings.CommonSettings.ApiUrl}/rooms`,
            data: JSON.stringify(roomDetails)
        }, PayloadTypes.Json);

        return result;
        
    }

    static updateRoom = (id:number, roomDetails: any):  Promise<Result<any>> => {
        
        var result = RoomsService.sendData<any>({
            method: "PUT",
            url: `${AppSettings.CommonSettings.ApiUrl}/rooms/${id}`,
            data: JSON.stringify(roomDetails)
        }, PayloadTypes.Json);

        return result;
        
    }

    static deactivateRoom = (id:number):  Promise<Result<any>> => {
        
        var result = RoomsService.sendData<any>({
            method: "PUT",
            url: `${AppSettings.CommonSettings.ApiUrl}/rooms/${id}/deactivate`,
            data: null
        }, PayloadTypes.Json);

        return result;
        
    }

    static activateRoom = (id:number):  Promise<Result<any>> => {
        
        var result = RoomsService.sendData<any>({
            method: "PUT",
            url: `${AppSettings.CommonSettings.ApiUrl}/rooms/${id}/activate`,
            data: null
        }, PayloadTypes.Json);

        return result;
        
    }

}