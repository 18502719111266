import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { ITeam } from "../models/Teams/Interfaces/ITeam";
import update from "immutability-helper";
import { getServerSideErrors } from "../utils/utils";
import TeamsService from "../services/TeamsService";
import BenefitService from "../services/BenefitsService";
import ClientsService from "../services/ClientsService";

export namespace TeamsStore {

    export interface IState {
        loading: boolean;
        teams: ITeam[];
        hasServerSideErrors: boolean;
        errors: string;
    }

    export enum Actions {
        Initialize = "TEAMS_INITIALIZE",
        ToggleLoading = "TEAMS_TOGGLE_LOADING",
        SetTeams = "TEAMS_SET_TEAMS",
        DeleteTeamBenefit = "TEAMS_DELETE_TEAM_BENEFIT",
        SetErrors = "TEAMS_DETAILS_SET_ERRORS"
    }

    interface IInitialize {
        type: Actions.Initialize
    }

    interface IToggleLoading {
        type: Actions.ToggleLoading;
        loading: boolean;
    }

    interface IDeleteTeamBenefit {
        type: Actions.DeleteTeamBenefit,
        benefitId: number,
        teamId: number
    }

    interface ISetCompanyTeams {
        type: Actions.SetTeams,
        teams: ITeam[]
    }

    interface ISetErrors {
        type: Actions.SetErrors,
        hasServerSideErrors: boolean,
        errors: string
    }

    type KnownAction = IToggleLoading | ISetCompanyTeams | ISetErrors | IDeleteTeamBenefit | IInitialize;

    export const actionCreators = {
        initializeTeams: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.Initialize });
        },
        getTeams: (clientId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ToggleLoading, loading: true });

            var teams = await ClientsService.getTeams(clientId)
                .then(response => response.value);

            dispatch({ type: Actions.SetTeams, teams: teams });

            dispatch({ type: Actions.ToggleLoading, loading: false });
        },

        reset: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({
                type: Actions.SetErrors, hasServerSideErrors: false, errors: ''
            });
        },
        deleteTeamBenefit: (benefitId: number, teamId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ToggleLoading, loading: true });

            var serverResponse = await BenefitService.deleteTeamBenefit(benefitId, teamId).then(response => response);
            var errors = getServerSideErrors(serverResponse);
            var hasServerSideErrors = !errors ? false : true;

            if (hasServerSideErrors) {
                dispatch({ type: Actions.SetErrors, hasServerSideErrors: hasServerSideErrors, errors: errors });
            } else {
                dispatch({ type: Actions.DeleteTeamBenefit, benefitId: benefitId, teamId: teamId });
            }

            dispatch({ type: Actions.ToggleLoading, loading: false });
        },
        addUserToTeam: (teamId: number, userId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            var serverResponse = await TeamsService.addUserToTeam(teamId, userId).then(response => response);
            var errors = getServerSideErrors(serverResponse)
            var hasServerSideErrors = errors ? true : false;

            if (hasServerSideErrors) {
                dispatch({ type: Actions.SetErrors, hasServerSideErrors: hasServerSideErrors, errors: errors });
            }
        }
    }

    const initialState: IState = {
        loading: false,
        teams: [],
        hasServerSideErrors: false,
        errors: ""
    }

    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case Actions.Initialize:
                return initialState;
            case Actions.ToggleLoading:
                return { ...currentState, loading: action.loading }
            case Actions.SetTeams:
                return update(currentState,
                    {
                        teams: { $set: action.teams }
                    });
            case Actions.DeleteTeamBenefit:
                var teamIndex = currentState.teams.findIndex(e => e.id == action.teamId);
                var team = { ...currentState.teams[teamIndex] };
                return update(currentState, {
                    teams: {
                        [teamIndex]: {
                            benefits: { $set: team.benefits.filter(e => e.id != action.benefitId) }
                        }
                    }
                });
            case Actions.SetErrors:
                return update(currentState,
                    {
                        hasServerSideErrors: { $set: action.hasServerSideErrors },
                        errors: { $set: action.errors },
                    });
            default:
                return currentState || initialState;
        }
    }
}