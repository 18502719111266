import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { ApplicationState } from "../../../store";
import { TabStore } from "../../../store/TabStore";

import { ITabItem, ITabTitleItem } from "../../../utils/reactUtils";
import { AdditionalInfoTypes } from "../../../enums/Clients/AdditionalInfoTypes";
import ClientDetails from "../../../resources/AdminUI/Clients/ClientDetails";
import TabsHeader from "../../Tabs/TabsHeader";
import ContactInfoTabContent from "../TabContents/ContactInfoTabContent";
import ClientMoreDetailsTabContent from "../TabContents/ClientMoreDetailsTabContent";
import Loading from "../../Shared/Loading";

class ClientAdditionalDetails extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);

    var tabs = [
      {
        tabId: AdditionalInfoTypes.ContactInfo.toString(),
        title: ClientDetails.Resources.contactInfoTabLabel,
        content: () => <ContactInfoTabContent client={this.props.client} />,
      } as ITabItem,
      {
        tabId: AdditionalInfoTypes.More.toString(),
        title: ClientDetails.Resources.moreTabLabel,
        content: () => (
          <ClientMoreDetailsTabContent client={this.props.client} />
        ),
      } as ITabItem,
    ];

    this.props.initializeTabPage(
      tabs,
      AdditionalInfoTypes.ContactInfo.toString(),
      null
    );
  }

  render() {
    {
      if (this.props.tabState == undefined) {
        return (
          <div>
            <Loading />
          </div>
        );
      }
    }
    var activeTab = this.props.tabState.tabs.filter(
      (t) => t.tabId == this.props.tabState.activeTabId
    )[0];
    return (
      <div>
        <TabsHeader
          onTabClick={this.props.changeActiveTab}
          activeTabId={activeTab.tabId}
          className={"page-tabs"}
          tabs={this.props.tabState.tabs.map((t) => {
            return {
              title: t.title,
              tabId: t.tabId,
              class: t.class,
            } as ITabTitleItem;
          })}
        />
        <div className="tab-content">{activeTab.content()}</div>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState, ownProps: any) => {
    return {
      tabState: state.tab[ownProps.componentId],
    };
  },
  (dispatch, ownProps: any) =>
    bindActionCreators(
      {
        ...TabStore.getActionCreators(ownProps.componentId),
      },
      dispatch
    )
)(ClientAdditionalDetails);
