import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import update from "immutability-helper";
import { getServerSideErrors } from "../utils/utils";
import { IAddOrEditBenefit } from "../models/Benefits/Interfaces/IAddOrEditBenefit";
import BenefitService from "../services/BenefitsService";

export namespace AddOrEditBenefitStore {
  export interface IState {
    loading: boolean;
    benefit: IAddOrEditBenefit;
    hasServerSideErrors: boolean;
    errors: string;
  }

  export enum Actions {
    ToggleLoading = "ADD_OR_EDIT_BENEFIT_TOGGLE_LOADING",
    Initialize = "ADD_OR_EDIT_BENEFIT_INITIALIZE",
    SetCompanyId = "ADD_OR_EDIT_BENEFIT_SET_COMPANY_ID",
    SetSubscriptionId = "ADD_OR_EDIT_BENEFIT_SET_SUBSCRIPTION_ID",
    SetBenefitDetails = "ADD_OR_EDIT_BENEFIT_SET_BENEFIT_DETAILS",
    SaveBenefit = "ADD_OR_EDIT_BENEFIT_SAVE_BENEFIT",
    SetErrors = "ADD_OR_EDIT_BENEFIT_SET_ERRORS",
  }

  interface IToggleLoading {
    type: Actions.ToggleLoading;
    loading: boolean;
  }

  interface IInitialize {
    type: Actions.Initialize;
  }

  interface ISetCompanyId {
    type: Actions.SetCompanyId;
    companyId: number;
  }

  interface ISetSubscriptionId {
    type: Actions.SetSubscriptionId;
    subscriptionId: number;
  }

  interface ISetBenefitDetails {
    type: Actions.SetBenefitDetails;
    benefitDetails: IAddOrEditBenefit;
  }

  interface ISetErrors {
    type: Actions.SetErrors;
    hasServerSideErrors: boolean;
    errors: string;
  }

  type KnownAction =
    | IToggleLoading
    | IInitialize
    | ISetCompanyId
    | ISetSubscriptionId
    | ISetBenefitDetails
    | ISetErrors;

  export const actionCreators = {
    initializeCreatePage:
      (
        companyId: number,
        subscriptionId: number
      ): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({ type: Actions.Initialize });
        dispatch({ type: Actions.SetCompanyId, companyId: companyId });
        dispatch({
          type: Actions.SetSubscriptionId,
          subscriptionId: subscriptionId,
        });
      },

    initializeEditPage:
      (id: number): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({ type: Actions.ToggleLoading, loading: true });

        var details = await BenefitService.getBenefitDetailsForEdit(id).then(
          (response) => response.value
        );

        dispatch({
          type: Actions.SetBenefitDetails,
          benefitDetails: details,
        });
        dispatch({ type: Actions.ToggleLoading, loading: false });
      },

    addOrEditBenefit:
      (benefit: IAddOrEditBenefit): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        if (benefit.id) {
          var serverResponse = await BenefitService.editBenefit(benefit).then(
            (response) => response
          );
        } else {
          var serverResponse = await BenefitService.addBenefit(benefit).then(
            (response) => response
          );
        }

        var errors = getServerSideErrors(serverResponse);
        var hasServerSideErrors = errors ? true : false;

        if (hasServerSideErrors) {
          dispatch({
            type: Actions.SetErrors,
            hasServerSideErrors: hasServerSideErrors,
            errors: errors,
          });
        }
      },
  };

  const initialState: IState = {
    loading: false,
    benefit: {
      id: null,
      name: null,
      description: null,
      featureId: 1,
      startFromCurrentCycle: true,
      isUnlimited: true,
      value: null,
      currency: null,
      userUsageLimit: null,
      hasUserLimit: false,
      renewMaxCycles: null,
      renewIntervalUnitId: null,
      renewIntervalValue: null,
      companyId: null,
      subscriptionId: null,
      subscriptionStartsAt: "",
      costPerEntry: null,
      isPromo: false,
    },
    hasServerSideErrors: false,
    errors: "",
  };

  export const reducer: Reducer<IState> = (
    currentState: IState,
    incomingAction: Action
  ) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
      case Actions.ToggleLoading:
        return { ...currentState, loading: action.loading };
      case Actions.Initialize:
        return initialState;
      case Actions.SetCompanyId:
        return update(currentState, {
          benefit: {
            companyId: { $set: action.companyId },
          },
        });
      case Actions.SetSubscriptionId:
        return update(currentState, {
          benefit: {
            subscriptionId: { $set: action.subscriptionId },
          },
        });
      case Actions.SetBenefitDetails:
        return update(currentState, {
          benefit: {
            id: { $set: action.benefitDetails.id },
            name: { $set: action.benefitDetails.name },
            description: { $set: action.benefitDetails.description },
            companyId: { $set: action.benefitDetails.companyId },
            featureId: { $set: action.benefitDetails.featureId },
            startFromCurrentCycle: {
              $set: action.benefitDetails.startFromCurrentCycle,
            },
            isUnlimited: { $set: action.benefitDetails.isUnlimited },
            value: { $set: action.benefitDetails.value },
            currency: { $set: action.benefitDetails.currency },
            userUsageLimit: { $set: action.benefitDetails.userUsageLimit },
            hasUserLimit: { $set: action.benefitDetails.hasUserLimit },
            renewMaxCycles: { $set: action.benefitDetails.renewMaxCycles },
            renewIntervalUnitId: {
              $set: action.benefitDetails.renewIntervalUnitId,
            },
            renewIntervalValue: {
              $set: action.benefitDetails.renewIntervalValue,
            },
            subscriptionId: { $set: action.benefitDetails.subscriptionId },
            subscriptionStartsAt: {
              $set: action.benefitDetails.subscriptionStartsAt,
            },
            costPerEntry: { $set: action.benefitDetails.costPerEntry },
            isPromo: { $set: action.benefitDetails.isPromo },
          },
        });
      case Actions.SetErrors:
        return update(currentState, {
          hasServerSideErrors: { $set: action.hasServerSideErrors },
          errors: { $set: action.errors },
        });
      default:
        return currentState || initialState;
    }
  };
}
