import bind from "bind-decorator";
import React from "react";

interface IState {
    errors?: {}
}

interface IProps {
    validator?: any
}

export class ValidatedComponent<P, S> extends React.Component<P & IProps, S & IState> {

    constructor(props) {
        super(props);
        this.state = {
            errors: {}
        } as S & IState

    }

    @bind
    modelGetter(){
        return this.state;
    }

    @bind
    getFieldErrors(key: string) {
        var fieldErrors = this.state.errors && this.state.errors[key] ? this.state.errors[key] : [];
        return fieldErrors;
    }

    @bind
    validate(onValidateCallback?: () => void) {
        var vldt = new this.props.validator();
        var values = this.modelGetter();
        var errors = vldt.validate(values, this.props);
        this.setState({ errors: errors });
        if (Object.keys(errors).length == 0) {
            if (onValidateCallback) {
                onValidateCallback();
            }
        }

        return Object.keys(errors).length == 0;
    }
    @bind
    async validateAsync(onValidateCallback: () => void) {
        var vldt = new this.props.validator();
        var values = this.modelGetter();
        var errors = vldt.validate(values, this.props);
        this.setState({ errors: errors });
        if (Object.keys(errors).length == 0) {
            await onValidateCallback();
        } 
    
        return Object.keys(errors).length == 0;
    }

}
