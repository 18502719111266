import React, { FC, useCallback } from "react";
import { v4 } from "uuid";
import IPageIndex from "../../models/Pagination/IPageIndex";
import { String_Format } from "../../utils/utils";

interface PaginationBarProps {
  gridTextItemResource?: any;
  totalItems: number;
  maxPagesDisplayed?: number;
  pageNeighbours?: number;
  firstIndexFromPage: number;
  lastIndexFromPage: number;
  pageIndexArray: IPageIndex[];
  reload: () => void;
  changeCurrentPage: (currentPage: number) => void;
}

const PaginationBar: FC<PaginationBarProps> = ({
  gridTextItemResource,
  totalItems,
  maxPagesDisplayed = 7,
  pageNeighbours = 1,
  reload,
  changeCurrentPage,
  firstIndexFromPage,
  lastIndexFromPage,
  pageIndexArray,
}) => {
  const calculatePageIndexArrayRendered = useCallback(() => {
    const array = [];
    const totalPages = pageIndexArray.length;

    if (pageIndexArray.length) {
      const currentPage =
        pageIndexArray.find((p) => p.isActive)?.pageNumber || 1;
      const elipsis = (
        <a
          onClick={(e) => e.preventDefault()}
          className="pagination-elipsis"
          href="#"
        >
          ...
        </a>
      );

      let leftSizeEllipsis =
        currentPage - pageNeighbours - pageIndexArray[0].pageNumber - 1;
      let rightSizeEllipsis =
        totalPages -
        currentPage -
        pageNeighbours -
        pageIndexArray[0].pageNumber;

      if (leftSizeEllipsis <= 0) {
        rightSizeEllipsis += leftSizeEllipsis - 1;
      }
      if (rightSizeEllipsis <= 0) {
        leftSizeEllipsis += rightSizeEllipsis - 1;
      }

      array.push(renderPageIndex(pageIndexArray[0]));
      if (leftSizeEllipsis >= 1) {
        array.push(<li key={v4()}>{elipsis}</li>);
      }

      pageIndexArray.forEach((i) => {
        if (
          totalPages <= maxPagesDisplayed &&
          i.pageNumber !== 1 &&
          i.pageNumber !== totalPages
        ) {
          array.push(renderPageIndex(i));
        } else if (
          i.pageNumber !== 1 &&
          i.pageNumber !== totalPages &&
          i.pageNumber !== pageIndexArray[totalPages - 1].pageNumber &&
          i.pageNumber > 1 + leftSizeEllipsis &&
          i.pageNumber <= totalPages - rightSizeEllipsis - 1
        ) {
          array.push(renderPageIndex(i));
        }
      });

      if (rightSizeEllipsis >= 1) {
        array.push(<li key={v4()}>{elipsis}</li>);
      }
      if (totalPages !== 1) {
        array.push(renderPageIndex(pageIndexArray[totalPages - 1]));
      }
    }

    return array;
  }, [pageIndexArray, maxPagesDisplayed, pageNeighbours]);

  const onPrevButtonChange = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      e.preventDefault();
      if (!e.currentTarget.classList.contains("disabled")) {
        const currentPage =
          pageIndexArray.find((p) => p.isActive)?.pageNumber || 1;
        changeCurrentPage(currentPage - 1);
        reload();
      }
    },
    [pageIndexArray, changeCurrentPage, reload]
  );

  const onNextButtonChange = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      e.preventDefault();
      if (!e.currentTarget.classList.contains("disabled")) {
        const currentPage =
          pageIndexArray.find((p) => p.isActive)?.pageNumber || 1;
        changeCurrentPage(currentPage + 1);
        reload();
      }
    },
    [pageIndexArray, changeCurrentPage, reload]
  );

  const onPageIndexClick = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      e.preventDefault();
      const pageIndexClicked = +(
        e.currentTarget.getAttribute("data-page-number") || "1"
      );
      if (!pageIndexArray[pageIndexClicked - 1].isActive) {
        changeCurrentPage(pageIndexClicked);
        reload();
      }
    },
    [pageIndexArray, changeCurrentPage, reload]
  );

  const renderPageIndex = useCallback(
    (model: IPageIndex) => {
      if (model.isActive) {
        return (
          <li
            key={v4()}
            className="active"
            data-page-number={model.pageNumber}
            onClick={onPageIndexClick}
          >
            <span>{model.pageNumber}</span>
          </li>
        );
      } else {
        return (
          <li
            key={v4()}
            data-page-number={model.pageNumber}
            onClick={onPageIndexClick}
          >
            <a href="#">{model.pageNumber}</a>
          </li>
        );
      }
    },
    [onPageIndexClick]
  );

  if (!pageIndexArray || pageIndexArray.length === 0) {
    return null;
  }

  const currentPage = pageIndexArray.find((p) => p.isActive)?.pageNumber || 1;
  const totalPages = pageIndexArray.length;

  return (
    <div className="pagination">
      <div className="items-info">
        {gridTextItemResource != null
          ? String_Format(
              gridTextItemResource,
              totalItems === 0 ? 0 : firstIndexFromPage,
              lastIndexFromPage,
              totalItems
            )
          : String_Format(
              "Showing {0} - {1} out of {2} items",
              totalItems === 0 ? 0 : firstIndexFromPage,
              lastIndexFromPage,
              totalItems
            )}
      </div>
      <div>
        <ul className="pager">
          <li
            onClick={onPrevButtonChange}
            className={`prev ${
              currentPage === 1 || totalItems === 0 ? "disabled" : ""
            }`}
          >
            <a href="#">
              <span className="material-symbols-outlined">chevron_left</span>
            </a>
          </li>
          {calculatePageIndexArrayRendered()}
          <li
            onClick={onNextButtonChange}
            className={`next ${
              currentPage === totalPages || totalItems === 0 ? "disabled" : ""
            }`}
          >
            <a href="#">
              <span className="material-symbols-outlined">chevron_right</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PaginationBar;
