import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import update from "immutability-helper";
import RoomsService from "../services/RoomsService";
import { getServerSideErrors } from "../utils/utils";

export namespace RoomDetailsStore {

    export interface IState {
        amenities: string[],
        loading: boolean,

        hasServerSideErrors: boolean;
        serverSideErrors: string;
    }

    export enum Actions {
        RoomDetailsSetAmenities = "ROOM_DETAILS_SET_AMENITIES",
        RoomDetailsToggleLoading = "ROOM_DETAILS_TOGGLE_LOADING",
        SetErrors = "ROOM_DETAILS_SET_ERRORS"
    }

    interface IRoomDetailsSetAmenities {
        type: Actions.RoomDetailsSetAmenities;
        amenities: string[],
    }

    interface IRoomDetailsToggleLoading {
        type: Actions.RoomDetailsToggleLoading;
        loading: boolean;
    }

    interface ISetErrors {
        type: Actions.SetErrors,
        hasServerSideErrors: boolean;
        serverSideErrors: string;
    }

    type KnownAction = IRoomDetailsToggleLoading |
        IRoomDetailsSetAmenities | ISetErrors;

    export const actionCreators = {

        getAmenities: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.RoomDetailsToggleLoading, loading: true });
            var roomAmenities = await RoomsService.getRoomAmenities(id).then(response => response.value);
            dispatch({ type: Actions.RoomDetailsSetAmenities, amenities: roomAmenities });
            dispatch({ type: Actions.RoomDetailsToggleLoading, loading: false });
        },

        deactivateRoom: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            var serverResponse = await RoomsService.deactivateRoom(id).then(result => result);
            var errors = getServerSideErrors(serverResponse);
            var hasServerSideErrors = !errors ? false : true;

            if (hasServerSideErrors) {
                dispatch({ type: Actions.SetErrors, hasServerSideErrors: hasServerSideErrors, serverSideErrors: errors });
            }
        },

        activateRoom: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            var serverResponse = await RoomsService.activateRoom(id).then(result => result);
            var errors = getServerSideErrors(serverResponse);
            var hasServerSideErrors = !errors ? false : true;

            if (hasServerSideErrors) {
                dispatch({ type: Actions.SetErrors, hasServerSideErrors: hasServerSideErrors, serverSideErrors: errors });
            }
        },
    }

    const initialState: IState = {
        amenities: [],
        loading: true,

        hasServerSideErrors: false,
        serverSideErrors: null
    }

    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case Actions.RoomDetailsSetAmenities:
                return update(currentState,
                    {
                        amenities: { $set: action.amenities }
                    });
            case Actions.RoomDetailsToggleLoading:
                return update(currentState,
                    {
                        loading: { $set: action.loading }
                    });
            case Actions.SetErrors:
                return update(currentState, {
                    hasServerSideErrors: { $set: action.hasServerSideErrors },
                    serverSideErrors: { $set: action.serverSideErrors }
                });
            default:
                return currentState || initialState;
        }

    }


}