import Result from "../models/Common/Result";
import { AppSettings } from "../utils/settings";
import { ServiceBase } from "./ServiceBase";
import { ICountryItem } from "../models/CountriesAndCities/ICountryItem";
import { ICityItem } from "../models/CountriesAndCities/ICityItem";
import { PayloadTypes } from "../enums/Common/PayloadTypes";

export default class CountriesAndCitiesService extends ServiceBase {

    static getCountriesList = (): Promise<Result<ICountryItem[]>> => {
        try{
            var result = CountriesAndCitiesService.requestJsonFetch<ICountryItem[]>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}/countries`
            });
            return result;
        }catch(error) {}
    }

    static getCitiesForCountry = (id: number): Promise<Result<ICityItem[]>> => {
        try{
            var result = CountriesAndCitiesService.requestJsonFetch<ICityItem[]>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}/countries/${id}/cities`
            });
            return result;
        }catch(error) {}
    }

    static addCity = (city: ICityItem):  Promise<Result<ICityItem>> => {
        
        var result = CountriesAndCitiesService.sendData<any>({
            method: "POST",
            url: `${AppSettings.CommonSettings.ApiUrl}/countries/cities`,
            data: JSON.stringify(city)
        }, PayloadTypes.Json);

        return result;
        
    }

    static updateCity = (city: ICityItem):  Promise<Result<ICityItem>> => {
        
        var result = CountriesAndCitiesService.sendData<any>({
            method: "PUT",
            url: `${AppSettings.CommonSettings.ApiUrl}/countries/cities/${city.id}`,
            data: JSON.stringify(city)
        }, PayloadTypes.Json);

        return result;
    }
}