import { BenefitTypes } from "../../enums/Benefits/BenefitTypes";
import BenefitHistory from "../../resources/AdminUI/Benefits/BenefitHistory";
import Benefits from "../../resources/AdminUI/Benefits/Benefits";
import { DATE_FORMAT, formatDate } from "../../utils/date";
import { BaseEntity } from "../../utils/reactUtils";
import { IBenefitHistory } from "./Interfaces/IBenefitHistory";

export class BenefitHistoryItem extends BaseEntity implements IBenefitHistory {
    id: number;
    name: string;
    sourceCycleIndex: number;
    maxValue: number;
    currency: string;
    featureId: number;
    startsAt: string;
    expiresAt: string;
    usage: number;

    constructor(benefit: IBenefitHistory) {
        super();

        this.additionalConstructor(this, benefit);

        this.ForProperty(p => "sourceCycleIndex")
            .SetDisplayName(BenefitHistory.Resources.cycleNumberLabel)
            .Show(_self => <div>
                <p>{benefit.sourceCycleIndex}</p>
            </div>);

        this.ForProperty(p => "startsAt")
            .SetDisplayName(BenefitHistory.Resources.startDateLabel)
            .Show(_self => <div>
                <p className="timestamp">{formatDate(benefit.startsAt, DATE_FORMAT)}</p>
            </div>);

        this.ForProperty(p => "expiresAt")
            .SetDisplayName(BenefitHistory.Resources.endDateLabel)
            .Show(_self => <div>
                <p className="timestamp">{formatDate(benefit.expiresAt, DATE_FORMAT)}</p>
            </div>);

        this.ForProperty(p => "usage")
            .SetDisplayName(BenefitHistory.Resources.valueUsedLabel)
            .Show(_self => <div>
                {
                    benefit.featureId == BenefitTypes.DeskEntries ?
                        <p>{benefit.usage} / {benefit.maxValue} {Benefits.Resources.entriesText}</p>
                        : <p>{benefit.usage / 100} / {benefit.maxValue / 100} {benefit.currency}</p>
                }
            </div>);
    }

}