import dayjs from "dayjs";
import { Link } from "react-router-dom";

import { ApplicationPathBuilder } from "../../enums/Common/ApplicationPaths";
import { RoomBookingTypes } from "../../enums/RoomBookings/RoomBookingTypes";
import General from "../../resources/AdminUI/General";
import AddOrEditHotdeskBooking from "../../resources/AdminUI/HotdeskBookings/AddOrEditHotdeskBooking";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import RoomBookingTypeList from "../../resources/AdminUI/RoomBookings/RoomBookingTypeList";
import { formatDateInZone, isSameDay, DATE_FORMAT } from "../../utils/date";
import { firstLetterToLowerCase, String_Format } from "../../utils/utils";
import ModalDialog from "../Shared/ModalDialog";
import PriceLabel from "../Shared/PriceLabel";
import DeclineRoomBookingConfirmationModal from "./DeclineRoomBookingConfirmationModal";

interface IProps {
  isDeclineModalOpen: boolean;
  selectedRoomBooking: any;
  showDeclineModal: (roomBooking: any) => void;
  hideDeclineModal: () => void;
  getDeclineReasonsList: () => any;
  showAcceptRoomBookingModal: (id: number) => void;
}

export const getPendingRoomBookingsColumns = (props: IProps) => {
  const formatDate = (date) => dayjs(date).format("DD MMM YYYY, HH:mm");

  const buildBookingInfoCell = (row) => {
    const { bookingTypeId, startsAt, endsAt, timezone, bookingAmount } =
      row.original;
    let type = RoomBookingTypes[bookingTypeId];
    let typeName = RoomBookingTypeList.Resources[firstLetterToLowerCase(type)];

    switch (bookingTypeId) {
      case RoomBookingTypes.Hourly:
        return (
          <div className="column-xlarge">
            <p>{typeName}</p>
            <p className="timestamp">
              {formatDateInZone(startsAt, timezone)}-{" "}
              {formatDateInZone(endsAt, timezone)}
              <span className="timezone"> {timezone}</span>
            </p>
          </div>
        );
      case RoomBookingTypes.Daily:
        return (
          <div className="column-xlarge">
            <p>
              {String_Format(
                RoomBookingsGrid.Resources.bookingAmount,
                typeName,
                bookingAmount
              )}
            </p>
            <p className="timestamp">
              {isSameDay(startsAt, endsAt)
                ? formatDateInZone(startsAt, timezone, DATE_FORMAT)
                : formatDateInZone(startsAt, timezone, DATE_FORMAT) +
                  " - " +
                  formatDateInZone(endsAt, timezone, DATE_FORMAT)}
              <span className="timezone"> {timezone}</span>
            </p>
          </div>
        );
      case RoomBookingTypes.Monthly:
        return (
          <div className="column-xlarge">
            <p>
              {String_Format(
                RoomBookingsGrid.Resources.bookingAmount,
                typeName,
                bookingAmount * 30
              )}
            </p>
            <p className="timestamp">
              {formatDateInZone(startsAt, timezone, DATE_FORMAT)}-{" "}
              {formatDateInZone(endsAt, timezone, DATE_FORMAT)}
              <span className="timezone"> {timezone}</span>
            </p>
          </div>
        );
    }
  };

  return [
    {
      header: "ACTIONS",
      cell: ({ row }) => (
        <td className="row-actions">
          <div className="rooms-requests-buttons">
            <div
              className="btn-action decline"
              onClick={() => props.showDeclineModal(row.original)}
            >
              {General.Resources.decline}
            </div>
            <ModalDialog
              title={RoomBookingsGrid.Resources.declineRoomRequest}
              open={
                props.isDeclineModalOpen &&
                props.selectedRoomBooking === row.original
              }
              onCancel={props.hideDeclineModal}
            >
              <DeclineRoomBookingConfirmationModal
                onCancelCallback={props.hideDeclineModal}
                id={row.original.id}
                reasons={props.getDeclineReasonsList()}
              />
            </ModalDialog>

            <div
              className="btn-action accept"
              onClick={() => props.showAcceptRoomBookingModal(row.original.id)}
            >
              {General.Resources.accept}
            </div>
          </div>
        </td>
      ),
    },
    {
      header: "VISIT ID",
      cell: ({ row }) => (
        <div className="column-xsmall">
          <p>{String_Format(RoomBookingsGrid.Resources.id, row.original.id)}</p>
        </div>
      ),
    },
    {
      header: "REQUEST TIMESTAMP",
      cell: ({ row }) => (
        <div className="column-medium">
          <p className="timestamp">{formatDate(row.original.createdAt)}</p>
        </div>
      ),
    },
    {
      header: "SPACE",
      cell: ({ row }) => (
        <div className="column-large">
          <Link
            className="table-item-link"
            to={ApplicationPathBuilder.ViewSpaceDetails(
              row.original.workspaceId.toString()
            )}
          >
            {row.original.workspaceName}
          </Link>

          <p>
            {row.original.workspaceCountryFlag} {row.original.workspaceCityName}{" "}
            | {AddOrEditHotdeskBooking.Resources.spaceId}:{" "}
            {row.original.workspaceId}
          </p>
        </div>
      ),
    },
    {
      header: "ROOM",
      cell: ({ row }) => (
        <div className="column-medium">
          <p className="room-name">{row.original.roomName}</p>
          <p>
            {String_Format(
              RoomBookingsGrid.Resources.roomId,
              row.original.roomId
            )}
          </p>
        </div>
      ),
    },
    {
      header: "BOOKING INFO",
      cell: ({ row }) => buildBookingInfoCell(row),
    },
    {
      header: "PRICE",
      cell: ({ row }) => (
        <div className="column-small">
          <PriceLabel
            price={row.original.price}
            currency={row.original.currency}
          />
        </div>
      ),
    },
    {
      header: "STATUS",
      cell: ({ row }) => (
        <div className="column-small">
          <p>{row.original.statusName}</p>
        </div>
      ),
    },
  ];
};
