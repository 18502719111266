import Globals from "./../../../Globals";

export default class Workspaces {
       public activateSpace: string = `Activate space`;
       public active: string = `Active`;
       public activeConfirmationMessage: string = `Are you sure you want to set the space to Active? The space will be available in the mobile app as with the possibility for users to book desks or rooms.`;
       public addressGridTitle: string = `Address`;
       public addSpace: string = `Add space`;
       public amenities: string = `Amenities`;
       public areYouSure: string = `Are you sure?`;
       public bookingRequired: string = `Booking required`;
       public buildingAccessInformation: string = `Building Access Information`;
       public businessContactInfo: string = `Business Contact Info`;
       public changeStatus: string = `Change status`;
       public changeStatusToDraft: string = `Change status to draft`;
       public changeStatusToUnavailable: string = `Change status to unavailable`;
       public contactInfo: string = `Contact Info`;
       public containsWorkspaces: string = `Contains {0} workspaces`;
       public createdOn: string = `Created on {0} `;
       public csvExtension: string = `.csv`;
       public deactivatedConfirmationMessage: string = `Are you sure you want to set the status to Deactivated? The space will no longer be displayed in the mobile app and all future bookings will be automatically cancelled.`;
       public deactivateSpace: string = `DEACTIVATE SPACE`;
       public description: string = `Description`;
       public desksBooking: string = `DESKS BOOKING`;
       public draftConfirmationMessage: string = `Are you sure you want to revert the space to Draft? The space will no longer be displayed in the mobile app. Please check if there are any future booking that need to be handled manually.`;
       public editSpaceInfo: string = `Edit space info`;
       public emailAddress: string = `Email address`;
       public exportWorkspaceExcelHeader: string = `Id,Name,Address,City Name,City Id,Country Name,Country Id,Max Seats,Created At,Last Modified At,Number Of Meeting Rooms,Number Of Private Offices,Require Booking,Category Name,Category Id,Email,Phone,Status Id,Status Name,Wifi Name,Wifi Password,Access Instructions`;
       public exportWorkspacesCSV: string = `Export Workspaces.csv`;
       public featuresGridTitle: string = `Features`;
       public VAT: string = `VAT`;
       public fullDay: string = `Full day`;
       public fullDays: string = `Full days`;
       public generalAboutSpace: string = `General about {0}`;
       public halfDay: string = `Half day`;
       public halfDays: string = `Half days`;
       public helloGroupName: string = `Hello, {0}`;
       public hotDesks: string = `Hot desks`;
       public hotDesksRequirementsGridTitle: string = `Hot desks requirements`;
       public idLabel: string = `ID: #{0}`;
       public maxDesksDaily: string = `Max. {0} desks daily`;
       public meetingRooms: string = `Meeting rooms`;
       public meetingRoomsLabel: string = `{0} meeting rooms`;
       public nameGridTitle: string = `Name`;
       public notActive: string = `Not Active`;
       public phoneNumber: string = `Phone number`;
       public photographs: string = `Photographs`;
       public pricing: string = `Pricing`;
       public privateOfficeLabel: string = `{0}  private offices`;
       public privateOffices: string = `Private offices`;
       public publicInformationGridTitle: string = `Public contact`;
       public schedule: string = `Schedule`;
       public seeSpaceInGoogleMaps: string = `See space in Google Maps`;
       public spaceAddress: string = `Space address`;
       public spaceDetails: string = `Space Details`;
       public spaceDoesNotHaveLimitForHotdesks: string = `Space does not have a limit for hot desks`;
       public spaceDoesNotOfferHotDesks: string = `Space does not offer hot desks`;
       public spaceHasNoMeetingRoomsOrPrivateOfficesYet: string = `Space has no meeting rooms or private offices yet`;
       public spaces: string = `Spaces`;
       public spacesGridCitiesPlaceholder: string = `Cities`;
       public spacesGridCountriesPlaceholder: string = `Countries`;
       public spacesGridSearchPlaceholder: string = `Search by ID, space name or address`;
       public spacesRequiresBookingLimitDesksPerDay: string = `Spaces requires booking | Limit {0} desks / day`;
       public totalActivity: string = `Total activity`;
       public totalCheckins: string = `Total check-ins`;
       public typesOfRooms: string = `Types of rooms`;
       public unavailableConfirmationMessage: string = `Are you sure you want to set the space to Unavailable? The space will be displayed in the mobile app as Temporary unavailable, without the possibility to book desks or rooms. Please check if there are any future booking that need to be handled manually.`;
       public unredeemed: string = `Unredeemed`;
       public walkIn: string = `Walk-In`;
       public wifiName: string = `WI-FI name`;
       public wifiPassword: string = `WI-FI password`;
       public wifiSetupAndAccess: string = `WI-FI Setup & Access`;
       public worskpacesTitle: string = `Workspaces`;

		public static get Resources(): Workspaces{
			switch (Globals.currentActiveLanguage) {
				default:
				return new Workspaces();
		}
   	}
}
