import Globals from "./../../../Globals";

export default class AddOrEditOffice {
       public acceptedFormats: string = `Accepted formats: .png or .jpg, maxim 2MB each`;
       public addOffice: string = `Add Office`;
       public anAutomatedEmailWillBeSentToUserWithWiFiDetailsAndBuildingInfo: string = `An automated email will be sent to user with WI-FI network details and building access information`;
       public buildingAccessInfoWithExamples: string = `Building access information (ex: at the corner, white door)`;
       public chooseInWhichLanguageTheEmailsShouldBeSent: string = `Choose in which language the emails should be sent for this space`;
       public completeAddress: string = `Complete address`;
       public distanceAndMapLocationAreBasedOnThem: string = `Distance and map location are based on them`;
       public doesOfficeOffersHotDesks: string = `Does Office offers hot desks?`;
       public editOffice: string = `Edit Office`;
       public emailAddress: string = `Email address`;
       public emailLanguage: string = `Email language`;
       public fullDescription: string = `Full description`;
       public gpsCoordinates: string = `GPS Coordinates`;
       public latitude: string = `Latitude`;
       public longitude: string = `Longitude`;
       public maximumCapacityPerDay: string = `Maximum capacity / day`;
       public nameVisibleInMobileApplication: string = `Name will be visible in mobile application`;
       public noThisIsAWalkInOfficeAndDoesNotRequireBooking: string = `No, this is a walk-in office and does not require booking`;
       public office: string = `Office`;
       public officeAddress: string = `Office Address`;
       public officeAmenities: string = `Office Amenities`;
       public officeName: string = `Office Name`;
       public officeOpeningHours: string = `Office Opening Hours`;
       public officePhoneNumberAndEmailAddressWillBeDisplayedInAppForUsers: string = `Office phone number and email address will be displayed in application for users`;
       public officePhotographs: string = `Office Photographs`;
       public phoneNumber: string = `Phone number`;
       public pleaseCompleteEverydaysProgramIfTheOfficeIsClosedCheckTheClosedOption: string = `Please complete everyday's program. If the office is closed, check the 'Closed' option`;
       public pleaseSelectAllAvailableAmenitiesHighlightImportantAmenitiesAndTheyWillAppearOnTheMainSpaceDetailsScreen: string = `Please select all available space amenities. Highlight important amenities by checking the HIGHLIGHTED button and they will appear on the main Space Details screen.`;
       public presentationDetails: string = `PRESENTATION DETAILS`;
       public publicContactInformation: string = `Public contact information`;
       public recommendBetween200And400Characters: string = `We recommend a description between 200 and 400 characters`;
       public selectAmenities: string = `SELECT OFFICE AMENITIES`;
       public selectCity: string = `Select city...`;
       public selectCountry: string = `Select country...`;
       public selectFromTheListAndHighlightTheImportantOnes: string = `Select from the list and highlight the important ones`;
       public selectIfTheOfficeRequiresBookingCompleteMaxDesksAvailable: string = `Select if the office requires booking. If yes, complete the maximum desks available / day.`;
       public selectOfficeAmenities: string = `Select Office Amenities`;
       public selectSchedule: string = `SELECT OFFICE SCHEDULE`;
       public setOfficeProgram: string = `Set Office Program`;
       public thisOfficeRequiresBooking: string = `This Office requires booking?`;
       public wifiInfoAndAccess: string = `Wi-Fi Information & Access`;
       public wifiNetwork: string = `Wi-Fi Network`;
       public wifiPassword: string = `Wi-Fi Password`;
       public yesThisOfficeRequiresPriorBooking: string = `Yes, this office requires prior booking`;

		public static get Resources(): AddOrEditOffice{
			switch (Globals.currentActiveLanguage) {
				default:
				return new AddOrEditOffice();
		}
   	}
}
