import React from "react";
import Workspaces from "../../resources/AdminUI/Spaces/Workspaces";
import General from "../../resources/AdminUI/General";

interface IProps {
    phone: string,
    email: string,
}

interface IState {

}

export class ContactInfoTab extends React.Component<IProps, IState>{

    constructor(props) {
        super(props);
    }

    render() {

        return <div>
           <div className="header first">{Workspaces.Resources.phoneNumber}</div>
            <p>{this.props.phone ? this.props.phone : General.Resources.notCompletedYet}</p>
            <div className="header">{Workspaces.Resources.emailAddress}</div>
            <p>{this.props.email ? this.props.email : General.Resources.notCompletedYet}</p>
        </div>
    }
}