import React from "react";
import { ICountryItem } from "../../../models/CountriesAndCities/ICountryItem";

interface IProps {
    country: ICountryItem
}

export class CountryCard extends React.PureComponent<IProps, any> {
    constructor(props) {
        super(props)
    }

    render() {
        return <div className="list-item">
            <span className="flag">{this.props.country.flag}</span>
            <h3 className="country-text">{this.props.country.name}</h3>
        </div>
    }
}