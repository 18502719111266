import Globals from "./../../../Globals";

export default class BenefitBeneficiaryList {
       public teams: string = `Team(s)`;
       public users: string = `Users(s)`;

		public static get Resources(): BenefitBeneficiaryList{
			switch (Globals.currentActiveLanguage) {
				default:
				return new BenefitBeneficiaryList();
		}
   	}
}
