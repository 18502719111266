import React from "react";
import { IClientDetails } from "../../../models/Clients/Interfaces/IClientDetails";
import ClientDetails from "../../../resources/AdminUI/Clients/ClientDetails";
import { String_Format } from "../../../utils/utils";
import General from "../../../resources/AdminUI/General";

interface IProps {
    client: IClientDetails
}

export default class ClientMoreDetailsTabContent extends React.PureComponent<IProps, any> {
    constructor(props) {
        super(props)
    }

    render() {
        return <div>
                <div className="header first">{ClientDetails.Resources.legalNameLabel}</div>
                <p>{this.props.client.legalClientName || General.Resources.notCompletedYet}</p>

                <div className="header">{ClientDetails.Resources.cuiLabel}</div>
                <p>{this.props.client.cui || General.Resources.notCompletedYet}</p>

                <div className="header">{ClientDetails.Resources.industryLabel}</div>
                <p>{this.props.client.industry || General.Resources.notCompletedYet}</p>

                <div className="header">{ClientDetails.Resources.noUsersAndEmployees}</div>
                <p>{this.props.client.noEmployees ? String_Format(ClientDetails.Resources.noEmployeesText, this.props.client.noEmployees) : General.Resources.notCompletedYet}</p>
           

            <div className="info-subsection">
                <div className="header">{ClientDetails.Resources.detailsLabel}</div>
                <p>{this.props.client.details || General.Resources.notCompletedYet}</p>
            </div>
        </div>
    }
}