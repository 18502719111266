import Globals from "./../../../Globals";

export default class DeactivateOptions {
       public deactivateImmediately: string = `Deactivate immediately (from current renewal cycle)`;
       public deactivateNextCycle: string = `Deactivate from next renewal cycle`;

		public static get Resources(): DeactivateOptions{
			switch (Globals.currentActiveLanguage) {
				default:
				return new DeactivateOptions();
		}
   	}
}
