import bind from "bind-decorator";
import Validations from "../../../resources/Common/Validations";
import { String_Format, nameof } from "../../../utils/utils";
import { BaseValidator } from "../Base/BaseValidator";
import AddOrEditHotdeskBooking from "../../../resources/AdminUI/HotdeskBookings/AddOrEditHotdeskBooking";
import { HotdeskBookingStatuses } from "../../../enums/HotdeskBookings/HotdeskBookingStatuses";

export class AddOrEditHotdeskBookingFormValidator extends BaseValidator {

    constructor() {
        super();
    }

    @bind
    validate(model: any, props: any) {

        this.check(() => model.userId != '' && model.userId != null, nameof(e => e.userId), String_Format(Validations.Resources.required, AddOrEditHotdeskBooking.Resources.userId));
        this.check(() => model.workspaceId != '' && model.workspaceId != null, nameof(e => e.workspaceId), String_Format(Validations.Resources.required, AddOrEditHotdeskBooking.Resources.spaceId));
        this.check(() => model.statusId != '' && model.statusId != null, nameof(e => e.statusId), String_Format(Validations.Resources.required, AddOrEditHotdeskBooking.Resources.status));
        this.check(() => model.statusId == HotdeskBookingStatuses.Cancelled ? model.cancelledAt != null && model.cancelledAtTime != null : true, nameof(e => e.cancelledDateAndTime), String_Format(Validations.Resources.required, AddOrEditHotdeskBooking.Resources.cancelledDateAndTime));
        this.check(() => model.statusId == HotdeskBookingStatuses.Checkedin ? model.checkinAt != null && model.checkinAtTime != null : true, nameof(e => e.checkinDateAndTime), String_Format(Validations.Resources.required, AddOrEditHotdeskBooking.Resources.checkinDateAndTime));
        this.check(() => model.statusId == HotdeskBookingStatuses.Ended ? model.checkinAt != null && model.checkinAtTime != null : true, nameof(e => e.checkinDateAndTime), String_Format(Validations.Resources.required, AddOrEditHotdeskBooking.Resources.checkinDateAndTime));
        this.check(() => model.statusId == HotdeskBookingStatuses.Ended ? model.checkoutAt != null && model.checkoutAtTime != null : true, nameof(e => e.checkoutDateAndTime), String_Format(Validations.Resources.required, AddOrEditHotdeskBooking.Resources.checkoutDateAndTime));
        this.check(() => model.statusId == HotdeskBookingStatuses.Scheduled || !props.isEditMode ? model.bookingAt != null && model.bookingAtTime != null : true, nameof(e => e.bookingDateAndTime), String_Format(Validations.Resources.required, AddOrEditHotdeskBooking.Resources.bookingDateAndTime));
        this.check(() => !model.statusId || model.statusId == HotdeskBookingStatuses.Ended ? model.checkinAt == model.checkoutAt : true , nameof(e => e.checkinDateAndTime), AddOrEditHotdeskBooking.Resources.sameCheckinAndCheckoutDate);

        return this.getErrorsDictionary();
    }
}