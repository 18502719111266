import bind from "bind-decorator";
import Validations from "../../../resources/Common/Validations";
import { String_Format, nameof } from "../../../utils/utils";
import { BaseValidator } from "../Base/BaseValidator";
import Subscriptions from "../../../resources/AdminUI/Subscriptions/Subscriptions";

export class AddOrEditSubscriptionValidator extends BaseValidator {

    constructor() {
        super();
    }

    @bind
    validate(model: any, props: any) {

        this.check(() => model.subscription.name != '' && model.subscription.name != null, nameof(e => e.name), String_Format(Validations.Resources.required, Subscriptions.Resources.pricingPlanText));
        // this.check(() => !model.subscription.name || model.subscription.name.length <= 50, nameof(e => e.name), String_Format(Validations.Resources.maxLength, 50));
        this.check(() => model.subscription.currency != '' && model.subscription.currency != null, nameof(e => e.currency), String_Format(Validations.Resources.required, Subscriptions.Resources.currencyNameText));
        this.check(() => model.subscription.startsAt != '' && model.subscription.startsAt != null, nameof(e => e.startsAt), String_Format(Validations.Resources.required, Subscriptions.Resources.pricingPlanStartDateText));
        this.check(() => model.subscription.renewIntervalUnit != '' && model.subscription.renewIntervalUnit != null, nameof(e => e.renewIntervalUnit), String_Format(Validations.Resources.required, Subscriptions.Resources.paymentRenewalTypeText));
        this.check(() => model.subscription.renewIntervalValue != '' && model.subscription.renewIntervalValue != null, nameof(e => e.renewIntervalValue), String_Format(Validations.Resources.required, Subscriptions.Resources.paymentRenewalValueText));

        return this.getErrorsDictionary();
    }
}