// CheckboxInput.tsx
import React from "react";

import Loader from "../Loader";
import { IInputProps } from "./FormInput";

const CheckboxInput: React.FC<IInputProps> = ({
  label,
  value,
  className,
  errors,
  isDataLoading,
  name,
  readOnly,
  options,
  hidden,
  refreshData,
  onChange,
}) => {
  if (!options || options.length === 0) {
    if (refreshData && !isDataLoading) {
      refreshData();
    }
    return <Loader show={true} />;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!readOnly && onChange) {
      const updatedOptions = [...options];
      updatedOptions.forEach((u) => {
        if (u.value === e.target.value) {
          u.selected = !u.selected;
        }
      });
      onChange(
        name,
        updatedOptions.filter((c) => c.selected)
      );
    }
  };

  return (
    <div className="form-item" hidden={hidden}>
      {label && <label>{label}</label>}
      <div className={className}>
        {options.map((ch: any) => (
          <label key={ch.value} className="checkbox">
            <input
              type="checkbox"
              onChange={readOnly || ch.disabled ? undefined : handleChange}
              checked={ch.selected || ch.value === value}
              value={ch.value}
              readOnly={readOnly || ch.disabled}
              name={name}
            />
            {ch.text}
          </label>
        ))}
      </div>
      {errors.map((err: string) => (
        <span key={err} className="field-validation-error">
          {err}
        </span>
      ))}
    </div>
  );
};

export default CheckboxInput;
