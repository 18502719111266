import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { ApplicationState } from "../../../store";
import { TabStore } from "../../../store/TabStore";
import TabsHeader from "../../Tabs/TabsHeader";
import General from "../../../resources/AdminUI/General";
import { DetailsTypes } from "../../../enums/Common/DetailsTypes";
import { SpacePricing } from "./SpacePricing";
import { ITabItem } from "../../../utils/reactUtils";
import { WIFISetupAndAccess } from "./WIFISetupAndAccess";
import { AppSettings } from "../../../utils/settings";
import { ContactInfoTab } from "../../Tabs/ContactInfoTab";
import { DescriptionTab } from "../../Tabs/DescriptionTab";
import { AmenitiesTab } from "../../Tabs/AmenitiesTab";
import { ScheduleTab } from "../../Tabs/ScheduleTab";
import Workspaces from "../../../resources/AdminUI/Spaces/Workspaces";
import NewImageGalleryViewer from "../../Shared/NewImageGalleryViewer";
import IDisplayAmenityDTO from "../../../models/Common/IDisplayAmenityDTO";

// Define props interface
interface Props {
  tabState: any;
  images: string[];
  vat: string;
  fullDayPrice: string;
  halfDayPrice: string;
  unredeemedPrice: string;
  currencyLabel: string;
  descriptionEn: string;
  descriptionEs: string;
  descriptionPt: string;
  amenities: IDisplayAmenityDTO[];
  schedule: any;
  email: string;
  phone: string;
  businessEmail: string;
  businessPhone: string;
  wifiName: string;
  wifiPassword: string;
  accessInstructionsEn: string;
  accessInstructionsEs: string;
  accessInstructionsPt: string;
  initializeTabPage: (
    tabs: ITabItem[],
    activeTabId: string,
    callback: any
  ) => void;
  changeActiveTab: (tabId: string) => void;
}

const SpaceDetailsTabsComponent: React.FC<Props> = (props) => {
  useEffect(() => {
    // Initialize the tabs only if tabState is undefined to avoid infinite updates
    if (!props.tabState) {
      const tabs: ITabItem[] = [
        {
          tabId: DetailsTypes.Photographs.toString(),
          title: Workspaces.Resources.photographs,
          class: "",
          content: () => (
            <NewImageGalleryViewer
              itemUrls={
                props.images && props.images.length > 0
                  ? props.images.map(
                      (e) => AppSettings.CommonSettings.ImageBaseUrl + e
                    )
                  : []
              }
            />
          ),
        },
        {
          tabId: DetailsTypes.Pricing.toString(),
          title: Workspaces.Resources.pricing,
          class: "",
          content: () => (
            <SpacePricing
              vat={props.vat}
              fullDayPrice={props.fullDayPrice}
              halfDayPrice={props.halfDayPrice}
              unredeemedPrice={props.unredeemedPrice}
              currencyLabel={props.currencyLabel}
            />
          ),
        },
        {
          tabId: DetailsTypes.Description.toString(),
          title: Workspaces.Resources.description,
          class: "",
          content: () => (
            <DescriptionTab
              descriptionEn={props.descriptionEn}
              descriptionEs={props.descriptionEs}
              descriptionPt={props.descriptionPt}
            />
          ),
        },
        {
          tabId: DetailsTypes.Amenities.toString(),
          title: Workspaces.Resources.amenities,
          class: "",
          content: () => <AmenitiesTab amenities={props.amenities} />,
        },
        {
          tabId: DetailsTypes.Schedule.toString(),
          title: Workspaces.Resources.schedule,
          class: "",
          content: () => <ScheduleTab schedule={props.schedule} />,
        },
        {
          tabId: DetailsTypes.ContactInfo.toString(),
          title: Workspaces.Resources.contactInfo,
          class: "",
          content: () => (
            <ContactInfoTab email={props.email} phone={props.phone} />
          ),
        },
        {
          tabId: DetailsTypes.BusinessContactInfo.toString(),
          title: Workspaces.Resources.businessContactInfo,
          class: "",
          content: () => (
            <ContactInfoTab
              email={props.businessEmail}
              phone={props.businessPhone}
            />
          ),
        },
        {
          tabId: DetailsTypes.WIFISetupAndAccess.toString(),
          title: Workspaces.Resources.wifiSetupAndAccess,
          class: "",
          content: () => (
            <WIFISetupAndAccess
              wifiName={props.wifiName}
              wifiPassword={props.wifiPassword}
              accessInstructionsEn={props.accessInstructionsEn}
              accessInstructionsEs={props.accessInstructionsEs}
              accessInstructionsPt={props.accessInstructionsPt}
            />
          ),
        },
      ];

      props.initializeTabPage(tabs, DetailsTypes.Photographs.toString(), null);
    }
  }, [props.tabState, props]); // Ensure this effect only runs when tabState or props change

  if (!props.tabState) {
    return (
      <div>
        <h2 className="text-center">{General.Resources.loading}</h2>
      </div>
    );
  }

  const activeTab = props.tabState.tabs.find(
    (t) => t.tabId === props.tabState.activeTabId
  );

  return (
    <div>
      <TabsHeader
        onTabClick={props.changeActiveTab}
        activeTabId={activeTab.tabId}
        className="page-tabs"
        tabs={props.tabState.tabs.map((t) => ({
          title: t.title,
          tabId: t.tabId,
          class: t.class,
        }))}
      />
      <div className="tab-content spaces-info-tabs">{activeTab.content()}</div>
    </div>
  );
};

export default connect(
  (state: ApplicationState, ownProps: any) => ({
    tabState: state.tab[ownProps.componentId],
  }),
  (dispatch, ownProps: any) =>
    bindActionCreators(
      {
        ...TabStore.getActionCreators(ownProps.componentId),
      },
      dispatch
    )
)(SpaceDetailsTabsComponent);
