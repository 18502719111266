import { useCallback, useEffect, useMemo } from "react";
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";

import { DefaultFilterValues } from "../../enums/Common/DefaultFilterValues";
import { useRoomBookingsContext } from "../../providers/RoomBookingsProvider";
import RegularExpressions from "../../resources/Common/RegularExpressions";
import { IRoomBookingsItem } from "../../models/RoomBookings/Interfaces/IRoomBookingsItem";
import { IEntitiesList } from "../../models/Common/IEntitiesList";
import useDebounce from "../Shared/useDebounce";
import { useRoomBookingsQueries } from "./useRoomBookingsQueries";
import { useRoomBookingsHelpers } from "./useRoomBookingsHelpers";

export const useRoomBookingsPage = () => {
  const {
    itemsPerPage,
    currentPage,
    filters,
    numberOfRoomBookings,
    searchTerm,
    singleSelectedStatus,
    singleSelectedCountry,
    singleSelectedCity,
    startDateSearchTemplate,
    endDateSearchTemplate,
    setFilters,
    setLoading,
    setRoomBookings,
    setNumberOfRoomBookings,
    setCurrentPage,
    setPageIndexArray,
    setFirstIndexFromPage,
    setLastIndexFromPage,
  } = useRoomBookingsContext();
  const {
    recalculatePageArray,
    recalculateIndexes,
    rebuildODataFilters,
    getAllRoomStatuses,
  } = useRoomBookingsHelpers(itemsPerPage, numberOfRoomBookings, currentPage);

  const { getRoomBookings, useAllCountriesWithCities, useAllCities } =
    useRoomBookingsQueries();

  const queryClient = useQueryClient();
  const debouncedSearchTerm = useDebounce(searchTerm, 800);

  const skip = useMemo(
    () => (currentPage - 1) * itemsPerPage,
    [currentPage, itemsPerPage]
  );

  const {
    data,
    isLoading,
    error,
  }: UseQueryResult<IEntitiesList<IRoomBookingsItem>, Error> = getRoomBookings(
    itemsPerPage,
    skip,
    filters
  );
  const { data: countriesData } = useAllCountriesWithCities();
  const { data: citiesData } = useAllCities();

  useEffect(() => {
    setCurrentPage(currentPage);
  }, []);

  useEffect(() => {
    if (data) {
      setRoomBookings(data.entities);
      setNumberOfRoomBookings(data.numberOfEntities);
    } else {
      setRoomBookings([]);
      setNumberOfRoomBookings(0);
    }
    setLoading(false);
  }, [data]);

  useEffect(() => {
    const pageArray = recalculatePageArray();
    setPageIndexArray(pageArray);
    const { firstIndex, lastIndex } = recalculateIndexes();
    setFirstIndexFromPage(firstIndex);
    setLastIndexFromPage(lastIndex);
  }, [numberOfRoomBookings, currentPage]);

  const changeCurrentPage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const rebuildODataFiltersWithCustomLogic = (
    toTake: number,
    toSkip: number
  ): string => {
    const filters: any = {};

    // Add search term filters
    if (searchTerm) {
      const lowercaseTerm = searchTerm.toLowerCase();
      const searchFilter = {
        or: [
          { "tolower(UserCompanyName)": { contains: lowercaseTerm } },
          { "tolower(UserFullName)": { contains: lowercaseTerm } },
          { "tolower(WorkspaceName)": { contains: lowercaseTerm } },
        ],
      };

      const pattern = RegularExpressions.Resources.onlyNumbers;
      if (RegExp(pattern).test(searchTerm)) {
        // Add Id filter if search term is numeric
        filters.or = [
          searchFilter,
          { Id: parseInt(searchTerm) },
          { UserId: parseInt(searchTerm) }, // New condition for UserId
        ];
      } else {
        filters.or = searchFilter;
      }
    }

    // Add Status, Country, City, and Date filters
    if (
      singleSelectedStatus &&
      singleSelectedStatus !== DefaultFilterValues.All.toString()
    ) {
      filters.StatusId = parseInt(singleSelectedStatus);
    }

    if (
      singleSelectedCountry &&
      singleSelectedCountry !== DefaultFilterValues.All.toString()
    ) {
      filters.WorkspaceCountryId = parseInt(singleSelectedCountry);
    }

    if (
      singleSelectedCity &&
      singleSelectedCity !== DefaultFilterValues.All.toString()
    ) {
      filters.WorkspaceCityId = parseInt(singleSelectedCity);
    }

    if (startDateSearchTemplate && endDateSearchTemplate) {
      if (startDateSearchTemplate === endDateSearchTemplate) {
        filters.UnixStartsAtWithTimezone = startDateSearchTemplate;
      } else {
        filters.and = [
          { UnixStartsAtWithTimezone: { ge: startDateSearchTemplate } },
          { UnixStartsAtWithTimezone: { le: endDateSearchTemplate } },
        ];
      }
    }

    return rebuildODataFilters(toTake, toSkip, filters);
  };

  const oDataFilter = useMemo(
    () => rebuildODataFiltersWithCustomLogic(itemsPerPage, skip),
    [
      debouncedSearchTerm,
      singleSelectedStatus,
      singleSelectedCountry,
      singleSelectedCity,
      startDateSearchTemplate,
      endDateSearchTemplate,
      itemsPerPage,
      skip,
    ]
  );

  useEffect(() => {
    setFilters(oDataFilter);
  }, [oDataFilter]);

  const reloadRoomBookingsData = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ["roomBookings"] });
  }, [queryClient]);

  return {
    countriesData,
    citiesData,
    filters: oDataFilter,
    skip,
    isLoading,
    error,
    rebuildODataFilters: rebuildODataFiltersWithCustomLogic,
    changeCurrentPage,
    getAllRoomStatuses,
    recalculateIndexes,
    recalculatePageArray,
    reloadRoomBookingsData,
  };
};
