import Globals from "./../../../Globals";

export default class Rooms {
       public activateRoom: string = `ACTIVATE ROOM`;
       public addRoom: string = `Add room`;
       public amenities: string = `Amenities`;
       public createdOn: string = `Created on {0} `;
       public deactivateRoom: string = `DEACTIVATE ROOM`;
       public deactivateRoomConfirmation: string = `By continuing this room will be deactivated, which means that users will not be able to see this room in the mobile application and all the bookings will be cancelled (even if confirmed by the space).`;
       public description: string = `Description`;
       public editRoomInfo: string = `EDIT ROOM INFO`;
       public maxGuests: string = `max. {0} guests`;
       public moreDetails: string = `More details`;
       public priceDaily: string = `{0} {1} /daily`;
       public priceHourly: string = `{0} {1} / hourly`;
       public publicContactInfo: string = `Public Contact Info`;
       public roomDetails: string = `Room details`;
       public schedule: string = `Schedule`;

		public static get Resources(): Rooms{
			switch (Globals.currentActiveLanguage) {
				default:
				return new Rooms();
		}
   	}
}
