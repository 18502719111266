import React from "react";
import ManageAccounts from "../../../resources/AdminUI/ManageAccounts";
import bind from "bind-decorator";
import { String_Format, nameof } from "../../../utils/utils";
import { ApplicationState } from "../../../store";
import { LookupStore } from "../../../store/LookupStore";
import { connect } from "react-redux";
import { ManageAccountStore } from "../../../store/ManageAccountsStore";
import SpaceGroupGrid from "./SpaceGroupGrid";
import { DefaultFilterValues } from "../../../enums/Common/DefaultFilterValues";
import { IAntdSelectListItem } from "../../../models/Common/IAntdSelectListItem";
import { Select } from "antd";
import General from "../../../resources/AdminUI/General";

interface IProps {
    countries?: IAntdSelectListItem[],
    filters?: string,
    getCountries?: (allOpt: boolean) => void,
    reloadData?: (filters?: string) => void
}

class SpaceGroupsContainer extends React.PureComponent<IProps, any> {
    constructor(props) {
        super(props)

        this.state = {
            filter: this.props.filters,
            wordSearched: ''
        }

        this.props.getCountries(true);
    }

    searchByNameTemplate = `contains(tolower(name), tolower('{0}'))`;
    wordSearched = '';

    countryFilterTemplate = `{0} in countriesList`;
    selectedCountryId = null;

    @bind
    reloadAndGetData() {
        var filters = [];

        if (this.wordSearched) {
            var nameFilter = String_Format(this.searchByNameTemplate, this.wordSearched);
            filters.push(nameFilter);
        }
        if(this.selectedCountryId && this.selectedCountryId != DefaultFilterValues.All.toString()) { 
            filters.push(`${String_Format(this.countryFilterTemplate, this.selectedCountryId)}`);
        }

        var oDataFilter = filters.join(" and ");

        this.props.reloadData(oDataFilter);
    }

    @bind
    onSearchFilterChange(e) {
        if (this.wordSearched != e.currentTarget.value) {
            this.wordSearched = e.currentTarget.value;
            this.reloadAndGetData()
        }

        this.setState({ wordSearched: this.wordSearched });
    }

    @bind
    onCountryFilterChange(value: string, option: any) {
        this.selectedCountryId = value;
        this.reloadAndGetData();
    }

    render() {

        return <div>

			 <div className="filter-bar">

                <div className="filter-search">
                    <div className="input-group">
                        <span className="icon material-symbols-outlined">search</span>
                        <input type="text"
                            className="form-control"
                            placeholder={ManageAccounts.Resources.searchBarPlaceholder}
                            onChange={this.onSearchFilterChange}
                            name={nameof(e => e.searchFilter)}
                            value={this.state.wordSearched}
                        />
                    </div>
                </div>

            	<div className="filter-item">
                    <div className="input-group">
                    <Select
                        options={this.props.countries}
                        onChange={this.onCountryFilterChange}
                        placeholder={General.Resources.select}
                        value={this.selectedCountryId}
                        showSearch
                        optionFilterProp="label"
                    ></Select>
                    </div>
                </div>
            </div>

            <SpaceGroupGrid />
        </div>
    }
}

export default connect(
    (state: ApplicationState, ownProps: IProps) => {
        return {
            countries: state.lookup.allCountries,
            filters: state.manageAccounts.filters,
            ...ownProps
        }
    },
    {
        getCountries: LookupStore.actionCreators.getCountries,
        reloadData: ManageAccountStore.actionCreators.reloadSpaceGroupsList
    }
)(SpaceGroupsContainer as any)