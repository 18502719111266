import bind from "bind-decorator";
import React from "react";
import Helmet from "react-helmet";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { NavigateFunction } from "react-router-dom";
import { LookupStore } from "../../store/LookupStore";
import { SpaceCategories } from "../../enums/Spaces/SpaceCategories";
import SpaceCategoryList from "../../resources/AdminUI/Spaces/SpaceCategoryList";
import { firstLetterToLowerCase } from "../../utils/utils";
import { ApplicationPaths } from "../../enums/Common/ApplicationPaths";
import AddOrEditSpaceForm from "../../components/Spaces/AddOrEditSpaceForm";
import { AddOrEditSpaceStore } from "../../store/AddOrEditSpaceStore";
import General from "../../resources/AdminUI/General";
import Workspaces from "../../resources/AdminUI/Spaces/Workspaces";
import AddOrEditWorkspaceForm from "../../resources/AdminUI/Spaces/AddOrEditWorkspaceForm";
import { IAntdSelectListItem } from "../../models/Common/IAntdSelectListItem";
import { withRouter } from "../../components/Shared/withRouter";
import Loading from "../../components/Shared/Loading";

interface IProps {
  navigate?: NavigateFunction;
  params: URLSearchParams;

  countries?: IAntdSelectListItem[];
  cities?: IAntdSelectListItem[];
  currencies?: IAntdSelectListItem[];
  workspaceGroups?: IAntdSelectListItem[];
  languages?: IAntdSelectListItem[];
  loading: boolean;

  getCountriesWithCities?: (addAllOption?: boolean) => void;
  getCities?: (addAllOption?: boolean) => void;
  getCurrencies?: () => void;
  getWorkspaceGroups?: () => void;
  getLanguages?: () => void;
  getSpaceAmenities?: () => void;
  initializeCreatePage?: () => void;
  initializeEditPage?: (id: number) => void;
}

interface IState {
  isEditMode: boolean;
  spaceId: number;
}

class AddOrEditSpacePage extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);

    if (window.location.pathname == ApplicationPaths.AddSpace) {
      this.state = { isEditMode: false, spaceId: null };
      this.props.initializeCreatePage();
      this.props.getSpaceAmenities();
    } else if (window.location.pathname == ApplicationPaths.EditSpace) {
      if (props.params.has("id")) {
        const id = parseInt(props.params.get("id"));
        this.state = { isEditMode: true, spaceId: id };
        this.props.initializeEditPage(id);
      } else {
        this.props.navigate(ApplicationPaths.NotFound);
      }
    }
    this.props.getCountriesWithCities(false);
    this.props.getCities(false);
    this.props.getCurrencies();
    this.props.getWorkspaceGroups();
    this.props.getLanguages();
  }

  @bind
  getAllSpaceCategories() {
    var options = [];
    let keys = Object.keys(SpaceCategories).filter(
      (k) => typeof SpaceCategories[k as any] === "number"
    );

    keys.forEach((key) => {
      let value = SpaceCategories[key as any].toString();
      if (
        value != SpaceCategories.All.toString() &&
        value != SpaceCategories.CompanyOffice.toString()
      ) {
        options.push({
          value: value,
          label: SpaceCategoryList.Resources[firstLetterToLowerCase(key)],
        });
      }
    });
    return options;
  }

  render() {
    return (
      <div>
        <Helmet title={Workspaces.Resources.spaces} />

        <div className="details-page-top">
          <div className="page-navigation">
            <span
              className="material-symbols-outlined"
              onClick={() => this.props.navigate(-1)}
            >
              arrow_back
            </span>
            <div className="page-title">
              {this.state.isEditMode
                ? AddOrEditWorkspaceForm.Resources.editSpace
                : AddOrEditWorkspaceForm.Resources.addSpace}
            </div>
          </div>
        </div>

        {!this.props.countries ||
        !this.props.cities ||
        !this.props.currencies ||
        !this.props.workspaceGroups ||
        !this.props.languages ||
        this.props.loading ? (
          <div>
            <Loading />
          </div>
        ) : (
          <div>
            <AddOrEditSpaceForm
              spaces={this.getAllSpaceCategories()}
            ></AddOrEditSpaceForm>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: ApplicationState) => {
      return {
        countries: state.lookup.countries,
        cities: state.lookup.cities,
        currencies: state.lookup.currencies,
        workspaceGroups: state.lookup.workspaceGroups,
        languages: state.lookup.languages,
        loading: state.addOrEditSpace.loading,
      };
    },
    {
      getCountriesWithCities: LookupStore.actionCreators.getCountriesWithCities,
      getCities: LookupStore.actionCreators.getCities,
      getCurrencies: LookupStore.actionCreators.getCurrencies,
      getWorkspaceGroups: LookupStore.actionCreators.getWorkspaceGroups,
      getLanguages: LookupStore.actionCreators.getLanguages,
      getSpaceAmenities: AddOrEditSpaceStore.actionCreators.getSpaceAmenities,
      initializeCreatePage:
        AddOrEditSpaceStore.actionCreators.initializeCreatePage,
      initializeEditPage: AddOrEditSpaceStore.actionCreators.initializeEditPage,
    }
  )(AddOrEditSpacePage as any)
);
