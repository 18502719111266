import React from "react";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { ICityItem } from "../../models/CountriesAndCities/ICityItem";
import CountriesAndCities from "../../resources/AdminUI/CountriesAndCities";
import { CityCard } from "./Cards/CityCard";
import bind from "bind-decorator";
import { String_Format } from "../../utils/utils";
import { ICountryItem } from "../../models/CountriesAndCities/ICountryItem";
import AddOrEditCityForm from "./AddOrEditCityForm";
import { CountriesAndCitiesStore } from "../../store/CountriesAndCitiesStore";
import General from "../../resources/AdminUI/General";
import ModalDialog from "../Shared/ModalDialog";

interface IProps {
    loadingCities: boolean,
    selectedCountry: ICountryItem;
    cities?: ICityItem[];
    onSaveCity?: (city: ICityItem) => void;
    onReset?: () => void;
}

interface IState {
    isAddCityModalOpen: boolean;
}

class CitiesContainer extends React.PureComponent<IProps, IState> {
    constructor(props) {
        super(props)
        this.state = {
            isAddCityModalOpen: false
        }
    }

    @bind
    showAddCityModal() {
        this.setState({ isAddCityModalOpen: true });
    }

    @bind
    hideAddCityModal() {
        this.setState({ isAddCityModalOpen: false });
    }

    render() {
        if (this.props.loadingCities) {
            return <div>
                {General.Resources.loading}
            </div>
        } else {
            return <div className="cities-scrollable-list">

                <h3 className="title">{String_Format(CountriesAndCities.Resources.citiesLabel, this.props.selectedCountry.name)}</h3>

                <div>
                    <button className="btn-big btn-primary displayed-bottom" onClick={this.showAddCityModal}>
                        <span className="material-symbols-outlined">add_circle</span>
                        <p className="btn-primary-text">{CountriesAndCities.Resources.addCityButtonLabel}</p>
                    </button>
                </div>

                <div>
                    <ul style={{ listStyle: 'none' }}>
                        {
                            this.props.cities.length == 0 ?
                                <div>{CountriesAndCities.Resources.thereIsNoCityForThisCountry}</div>
                                :
                                this.props.cities.map(city =>
                                    <li key={city.id}>
                                        <CityCard
                                            city={city}
                                            countryId={this.props.selectedCountry.id}
                                            onSaveCityCallback={this.props.onSaveCity}
                                            onResetCallback={this.props.onReset}
                                        />
                                    </li>
                                )
                        }
                    </ul>

                    <ModalDialog
                        title={""}
                        open={this.state.isAddCityModalOpen}
                        onCancel={this.hideAddCityModal}>
                        <AddOrEditCityForm
                            countryId={this.props.selectedCountry.id}
                            onCancelCallback={this.hideAddCityModal}
                            onSaveCityCallback={this.props.onSaveCity}
                            onResetCallback={this.props.onReset} />
                    </ModalDialog>
                </div>
            </div>
        }
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            loadingCities: state.countriesAndCities.loadingCities,
            selectedCountry: state.countriesAndCities.selectedCountry,
            cities: state.countriesAndCities.citiesList
        }
    },
    {
        onSaveCity: CountriesAndCitiesStore.actionCreators.saveCity,
        onReset: CountriesAndCitiesStore.actionCreators.reset
    }
)(CitiesContainer as any)