// FileInput.tsx
import React from "react";
import { IInputProps } from "./FormInput";

const FileInput: React.FC<IInputProps> = ({
  label,
  value,
  placeholder,
  errors,
  name,
  readOnly,
  hidden,
  onChange,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!readOnly && onChange && e.target.files?.length) {
      const url = URL.createObjectURL(e.target.files[0]);
      const uploadedFile = { formFile: e.target.files[0], fileUrl: url };
      onChange(name, uploadedFile, null);
    }
  };

  const fileUrl =
    value.formFile && value.formFile.name.trim() !== ""
      ? URL.createObjectURL(value.formFile)
      : value.fileUrl;

  const fileLabel =
    value.formFile && value.formFile.name.trim() !== ""
      ? value.formFile.name
      : label;

  return (
    <div className="form-item">
      {label && <label>{label}</label>}
      {fileUrl && (
        <a target="_" href={fileUrl}>
          Download {fileLabel}
        </a>
      )}
      <div className="form-upload">
        <input
          type="file"
          placeholder={placeholder}
          className={`${errors.length > 0 ? "input-validation-error" : ""}`}
          onChange={handleChange}
          name={name}
          readOnly={readOnly}
          hidden={hidden}
        />
        <div className="form-upload-wrapper">
          <label>
            {value.formFile && value.formFile.name.trim() !== ""
              ? value.formFile.name
              : "No File Chosen"}
          </label>
          {!(value.formFile && value.formFile.name.trim() !== "") && (
            <span>Choose File</span>
          )}
        </div>
      </div>
      {errors.map((err: string) => (
        <span key={err} className="field-validation-error">
          {err}
        </span>
      ))}
    </div>
  );
};

export default FileInput;
