import { ServiceBase } from "./ServiceBase";
import Result from "../models/Common/Result";
import { AppSettings } from "../utils/settings";
import { ISpaceItem } from "../models/Spaces/Interfaces/ISpaceItem";
import { SpaceItem } from "../models/Spaces/SpaceItem";
import { EntitiesList, IEntitiesList } from "../models/Common/IEntitiesList";
import { PayloadTypes } from "../enums/Common/PayloadTypes";
import { ChangeHotdeskBookingPricesDTO } from "../models/Spaces/Interfaces/ChangeHotdeskBookingPricesDTO";

export default class SpacesService extends ServiceBase {
  static getSpaces = (
    toTake: number,
    toSkip: number,
    filters?: string
  ): Promise<Result<IEntitiesList<ISpaceItem>>> => {
    var url = `/odata/spaces?$top=${toTake}&$skip=${toSkip}`;

    if (filters && filters.length > 0) {
      url = `${url}&$filter=${filters}`;
    }

    try {
      var result = SpacesService.requestJsonFetch<IEntitiesList<ISpaceItem>>({
        method: "GET",
        url: `${AppSettings.CommonSettings.ApiUrl}${url}&$orderby=Id desc&$count=true`,
      });
      return result;
    } catch (error) {}

    return new Promise<Result<IEntitiesList<ISpaceItem>>>(null);
  };

  static additionalMapping = (
    entitiesList: IEntitiesList<ISpaceItem>
  ): EntitiesList<ISpaceItem> => {
    var newEntities = new EntitiesList<ISpaceItem>();
    newEntities.entities = entitiesList.entities.map((e) => new SpaceItem(e));
    newEntities.numberOfEntities = entitiesList.numberOfEntities;
    return newEntities;
  };

  static getSpaceDetails = (id: number): Promise<Result<any>> => {
    var result = SpacesService.requestJsonFetch<any>({
      method: "GET",
      url: `${AppSettings.CommonSettings.ApiUrl}/spaces/${id}`,
    });

    return result;
  };

  static getSpaceDetailsForEdit = (id: number): Promise<Result<any>> => {
    var result = SpacesService.requestJsonFetch<any>({
      method: "GET",
      url: `${AppSettings.CommonSettings.ApiUrl}/spaces/${id}/form-details`,
    });

    return result;
  };

  static addSpace = (spaceDetails: any): Promise<Result<any>> => {
    var result = SpacesService.sendData<any>(
      {
        method: "POST",
        url: `${AppSettings.CommonSettings.ApiUrl}/spaces`,
        data: JSON.stringify(spaceDetails),
      },
      PayloadTypes.Json
    );

    return result;
  };

  static updateSpace = (
    id: number,
    spaceDetails: any
  ): Promise<Result<any>> => {
    var result = SpacesService.sendData<any>(
      {
        method: "PUT",
        url: `${AppSettings.CommonSettings.ApiUrl}/spaces/${id}`,
        data: JSON.stringify(spaceDetails),
      },
      PayloadTypes.Json
    );

    return result;
  };

  static saveSpaceGallery = (data: any): Promise<Result<any>> => {
    var form_data = new FormData();
    data.forEach((d, i) => {
      form_data.append(`items[${i}].id`, d.id);
      form_data.append(`items[${i}].file`, d.file);
    });

    var result = SpacesService.sendData<any>(
      {
        method: "POST",
        url: `${AppSettings.CommonSettings.ApiUrl}/spaces/files`,
        data: form_data,
      },
      PayloadTypes.MultipartFormData
    );

    return result;
  };

  static changeSpaceStatus = (
    workspaceId: number,
    statusId: number
  ): Promise<Result<any>> => {
    var result = SpacesService.sendData<any>(
      {
        method: "PUT",
        url: `${AppSettings.CommonSettings.ApiUrl}/spaces/${workspaceId}/status`,
        data: JSON.stringify({
          workspaceId: workspaceId,
          statusId: statusId,
        }),
      },
      PayloadTypes.Json
    );

    return result;
  };

  static addOffice = (officeDetails: any): Promise<Result<any>> => {
    var result = SpacesService.sendData<any>(
      {
        method: "POST",
        url: `${AppSettings.CommonSettings.ApiUrl}/spaces/offices`,
        data: JSON.stringify(officeDetails),
      },
      PayloadTypes.Json
    );

    return result;
  };

  static updateOffice = (
    id: number,
    officeDetails: any
  ): Promise<Result<any>> => {
    var result = SpacesService.sendData<any>(
      {
        method: "PUT",
        url: `${AppSettings.CommonSettings.ApiUrl}/spaces/${id}/office`,
        data: JSON.stringify(officeDetails),
      },
      PayloadTypes.Json
    );

    return result;
  };

  static getSpacesForExport = (
    filters?: string
  ): Promise<Result<IEntitiesList<ISpaceItem>>> => {
    var url = `/odata/spaces?$orderby=Id desc`;
    if (filters && filters.length > 0) {
      url = `${url}&$filter=${filters}`;
    }
    try {
      var result = SpacesService.requestJsonFetch<IEntitiesList<ISpaceItem>>({
        url: `${AppSettings.CommonSettings.ApiUrl}${url}`,
        method: "GET",
      });
      return result;
    } catch (error) {}

    return new Promise<Result<IEntitiesList<ISpaceItem>>>(null);
  };

  static getWorkspaceSchedule = (id: number): Promise<Result<any>> => {
    var result = SpacesService.requestJsonFetch<any>({
      method: "GET",
      url: `${AppSettings.CommonSettings.ApiUrl}/spaces/${id}/schedule`,
    });

    return result;
  };

  static changeHotdeskBookingPrices = (
    spaceId: number,
    model: ChangeHotdeskBookingPricesDTO
  ): Promise<Result<any>> => {
    var result = SpacesService.sendData<any>(
      {
        method: "POST",
        url: `${AppSettings.CommonSettings.ApiUrl}/spaces/${spaceId}/change-hotdesk-booking-prices`,
        data: JSON.stringify(model),
      },
      PayloadTypes.Json
    );
    return result;
  };
}
