export enum DetailsTypes {
    Photographs = 1,
    Pricing = 2,
    Description = 3,
    Amenities = 4,
    Schedule = 5,
    ContactInfo = 6,
    BusinessContactInfo = 7,
    WIFISetupAndAccess = 8
}
