import React from "react";
import Helmet from "react-helmet";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { LookupStore } from "../../store/LookupStore";
import { ApplicationPaths } from "../../enums/Common/ApplicationPaths";
import General from "../../resources/AdminUI/General";
import { IAntdSelectListItem } from "../../models/Common/IAntdSelectListItem";
import { NavigateFunction } from "react-router";
import { withRouter } from "../../components/Shared/withRouter";
import { AddOrEditBenefitStore } from "../../store/AddOrEditBenefitStore";
import AddOrEditBenefit from "../../resources/AdminUI/Benefits/AddOrEditBenefit";
import AddOrEditBenefitForm from "../../components/Clients/Forms/AddOrEditBenefitForm";
import Loading from "../../components/Shared/Loading";

interface IProps {
  navigate?: NavigateFunction;
  params: URLSearchParams;

  currencies?: IAntdSelectListItem[];
  loading: boolean;

  getCurrencies?: () => void;
  initializeCreatePage?: (clientId: number, subscriptionId: number) => void;
  initializeEditPage?: (id: number) => void;
}

interface IState {
  isEditMode: boolean;
}

class AddOrEditBenefitPage extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);

    if (window.location.pathname == ApplicationPaths.AddBenefit) {
      if (
        !this.props.params.get("customerId") ||
        !this.props.params.get("subscriptionId")
      ) {
        this.props.navigate(ApplicationPaths.NotFound);
      }

      const clientId = parseInt(this.props.params.get("customerId"));
      const subscriptionId = parseInt(this.props.params.get("subscriptionId"));
      this.state = { isEditMode: false };
      this.props.initializeCreatePage(clientId, subscriptionId);
    } else if (window.location.pathname == ApplicationPaths.EditBenefit) {
      if (!this.props.params.get("id")) {
        this.props.navigate(ApplicationPaths.NotFound);
      }

      const id = parseInt(this.props.params.get("id"));
      this.state = { isEditMode: true };
      this.props.initializeEditPage(id);
    }

    this.props.getCurrencies();
  }

  render() {
    return (
      <div>
        <Helmet title={AddOrEditBenefit.Resources.serviceUsed} />

        <div className="details-page-top">
          <div className="page-navigation">
            <span
              className="material-symbols-outlined"
              onClick={() => this.props.navigate(-1)}
            >
              arrow_back
            </span>
            <div className="page-title">
              {this.state.isEditMode
                ? AddOrEditBenefit.Resources.editService
                : AddOrEditBenefit.Resources.addService}
            </div>
          </div>
        </div>

        {!this.props.currencies || this.props.loading ? (
          <div>
            <Loading />
          </div>
        ) : (
          <div>
            <AddOrEditBenefitForm />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: ApplicationState) => {
      return {
        currencies: state.lookup.currencies,
        loading: state.addOrEditBenefit.loading,
      };
    },
    {
      getCurrencies: LookupStore.actionCreators.getCurrencies,
      initializeCreatePage:
        AddOrEditBenefitStore.actionCreators.initializeCreatePage,
      initializeEditPage:
        AddOrEditBenefitStore.actionCreators.initializeEditPage,
    }
  )(AddOrEditBenefitPage as any)
);
