import { ClientItem } from "../models/Clients/ClientItem";
import { IAddOrEditClient } from "../models/Clients/Interfaces/IAddOrEditClient";
import { IClientItem } from "../models/Clients/Interfaces/IClientItem";
import { EntitiesList, IEntitiesList } from "../models/Common/IEntitiesList";
import Result from "../models/Common/Result";
import { AppSettings } from "../utils/settings";
import { ServiceBase } from "./ServiceBase";
import { IClientDetails } from "../models/Clients/Interfaces/IClientDetails";
import { PayloadTypes } from "../enums/Common/PayloadTypes";
import { IClientOffice } from "../models/Clients/Interfaces/IClientOffice";
import { ISubscription } from "../models/Subscriptions/Interfaces/ISubscription";
import { ITeam } from "../models/Teams/Interfaces/ITeam";
import { IBenefit } from "../models/Benefits/Interfaces/IBenefit";

export default class ClientsService extends ServiceBase {
  static getClients = (
    toTake: number,
    toSkip: number,
    filters?: string
  ): Promise<Result<IEntitiesList<IClientItem>>> => {
    var url = `/odata/companies?$top=${toTake}&$skip=${toSkip}`;

    if (filters && filters.length > 0) {
      url = `${url}&$filter=${filters}`;
    }

    try {
      var result = ClientsService.requestJsonFetch<IEntitiesList<IClientItem>>({
        method: "GET",
        url: `${AppSettings.CommonSettings.ApiUrl}${url}&$orderby=Id desc&$count=true`,
      });
      return result;
    } catch (error) {}

    return new Promise<Result<IEntitiesList<IClientItem>>>(null);
  };

  static additionalMapping = (
    entitiesList: IEntitiesList<IClientItem>
  ): EntitiesList<IClientItem> => {
    var newEntities = new EntitiesList<IClientItem>();
    newEntities.entities = entitiesList.entities.map((e) => new ClientItem(e));
    newEntities.numberOfEntities = entitiesList.numberOfEntities;
    return newEntities;
  };

  static getClientDetailsForEdit = (
    id: number
  ): Promise<Result<IAddOrEditClient>> => {
    try {
      var result = ClientsService.requestJsonFetch<IAddOrEditClient>({
        method: "GET",
        url: `${AppSettings.CommonSettings.ApiUrl}/companies/${id}/form-details`,
      });
      return result;
    } catch (error) {}
  };

  static getClientDetails = (id: number): Promise<Result<IClientDetails>> => {
    try {
      var result = ClientsService.requestJsonFetch<IClientDetails>({
        method: "GET",
        url: `${AppSettings.CommonSettings.ApiUrl}/companies/${id}`,
      });
      return result;
    } catch (error) {}
  };

  static addClient = (client: IAddOrEditClient): Promise<Result<any>> => {
    var result = ClientsService.sendData<any>(
      {
        method: "POST",
        url: `${AppSettings.CommonSettings.ApiUrl}/companies`,
        data: JSON.stringify(client),
      },
      PayloadTypes.Json
    );

    return result;
  };

  static updateClient = (client: IAddOrEditClient): Promise<Result<any>> => {
    var result = ClientsService.sendData<any>(
      {
        method: "PUT",
        url: `${AppSettings.CommonSettings.ApiUrl}/companies/${client.id}`,
        data: JSON.stringify(client),
      },
      PayloadTypes.Json
    );

    return result;
  };

  static deactivateClient = (clientId: number): Promise<Result<any>> => {
    var result = ClientsService.sendData<any>(
      {
        method: "PUT",
        url: `${AppSettings.CommonSettings.ApiUrl}/companies/${clientId}/deactivate`,
        data: null,
      },
      PayloadTypes.Json
    );

    return result;
  };

  static activateClient = (clientId: number): Promise<Result<any>> => {
    var result = ClientsService.sendData<any>(
      {
        method: "PUT",
        url: `${AppSettings.CommonSettings.ApiUrl}/companies/${clientId}/activate`,
        data: null,
      },
      PayloadTypes.Json
    );

    return result;
  };

  static getClientsForExport = (
    filters?: string
  ): Promise<Result<IEntitiesList<IClientItem>>> => {
    var url = `/odata/companies?$orderby=Id desc`;

    if (filters && filters.length > 0) {
      url = `${url}?$filter=${filters}`;
    }
    try {
      var result = ClientsService.requestJsonFetch<IEntitiesList<IClientItem>>({
        method: "GET",
        url: `${AppSettings.CommonSettings.ApiUrl}${url}`,
      });
      return result;
    } catch (error) {}

    return new Promise<Result<IEntitiesList<IClientItem>>>(null);
  };

  static getClientOffices = (
    clientId: number
  ): Promise<Result<IClientOffice[]>> => {
    try {
      var result = ClientsService.requestJsonFetch<IClientOffice[]>({
        method: "GET",
        url: `${AppSettings.CommonSettings.ApiUrl}/companies/${clientId}/offices`,
      });
      return result;
    } catch (error) {}
  };

  static getSubscription = (
    clientId: number
  ): Promise<Result<ISubscription>> => {
    try {
      var result = ClientsService.requestJsonFetch<ISubscription>({
        method: "GET",
        url: `${AppSettings.CommonSettings.ApiUrl}/companies/${clientId}/subscriptions`,
      });
      return result;
    } catch (error) {}
  };

  static getTeams = (clientId: number): Promise<Result<ITeam[]>> => {
    try {
      var result = ClientsService.requestJsonFetch<ITeam[]>({
        method: "GET",
        url: `${AppSettings.CommonSettings.ApiUrl}/companies/${clientId}/teams`,
      });
      return result;
    } catch (error) {}
  };

  static getBenefits = (clientId: number): Promise<Result<IBenefit[]>> => {
    try {
      var result = ClientsService.requestJsonFetch<IBenefit[]>({
        method: "GET",
        url: `${AppSettings.CommonSettings.ApiUrl}/companies/${clientId}/benefits`,
      });
      return result;
    } catch (error) {}
  };

  static importUsers = (
    csvFile: any,
    companyId: any,
    teamId: any
  ): Promise<Result<any>> => {
    try {
      var form_data = new FormData();
      form_data.append("file", csvFile);
      form_data.append("companyId", companyId);
      form_data.append("teamId", teamId);
      var result = ClientsService.sendData<[]>(
        {
          method: "POST",
          url: `${AppSettings.CommonSettings.ApiUrl}/companies/import-users`,
          data: form_data,
        },
        PayloadTypes.MultipartFormData
      );
      return result;
    } catch (error) {}
  };

  static uploadCompanyFile = (data: any): Promise<Result<any>> => {
    var form_data = new FormData();
    form_data.set("file", data.file);
    form_data.set("id", data.file.name);

    var result = ClientsService.sendData<any>(
      {
        method: "POST",
        url: `${AppSettings.CommonSettings.ApiUrl}/companies/file`,
        data: form_data,
      },
      PayloadTypes.MultipartFormData
    );
    return result;
  };
}
