import React from "react";
import Helmet from "react-helmet";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { LookupStore } from "../../store/LookupStore";
import { firstLetterToLowerCase } from "../../utils/utils";
import { ApplicationPaths } from "../../enums/Common/ApplicationPaths";
import General from "../../resources/AdminUI/General";
import { IAntdSelectListItem } from "../../models/Common/IAntdSelectListItem";
import { NavigateFunction } from "react-router";
import { withRouter } from "../../components/Shared/withRouter";
import { AddOrEditTeamStore } from "../../store/AddOrEditTeamStore";
import AddOrEditTeam from "../../resources/AdminUI/Teams/AddOrEditTeam";
import AddOrEditTeamForm from "../../components/Teams/AddOrEditTeamForm";
import bind from "bind-decorator";
import { DaysOfWeek } from "../../enums/Common/DaysOfWeek";
import WeekDays from "../../resources/AdminUI/WeekDays";
import SpaceCategoryList from "../../resources/AdminUI/Spaces/SpaceCategoryList";
import { SpaceCategories } from "../../enums/Spaces/SpaceCategories";
import Loading from "../../components/Shared/Loading";

interface IProps {
  navigate?: NavigateFunction;
  params: URLSearchParams;

  countries?: IAntdSelectListItem[];
  cities?: IAntdSelectListItem[];
  languages?: IAntdSelectListItem[];
  loading: boolean;

  getCountries?: (addAllOption?: boolean) => void;
  getCities?: (addAllOption?: boolean) => void;
  initializeCreatePage?: (clientId: number) => void;
  initializeEditPage?: (id: number) => void;
}

interface IState {
  isEditMode: boolean;
  teamId: number;
}

class AddOrEditTeamPage extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);

    if (window.location.pathname == ApplicationPaths.AddTeam) {
      if (!this.props.params.has("customerId")) {
        this.props.navigate(ApplicationPaths.NotFound);
      }

      const clientId = parseInt(this.props.params.get("customerId"));
      this.state = { isEditMode: false, teamId: null };
      this.props.initializeCreatePage(clientId);
    } else if (window.location.pathname == ApplicationPaths.EditTeam) {
      if (!this.props.params.has("id")) {
        this.props.navigate(ApplicationPaths.NotFound);
      }

      const id = parseInt(this.props.params.get("id"));
      this.state = { isEditMode: true, teamId: id };
      this.props.initializeEditPage(id);
    }

    this.props.getCities(false);
    this.props.getCountries(false);
  }

  @bind
  getAllSpaceCategories() {
    var options = [];
    let keys = Object.keys(SpaceCategories).filter(
      (k) => typeof SpaceCategories[k as any] === "number"
    );

    keys.forEach((key) => {
      let value = SpaceCategories[key as any].toString();
      if (
        value != SpaceCategories.All.toString() &&
        value != SpaceCategories.CompanyOffice.toString()
      ) {
        options.push({
          value: value,
          text: SpaceCategoryList.Resources[firstLetterToLowerCase(key)],
        });
      }
    });
    return options;
  }

  @bind
  getAllWeekDays() {
    var options = [];
    let keys = Object.keys(DaysOfWeek).filter(
      (k) => typeof DaysOfWeek[k as any] === "number"
    );

    keys.forEach((key) => {
      let value = DaysOfWeek[key as any].toString();
      options.push({
        value: value,
        text: WeekDays.Resources[firstLetterToLowerCase(key)],
      });
    });
    return options;
  }

  render() {
    return (
      <div>
        <Helmet title={AddOrEditTeam.Resources.team} />

        <div className="details-page-top">
          <div className="page-navigation">
            <span
              className="material-symbols-outlined"
              onClick={() => this.props.navigate(-1)}
            >
              arrow_back
            </span>
            <div className="page-title">
              {this.state.isEditMode
                ? AddOrEditTeam.Resources.editTeam
                : AddOrEditTeam.Resources.addTeam}
            </div>
          </div>
        </div>

        {!this.props.countries || !this.props.cities || this.props.loading ? (
          <div>
            <Loading />
          </div>
        ) : (
          <div>
            <AddOrEditTeamForm
              spaceCategories={this.getAllSpaceCategories()}
              weekDays={this.getAllWeekDays()}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: ApplicationState) => {
      return {
        countries: state.lookup.allCountries,
        cities: state.lookup.cities,
        loading: state.addOrEditTeam.loading,
      };
    },
    {
      getCountries: LookupStore.actionCreators.getCountries,
      getCities: LookupStore.actionCreators.getCities,
      initializeCreatePage:
        AddOrEditTeamStore.actionCreators.initializeCreatePage,
      initializeEditPage: AddOrEditTeamStore.actionCreators.initializeEditPage,
    }
  )(AddOrEditTeamPage as any)
);
