import { useState, useEffect, useCallback, useMemo } from "react";
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { UserStates } from "../../enums/Users/UserStates";
import General from "../../resources/AdminUI/General";
import UserDetails from "../../resources/AdminUI/Users/UserDetails";
import IPageIndex from "../../models/Pagination/IPageIndex";
import { IRoomBookingsItem } from "../../models/RoomBookings/Interfaces/IRoomBookingsItem";
import { IEntitiesList } from "../../models/Common/IEntitiesList";
import { useRoomBookingsQueries } from "../RoomBookings/useRoomBookingsQueries";
import { IUserDetails } from "../../models/Users/Interfaces/IUserDetails";
import { useRoomBookingsHelpers } from "../RoomBookings/useRoomBookingsHelpers";

const { confirm } = Modal;

interface UseUserDetailsPageProps {
  params: URLSearchParams;
  user: IUserDetails;
  loading: boolean;
  initializeBenefits: () => void;
  getUserDetails: (userId: number) => void;
  activateUser: (userId: number) => void;
  deactivateUser: (userId: number) => void;
  deleteUser: (userId: number) => void;
}

export const useUserDetailsPage = ({
  params,
  user,
  loading,
  initializeBenefits,
  getUserDetails,
  activateUser,
  deactivateUser,
  deleteUser,
}: UseUserDetailsPageProps) => {
  const itemsPerPage = 15;
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [firstIndexFromPage, setFirstIndexFromPage] = useState(1);
  const [lastIndexFromPage, setLastIndexFromPage] = useState(1);
  const [pageIndexArray, setPageIndexArray] = useState<IPageIndex[]>([]);
  const [filters, setFilters] = useState("");
  const [numberOfRoomBookings, setNumberOfRoomBookings] = useState(0);

  const { recalculatePageArray, recalculateIndexes, rebuildODataFilters } =
    useRoomBookingsHelpers(itemsPerPage, numberOfRoomBookings, currentPage);

  const { getRoomBookings } = useRoomBookingsQueries();
  const queryClient = useQueryClient();

  const skip = useMemo(
    () => (currentPage - 1) * itemsPerPage,
    [currentPage, itemsPerPage]
  );

  const {
    data,
    isLoading,
    error,
  }: UseQueryResult<IEntitiesList<IRoomBookingsItem>, Error> = getRoomBookings(
    itemsPerPage,
    skip,
    filters
  );

  useEffect(() => {
    if (params.has("id")) {
      const userId = parseInt(params.get("id")!, 10);
      initializeBenefits();
      getUserDetails(userId);
    }
  }, [params, initializeBenefits, getUserDetails]);

  const showEditUserModal = useCallback(() => setIsEditUserModalOpen(true), []);
  const hideEditUserModal = useCallback(
    () => setIsEditUserModalOpen(false),
    []
  );

  useEffect(() => {
    setCurrentPage(currentPage);
  }, []);

  useEffect(() => {
    if (data) {
      setNumberOfRoomBookings(data.numberOfEntities);
    } else {
      setNumberOfRoomBookings(0);
    }
  }, [data]);

  useEffect(() => {
    const pageArray = recalculatePageArray();
    setPageIndexArray(pageArray);
    const { firstIndex, lastIndex } = recalculateIndexes();
    setFirstIndexFromPage(firstIndex);
    setLastIndexFromPage(lastIndex);
  }, [numberOfRoomBookings, currentPage]);

  const changeCurrentPage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const rebuildODataFiltersWithCustomLogic = (
    toTake: number,
    toSkip: number
  ): string => {
    const filters: any = {};

    if (user && user?.id) {
      filters.UserId = { eq: user?.id };
    }

    return rebuildODataFilters(toTake, toSkip, filters);
  };

  const oDataFilter = useMemo(
    () => rebuildODataFiltersWithCustomLogic(itemsPerPage, skip),
    [itemsPerPage, skip, user?.id]
  );

  useEffect(() => {
    setFilters(oDataFilter);
  }, [oDataFilter]);

  const reloadRoomBookingsData = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ["roomBookings"] });
  }, [queryClient]);

  const showDeactivateUserModal = useCallback(() => {
    confirm({
      title: General.Resources.areYouSureModalLabel,
      icon: <ExclamationCircleOutlined />,
      content: <p>{UserDetails.Resources.deactivateUserDescLabel}</p>,
      okText: General.Resources.deactivateLabel,
      okType: "danger",
      cancelText: General.Resources.cancelButtonLabel,
      onOk: () => deactivateUser(user?.id),
    });
  }, [deactivateUser, user]);

  const showDeleteUserModal = useCallback(() => {
    confirm({
      title: General.Resources.areYouSureModalLabel,
      icon: <ExclamationCircleOutlined />,
      content: <p>{UserDetails.Resources.deleteUserDescLabel}</p>,
      okText: General.Resources.deleteLabel,
      okType: "danger",
      cancelText: General.Resources.cancelButtonLabel,
      onOk: () => deleteUser(user?.id),
    });
  }, [deleteUser, user]);

  const handleOnActivateUser = useCallback(
    () => activateUser(user?.id),
    [activateUser, user]
  );

  const getAvailableActions = useCallback(() => {
    const moreActionsButtonItems = [];

    if (user?.status === UserStates.Active) {
      moreActionsButtonItems.push({
        icon: "toggle_off",
        text: UserDetails.Resources.deactivateUserOptionLabel,
        action: showDeactivateUserModal,
      });
    }

    if (user?.status === UserStates.Deactivated) {
      moreActionsButtonItems.push({
        icon: "toggle_on",
        text: UserDetails.Resources.activateUserOptionLabel,
        action: handleOnActivateUser,
      });
    }

    moreActionsButtonItems.push({
      icon: "delete",
      text: UserDetails.Resources.deleteUserOptionLabel,
      action: showDeleteUserModal,
    });

    return moreActionsButtonItems;
  }, [
    user,
    showDeactivateUserModal,
    handleOnActivateUser,
    showDeleteUserModal,
  ]);

  return {
    isEditUserModalOpen,
    loading: loading ?? isLoading,
    numberOfRoomBookings,
    itemsPerPage,
    currentPage,
    firstIndexFromPage,
    lastIndexFromPage,
    pageIndexArray,
    filters,
    skip,
    changeCurrentPage,
    reloadRoomBookingsData,
    showEditUserModal,
    hideEditUserModal,
    getAvailableActions,
  };
};
