import React, { FC, useEffect, useMemo } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ApplicationState } from "../../store";
import { String_Format, firstLetterToLowerCase } from "../../utils/utils";
import SpaceDetailsTabsComponent from "../../components/Spaces/SpaceDetailsTabs/SpaceDetailsTabsComponent";
import { SpaceDetailsStore } from "../../store/SpaceDetailsStore";
import Workspaces from "../../resources/AdminUI/Spaces/Workspaces";
import { RoomCardsSlider } from "../../components/Spaces/SpaceDetailsTabs/RoomCardsSlider";
import Rooms from "../../resources/AdminUI/Rooms/Rooms";
import SpaceDetailsBookingsTablesTabComponent from "../../components/Spaces/SpaceDetailsTabs/SpaceDetailsBookingsTablesTabComponent";
import { SpaceStatuses } from "../../enums/Spaces/SpaceStatuses";
import SpaceStatusesList from "../../resources/AdminUI/Spaces/SpaceStatusesList";
import { AppSettings } from "../../utils/settings";
import DropdownButton from "../../components/Shared/DropdownButton";
import { AddOrEditRoomStore } from "../../store/AddOrEditRoomStore";
import { withRouter } from "../../components/Shared/withRouter";
import { formatDate } from "../../utils/date";
import Loading from "../../components/Shared/Loading";
import { useSpaceDetails } from "../../hooks/Spaces/useSpaceDetails";
import {
  getItemsForChangeStatusDropdownButton,
  goToEditSpace,
  getCategoryName,
  goToAddRoom,
} from "../../utils/helpers/spaceDetails";
import { useRoomBookingsQueries } from "../../hooks/RoomBookings/useRoomBookingsQueries";
import { getRoomBookingsColumns } from "../../components/RoomBookings/RoomBookingsTableColumns";

interface IProps {
  params: URLSearchParams;
  loading?: boolean;
  details?: any;
  getData?: (id: number) => void;
  changeStatus?: (workspaceId: number, statusId: number) => any;
}

const SpaceDetailsPage: FC<IProps> = (props) => {
  const tableFilterTemplate = `WorkspaceId eq {0}`;
  const navigate = useNavigate();
  const { params, loading, details, getData, changeStatus } = props;

  const {
    loading: loadingRoomBookings,
    numberOfRoomBookings,
    itemsPerPage,
    firstIndexFromPage,
    lastIndexFromPage,
    pageIndexArray,
    filters,
    skip,
    changeCurrentPage,
    reloadRoomBookingsData,
    showDraftModal,
    showUnavailableModal,
    showActiveModal,
    showDeactivatedModal,
  } = useSpaceDetails(details.id, changeStatus);
  const { getRoomBookings } = useRoomBookingsQueries();
  const { data, isLoading } = getRoomBookings(itemsPerPage, skip, filters);

  useEffect(() => {
    if (params.has("id")) {
      const spaceId = parseInt(params.get("id")!);
      getData(spaceId);
    }
  }, []);

  const roomBookingsTableProps = useMemo(
    () => ({
      roomBookingsColumns: getRoomBookingsColumns(false),
      itemsPerPage,
      data,
      isLoading,
      pagination: {
        totalItems: numberOfRoomBookings,
        firstIndexFromPage,
        lastIndexFromPage,
        pageIndexArray,
        maxPagesDisplayed: 3,
        pageNeighbours: 1,
        reload: reloadRoomBookingsData,
        changeCurrentPage,
      },
    }),
    [
      itemsPerPage,
      data,
      isLoading,
      numberOfRoomBookings,
      firstIndexFromPage,
      lastIndexFromPage,
      pageIndexArray,
      reloadRoomBookingsData,
      changeCurrentPage,
    ]
  );

  if (loading || loadingRoomBookings) {
    return <Loading />;
  }

  return (
    <div>
      <Helmet title={Workspaces.Resources.spaceDetails} />

      <div className="details-page-top">
        <div className="page-navigation">
          <span
            className="material-symbols-outlined"
            onClick={() => navigate(-1)}
          >
            arrow_back
          </span>
          <div className="page-title">{Workspaces.Resources.spaceDetails}</div>
        </div>

        <div className="page-actions">
          <DropdownButton
            text={Workspaces.Resources.changeStatus}
            items={getItemsForChangeStatusDropdownButton(
              details.statusId,
              showDraftModal,
              showUnavailableModal,
              showActiveModal,
              showDeactivatedModal
            )}
            icon="arrow_drop_down"
            className="btn-big btn-secondary"
          />

          <button
            className="btn-big btn-primary"
            onClick={() => goToEditSpace(details, navigate)}
          >
            <span className="material-symbols-outlined">edit</span>
            <p className="btn-primary-text">
              {Workspaces.Resources.editSpaceInfo}
            </p>
          </button>
        </div>
      </div>

      <div className="two-columns-container">
        <section className="container">
          <div className="item-general-info">
            <div className="type">{getCategoryName(details.categoryId)}</div>
            <h2 className="item-name">{details.name}</h2>
            <div className="db_info">
              <p>
                <span
                  className={`status ${firstLetterToLowerCase(
                    SpaceStatuses[details.statusId]
                  )}`}
                >
                  {
                    SpaceStatusesList.Resources[
                      firstLetterToLowerCase(SpaceStatuses[details.statusId])
                    ]
                  }{" "}
                </span>
              </p>
              <div className="id_created_on">
                {" "}
                | {String_Format(
                  Workspaces.Resources.idLabel,
                  details?.id
                )} |{" "}
                {String_Format(
                  Workspaces.Resources.createdOn,
                  formatDate(details.createdAt)
                )}
              </div>
            </div>

            <div className="header">{Workspaces.Resources.spaceAddress}</div>
            <div className="country">
              {details.countryFlag} {details.countryName}, {details.cityName}
            </div>
            <div className="address">{details.address}</div>
            <a
              className="btn-text"
              target="_blank"
              href={String_Format(
                AppSettings.CommonSettings.GoogleMapsSearchUrl,
                details.lat,
                details.lng
              )}
            >
              {Workspaces.Resources.seeSpaceInGoogleMaps}
            </a>
            <div className="header">{Workspaces.Resources.desksBooking}</div>
            {details.maxSeats == 0 ? (
              <div>{Workspaces.Resources.spaceDoesNotOfferHotDesks}</div>
            ) : !details.maxSeats ? (
              <div>{Workspaces.Resources.spaceDoesNotHaveLimitForHotdesks}</div>
            ) : (
              <div>
                {details.maxSeats
                  ? String_Format(
                      Workspaces.Resources
                        .spacesRequiresBookingLimitDesksPerDay,
                      details.maxSeats
                    )
                  : null}
              </div>
            )}
          </div>
        </section>

        <section className="container">
          <div className="item-more-info-container">
            <SpaceDetailsTabsComponent
              componentId={`space-details-${details?.id}`}
              images={details.images}
              vat={details.vat}
              fullDayPrice={details.fullDayPrice}
              halfDayPrice={details.halfDayPrice}
              unredeemedPrice={details.unredeemedPrice}
              currencyLabel={details.currency}
              descriptionEn={details.descriptionEn}
              descriptionEs={details.descriptionEs}
              descriptionPt={details.descriptionPt}
              amenities={details.amenities}
              schedule={details.schedule}
              email={details.email}
              phone={details.phone}
              businessEmail={details.businessEmail}
              businessPhone={details.businessPhone}
              wifiName={details.wifiName}
              wifiPassword={details.wifiPassword}
              accessInstructionsEn={details.accessInstructionsEn}
              accessInstructionsEs={details.accessInstructionsEs}
              accessInstructionsPt={details.accessInstructionsPt}
            />
          </div>
        </section>
      </div>

      <div className="container">
        <div className="rooms-sections">
          <div className="section-top">
            <div className="section-title">
              {Workspaces.Resources.typesOfRooms}
            </div>
            <div className="page-actions">
              <button
                className="btn-big btn-primary"
                onClick={() => goToAddRoom(details?.id, navigate)}
              >
                <span className="material-symbols-outlined">add_circle</span>
                <p className="btn-primary-text">{Rooms.Resources.addRoom}</p>
              </button>
            </div>
          </div>
        </div>

        {!details.rooms || details.rooms.length == 0 ? (
          <div>
            {Workspaces.Resources.spaceHasNoMeetingRoomsOrPrivateOfficesYet}
          </div>
        ) : (
          <RoomCardsSlider slides={details.rooms} currency={details.currency} />
        )}
      </div>

      <div className="container">
        <SpaceDetailsBookingsTablesTabComponent
          componentId={`space-bookings-${details.id}`}
          spaceId={details.id!}
          hotdeskBookingsTableFilters={String_Format(
            tableFilterTemplate,
            details.id
          )}
          hotdeskBookingsTableHiddenColumns={[
            "userEmail",
            "userPhone",
            "statusName",
            "userFirstName",
            "userLastName",
            "userCompanyName",
            "workspaceName",
            "workspaceCategoryId",
            "workspaceCityId",
            "workspaceCityName",
            "workspaceCountryId",
            "workspaceCountryName",
            "workspaceCountryFlag",
            "timeSpent",
            "isFullDay",
            "createdAt",
            "checkinAt",
            "checkoutAt",
            "expiresAt",
            "cancelledAt",
            "bookingAt",
            "timezone",
            "isWalkin",
            "isAutomatedCheckout",
            "unixBookingAtWithTimezone",
          ]}
          hotdeskBookingsTableExpandableColumns={["createdAt"]}
          showActionsColumn={true}
          roomBookingsTableProps={roomBookingsTableProps}
        />
      </div>
    </div>
  );
};

export default withRouter(
  connect(
    (state: ApplicationState) => {
      return {
        details: state.spaceDetails.details,
        loading: state.spaceDetails.loading,
      };
    },
    {
      getData: SpaceDetailsStore.actionCreators.getData,
      changeStatus: SpaceDetailsStore.actionCreators.changeStatus,
      setWorkspaceSchedule:
        AddOrEditRoomStore.actionCreators.setWorkspaceSchedule,
    }
  )(SpaceDetailsPage)
);
