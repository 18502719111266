import Globals from "./../../../Globals";

export default class AddOrEditRoomBooking {
       public areYouSureYouWantToDeleteThisRoomLog: string = `Are you sure you want to delete this room log? By continuing the log will be deleted from the database and user’s history list from his/her account.`;
       public bookingEndsAt: string = `Booking ends at`;
       public bookingInterval: string = `Booking interval`;
       public bookingStartsAt: string = `Booking starts at`;
       public bookingType: string = `Booking Type`;
       public cancelledDateAndTime: string = `Cancelled date & time`;
       public confirmedDateAndTime: string = `Confirmed date & time`;
       public createNewRoomLog: string = `Create new room logs`;
       public deleteRoomLog: string = `Delete Room Log`;
       public editRoomLog: string = `Edit Room Log | ID: #{0}`;
       public roomId: string = `Room ID`;
       public sameStartsAtAndEndsAtDate: string = `Starts at date and ends at date must be the same`;
       public status: string = `Status`;
       public userId: string = `User ID`;
       public youMustChooseBasedOnSpaceTimezone: string = `You must choose the date & hour based on the working space's timezone`;

		public static get Resources(): AddOrEditRoomBooking{
			switch (Globals.currentActiveLanguage) {
				default:
				return new AddOrEditRoomBooking();
		}
   	}
}
