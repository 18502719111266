import Globals from "./../../../Globals";

export default class AddOrEditBenefit {
       public addService: string = `Add service`;
       public editService: string = `Edit service`;
       public serviceUsed: string = `Services used`;

		public static get Resources(): AddOrEditBenefit{
			switch (Globals.currentActiveLanguage) {
				default:
				return new AddOrEditBenefit();
		}
   	}
}
