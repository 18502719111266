// useRoomBookingsQueries.ts
import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryResult,
  UseMutationResult,
} from "@tanstack/react-query";

import { IEntitiesList, EntitiesList } from "../../models/Common/IEntitiesList";
import { IRoomBookingsItem } from "../../models/RoomBookings/Interfaces/IRoomBookingsItem";
import { RoomBookingsItem } from "../../models/RoomBookings/RoomBookingsItem";
import { AppSettings } from "../../utils/settings";
import { useServiceBase } from "../../services/useServiceBase";
import { IAntdSelectListItem } from "../../models/Common/IAntdSelectListItem";

const API_URL = AppSettings.CommonSettings.ApiUrl;

export const additionalMapping = (
  entitiesList: any
): EntitiesList<IRoomBookingsItem> => {
  let newEntities = new EntitiesList<IRoomBookingsItem>();
  newEntities.entities = entitiesList.entities.map(
    (e) => new RoomBookingsItem(e)
  );
  newEntities.numberOfEntities = entitiesList.numberOfEntities;
  return newEntities;
};

export const useRoomBookingsQueries = () => {
  const { authenticatedFetch, isLoading: isFetching } = useServiceBase();
  const queryClient = useQueryClient();

  const getRoomBookings = (
    toTake: number,
    toSkip: number,
    filters: any
  ): UseQueryResult<EntitiesList<IRoomBookingsItem>, Error> => {
    return useQuery({
      queryKey: ["roomBookings", toTake, toSkip, filters],
      queryFn: async () => {
        let url = `${API_URL}/odata/room-bookings/`;
        if (filters) {
          url = `${url}${filters}`;
        }
        const result = await authenticatedFetch<
          IEntitiesList<IRoomBookingsItem>
        >(url);
        return additionalMapping(result.value);
      },
    });
  };

  const getRoomBookingDetails = (id: number): UseQueryResult<any, Error> => {
    return useQuery({
      queryKey: ["roomBookingDetails", id],
      queryFn: () =>
        authenticatedFetch<any>(`${API_URL}/room-bookings/${id}/form-details`),
    });
  };

  const addRoomBooking = (): UseMutationResult<any, Error, any> => {
    return useMutation({
      mutationFn: (roomBookingDetails: any) =>
        authenticatedFetch(`${API_URL}/room-bookings`, {
          method: "POST",
          body: JSON.stringify(roomBookingDetails),
          headers: { "Content-Type": "application/json" },
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["roomBookings"] });
      },
    });
  };

  const updateRoomBooking = (): UseMutationResult<
    any,
    Error,
    { id: number; roomBookingDetails: any }
  > => {
    return useMutation({
      mutationFn: ({ id, roomBookingDetails }) =>
        authenticatedFetch(`${API_URL}/room-bookings/${id}`, {
          method: "PUT",
          body: JSON.stringify(roomBookingDetails),
          headers: { "Content-Type": "application/json" },
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["roomBookings"] });
      },
    });
  };

  const deleteRoomBooking = (): UseMutationResult<any, Error, number> => {
    return useMutation({
      mutationFn: (id: number) =>
        authenticatedFetch(`${API_URL}/room-bookings/${id}`, {
          method: "DELETE",
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["roomBookings"] });
      },
    });
  };

  const acceptRoomBooking = (): UseMutationResult<any, Error, number> => {
    return useMutation({
      mutationFn: (id: number) =>
        authenticatedFetch(`${API_URL}/room-bookings/${id}/accept`, {
          method: "PUT",
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["roomBookings"] });
      },
    });
  };

  const rejectRoomBooking = (): UseMutationResult<
    any,
    Error,
    { id: number; reasonId: string }
  > => {
    return useMutation({
      mutationFn: ({ id, reasonId }) =>
        authenticatedFetch(
          `${API_URL}/room-bookings/${id}/reject/${reasonId}`,
          {
            method: "PUT",
          }
        ),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["roomBookings"] });
      },
    });
  };

  const getRoomBookingsForExport = (
    filters?: string
  ): UseQueryResult<any, Error> => {
    return useQuery({
      queryKey: ["roomBookingsExport", filters],
      queryFn: async () => {
        let url = `${API_URL}/odata/room-bookings`;
        if (filters && filters.length > 0) {
          url = `${url}${filters}`;
        }
        const result = await authenticatedFetch<
          IEntitiesList<IRoomBookingsItem>
        >(url);
        return result;
      },
    });
  };

  const getNumberOfPendingRoomRequests = (): UseQueryResult<number, Error> => {
    return useQuery({
      queryKey: ["pendingRoomRequests"],
      queryFn: () =>
        authenticatedFetch<number>(`${API_URL}/room-bookings/pending/count`),
    });
  };

  // Hook for fetching all countries with cities
  const useAllCountriesWithCities = (): UseQueryResult<
    IAntdSelectListItem[],
    Error
  > => {
    return useQuery({
      queryKey: ["countriesWithCities"],
      queryFn: async () => {
        const result = await authenticatedFetch(
          `${AppSettings.CommonSettings.ApiUrl}/lookup/countries-with-cities`
        );
        return result?.value;
      },
    });
  };

  // Hook for fetching all cities
  const useAllCities = (): UseQueryResult<IAntdSelectListItem[], Error> => {
    return useQuery({
      queryKey: ["cities"],
      queryFn: async () => {
        const result = await authenticatedFetch<any>(
          `${AppSettings.CommonSettings.ApiUrl}/lookup/cities`
        );

        return result?.value;
      },
    });
  };

  return {
    getRoomBookings,
    getRoomBookingDetails,
    addRoomBooking,
    updateRoomBooking,
    deleteRoomBooking,
    acceptRoomBooking,
    rejectRoomBooking,
    getRoomBookingsForExport,
    getNumberOfPendingRoomRequests,
    useAllCountriesWithCities,
    useAllCities,
    isFetching,
  };
};
