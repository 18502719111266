import bind from "bind-decorator";
import Validations from "../../../resources/Common/Validations";
import { String_Format, isEmail, nameof } from "../../../utils/utils";
import { BaseValidator } from "../Base/BaseValidator";
import UserDetails from "../../../resources/AdminUI/Users/UserDetails";

export class EditUserValidator extends BaseValidator {

    constructor() {
        super();
    }

    @bind
    validate(model: any, props: any) {

        this.check(() => model.email != '' && model.email != null, nameof(e => e.email), String_Format(Validations.Resources.required, UserDetails.Resources.emailLabel))
        this.check(() => !model.email || isEmail(model.email) , nameof(e => e.email), Validations.Resources.emailNotValid);
        // this.check(() => !model.email || model.email.length <= 50, nameof(e => e.email), String_Format(Validations.Resources.maxLength, 50));        
        
        return this.getErrorsDictionary();
    }
}