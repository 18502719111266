import React from "react";

import { ApplicationPaths } from "../enums/Common/ApplicationPaths";
import { AccountsAndLogsPage } from "../pages/AccountsAndLogs/AccountsAndLogsPage";
import AddOrEditBenefitPage from "../pages/Benefits/AddOrEditBenefitPage";
import AddOrEditClientPage from "../pages/Clients/AddOrEditClientPage";
import ClientsDetailsPage from "../pages/Clients/ClientsDetailsPage";
import ClientsGridPage from "../pages/Clients/ClientsGridPage";
import CountriesAndCitiesPage from "../pages/Countries/CountriesAndCitiesPage";
import AddOrEditHotdeskBookingPage from "../pages/HotdeskBookings/AddOrEditHotdeskBookingPage";
import HotdeskBookingsGridPage from "../pages/HotdeskBookings/HotdeskBookingsGridPage";
import ManageAccountsPage from "../pages/ManageAccounts/ManageAccountsPage";
import AddOrEditRoomBookingPage from "../pages/RoomBookings/AddOrEditRoomBookingPage";
import PendingRoomRequestsPage from "../pages/RoomBookings/PendingRoomRequestsPage";
import RoomBookingsGridPage from "../pages/RoomBookings/RoomBookingsGridPage";
import AddOrEditRoomPage from "../pages/Rooms/AddOrEditRoomPage";
import AddOrEditOfficePage from "../pages/Spaces/AddOrEditOfficePage";
import AddOrEditSpacePage from "../pages/Spaces/AddOrEditSpacePage";
import EditSpacePricePage from "../pages/Spaces/EditSpacePricePage";
import SpaceDetailsPage from "../pages/Spaces/SpaceDetailsPage";
import SpaceGridPage from "../pages/Spaces/SpaceGridPage";
import AddOrEditTeamPage from "../pages/Teams/AddOrEditTeamPage";
import UsersGridPage from "../pages/Users/UsersGridPage";
import UserDetails from "../pages/Users/UserDetailsPage";

export const authorizedRoutes = [
  {
    path: ApplicationPaths.ViewSpaceDetails,
    component: <SpaceDetailsPage />,
  },
  {
    path: ApplicationPaths.AddSpace,
    component: <AddOrEditSpacePage />,
  },
  {
    path: ApplicationPaths.EditSpace,
    component: <AddOrEditSpacePage />,
  },
  {
    path: ApplicationPaths.AddRoom,
    component: <AddOrEditRoomPage />,
  },
  {
    path: ApplicationPaths.EditRoom,
    component: <AddOrEditRoomPage />,
  },
  {
    path: ApplicationPaths.Spaces,
    component: <SpaceGridPage />,
  },
  {
    path: ApplicationPaths.ViewUserDetails,
    component: <UserDetails />,
  },
  {
    path: ApplicationPaths.Users,
    component: <UsersGridPage />,
  },
  {
    path: ApplicationPaths.ViewCustomerDetails,
    component: <ClientsDetailsPage />,
  },
  {
    path: ApplicationPaths.AddCustomer,
    component: <AddOrEditClientPage />,
  },
  {
    path: ApplicationPaths.EditCustomer,
    component: <AddOrEditClientPage />,
  },
  {
    path: ApplicationPaths.AddTeam,
    component: <AddOrEditTeamPage />,
  },
  {
    path: ApplicationPaths.EditTeam,
    component: <AddOrEditTeamPage />,
  },
  {
    path: ApplicationPaths.AddBenefit,
    component: <AddOrEditBenefitPage />,
  },
  {
    path: ApplicationPaths.EditBenefit,
    component: <AddOrEditBenefitPage />,
  },
  {
    path: ApplicationPaths.AddOffice,
    component: <AddOrEditOfficePage />,
  },
  {
    path: ApplicationPaths.EditOffice,
    component: <AddOrEditOfficePage />,
  },
  {
    path: ApplicationPaths.Customers,
    component: <ClientsGridPage />,
  },
  {
    path: ApplicationPaths.ManageAccounts,
    component: <ManageAccountsPage />,
  },
  {
    path: ApplicationPaths.AddHotdeskBooking,
    component: <AddOrEditHotdeskBookingPage />,
  },
  {
    path: ApplicationPaths.EditHotdeskBooking,
    component: <AddOrEditHotdeskBookingPage />,
  },
  {
    path: ApplicationPaths.DesksLogs,
    component: <HotdeskBookingsGridPage />,
  },
  {
    path: ApplicationPaths.AddRoomBooking,
    component: <AddOrEditRoomBookingPage />,
  },
  {
    path: ApplicationPaths.EditRoomBooking,
    component: <AddOrEditRoomBookingPage />,
  },
  {
    path: ApplicationPaths.RoomsLogs,
    component: <RoomBookingsGridPage />,
  },
  {
    path: ApplicationPaths.PendingRoomRequests,
    component: <PendingRoomRequestsPage />,
  },
  {
    path: ApplicationPaths.CountriesAndCities,
    component: <CountriesAndCitiesPage />,
  },
  {
    path: ApplicationPaths.AccountsAndLogs,
    component: <AccountsAndLogsPage />,
  },
  {
    path: ApplicationPaths.EditSpacePrice,
    component: <EditSpacePricePage />,
  },
];
