import Globals from "./../../../Globals";

export default class AddOrEditTeam {
       public addTeam: string = `Add Team`;
       public addUserText: string = `Add user`;
       public allow: string = `Allow`;
       public allowCity: string = `Allow City: {0}`;
       public allowCountry: string = `Allow Country: {0}`;
       public allowSpace: string = `Allow Space: {0}`;
       public allowType: string = `Allow Type: {0}`;
       public allowWeekDay: string = `Allow Week Day: {0}`;
       public assignedServices: string = `Assigned Services`;
       public chooseInWhichCitiesEmployeesCanMakeBookingsInPluriaApp: string = `Choose in which cities this customers’s employees can make bookings in Pluria app`;
       public chooseInWhichCountriesEmployeeCanMakeBookings: string = `Choose in which countries this customer’s employees can make bookings in Pluria app`;
       public chooseInWhichDaysEmployeesCanMakeBookingsInPluriaApp: string = `Choose in which days this customer’s employees can make bookings in Pluria app`;
       public chooseInWhichSpacesEmployeesCanMakeBookingsInPluriaApp: string = `Choose in which spaces this customer’s employees can make bookings in Pluria app`;
       public chooseTypesOfSpacesEmployeesCanSeeInPluriaApp: string = `Choose the types of spaces this customer’s employees can see in Pluria app`;
       public deny: string = `Deny`;
       public denyCity: string = `Deny City: {0}`;
       public denyCountry: string = `Deny Country: {0}`;
       public denySpace: string = `Deny Space: {0}`;
       public denyType: string = `Deny Type: {0}`;
       public denyWeekDay: string = `Deny Week Day: {0}`;
       public description: string = `Description`;
       public details: string = `Details`;
       public editTeam: string = `Edit Team`;
       public editThisTeam: string = `Edit "{0}" team`;
       public excludeWeekDays: string = `Exclude week days`;
       public generalInformation: string = `General Information`;
       public maxNrOfDailyChecinsRestriction: string = `Max nr. of daily check-ins: {0}`;
       public maxNrOfDailyCheckins: string = `Max nr. of daily check-ins`;
       public name: string = `Name`;
       public nameAndId: string = `{0} - ID: #{1}`;
       public none: string = `None`;
       public noServicesAssigned: string = `No services assigned`;
       public permissionsAndRestrictions: string = `Permissions / Restrictions`;
       public restrictions: string = `Restrictions`;
       public restrictionsForBookingInCities: string = `Restrictions for booking in cities`;
       public restrictionsForBookingInCountries: string = `Restrictions for booking in countries`;
       public restrictionsForBookingInSpaces: string = `Restrictions for booking in spaces`;
       public seeTypeOfSpace: string = `See type of space`;
       public team: string = `Team`;
       public teamDescription: string = `Team Description`;
       public teamTitle: string = `Team Title`;

		public static get Resources(): AddOrEditTeam{
			switch (Globals.currentActiveLanguage) {
				default:
				return new AddOrEditTeam();
		}
   	}
}
