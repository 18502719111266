import bind from "bind-decorator";
import Validations from "../../../resources/Common/Validations";
import { String_Format, nameof } from "../../../utils/utils";
import { BaseValidator } from "../Base/BaseValidator";
import CountriesAndCities from "../../../resources/AdminUI/CountriesAndCities";

export class AddCityValidator extends BaseValidator {

    constructor() {
        super();
    }

    @bind
    validate(model: any, props: any) {

        this.check(() => model.city.name != '' && model.city.name != null, nameof(e => e.name), String_Format(Validations.Resources.required, CountriesAndCities.Resources.cityNameLabel));
        this.check(() => model.city.lat != '' && model.city.lat != null, nameof(e => e.lat), String_Format(Validations.Resources.required, CountriesAndCities.Resources.cityLatitudeLabel));
        this.check(() => !model.city.lat || (model.city.lat >= -90 && model.city.lat <= 90), nameof(e => e.lat), Validations.Resources.latitude);
        this.check(() => model.city.lng != '' && model.city.lng != null, nameof(e => e.lng), String_Format(Validations.Resources.required, CountriesAndCities.Resources.cityLongitudeLabel));
        this.check(() => !model.city.lng || (model.city.lng >= -180 && model.city.lng <= 180), nameof(e => e.lng), Validations.Resources.longitude);
        this.check(() => model.city.timezone != '' && model.city.timezone != null, nameof(e => e.timezone), String_Format(Validations.Resources.required, CountriesAndCities.Resources.cityTimeZone));
        
        return this.getErrorsDictionary();
    }
}