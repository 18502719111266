import bind from "bind-decorator";
import Validations from "../../../resources/Common/Validations";
import { String_Format, isEmail, nameof } from "../../../utils/utils";
import { BaseValidator } from "../Base/BaseValidator";
import AddOrEditClient from "../../../resources/AdminUI/Clients/AddOrEditClient";

export class AddOrEditClientFormValidator extends BaseValidator {
  constructor() {
    super();
  }

  @bind
  validate(model: any, props: any) {
    this.check(
      () => model.clientDetails.name != "" && model.clientDetails.name != null,
      nameof((e) => e.name),
      String_Format(
        Validations.Resources.required,
        AddOrEditClient.Resources.customerNameText
      )
    );
    // this.check(() => !model.clientDetails.name || model.clientDetails.name.length <= 50, nameof(e => e.name), String_Format(Validations.Resources.maxLength, 50));
    this.check(
      () =>
        model.clientDetails.countryId != "" &&
        model.clientDetails.countryId != null,
      nameof((e) => e.countryId),
      String_Format(
        Validations.Resources.required,
        AddOrEditClient.Resources.countryLabel
      )
    );

    if (!model.clientDetails.id) {
      this.check(
        () =>
          model.clientDetails.subscriptionName != "" &&
          model.clientDetails.subscriptionName != null,
        nameof((e) => e.subscriptionName),
        String_Format(
          Validations.Resources.required,
          AddOrEditClient.Resources.pricingPlanLabel
        )
      );
      // this.check(() => !model.clientDetails.subscriptionName || model.clientDetails.subscriptionName.length <= 50, nameof(e => e.subscriptionName), String_Format(Validations.Resources.maxLength, 50));
      this.check(
        () =>
          model.clientDetails.currency != "" &&
          model.clientDetails.currency != null,
        nameof((e) => e.currency),
        String_Format(
          Validations.Resources.required,
          AddOrEditClient.Resources.currencyLabel
        )
      );
      this.check(
        () =>
          model.clientDetails.subscriptionStartDate != "" &&
          model.clientDetails.subscriptionStartDate != null,
        nameof((e) => e.subscriptionStartDate),
        String_Format(
          Validations.Resources.required,
          AddOrEditClient.Resources.pricingPlanStartDateLabel
        )
      );
      this.check(
        () =>
          model.clientDetails.paymentRecurrenceUnit != "" &&
          model.clientDetails.paymentRecurrenceUnit != null,
        nameof((e) => e.paymentRecurrenceUnit),
        String_Format(
          Validations.Resources.required,
          AddOrEditClient.Resources.paymentRenewalTypeText
        )
      );
      this.check(
        () =>
          model.clientDetails.paymentRecurrenceValue != "" &&
          model.clientDetails.paymentRecurrenceValue != null,
        nameof((e) => e.paymentRecurrenceValue),
        String_Format(
          Validations.Resources.required,
          AddOrEditClient.Resources.paymentRenewalValueText
        )
      );
      this.check(
        () =>
          model.clientDetails.subscriptionMetadata.platformAccessFee != null &&
          model.clientDetails.subscriptionMetadata.platformAccessFee !== "",
        nameof((e) => e.platformAccessFee),
        String_Format(
          Validations.Resources.required,
          AddOrEditClient.Resources.pricingPlanAccessFee
        )
      );
      this.check(
        () =>
          model.clientDetails.subscriptionMetadata.prepaid != null &&
          model.clientDetails.subscriptionMetadata.prepaid !== "",
        nameof((e) => e.prepaid),
        String_Format(
          Validations.Resources.required,
          AddOrEditClient.Resources.pricingPlanPrepaid
        )
      );
      this.check(
        () =>
          model.clientDetails.subscriptionMetadata.minimumSpend != null &&
          model.clientDetails.subscriptionMetadata.minimumSpend !== "",
        nameof((e) => e.minimumSpend),
        String_Format(
          Validations.Resources.required,
          AddOrEditClient.Resources.pricingPlanMininumSpend
        )
      );
      this.check(
        () =>
          model.clientDetails.subscriptionMetadata.vat != null &&
          model.clientDetails.subscriptionMetadata.vat !== "",
        nameof((e) => e.vat),
        String_Format(
          Validations.Resources.required,
          AddOrEditClient.Resources.pricingPlanVAT
        )
      );
    }

    this.check(
      () =>
        model.clientDetails.contactEmail != "" &&
        model.clientDetails.contactEmail != null,
      nameof((e) => e.contactEmail),
      String_Format(
        Validations.Resources.required,
        AddOrEditClient.Resources.emailAddressPlaceholder
      )
    );
    this.check(
      () =>
        !model.clientDetails.contactEmail ||
        isEmail(model.clientDetails.contactEmail),
      nameof((e) => e.contactEmail),
      Validations.Resources.emailNotValid
    );
    // this.check(() => !model.clientDetails.contactEmail || model.clientDetails.contactEmail.length <= 50, nameof(e => e.contactEmail), String_Format(Validations.Resources.maxLength, 50));
    this.check(
      () =>
        model.clientDetails.contactPhone != "" &&
        !model.clientDetails.contactPhone != null,
      nameof((e) => e.contactPhone),
      String_Format(
        Validations.Resources.required,
        AddOrEditClient.Resources.phoneNumberPlaceholder
      )
    );
    // this.check(() => !model.clientDetails.contactPhone || model.clientDetails.contactPhone.length <= 50, nameof(e => e.contactPhone), String_Format(Validations.Resources.maxLength, 50));

    this.check(
      () =>
        model.clientDetails.accountManagerEmail != "" &&
        model.clientDetails.accountManagerEmail != null,
      nameof((e) => e.accountManagerEmail),
      String_Format(
        Validations.Resources.required,
        AddOrEditClient.Resources.plurialAccountManagerLabel
      )
    );
    this.check(
      () =>
        !model.clientDetails.accountManagerEmail ||
        isEmail(model.clientDetails.accountManagerEmail),
      nameof((e) => e.accountManagerEmail),
      Validations.Resources.emailNotValid
    );
    // this.check(() => !model.clientDetails.accountManagerEmail || model.clientDetails.accountManagerEmail.length <= 50, nameof(e => e.accountManagerEmail), String_Format(Validations.Resources.maxLength, 50));

    this.check(
      () =>
        model.clientDetails.salesPersonEmail != "" &&
        model.clientDetails.salesPersonEmail != null,
      nameof((e) => e.salesPersonEmail),
      String_Format(
        Validations.Resources.required,
        AddOrEditClient.Resources.pluriaSalesPersonLabel
      )
    );
    this.check(
      () =>
        !model.clientDetails.salesPersonEmail ||
        isEmail(model.clientDetails.salesPersonEmail),
      nameof((e) => e.salesPersonEmail),
      Validations.Resources.emailNotValid
    );
    // this.check(() => !model.clientDetails.salesPersonEmail || model.clientDetails.salesPersonEmail.length <= 50, nameof(e => e.salesPersonEmail), String_Format(Validations.Resources.maxLength, 50));

    // this.check(() => !model.clientDetails.legalClientName || model.clientDetails.legalClientName.length <= 100, nameof(e => e.legalClientName), String_Format(Validations.Resources.maxLength, 100));
    // this.check(() => !model.clientDetails.noEmployees || model.clientDetails.noEmployees.toString().length <= 9, nameof(e => e.noEmployees), String_Format(Validations.Resources.maxLength, 9));
    // this.check(() => !model.clientDetails.details || model.clientDetails.details.length <= 1000, nameof(e => e.details), String_Format(Validations.Resources.maxLength, 1000));
    this.check(
      () =>
        model.clientDetails?.languageId != "" &&
        model.clientDetails?.languageId != null,
      nameof((e) => e.languageId),
      String_Format(
        Validations.Resources.required,
        Validations.Resources.emailLanguage
      )
    );
    return this.getErrorsDictionary();
  }
}
