import React, { useEffect } from "react";
import { connect } from "react-redux";

import { ApplicationState } from "../../../store";
import { BenefitHistoryItem } from "../../../models/Benefits/BenefitHistoryItem";
import General from "../../../resources/AdminUI/General";
import { BenefitHistoryStore } from "../../../store/BenefitHistoryStore";
import Loading from "../../Shared/Loading";

interface IProps {
  benefitHistory?: BenefitHistoryItem[];
  loading?: boolean;
  hiddenColumns: string[];
  benefitId: number;
  getBenefitHistory?: (benefitId: number) => void;
}

const BenefitHistoryTable: React.FC<IProps> = (props) => {
  const tableClass = "table";

  useEffect(() => {
    if (props.getBenefitHistory) {
      props.getBenefitHistory(props.benefitId);
    }
  }, [props.benefitId]);

  if (props.loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (!props.benefitHistory || props.benefitHistory.length === 0) {
    return <div>{General.Resources.noElements}</div>;
  }

  return (
    <div className="table-wrapper">
      <table className={tableClass}>
        <thead>
          <tr>
            {props.benefitHistory[0].TrueProperties.filter(
              (p) => props.hiddenColumns.indexOf(p) < 0
            ).map((p) => (
              <th key={p}>
                {props.benefitHistory[0].GetDisplayNameForProperty(p)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.benefitHistory.map((benefit) => (
            <tr key={benefit.sourceCycleIndex}>
              {benefit.TrueProperties.filter(
                (p) => props.hiddenColumns.indexOf(p) < 0
              ).map((p) => (
                <td key={p}>{benefit.GetDisplayValueForProperty(p)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default connect(
  (state: ApplicationState, ownProps: IProps) => ({
    loading: state.benefitHistory.loading,
    benefitHistory: state.benefitHistory.benefitHistory,
    ...ownProps,
  }),
  {
    getBenefitHistory: BenefitHistoryStore.actionCreators.getBenefitHistory,
  }
)(BenefitHistoryTable);
