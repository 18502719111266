import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import update from "immutability-helper";
import SpacesService from "../services/SpacesService";
import { getServerSideErrors } from "../utils/utils";

export namespace SpaceDetailsStore {

    export interface IState {
        details: {},
        loading: boolean,

        hasServerSideErrors: boolean,
        serverSideErrors: string,
    }

    export enum Actions {
        SpaceDetailsSetData = "SPACE_DETAILS_SET_DATA",
        SpaceDetailsToggleLoading = "SPACE_DETAILS_TOGGLE_LOADING",
        SetErrors = "SPACE_DETAILS_SET_ERRORS"
    }

    interface ISpaceDetailsSetData {
        type: Actions.SpaceDetailsSetData;
        details: any;
    }

    interface ISpaceDetailsToggleLoading {
        type: Actions.SpaceDetailsToggleLoading;
        loading: boolean;
    }

    interface ISetErrors {
        type: Actions.SetErrors,
        hasServerSideErrors: boolean;
        serverSideErrors: string;
    }

    type KnownAction = ISpaceDetailsToggleLoading |
        ISpaceDetailsSetData | ISetErrors;

    export const actionCreators = {

        getData: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.SpaceDetailsToggleLoading, loading: true });
            var spaceDetails = await SpacesService.getSpaceDetails(id).then(response => response.value);
            dispatch({ type: Actions.SpaceDetailsSetData, details: spaceDetails });
            dispatch({ type: Actions.SpaceDetailsToggleLoading, loading: false });
        },

        changeStatus: (workspaceId: number, statusId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            var serverResponse = await SpacesService.changeSpaceStatus(workspaceId, statusId).then(result => result);
            var errors = getServerSideErrors(serverResponse);
            var hasServerSideErrors = !errors ? false : true;

            if (hasServerSideErrors) {
                dispatch({ type: Actions.SetErrors, hasServerSideErrors: hasServerSideErrors, serverSideErrors: errors });
            } else {
                var spaceDetails = await SpacesService.getSpaceDetails(workspaceId).then(response => response.value);
                dispatch({ type: Actions.SpaceDetailsSetData, details: spaceDetails });
            }
        }
    }

    const initialState: IState = {
        details: {},
        loading: true,

        hasServerSideErrors: false,
        serverSideErrors: null
    }

    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case Actions.SpaceDetailsSetData:
                return update(currentState,
                    {
                        details: { $set: action.details }
                    });
            case Actions.SpaceDetailsToggleLoading:
                return update(currentState,
                    {
                        loading: { $set: action.loading }
                    });
            case Actions.SetErrors:
                return update(currentState, {
                    hasServerSideErrors: { $set: action.hasServerSideErrors },
                    serverSideErrors: { $set: action.serverSideErrors }
                });
            default:
                return currentState || initialState;
        }

    }


}