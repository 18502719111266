import Globals from "./../../../Globals";

export default class ChangeUserTeam {
       public changeTeamDesc: string = `By changing team, the user will not be able to use the current services anymore. New services from the new team will be available for use. `;
       public changeTeamHeader: string = `Change team for {0}`;
       public currentTeamText: string = `Current Team:`;
       public newTeamText: string = `New Team`;
       public selectNewTeam: string = `Select new team for user`;

		public static get Resources(): ChangeUserTeam{
			switch (Globals.currentActiveLanguage) {
				default:
				return new ChangeUserTeam();
		}
   	}
}
