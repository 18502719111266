import React from "react";
import bind from "bind-decorator";

interface IProps {
    text: string,
    name: string;
    placeholder: string,
    onChangeCallback: (name: string, data: any) => void,

}

interface IState {
    text: string,
}

export class TextareaFormTab extends React.Component<IProps, IState>{

    constructor(props) {
        super(props);
        this.state = {
            text: this.props.text
        }
    }

    @bind
    onHandleChange(event){
        this.setState({
            text: event.target.value
        });
        this.props.onChangeCallback(this.props.name, event.target.value);
    }

    render() {

        return <textarea
                className='form-control with-tabs'
                value={this.state.text}
                onChange={this.onHandleChange}
                placeholder={this.props.placeholder}
            ></textarea>
        
    }
}
