// RadioInput.tsx
import React from "react";

import Loader from "../Loader";
import { IInputProps } from "./FormInput";

const RadioInput: React.FC<IInputProps> = ({
  label,
  value,
  className,
  errors,
  isDataLoading,
  name,
  readOnly,
  options,
  refreshData,
  onChange,
}) => {
  if (!options || options.length === 0) {
    if (refreshData && !isDataLoading) {
      refreshData();
    }
    return <Loader show={true} />;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!readOnly && onChange) {
      onChange(e.target.name, e.target.value);
    }
  };

  return (
    <div className="form-item">
      {label && <label>{label}</label>}
      <div className={className}>
        {options.map((op: any) => (
          <div
            className="radio"
            style={{ display: "flex", gap: "8px" }}
            key={op.value}
          >
            <input
              type="radio"
              onChange={handleChange}
              value={op.value}
              name={name}
              readOnly={readOnly}
              checked={value.toString() === op.value}
            />
            {op.text}
            <span></span>
          </div>
        ))}
        {errors.map((err: string) => (
          <span key={err} className="field-validation-error">
            {err}
          </span>
        ))}
      </div>
    </div>
  );
};

export default RadioInput;
