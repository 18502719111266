import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { IClientOffice } from "../models/Clients/Interfaces/IClientOffice";
import ClientsService from "../services/ClientsService";
import update from "immutability-helper";

export namespace ClientOfficesStore {

    export interface IState {
        loading: boolean;
        offices: IClientOffice[]
    }

    export enum Actions {
        ToggleLoading = "CLIENT_OFFICES_TOGGLE_LOADING",
        SetClientOffices = "CLIENT_OFFICES_SET_CLIENT_DETAILS"
    }

    interface IToggleLoading {
        type: Actions.ToggleLoading;
        loading: boolean;
    }

    interface ISetClientOffices {
        type: Actions.SetClientOffices,
        offices: IClientOffice[]
    }

    type KnownAction = IToggleLoading | ISetClientOffices;

    export const actionCreators = {
        setClientDetails: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ToggleLoading, loading: true });

            var offices = await ClientsService.getClientOffices(id).then(response => response.value);

            dispatch({ type: Actions.SetClientOffices, offices: offices });

            dispatch({ type: Actions.ToggleLoading, loading: false });
        }
    }

    const initialState: IState = {
        loading: false,
        offices: []
    }

    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case Actions.ToggleLoading:
                return { ...currentState, loading: action.loading }
            case Actions.SetClientOffices:
                return update(currentState,
                    {
                        offices: { $set: action.offices }
                    });
            default:
                return currentState || initialState;
        }
    }
}

