// FormInput.tsx
import React from "react";

import { ISelectListItem } from "../../../models/Common/ISelectListItem";
import { EntityFieldInputType } from "../../../utils/reactUtils";
import CheckboxInput from "./CheckboxInput";
import DateInput from "./DateInput";
import FileInput from "./FileInput";
import ImageInput from "./ImageInput";
import RadioInput from "./RadioInput";
import TextInput from "./TextInput";

export interface IInputProps {
  inputType?: EntityFieldInputType;
  placeholder?: string;
  label?: string;
  leadingText?: string;
  className?: string;
  errors?: string[];
  options?: ISelectListItem[];
  value?: any;
  readOnly?: boolean;
  hidden?: boolean;
  name?: string;
  isDataLoading?: boolean;
  isFocused?: boolean;
  cursorPosition?: number;
  onChange?: (propName: string, data: any, cursorPosition?: number) => void;
  onKeyDown?: (e: React.FormEvent<HTMLInputElement>) => void;
  refreshData?: () => void;
}

const FormInput: React.FC<IInputProps> = (props) => {
  const {
    inputType = EntityFieldInputType.Text,
    errors = [],
    ...restProps
  } = props;

  const renderInput = () => {
    switch (inputType) {
      case EntityFieldInputType.Text:
      case EntityFieldInputType.Number:
      case EntityFieldInputType.Password:
        return (
          <TextInput {...restProps} inputType={inputType} errors={errors} />
        );

      case EntityFieldInputType.File:
        return <FileInput {...restProps} errors={errors} />;

      case EntityFieldInputType.Image:
        return <ImageInput {...restProps} errors={errors} />;

      case EntityFieldInputType.Radio:
        return <RadioInput {...restProps} errors={errors} />;

      case EntityFieldInputType.Date:
        return <DateInput {...restProps} errors={errors} />;

      case EntityFieldInputType.Checkbox:
        return <CheckboxInput {...restProps} errors={errors} />;

      default:
        return null;
    }
  };

  return renderInput();
};

export default FormInput;
