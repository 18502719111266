import bind from "bind-decorator";
import React from "react";
import General from "../../resources/AdminUI/General";
import AddOrEditWorkspaceForm from "../../resources/AdminUI/Spaces/AddOrEditWorkspaceForm";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { ISelectListItemForAmenity } from "../../models/Common/ISelectListItemForAmenity";
import update from "immutability-helper";
import { AppSettings } from "../../utils/settings";
import Loading from "../Shared/Loading";

export interface IProps {
  amenities?: ISelectListItemForAmenity[];
  additionalInofrmation?: string;
  onSaveCallback?: (amenities: ISelectListItemForAmenity[]) => void;
  onCloseCallback?: () => void;
}

export interface IState {
  amenities: ISelectListItemForAmenity[];
}

class AmenitiesForm extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      amenities: this.props.amenities,
    };
  }

  @bind
  handleChange(id: string, isSelected: boolean, isHighlighted: boolean) {
    let itemIndex = this.state.amenities.findIndex((e) => e.value == id);
    this.setState(
      update(this.state, {
        amenities: {
          [itemIndex]: {
            selected: { $set: isSelected },
            isHighlighted: { $set: isSelected ? isHighlighted : false },
          },
        },
      })
    );
  }

  @bind
  onSave() {
    this.props.onSaveCallback(this.state.amenities);
    this.props.onCloseCallback();
  }
  @bind
  onCancel() {
    this.props.onCloseCallback();
  }

  render() {
    if (this.props.amenities.length == 0 || this.props.amenities == null) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    return (
      <div>
        <label className="modal-description">
          {this.props.additionalInofrmation}
        </label>

        {this.state.amenities.map((e, i) => {
          return (
            <AmenityForm
              key={e.value}
              id={e.value}
              name={e.text}
              isChecked={e.selected}
              isHighlighted={e.isHighlighted}
              icon={e.icon}
              onChange={this.handleChange}
            ></AmenityForm>
          );
        })}

        <div className="modal-bottom-buttons">
          <button className="btn-big btn-primary" onClick={() => this.onSave()}>
            {General.Resources.save}
          </button>
          <button
            className="btn-big btn-secondary"
            onClick={() => this.onCancel()}
          >
            {General.Resources.cancelButtonLabel}
          </button>
        </div>
      </div>
    );
  }
}

interface IAmenityProps {
  id: string;
  name: string;
  isChecked: boolean;
  isHighlighted: boolean;
  icon: string;
  onChange: (id: string, isSelected: boolean, isHighlighted: boolean) => void;
}

class AmenityForm extends React.PureComponent<IAmenityProps, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="amenities-list-item">
        <div className="right-side">
          <input
            className="checkbox"
            type="checkbox"
            checked={this.props.isChecked}
            onChange={(event) => {
              this.props.onChange(
                this.props.id,
                event.target.checked,
                this.props.isHighlighted
              );
            }}
          />

          <div className="amenity-details">
            <img
              className="icon"
              src={AppSettings.CommonSettings.ImageBaseUrl + this.props.icon}
            />
            <p className="title">{this.props.name}</p>
          </div>
        </div>

        <div className="amenity-details">
          <input
            className="checkbox"
            type="checkbox"
            checked={this.props.isHighlighted}
            onChange={(event) => {
              if (this.props.isChecked) {
                this.props.onChange(
                  this.props.id,
                  this.props.isChecked,
                  event.target.checked
                );
              }
            }}
          />
          <label>
            {this.props.isHighlighted
              ? AddOrEditWorkspaceForm.Resources.highlighted
              : AddOrEditWorkspaceForm.Resources.notHighlighted}
          </label>
        </div>
      </div>
    );
  }
}

export default connect((state: ApplicationState, ownProps: IProps) => {
  return {
    amenities: state.addOrEditSpace.amenities,
    ...ownProps,
  };
}, null)(AmenitiesForm as any);

var roomComponent = connect(
  (state: ApplicationState, ownProps: IProps) => {
    return {
      amenities: state.addOrEditRoom.amenities,
      ...ownProps,
    };
  },
  null,
  null,
  { forwardRef: true }
)(AmenitiesForm as any);

export var RoomAmenitiesForm = roomComponent as any as typeof AmenitiesForm;

var officeComponent = connect(
  (state: ApplicationState, ownProps: IProps) => {
    return {
      amenities: state.addOrEditOffice.amenities,
      ...ownProps,
    };
  },
  null,
  null,
  { forwardRef: true }
)(AmenitiesForm as any);

export var OfficeAmenitiesForm = officeComponent as any as typeof AmenitiesForm;
