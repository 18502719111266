import Globals from "./../../../Globals";

export default class AddOrEditWorkspaceForm {
       public acceptedFormats: string = `Accepted formats: .png or .jpg, maxim 2MB each`;
       public addPeopleToReceiveAutomatedEmails: string = `Add people to receive automated emails. Complete with the email address and click the “plus” or enter button to add it to the list. You can add multiple email addresses.`;
       public addPeopleToReceiveAutomatedMessagesByWhatsapp: string = `Add people to receive automated messages by Whatsapp. Complete with the phone numbers and click the “plus” or enter button to add it to the list. You can add multiple numbers.`;
       public addressWillbeVisibleInMobileApp: string = `The address will be visible in mobile application`;
       public addSpace: string = `Add Space`;
       public appendSpaceToExistingGroupAndExistingCredentials: string = `Append this space to an existing group & existing credentials`;
       public atEveryBookingUsersGetAnAutomatedEmailwithWiFiDetailsAndAccessInfo: string = `At every booking, users get an automated email with Wi-Fi network details and building access information`;
       public automatedEmails: string = `AUTOMATED EMAILS`;
       public buildingAccessInfoWithExamples: string = `Building access information (ex: at the corner, white door)`;
       public businessContactInformation: string = `Business contact information`;
       public chooseGroupForThisSpaceOrCreateNewGroup: string = `Choose a group for this space or create a new group & credentials for workspaces dashboard`;
       public chooseInWhichLanguageTheEmailsShouldBeSent: string = `Choose in which language the emails should be sent for this space`;
       public commentsAreForInternalPurposes: string = `These comments are only for internal purposes, will not be displayed anywhere`;
       public commission: string = `Commission`;
       public completeAddress: string = `Complete address`;
       public completeBsnContactInfo: string = `Complete with the contact information of the person of business relationship from the space. Will not be displayed in the mobile app`;
       public completeInformationAboutSpaceFullHalfDayAndUnredeemedPricingOffer: string = `Complete the information about space full, half day and unredeemed pricing offer`;
       public completeSpaceScheduleWithOpeningAndClosingHours: string = `Complete space schedule with opening & closing hours`;
       public createNewGroupForThisSpace: string = `Create new group for this space`;
       public desksAvailability: string = `DESKS AVAILABILITY`;
       public distanceAndMapLocationAreBasedOnThem: string = `Distance and map location are based on them`;
       public doesTheSpaceOffersHotDesks: string = `Does the space offers hot desks?`;
       public doesTheSpaceHaveIncluedMeal: string = `Does the space have included meal?`;
       public doesTheSpaceRequirePriorBooking: string = `Does the space require prior booking?`;
       public editSpace: string = `Edit Space`;
       public emailAddress: string = `Email address`;
       public emailLanguage: string = `Email language`;
       public VAT: string = `VAT`;
       public fullDay: string = `Full day`;
       public halfDay: string = `Half day`;
       public unredeemed: string = `Unredeemed`;
       public fullDescription: string = `Full description`;
       public gpsCoordinates: string = `GPS Coordinates`;
       public group: string = `Group`;
       public groupEmailAddress: string = `Group Email Address`;
       public groupName: string = `Group Name`;
       public groupNamePasswordAndEmail: string = `Group Name, Email Address and Passowrd`;
       public highlighted: string = `Highlighted`;
       public ifComissionIs30CompleteWithValue30: string = `Ex: if the commission is 30%, complete with the value "30"`;
       public ifNotSpaceWillNotBeVisibleInMobileDesksSection: string = `If not, the space will not be visivle in mobile app, in the Desks section.`;
       public internalSpaceCost: string = `Internal space cost`;
       public latitude: string = `Latitude`;
       public longitude: string = `Longitude`;
       public maximumCapacityPerDay: string = `Maximum capacity / day`;
       public metaDescription: string = `Meta Description`;
       public metaInformation: string = `Meta Information`;
       public metaTitle: string = `Meta Title`;
       public moreInfoMetadata: string = `MORE INFO (METADATA)`;
       public nameVisibleInMobileApplication: string = `Name will be visible in mobile application`;
       public notes: string = `Notes/Comments`;
       public notHighlighted: string = `Not Highlighted`;
       public noThisIsAWalkInSpaceAndDoesNotRequireBooking: string = `No, this is a walk-in space and does not require booking`;
       public password: string = `Password`;
       public percent: string = `Percent`;
       public phoneNumber: string = `Phone number`;
       public pleaseCompleteEverydaysProgramIfTheSpaceIsClosed: string = `Please complete everyday's program. If the space is closed, check the 'Closed' option`;
       public pleaseSelectAllAvailableAmenitiesHighlightImportantAmenitiesAndTheyWillAppearOnTheMainSpaceDetailsScreen: string = `Please select all available space amenities. Highlight important amenities by checking the HIGHLIGHTED button and they will appear on the main Space Details screen.`;
       public publicContactInformation: string = `Public contact information`;
       public receiverEmailAddress: string = `Receiver email address`;
       public receiverPhoneNumber: string = `Receiver phone numbers`;
       public recommendBetween200And400Characters: string = `We recommend a description between 200 and 400 characters`;
       public selectAmenities: string = `SELECT AMENITIES`;
       public selectCity: string = `Select city...`;
       public selectCountry: string = `Select country...`;
       public selectFromTheListAndHighlightTheImportantOnes: string = `Select from the list and highlight the important ones`;
       public selectGroupOrCreateANewOne: string = `Select group or create a new one`;
       public selectIfTheSpaceRequiresBookingCompleteMaxDesksAvailable: string = `Select if the space requires booking. If yes, complete the maximum desks available / day.`;
       public selectingTheRightCurrencyIsImportantforCheckoutFlowAndGeneratingPrices: string = `Selecting the right currency is important for checkout flow & generating prices for rooms bookings`;
       public selectSchedule: string = `SELECT SCHEDULE`;
       public selectSpaceAmenities: string = `Select space amenities`;
       public selectSpaceTypeByChoosingFromDropdown: string = `Select the space type by choosing one from the dropdown`;
       public setSpacesProgram: string = `Set spaces program`;
       public spaceAddress: string = `Space Address`;
       public spaceAmenities: string = `Space amenities`;
       public spaceCurrency: string = `Space Currency`;
       public spaceDashboardAccess: string = `SPACE DASHBOARD ACCESS`;
       public spaceDisplayAndDetails: string = `SPACE DISPLAY & DETAILS`;
       public spaceIsClosed: string = `Space is closed`;
       public spaceName: string = `Space Name`;
       public spacePhoneNumberAndEmailAddressWillBeDisplayedInAppForUsers: string = `Space phone number and email address will be displayed in application for users`;
       public spacePhotographs: string = `Space photographs`;
       public spaceSchedule: string = `Space schedule`;
       public spaceType: string = `Space Type`;
       public whoWillReceiveAutomatedEmails: string = `Who will receive the automated emails?`;
       public whoWillReceiveAutomatedMessagesByWhatsapp: string = `Who will receive the automated messages by Whatsapp?`;
       public wifiInfoAndAccess: string = `Wi-Fi Information & Access`;
       public wifiNetwork: string = `Wi-Fi Network`;
       public wifiPassword: string = `Wi-Fi Password`;
       public yesThisSpaceRequiresPriorBooking: string = `Yes, this space requires prior booking`;

		public static get Resources(): AddOrEditWorkspaceForm{
			switch (Globals.currentActiveLanguage) {
				default:
				return new AddOrEditWorkspaceForm();
		}
   	}
}
