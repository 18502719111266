import bind from "bind-decorator";
import Validations from "../../../resources/Common/Validations";
import { String_Format, isEmail, nameof } from "../../../utils/utils";
import { BaseValidator } from "../Base/BaseValidator";
import AddOrEditOffice from "../../../resources/AdminUI/Spaces/AddOrEditOffice";

export class AddOrEditOfficeFormValidator extends BaseValidator {
  constructor() {
    super();
  }

  @bind
  validate(model: any, props: any) {
    this.check(
      () => model.name != "" && model.name != null,
      nameof((e) => e.name),
      String_Format(
        Validations.Resources.required,
        AddOrEditOffice.Resources.officeName
      )
    );
    // this.check(() => !model.name || model.name.length <= 100, nameof(form => form.name), String_Format(Validations.Resources.maxLength, 100));
    this.check(
      () => model.countryId != "" && model.countryId != null,
      nameof((e) => e.countryId),
      String_Format(
        Validations.Resources.required,
        AddOrEditOffice.Resources.selectCountry
      )
    );
    this.check(
      () => model.cityId != "" && model.cityId != null,
      nameof((e) => e.cityId),
      String_Format(
        Validations.Resources.required,
        AddOrEditOffice.Resources.selectCity
      )
    );
    this.check(
      () => model.address != "" && model.address != null,
      nameof((e) => e.address),
      String_Format(
        Validations.Resources.required,
        AddOrEditOffice.Resources.completeAddress
      )
    );
    // this.check(() => !model.address || model.address.length <= 200, nameof(form => form.address), String_Format(Validations.Resources.maxLength, 200));
    this.check(
      () => model.lat != "" && model.lat != null,
      nameof((e) => e.lat),
      String_Format(
        Validations.Resources.required,
        AddOrEditOffice.Resources.latitude
      )
    );
    this.check(
      () => !model.lat || (model.lat >= -90 && model.lat <= 90),
      nameof((e) => e.lat),
      Validations.Resources.latitude
    );
    this.check(
      () => model.lng != "" && model.lng != null,
      nameof((e) => e.lng),
      String_Format(
        Validations.Resources.required,
        AddOrEditOffice.Resources.latitude
      )
    );
    this.check(
      () => !model.lng || (model.lng >= -180 && model.lng <= 180),
      nameof((e) => e.lng),
      Validations.Resources.longitude
    );
    this.check(
      () => model.officeOffersHotDesks != null,
      nameof((e) => e.officeOffersHotDesks),
      String_Format(
        Validations.Resources.required,
        AddOrEditOffice.Resources.doesOfficeOffersHotDesks
      )
    );
    this.check(
      () =>
        model.officeOffersHotDesks && model.requireBooking
          ? model.maxSeats != 0 && model.maxSeats != null
          : true,
      nameof((e) => e.maxSeats),
      String_Format(
        Validations.Resources.required,
        AddOrEditOffice.Resources.doesOfficeOffersHotDesks
      )
    );
    this.check(
      () => !model.email || isEmail(model.email),
      nameof((e) => e.email),
      Validations.Resources.emailNotValid
    );
    // this.check(() => !model.email || model.email.length <= 50, nameof(e => e.email), String_Format(Validations.Resources.maxLength, 50));
    // this.check(() => !model.phone || model.phone.length <= 50, nameof(e => e.phone), String_Format(Validations.Resources.maxLength, 50));
    this.check(
      () =>
        props.schedule?.filter(
          (e) => e.openHour == null && e.closeHour == null && !e.isClosed
        ).length == 0,
      nameof((e) => e.schedule),
      String_Format(Validations.Resources.required, "schedule")
    );

    return this.getErrorsDictionary();
  }
}
