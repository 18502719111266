import bind from "bind-decorator";
import React from "react";
import { ApplicationPathBuilder } from "../../../enums/Common/ApplicationPaths";
import ClientOffice from "../../../resources/AdminUI/Clients/ClientOffice";
import { String_Format } from "../../../utils/utils";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { ClientOfficesStore } from "../../../store/ClientOfficesStore";
import General from "../../../resources/AdminUI/General";
import { IClientOffice } from "../../../models/Clients/Interfaces/IClientOffice";
import { AppSettings } from "../../../utils/settings";
import { withRouter } from "../../Shared/withRouter";
import { NavigateFunction } from "react-router";
import Loading from "../../Shared/Loading";

interface IProps {
  clientId: number;
  clientName: string;
  loading?: boolean;
  offices?: IClientOffice[];

  navigate?: NavigateFunction;

  getClientOffices?: (clientId: number) => void;
}

class ClientOfficeContainer extends React.PureComponent<IProps, any> {
  constructor(props) {
    super(props);

    this.props.getClientOffices(this.props.clientId);
  }

  @bind
  handleOnAddOfficeButtonClick() {
    this.props.navigate(ApplicationPathBuilder.AddOffice(this.props.clientId));
  }

  @bind
  handleOnViewOfficeButtonClick(officeId) {
    this.props.navigate(
      ApplicationPathBuilder.ViewSpaceDetails(officeId.toString())
    );
  }

  render() {
    if (this.props.loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    } else {
      return (
        <div>
          <div>
            <p>
              {String_Format(
                ClientOffice.Resources.clientOfficeDesc,
                this.props.clientName
              )}
            </p>
          </div>
          {this.props.offices.map((office) => (
            <div className="office-card">
              <div className="office-photo">
                <img
                  src={
                    AppSettings.CommonSettings.ImageBaseUrl +
                    office.mainImageUrl
                  }
                />
              </div>
              <div className="office-description-container">
                <h2 className="item-name">{office.name}</h2>
                <div className="header">ADDRESS</div>
                <div className="address"> {office.address} </div>
                <button
                  className="btn-big btn-secondary"
                  onClick={() => this.handleOnViewOfficeButtonClick(office.id)}
                >
                  {ClientOffice.Resources.viewOfficeText}
                </button>
              </div>
            </div>
          ))}
          <div>
            <button
              className="btn-big btn-primary"
              onClick={this.handleOnAddOfficeButtonClick}
            >
              {ClientOffice.Resources.addOfficeText}
            </button>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(
  connect(
    (state: ApplicationState, ownProps: IProps) => {
      return {
        ...ownProps,
        offices: state.clientOffices.offices,
        loading: state.clientOffices.loading,
      };
    },
    {
      getClientOffices: ClientOfficesStore.actionCreators.setClientDetails,
    }
  )(ClientOfficeContainer as any)
);
