import Globals from "./../../../Globals";

export default class ClientStateList {
       public active: string = `Active`;
       public all: string = `Any Status`;
       public deactivated: string = `Deactivated`;

		public static get Resources(): ClientStateList{
			switch (Globals.currentActiveLanguage) {
				default:
				return new ClientStateList();
		}
   	}
}
