import bind from "bind-decorator";
import Validations from "../../../resources/Common/Validations";
import { String_Format, isEmail, nameof } from "../../../utils/utils";
import { BaseValidator } from "../Base/BaseValidator";
import AddOrEditWorkspaceForm from "../../../resources/AdminUI/Spaces/AddOrEditWorkspaceForm";

export class AddOrEditSpaceFormValidator extends BaseValidator {
  constructor() {
    super();
  }

  @bind
  validate(model: any, props: any) {
    this.check(
      () => model.name != "" && model.name != null,
      nameof((e) => e.name),
      String_Format(
        Validations.Resources.required,
        AddOrEditWorkspaceForm.Resources.spaceName
      )
    );
    // this.check(() => !model.name || model.name.length <= 100, nameof(form => form.name), String_Format(Validations.Resources.maxLength, 100));
    this.check(
      () => model.categoryId != "" && model.categoryId != null,
      nameof((e) => e.categoryId),
      String_Format(
        Validations.Resources.required,
        AddOrEditWorkspaceForm.Resources.spaceType
      )
    );
    this.check(
      () => model.countryId != "" && model.countryId != null,
      nameof((e) => e.countryId),
      String_Format(
        Validations.Resources.required,
        AddOrEditWorkspaceForm.Resources.selectCountry
      )
    );
    this.check(
      () => model.cityId != "" && model.cityId != null,
      nameof((e) => e.cityId),
      String_Format(
        Validations.Resources.required,
        AddOrEditWorkspaceForm.Resources.selectCity
      )
    );
    this.check(
      () => model.address != "" && model.address != null,
      nameof((e) => e.address),
      String_Format(
        Validations.Resources.required,
        AddOrEditWorkspaceForm.Resources.completeAddress
      )
    );
    // this.check(() => !model.address || model.address.length <= 200, nameof(form => form.address), String_Format(Validations.Resources.maxLength, 200));
    this.check(
      () => model.lat != "" && model.lat != null,
      nameof((e) => e.lat),
      String_Format(
        Validations.Resources.required,
        AddOrEditWorkspaceForm.Resources.latitude
      )
    );
    this.check(
      () => !model.lat || (model.lat >= -90 && model.lat <= 90),
      nameof((e) => e.lat),
      Validations.Resources.latitude
    );
    this.check(
      () => model.lng != "" && model.lng != null,
      nameof((e) => e.lng),
      String_Format(
        Validations.Resources.required,
        AddOrEditWorkspaceForm.Resources.latitude
      )
    );
    this.check(
      () => !model.lng || (model.lng >= -180 && model.lng <= 180),
      nameof((e) => e.lng),
      Validations.Resources.longitude
    );
    this.check(
      () => model.currency != "" && model.currency != null,
      nameof((e) => e.currency),
      String_Format(
        Validations.Resources.required,
        AddOrEditWorkspaceForm.Resources.spaceCurrency
      )
    );
    this.check(
      () => model.spaceOffersHotDesks != null,
      nameof((e) => e.spaceOffersHotDesks),
      String_Format(
        Validations.Resources.required,
        AddOrEditWorkspaceForm.Resources.doesTheSpaceOffersHotDesks
      )
    );
    this.check(
      () =>
        model.spaceOffersHotDesks && model.requireBooking
          ? model.maxSeats != 0 && model.maxSeats != null
          : true,
      nameof((e) => e.maxSeats),
      String_Format(
        Validations.Resources.required,
        AddOrEditWorkspaceForm.Resources.doesTheSpaceOffersHotDesks
      )
    );
    this.check(
      () => !model.appendToAnExistingGroup || model.groupId,
      nameof((e) => e.groups),
      String_Format(
        Validations.Resources.required,
        AddOrEditWorkspaceForm.Resources.group
      )
    );
    this.check(
      () =>
        !model.createANewGroup ||
        (model.groupName && model.groupPassword && model.groupEmailAddress),
      nameof((e) => e.groups),
      String_Format(
        Validations.Resources.required,
        AddOrEditWorkspaceForm.Resources.groupNamePasswordAndEmail
      )
    );
    this.check(
      () =>
        !model.createANewGroup ||
        !model.groupEmailAddress ||
        isEmail(model.groupEmailAddress),
      nameof((e) => e.groupEmailAddress),
      Validations.Resources.emailNotValid
    );
    this.check(
      () => !model.email || isEmail(model.email),
      nameof((e) => e.email),
      Validations.Resources.emailNotValid
    );
    // this.check(() => !model.email || model.email.length <= 50, nameof(e => e.email), String_Format(Validations.Resources.maxLength, 50));
    // this.check(() => !model.phone || model.phone.length <= 50, nameof(e => e.phone), String_Format(Validations.Resources.maxLength, 50));
    this.check(
      () => !model.businessEmail || isEmail(model.businessEmail),
      nameof((e) => e.businessEmail),
      Validations.Resources.emailNotValid
    );
    // this.check(() => !model.businessEmail || model.businessEmail.length <= 50, nameof(e => e.businessEmail), String_Format(Validations.Resources.maxLength, 50));
    // this.check(() => !model.businessPhone || model.businessPhone.length <= 50, nameof(e => e.businessPhone), String_Format(Validations.Resources.maxLength, 50));
    // this.check(() => !model.notes || model.notes.length <= 50, nameof(e => e.notes), String_Format(Validations.Resources.maxLength, 50));
    this.check(
      () =>
        props.schedule?.filter(
          (e) => e.openHour == null && e.closeHour == null && !e.isClosed
        ).length == 0,
      nameof((e) => e.schedule),
      String_Format(Validations.Resources.required, "schedule")
    );

    if (model.automaticEmailReceivers.length > 0) {
      model.automaticEmailReceivers.map((email, index) => {
        this.check(
          () => isEmail(email),
          nameof((e) => e.automaticEmailReceivers),
          Validations.Resources.emailNotValid
        );
        // this.check(() => email.length <= 50, nameof(e => e.automaticEmailReceivers), String_Format(Validations.Resources.maxLength, 50));
      });
    }
    this.check(
      () =>
        !model.automaticEmailReceivers ||
        model.automaticEmailReceivers.length <= 10,
      nameof((e) => e.automaticEmailReceivers),
      String_Format(Validations.Resources.maxLength, 10)
    );
    this.check(
      () => model.localeId != "" && model.localeId != null,
      nameof((e) => e.localeId),
      String_Format(
        Validations.Resources.required,
        AddOrEditWorkspaceForm.Resources.emailLanguage
      )
    );

    return this.getErrorsDictionary();
  }
}
