import React from "react";
import AdminUserCard from "../Cards/AdminUserCard";
import AccountsAndLogs from "../../../resources/AdminUI/AccountsAndLogs/AccountsAndLogs";
import { bind } from "bind-decorator";
import ModalDialog from "../../Shared/ModalDialog";
import AddOrEditAdminCredentialsForm from "../Forms/AddOrEditAdminCredentialsForm";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { AdminAccountsStore } from "../../../store/AdminAccountsStore";
import General from "../../../resources/AdminUI/General";
import Loading from "../../Shared/Loading";

interface IProps {
  loading?: boolean;
  adminUsers?: any[];

  getAdminUsers: () => void;
}

interface IState {
  isAddCredentialsModalOpen: boolean;
}

class AdminAccounts extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isAddCredentialsModalOpen: false,
    };

    this.props.getAdminUsers();
  }

  @bind
  showAddCredentialsModal() {
    this.setState({ isAddCredentialsModalOpen: true });
  }

  @bind
  hideAddCredentialsModal() {
    this.setState({ isAddCredentialsModalOpen: false });
  }

  render() {
    if (this.props.loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    } else {
      return (
        <div>
          <button
            className="btn-big btn-primary no-margin"
            onClick={this.showAddCredentialsModal}
          >
            <span className="material-symbols-outlined">add_circle</span>
            <p className="btn-primary-text">
              {AccountsAndLogs.Resources.addCredentialsLabel}
            </p>
          </button>

          <ModalDialog
            title={""}
            open={this.state.isAddCredentialsModalOpen}
            onCancel={this.hideAddCredentialsModal}
          >
            <AddOrEditAdminCredentialsForm
              title={AccountsAndLogs.Resources.addNewCredentialsHeader}
              description={AccountsAndLogs.Resources.addNewCredentialsDesc}
              onCancelCallback={this.hideAddCredentialsModal}
            />
          </ModalDialog>

          {this.props.adminUsers.length == 0 ? (
            <div>{General.Resources.noElements}</div>
          ) : (
            <div className="admin-accounts-container">
              {this.props.adminUsers.map((adminUser) => (
                <AdminUserCard
                  key={adminUser.id}
                  id={adminUser.id}
                  name={adminUser.name}
                  email={adminUser.email}
                  isSuperAdmin={adminUser.isSuperAdmin}
                  deactivated={adminUser.deactivated}
                />
              ))}
            </div>
          )}
        </div>
      );
    }
  }
}

export default connect(
  (state: ApplicationState) => {
    return {
      loading: state.adminAccounts.loading,
      adminUsers: state.adminAccounts.adminAccounts,
    };
  },
  {
    getAdminUsers: AdminAccountsStore.actionCreators.getAdminAccounts,
  }
)(AdminAccounts as any);
