import { useCallback, useEffect, useMemo } from "react";
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";

import { IRoomBookingsItem } from "../../models/RoomBookings/Interfaces/IRoomBookingsItem";
import { IEntitiesList } from "../../models/Common/IEntitiesList";
import { usePendingBookingsContext } from "../../providers/PendingBookingsProvider";
import { useRoomBookingsHelpers } from "./useRoomBookingsHelpers";
import { useRoomBookingsQueries } from "./useRoomBookingsQueries";

export const usePendingRoomsRequestsPage = (statusId?: string) => {
  const {
    itemsPerPage,
    currentPage,
    filters,
    numberOfRoomBookings,
    setFilters,
    setLoading,
    setRoomBookings,
    setNumberOfRoomBookings,
    setCurrentPage,
    setPageIndexArray,
    setFirstIndexFromPage,
    setLastIndexFromPage,
  } = usePendingBookingsContext();
  const {
    recalculatePageArray,
    recalculateIndexes,
    rebuildODataFilters,
    getAllRoomStatuses,
  } = useRoomBookingsHelpers(itemsPerPage, numberOfRoomBookings, currentPage);

  const { getRoomBookings } = useRoomBookingsQueries();
  const queryClient = useQueryClient();
  const skip = useMemo(
    () => (currentPage - 1) * itemsPerPage,
    [currentPage, itemsPerPage]
  );

  const {
    data,
    isLoading,
    error,
  }: UseQueryResult<IEntitiesList<IRoomBookingsItem>, Error> = getRoomBookings(
    itemsPerPage,
    skip,
    filters
  );

  useEffect(() => {
    setCurrentPage(currentPage);
  }, []);

  useEffect(() => {
    if (data) {
      setRoomBookings(data.entities);
      setNumberOfRoomBookings(data.numberOfEntities);
    } else {
      setRoomBookings([]);
      setNumberOfRoomBookings(0);
    }
    setLoading(false);
  }, [data]);

  useEffect(() => {
    const pageArray = recalculatePageArray();
    setPageIndexArray(pageArray);
    const { firstIndex, lastIndex } = recalculateIndexes();
    setFirstIndexFromPage(firstIndex);
    setLastIndexFromPage(lastIndex);
  }, [numberOfRoomBookings, currentPage]);

  const changeCurrentPage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const rebuildODataFiltersWithCustomLogic = (
    toTake: number,
    toSkip: number
  ): string => {
    const filters: any = {};

    // Add StatusId filter
    if (statusId) {
      filters.StatusId = { eq: parseInt(statusId) };
    }
    return rebuildODataFilters(toTake, toSkip, filters);
  };

  const oDataFilter = useMemo(
    () => rebuildODataFiltersWithCustomLogic(itemsPerPage, skip),
    [itemsPerPage, skip]
  );

  useEffect(() => {
    setFilters(oDataFilter);
  }, [oDataFilter]);

  const reloadRoomBookingsData = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ["roomBookings"] });
  }, [queryClient]);

  return {
    filters: oDataFilter,
    skip,
    isLoading,
    error,
    itemsPerPage,
    rebuildODataFilters: rebuildODataFiltersWithCustomLogic,
    changeCurrentPage,
    getAllRoomStatuses,
    recalculateIndexes,
    recalculatePageArray,
    reloadRoomBookingsData,
  };
};
