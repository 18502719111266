import React from "react"
import { connect } from "react-redux"
import { ApplicationState } from "../../../store"
import { SubscriptionStore } from "../../../store/SubscriptionStore"
import { ISubscription } from "../../../models/Subscriptions/Interfaces/ISubscription"
import General from "../../../resources/AdminUI/General"
import bind from "bind-decorator"
import AddOrEditSubscriptionForm from "../Forms/AddOrEditSubscriptionForm"
import { getPromiseFromAction } from "../../../utils/utils"
import Subscriptions from "../../../resources/AdminUI/Subscriptions/Subscriptions"
import { NotificationStore } from "../../../store/NotificationStore"
import { SubscriptionTable } from "../Tables/SubscriptionTable"
import SubscriptionItem from "../../../models/Subscriptions/SubscriptionItem"
import { Modal, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from "@ant-design/icons"
import ModalDialog from "../../Shared/ModalDialog"
const { confirm } = Modal;

interface IProps {
    clientId: number,
    loading?: boolean,
    subscription?: SubscriptionItem,
    hasServerSideErrors?: boolean;

    getSubscription?: (clientId: number) => any,
    deactivateSubscription?: (subscriptionId: number) => any
    addOrEditSubscription?: (subscription: ISubscription) => any,
    showErrorNotification?: () => void,
}

interface IState {
    isAddSubscriptionModalOpen: boolean;
}

class SubscriptionContainer extends React.Component<IProps, IState> {
    constructor(props) {
        super(props)
        this.state = {
            isAddSubscriptionModalOpen: false
        }
        this.props.getSubscription(this.props.clientId);
    }

    tableClass: string = "table";
    hiddenColumns: string[] = ["id", "currency", "renewIntervalValue", "nextRenewDate", "companyId", "metadata", "price"]

    @bind
    showAddSubscriptionModal() {
        this.setState({ isAddSubscriptionModalOpen: true });
    }

    @bind
    hideAddSubscriptionModal() {
        this.setState({ isAddSubscriptionModalOpen: false });
    }

    @bind
    showDeactivateModal() {
        confirm({
            title: General.Resources.areYouSureModalLabel,
            icon: <ExclamationCircleOutlined />,
            content: <div>
                <p>{Subscriptions.Resources.deactivateContentText}</p>
            </div>,
            okText: Subscriptions.Resources.deactivatePricingPlanButtonText,
            okType: "danger",
            cancelText: General.Resources.cancelButtonLabel,
            onOk: () => {
                this.handleOnDeactivateSubscription();
            },
            onCancel() {
            },
        });
    }

    @bind
    handleOnDeactivateSubscription() {
        getPromiseFromAction(this.props.deactivateSubscription(this.props.subscription.id))
            .then(() => {
                if (!this.props.hasServerSideErrors) {
                } else {
                    this.props.showErrorNotification();
                }
            });
    }

    @bind
    handleOnSaveSubscription(subscription: ISubscription) {
        getPromiseFromAction(this.props.addOrEditSubscription(subscription))
            .then(() => {
                if (!this.props.hasServerSideErrors) {
                    this.hideAddSubscriptionModal();
                    this.props.getSubscription(this.props.clientId);
                }
            });
    }

    render() {
        if (this.props.loading) {
            return <div>{General.Resources.loading}</div>
        }
        else {
            return <div className="subscription-container">
                <div className="section-top">
                    <div className="section-title">{Subscriptions.Resources.pricingPlanText}</div>
                </div>

                {
                    this.props.subscription.id ?
                        <SubscriptionTable
                            subscription={this.props.subscription}
                            clientId={this.props.clientId}
                            hiddenColumns={this.hiddenColumns}
                            onEditSubscriptionCallback={this.handleOnSaveSubscription}
                        />
                        : <div>
                            {Subscriptions.Resources.noPricingPlanYetText}
                        </div>
                }
                <div className="bottom-btns">
                    {
                        this.props.subscription.id &&
                        <button className="btn-big btn-secondary" onClick={this.showDeactivateModal}>
                            {Subscriptions.Resources.deactivatePricingPlanButtonText}
                        </button>
                    }
                    <button className="btn-big btn-primary" disabled={this.props.subscription.id ? true : false}
                        onClick={this.showAddSubscriptionModal}>
                        {Subscriptions.Resources.addPricingPlanText}
                    </button>

                    <Tooltip title={Subscriptions.Resources.pricingPlanTooltipText}>
                        <span className="material-symbols-outlined">error</span>
                    </Tooltip>

                    <ModalDialog
                        title={""}
                        open={this.state.isAddSubscriptionModalOpen}
                        onCancel={this.hideAddSubscriptionModal}>
                        <AddOrEditSubscriptionForm
                            clientId={this.props.clientId}
                            onCancelCallback={this.hideAddSubscriptionModal}
                            onSaveCallback={this.handleOnSaveSubscription}
                        />
                    </ModalDialog>
                </div>

            </div>
        }
    }
}

export default connect(
    (state: ApplicationState, ownProps: IProps) => {
        return {
            ...ownProps,
            loading: state.subscription.loading,
            subscription: state.subscription.subscription,
            hasServerSideErrors: state.subscription.hasServerSideErrors,
        }
    },
    {
        getSubscription: SubscriptionStore.actionCreators.getClientSubscription,
        deactivateSubscription: SubscriptionStore.actionCreators.deactivateSubscription,
        addOrEditSubscription: SubscriptionStore.actionCreators.addOrEditSubscription,
        showErrorNotification: NotificationStore.actionCreators.showErrorNotification
    }
)(SubscriptionContainer as any)