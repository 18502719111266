import React from "react";
import SubscriptionItem from "../../../models/Subscriptions/SubscriptionItem";
import { ISubscription } from "../../../models/Subscriptions/Interfaces/ISubscription";
import bind from "bind-decorator";
import AddOrEditSubscriptionForm from "../Forms/AddOrEditSubscriptionForm";
import ModalDialog from "../../Shared/ModalDialog";
import General from "../../../resources/AdminUI/General";

interface IProps {
    subscription: SubscriptionItem,
    clientId: number;
    hiddenColumns?: string[],
    onEditSubscriptionCallback: (subscription: ISubscription) => void
}

interface IState {
    isEditSubscriptionModalOpen: boolean;
}

export class SubscriptionTable extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            isEditSubscriptionModalOpen: false
        }
    }

    tableClass: string = "table";


    @bind
    showEditSubscriptionModal() {
        this.setState({ isEditSubscriptionModalOpen: true });
    }

    @bind
    hideEditSubscriptionModal() {
        this.setState({ isEditSubscriptionModalOpen: false });
    }

    render() {      
        return <div className="table-wrapper">
            <table className={this.tableClass}>
                <thead>
                    <tr>
                        {this.props.subscription.TrueProperties
                            .filter(p => this.props.hiddenColumns.indexOf(p) < 0)
                            .map(p => <th key={p}>{this.props.subscription.GetDisplayNameForProperty(p)}</th>)}
                        <th>{General.Resources.actions}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        <tr>
                            {
                                this.props.subscription.TrueProperties
                                    .filter(p => this.props.hiddenColumns.indexOf(p) < 0)
                                    .map(p => (
                                        <td key={p}>
                                            {this.props.subscription.GetDisplayValueForProperty(p)}
                                        </td>
                                    ))
                            }
                            <td className="row-actions">
                                <button className="btn-table-action" onClick={() => this.showEditSubscriptionModal()}><span className="material-symbols-outlined">edit</span></button>

                                <ModalDialog
                                    title=""
                                    open={this.state.isEditSubscriptionModalOpen}
                                    onCancel={this.hideEditSubscriptionModal}>
                                    <AddOrEditSubscriptionForm
                                        subscription={this.props.subscription}
                                        clientId={this.props.clientId}
                                        onCancelCallback={this.hideEditSubscriptionModal}
                                        onSaveCallback={this.props.onEditSubscriptionCallback}
                                    />
                                </ModalDialog>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    }
}