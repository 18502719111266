import React from "react";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import General from "../../../resources/AdminUI/General";
import { ICompanyAccount } from "../../../models/ManageAccounts/ICompanyAccount";
import ManageAccounts from "../../../resources/AdminUI/ManageAccounts";
import bind from "bind-decorator";
import CreateCredentialsDialog from "../Dialogs/CreateCredentialsDialog";
import { ManageAccountStore } from "../../../store/ManageAccountsStore";
import ModalDialog from "../../Shared/ModalDialog";
import ICompany from "../../../models/ManageAccounts/ICompany";
import { CompanyAccountCard } from "../Cards/CompanyAccountCard";


interface IProps {
    loading?: boolean,
    selectedCompany?: ICompany,
    companyAccounts?: ICompanyAccount[],
    saveCompleted?: boolean,

    saveAccount?: (account: ICompanyAccount) => void
}

interface IState {
    isCreateCredentialsModalOpen: boolean;
}

class CompanyAccountsContainer extends React.Component<IProps, IState> {
    constructor(props) {
        super(props)
        this.state = {
            isCreateCredentialsModalOpen: false
        }
    }

    @bind
    showCreateCredentialsModal() {
        this.setState({ isCreateCredentialsModalOpen: true });
    }

    @bind
    hideCreateCredentialsModal() {
        this.setState({ isCreateCredentialsModalOpen: false });
    }

    @bind
    handelOnAddOrEditAccount(account: any) {
        this.props.saveAccount(account);
    }

    render() {
        if (this.props.loading) {
            return <div>
                {General.Resources.loading}
            </div>
        }
        if (!this.props.selectedCompany) {
            return <div>
                {General.Resources.noElements}
            </div>
        } else {
            return <div className="accounts-scrollable-list">
                <div className="credentials-list">
                    <h3 className="title">{this.props.selectedCompany.name}</h3>
                    {
                        this.props.companyAccounts.map(account =>
                            <CompanyAccountCard
                                account={account}
                                onSaveAccountCallback={this.handelOnAddOrEditAccount}
                                key={account.id}
                            />
                        )
                    }
                    <button className="btn-big btn-primary displayed-bottom" onClick={this.showCreateCredentialsModal}>
                        <span className="material-symbols-outlined">add_circle</span>
                        <p className="btn-primary-text">{ManageAccounts.Resources.createCredentialsButtonText}</p>
                    </button>
                    <ModalDialog
                        title={""}
                        open={this.state.isCreateCredentialsModalOpen}
                        onCancel={this.hideCreateCredentialsModal}>
                        <CreateCredentialsDialog
                            onCancelCallback={this.hideCreateCredentialsModal}
                            onSaveCallback={this.handelOnAddOrEditAccount} />
                    </ModalDialog>

                </div>
                
            </div>
        }
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            loading: state.manageAccounts.loading,
            selectedCompany: state.manageAccounts.selectedCompany,
            companyAccounts: state.manageAccounts.companyAccounts,
            saveCompleted: state.manageAccounts.saveCompleted
        }
    },
    {
        saveAccount: ManageAccountStore.actionCreators.saveCompanyAccount
    }
)(CompanyAccountsContainer as any)