import React from "react";
import { ApplicationState } from "../../../store";
import { TeamsStore } from "../../../store/TeamsStore";
import { connect } from "react-redux";
import { nameof } from "../../../utils/utils";
import bind from "bind-decorator";
import { ITeam } from "../../../models/Teams/Interfaces/ITeam";
import { ValidatedComponent } from "../../FormValidations/Base/ValidatedComponent";
import General from "../../../resources/AdminUI/General";
import ChangeUserTeam from "../../../resources/AdminUI/Users/ChangeUserTeam";
import { ChangeTeamValidator } from "../../FormValidations/Validators/ChangeTeamValidator";
import { IAntdSelectListItem } from "../../../models/Common/IAntdSelectListItem";
import { Select } from "antd";
import { String_Format } from "../../../utils/utils";
import Validations from "../../../resources/Common/Validations";
import Loading from "../../Shared/Loading";

interface IProps {
  userId: number;
  userName: string;
  currentTeamId: number;
  currentTeamName: string;
  clientId: number;

  loading?: boolean;
  teams?: ITeam[];

  getTeams?: (clientId: number) => void;
  onCancelCallback: () => void;
  onSaveCallback: (teamId: number, userId: number) => void;
}

class ChangeUserTeamForm extends ValidatedComponent<IProps, any> {
  constructor(props) {
    super(props);

    this.state = {
      currentTeam: this.props.currentTeamId,
      newTeam: this.props.currentTeamId,
    };
  }

  @bind
  getAvailableTeams() {
    var teamsItems = [];

    this.props.teams.forEach((team) => {
      teamsItems.push({
        value: team.id.toString(),
        label: team.name ? team.name : General.Resources.defaultTeamName,
      } as IAntdSelectListItem);
    });

    return teamsItems;
  }

  @bind
  handleOnInputChange(name: string, data: any) {
    this.setState({
      newTeam: data,
    });
  }

  @bind
  handleOnSaveButtonClick() {
    this.validate(() => {
      this.props.onSaveCallback(this.state.newTeam, this.props.userId);
    });
  }

  render() {
    if (this.props.loading || this.props.teams.length == 0) {
      return (
        <div>
          <Loading />
        </div>
      );
    } else {
      return (
        <div className="modal-container">
          <div className="modal-title">
            {String_Format(
              ChangeUserTeam.Resources.changeTeamHeader,
              this.props.userName
            )}
          </div>

          <div className="modal-description">
            {ChangeUserTeam.Resources.changeTeamDesc}
          </div>

          <div className="form-line">
            <div className="line-info">
              <div className="title in-modal">
                {ChangeUserTeam.Resources.currentTeamText}
              </div>
            </div>
            <div className="line-action">
              <p>
                {this.props.currentTeamName
                  ? this.props.currentTeamName
                  : General.Resources.defaultTeamName}
              </p>
            </div>
          </div>

          <div className="form-line">
            <div className="line-info">
              <div className="title in-modal">
                {ChangeUserTeam.Resources.newTeamText}:
                <span className="mandatory">*</span>
              </div>
            </div>
            <div className="line-action">
              <div className="input-group form-select">
                <Select
                  options={this.getAvailableTeams()}
                  onChange={(value, option) => {
                    this.handleOnInputChange(
                      nameof((e) => e.currency),
                      value
                    );
                  }}
                  value={
                    this.state.newTeam ? this.state.newTeam.toString() : null
                  }
                  placeholder={ChangeUserTeam.Resources.selectNewTeam}
                ></Select>
                {this.getFieldErrors(nameof((s) => s.newTeam)).map((err) => (
                  <span key={err} className="field-validation-error">
                    {err}
                  </span>
                ))}
              </div>
            </div>
          </div>

          {this.state.errors && Object.keys(this.state.errors).length ? (
            <div>
              <div>
                <span
                  className="field-validation-error"
                  style={{ color: "red" }}
                >
                  {Validations.Resources.notAllFieldsAreValid}
                </span>
              </div>
              {Object.values(this.state.errors).map((errorValues) => {
                return (errorValues as any).map((err) => (
                  <span key={err} className="field-validation-error">
                    {err}
                  </span>
                ));
              })}
            </div>
          ) : (
            <></>
          )}

          <div className="form-line form-bottom-actions">
            <button
              className="btn-big btn-secondary"
              onClick={this.props.onCancelCallback}
            >
              {General.Resources.cancelButtonLabel}
            </button>
            <button
              className="btn-big btn-primary"
              onClick={this.handleOnSaveButtonClick}
            >
              {General.Resources.save}
            </button>
          </div>
        </div>
      );
    }
  }
}

export default connect(
  (state: ApplicationState, ownProps: IProps) => {
    return {
      ownProps,
      validator: ChangeTeamValidator,
      loading: state.teams.loading,
      teams: state.teams.teams,
    };
  },
  {
    getTeams: TeamsStore.actionCreators.getTeams,
  }
)(ChangeUserTeamForm as any);
