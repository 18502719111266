import bind from "bind-decorator";
import { BaseValidator } from "../Base/BaseValidator";
import { String_Format, nameof } from "../../../utils/utils";
import Validations from "../../../resources/Common/Validations";
import ChangeUserTeam from "../../../resources/AdminUI/Users/ChangeUserTeam";

export class ChangeTeamValidator extends BaseValidator {

    constructor() {
        super();
    }

    @bind
    validate(model: any, props: any) {

        this.check(() => model.newTeam != '' && model.newTeam != null, nameof(e => e.newTeam), String_Format(Validations.Resources.required, ChangeUserTeam.Resources.newTeamText))
        this.check(() => model.newTeam != model.currentTeam, nameof(e => e.newTeam), Validations.Resources.userTeam);
        
        return this.getErrorsDictionary();
    }
}