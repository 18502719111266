import { PayloadTypes } from "../enums/Common/PayloadTypes";
import { ISubscription } from "../models/Subscriptions/Interfaces/ISubscription";
import Result from "../models/Common/Result";
import { AppSettings } from "../utils/settings";
import { ServiceBase } from "./ServiceBase";
import SubscriptionItem from "../models/Subscriptions/SubscriptionItem";

export default class SubscriptionsService extends ServiceBase {
    
    static mapSubscriptionToSubscriptionItem = (subscription: any): SubscriptionItem => {
        var subscriptionItem = new SubscriptionItem(subscription);
        return subscriptionItem;
    }
    
    static deactivateSubscription = (subscriptionId: number): Promise<Result<any>> => {
        var result = SubscriptionsService.sendData<any>({
            method: "PUT",
            url: `${AppSettings.CommonSettings.ApiUrl}/subscriptions/${subscriptionId}/deactivate`,
            data: null
        }, PayloadTypes.Json);

        return result;
    }

    static addSubscription = (subscription: ISubscription): Promise<Result<ISubscription>> => {
        var result = SubscriptionsService.sendData<any>({
            method: "POST",
            url: `${AppSettings.CommonSettings.ApiUrl}/subscriptions`,
            data: JSON.stringify(subscription)
        }, PayloadTypes.Json);

        return result;
    }

    static editSubscription = (subscription: ISubscription): Promise<Result<ISubscription>> => {
        var result = SubscriptionsService.sendData<any>({
            method: "PUT",
            url: `${AppSettings.CommonSettings.ApiUrl}/subscriptions/${subscription.id}`,
            data: JSON.stringify(subscription)
        }, PayloadTypes.Json);

        return result;
    }
}