import Globals from "./../../Globals";

export default class Gallery {
  public browse: string = `Browse`;
  public dragAndDropPhotosPlaceInTheOrderYouLikeThemToAppear: string = `Drag & drop photos to place them in the order you'd like them to appear`;
  public dropYourImage: string = `Drop your image here or`;
  public noImages: string = `No images`;
  public photoOrder: string = `Photo order`;
  public showAllPhotos: string = `Show all photos`;
  public supportsJPGandPNGfiles: string = `Supports JPG, PNG files`;

  public static get Resources(): Gallery {
    switch (Globals.currentActiveLanguage) {
      default:
        return new Gallery();
    }
  }
}
