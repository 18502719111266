import React from "react";
import Workspaces from "../../../resources/AdminUI/Spaces/Workspaces";
import General from "../../../resources/AdminUI/General";

interface IProps {
  fullDayPrice: string;
  halfDayPrice: string;
  unredeemedPrice: string;
  currencyLabel: string;
  vat: string;
}

interface IState {}

export class SpacePricing extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className="header first">{Workspaces.Resources.VAT}</div>
        <p>
          {this.props.vat
            ? parseInt(this.props.vat) + "%"
            : General.Resources.notCompletedYet}
        </p>
        <div className="header first">{Workspaces.Resources.fullDay}</div>
        <p>
          {this.props.fullDayPrice
            ? parseInt(this.props.fullDayPrice) / 100 +
              " " +
              this.props.currencyLabel
            : General.Resources.notCompletedYet}
        </p>
        <div className="header">{Workspaces.Resources.halfDay}</div>
        <p>
          {this.props.halfDayPrice
            ? parseInt(this.props.halfDayPrice) / 100 +
              " " +
              this.props.currencyLabel
            : General.Resources.notCompletedYet}
        </p>
        <div className="header">{Workspaces.Resources.unredeemed}</div>
        <p>
          {this.props.unredeemedPrice
            ? parseInt(this.props.unredeemedPrice) / 100 +
              " " +
              this.props.currencyLabel
            : General.Resources.notCompletedYet}
        </p>
      </div>
    );
  }
}
