import bind from "bind-decorator";
import { BaseValidator } from "../Base/BaseValidator";
import { String_Format, nameof } from "../../../utils/utils";
import Validations from "../../../resources/Common/Validations";
import { BenefitBeneficiaries } from "../../../enums/Benefits/BenefitBeneficiaries";
import AssignBenefitToTeamOrUser from "../../../resources/AdminUI/Benefits/AssignBenefitToTeamOrUser";

export class AssignBenefitValidator extends BaseValidator {

    constructor() {
        super();
    }

    @bind
    validate(model: any, props: any) {

        this.check(() => model.benefitBeneficiary == BenefitBeneficiaries.Teams.toString() ? model.teamIds.length > 0 : true, nameof(e => e.teamIds), String_Format(Validations.Resources.required, AssignBenefitToTeamOrUser.Resources.chooseBeneficiaries));
        this.check(() => model.benefitBeneficiary == BenefitBeneficiaries.Users.toString() ? model.userIds.length > 0 : true, nameof(e => e.userIds), String_Format(Validations.Resources.required, AssignBenefitToTeamOrUser.Resources.chooseBeneficiaries));
  
        return this.getErrorsDictionary();
    }
}