import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { ISpaceItem } from "../models/Spaces/Interfaces/ISpaceItem";
import update from "immutability-helper";
import SpacesService from "../services/SpacesService";
import IPageIndex from "../models/Pagination/IPageIndex";
import { DefaultFilterValues } from "../enums/Common/DefaultFilterValues";

export namespace SpaceGridStore {

    export interface IState {
        spaces: ISpaceItem[],
        numberOfSpaces: number,

        loading: boolean,

        filters: string,
        searchTerm: string,
        singleSelectedCategory: string,
        singleSelectedStatus: string,
        singleSelectedCountry: string,
        singleSelectedCity: string,

        itemsPerPage: number;
        currentPage: number,
        firstIndexFromPage: number;
        lastIndexFromPage: number;
        pageIndexArray: IPageIndex[];
    }

    export enum Actions {
        ToggleLoading = "SPACES_TOGGLE_LOADING",
        Initialize = "SPACES_INITIALIZE",
        ReloadData = "SPACES_RELOAD_DATA",
        RecalculatePageArray = "SPACES_RECALCULATE_PAGE_ARRAY",
        RecalculateIndexes = "SPACES_RECALCULATE_INDEXES",
        ChangeCurrentPage = "SPACES_CHANGE_CURRENT_PAGE",
        ChangeFilterTerm = "SPACES_CHANGE_FILTER_TERM",

        ChangeSearchTerm = "SPACES_CHANGE_SEARCH_TERM",
        ChangeSingleSelectedCategory = "SPACES_CHANGE_SINGLE_SELECTED_CATEGORY",
        ChangeSingleSelectedStatus = "SPACES_CHANGE_SINGLE_SELECTED_STATUS",
        ChangeSingleSelectedCountry = "SPACES_CHANGE_SINGLE_SELECTED_COUNTRY",
        ChangeSingleSelectedCity = "SPACES_CHANGE_SINGLE_SELECTED_CITY",
    }

    interface IInitialize {
        type: Actions.Initialize;
        itemsPerPage: number,
    }

    interface IToggleLoading {
        type: Actions.ToggleLoading;
        loadingValue: boolean;
    }

    interface IReloadData {
        type: Actions.ReloadData,
        spaces: ISpaceItem[],
        numberOfSpaces: number;
    }

    interface IRecalculatePageArray {
        type: Actions.RecalculatePageArray,
    }

    interface IRecalculateIndexes {
        type: Actions.RecalculateIndexes
    }

    interface IChangeCurrentPage {
        type: Actions.ChangeCurrentPage,
        currentPage: number
    }

    interface IChangeFilterTerm {
        type: Actions.ChangeFilterTerm,
        filters: string
    }

    interface IChangeSearchTerm {
        type: Actions.ChangeSearchTerm,
        filterValue: string
    }

    interface IChangeSingleSelectedCategory {
        type: Actions.ChangeSingleSelectedCategory,
        filterValue: string
    }

    interface IChangeSingleSelectedStatus {
        type: Actions.ChangeSingleSelectedStatus,
        filterValue: string
    }

    interface IChangeSingleSelectedCountry {
        type: Actions.ChangeSingleSelectedCountry,
        filterValue: string
    }

    interface IChangeSingleSelectedCity {
        type: Actions.ChangeSingleSelectedCity,
        filterValue: string
    }

    type KnownAction = IToggleLoading |
        IInitialize | IReloadData | IRecalculatePageArray | IRecalculateIndexes
        | IChangeCurrentPage | IChangeFilterTerm
        | IChangeSearchTerm | IChangeSingleSelectedCategory | IChangeSingleSelectedStatus | IChangeSingleSelectedCountry | IChangeSingleSelectedCity;

    export const actionCreators = {

        initialize: (defaultSelectedItemsPerPageOption: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.Initialize, itemsPerPage: defaultSelectedItemsPerPageOption });
            dispatch({ type: Actions.ToggleLoading, loadingValue: true });

            var spaces = await SpacesService.getSpaces(defaultSelectedItemsPerPageOption, 0, '').then(res => res.value);
            var spacesMapped = SpacesService.additionalMapping(spaces);

            if (spacesMapped.entities.length != 0) {
                dispatch({ type: Actions.ReloadData, spaces: spacesMapped.entities, numberOfSpaces: spacesMapped.numberOfEntities });
            } else {
                dispatch({ type: Actions.ReloadData, spaces: [], numberOfSpaces: 0 });
            }

            dispatch({ type: Actions.ChangeCurrentPage, currentPage: 1 });
            dispatch({ type: Actions.RecalculatePageArray });
            dispatch({ type: Actions.RecalculateIndexes });
            dispatch({ type: Actions.ToggleLoading, loadingValue: false });
        },
        reload: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ToggleLoading, loadingValue: true });
            var take = getState().spaceGrid.itemsPerPage;
            var skip = (getState().spaceGrid.currentPage - 1) * getState().spaceGrid.itemsPerPage;
            var filters = getState().spaceGrid.filters;


            var spaces = await SpacesService.getSpaces(take, skip, filters).then(res => res.value);
            var spacesMapped = SpacesService.additionalMapping(spaces);

            dispatch({ type: Actions.ReloadData, spaces: spacesMapped.entities, numberOfSpaces: spacesMapped.numberOfEntities });
            dispatch({ type: Actions.ToggleLoading, loadingValue: false });
        },
        changeCurrentPage: (currentPage: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ChangeCurrentPage, currentPage });
            dispatch({ type: Actions.RecalculatePageArray });
            dispatch({ type: Actions.RecalculateIndexes });
        },
        setFilter: (filters: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ChangeFilterTerm, filters: filters });
        },
        setSearchTerm: (searchTerm: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ChangeSearchTerm, filterValue: searchTerm });
        },
        setSingleSelectedCategory: (singleSelectedCategory: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ChangeSingleSelectedCategory, filterValue: singleSelectedCategory });
        },
        setSingleSelectedStatus: (singleSelectedStatus: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ChangeSingleSelectedStatus, filterValue: singleSelectedStatus });
        },
        setSingleSelectedCountry: (singleSelectedCountry: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ChangeSingleSelectedCountry, filterValue: singleSelectedCountry });
            dispatch({ type: Actions.ChangeSingleSelectedCity, filterValue: DefaultFilterValues.All.toString() });
        },
        setSingleSelectedCity: (singleSelectedCity: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ChangeSingleSelectedCity, filterValue: singleSelectedCity });
        }
       
    }

    const initialState: IState = {
        loading: false,
        spaces: [],
        numberOfSpaces: 0,

        filters: '',
        searchTerm: "",
        singleSelectedCategory: "0",
        singleSelectedStatus: "0",
        singleSelectedCountry: "0",
        singleSelectedCity: "0",

        itemsPerPage: 15,
        currentPage: 1,
        firstIndexFromPage: 1,
        lastIndexFromPage: 1,
        pageIndexArray: [],
    }

    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case Actions.Initialize:
                return {
                    ...initialState,
                    itemsPerPage: action.itemsPerPage,
                };
            case Actions.ToggleLoading:
                return { ...currentState, loading: action.loadingValue }
            case Actions.ReloadData:
                return update(currentState,
                    {
                        spaces: { $set: action.spaces },
                        numberOfSpaces: { $set: action.numberOfSpaces }
                    });
            case Actions.ChangeCurrentPage:
                return update(currentState,
                    {
                        currentPage: { $set: action.currentPage }
                    });
            case Actions.RecalculatePageArray:
                var totalPages = Math.floor((currentState.numberOfSpaces - 1) / currentState.itemsPerPage) + 1;
                var pageArray = [];
                pageArray.push({ pageNumber: 1, isActive: currentState.currentPage === 1 } as IPageIndex);
                for (let i = 2; i <= totalPages; i++) {
                    pageArray.push({ pageNumber: i, isActive: currentState.currentPage === i });
                }
                return update(currentState,
                    {
                        pageIndexArray: { $set: pageArray }
                    });
            case Actions.RecalculateIndexes:
                var firstIndex = (currentState.currentPage - 1) * currentState.itemsPerPage + 1;
                var totalPages = currentState.pageIndexArray.length;
                var lastIndex: number;

                if (totalPages == currentState.currentPage)
                    lastIndex = Math.min(currentState.numberOfSpaces, currentState.itemsPerPage * totalPages);
                else
                    lastIndex = currentState.currentPage * currentState.itemsPerPage;
                return update(currentState,
                    {
                        firstIndexFromPage: { $set: firstIndex },
                        lastIndexFromPage: { $set: lastIndex },
                    });
            case Actions.ChangeFilterTerm:
                return update(currentState,
                    {
                        filters: { $set: action.filters }
                    });
            case Actions.ChangeSearchTerm:
                return update(currentState,
                    {
                        searchTerm: { $set: action.filterValue }
                    });
            case Actions.ChangeSingleSelectedStatus:
                return update(currentState,
                    {
                        singleSelectedStatus: { $set: action.filterValue }
                    });
            case Actions.ChangeSingleSelectedCategory:
                return update(currentState,
                    {
                        singleSelectedCategory: { $set: action.filterValue }
                    });
            case Actions.ChangeSingleSelectedCountry:
                return update(currentState,
                    {
                        singleSelectedCountry: { $set: action.filterValue }
                    });
            case Actions.ChangeSingleSelectedCity:
                return update(currentState,
                    {
                        singleSelectedCity: { $set: action.filterValue }
                    });

            default:
                return currentState || initialState;
        }


    }

}