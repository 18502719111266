import Globals from "./../../../Globals";

export default class ClientOffice {
       public addOfficeText: string = `Add office`;
       public clientOfficeDesc: string = `Create or see more details about the offices at {0}`;
       public customerOfficeHeader: string = `Customer office(s)`;
       public viewOfficeText: string = `View office details`;

		public static get Resources(): ClientOffice{
			switch (Globals.currentActiveLanguage) {
				default:
				return new ClientOffice();
		}
   	}
}
