import Globals from "./../../../Globals";

export default class SpaceStatusesList {
       public all: string = `Any status`;
       public available: string = `Available`;
       public deactivated: string = `Deactivated`;
       public draft: string = `Draft`;
       public unavailable: string = `Unavailable`;

		public static get Resources(): SpaceStatusesList{
			switch (Globals.currentActiveLanguage) {
				default:
				return new SpaceStatusesList();
		}
   	}
}
