import Globals from "./../../../Globals";

export default class SpaceCategoryList {
       public all: string = `All types of spaces`;
       public businessCentre: string = `Business Centre`;
       public cafe: string = `Cafe`;
       public companyOffice: string = `Company Office`;
       public coworking: string = `Coworking`;
       public hotel: string = `Hotel`;

		public static get Resources(): SpaceCategoryList{
			switch (Globals.currentActiveLanguage) {
				default:
				return new SpaceCategoryList();
		}
   	}
}
