import bind from "bind-decorator";
import React from "react";
import General from "../../resources/AdminUI/General";
import dayjs from "dayjs";
import { TimePicker } from "antd";
import WeekDays from "../../resources/AdminUI/WeekDays";
import AddOrEditWorkspaceForm from "../../resources/AdminUI/Spaces/AddOrEditWorkspaceForm";
import { IWorkspaceSchedule } from "../../models/Spaces/Interfaces/IWorkspaceSchedule";
import update from "immutability-helper";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { ScheduleFormValidtor } from "../FormValidations/Validators/ScheduleFormValidtor";
import { ValidatedComponent } from "../FormValidations/Base/ValidatedComponent";
import Validations from "../../resources/Common/Validations";

export interface IProps {
  schedule: IWorkspaceSchedule[];
  additionalInofrmation?: string;
  onSaveCallback?: (schedule: IWorkspaceSchedule[]) => void;
  onCloseCallback?: () => void;
}

export interface IState {
  schedule: IWorkspaceSchedule[];
}

class ScheduleForm extends ValidatedComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      schedule: this.props.schedule,
    };
  }

  @bind
  handleChange(
    index: number,
    openHour: string,
    closeHour: string,
    isClosed: boolean
  ) {
    let newSchedule = update(this.state.schedule, {
      [index]: {
        isClosed: { $set: isClosed },
        openHour: { $set: openHour },
        closeHour: { $set: closeHour },
      },
    });

    this.setState({ schedule: newSchedule });
  }

  @bind
  copyScheduleToAllDays() {
    const newSchedule = new Array(7).fill(undefined)?.map((e, i) => {
      return {
        openHour: this.state.schedule[0]?.openHour,
        closeHour: this.state.schedule[0]?.closeHour,
        isClosed: this.state.schedule[0]?.isClosed,
      } as IWorkspaceSchedule;
    });

    this.setState({ schedule: newSchedule }, () => {
      this.onSave();
    });
  }

  @bind
  onSave() {
    this.validate(() => {
      this.props.onSaveCallback(this.state.schedule);
      this.props.onCloseCallback();
    });
  }
  @bind
  onCancel() {
    this.props.onCloseCallback();
  }

  @bind
  getDayName(index: number) {
    switch (index) {
      case 0:
        return WeekDays.Resources.monday;
      case 1:
        return WeekDays.Resources.tuesday;
      case 2:
        return WeekDays.Resources.wednesday;
      case 3:
        return WeekDays.Resources.thursday;
      case 4:
        return WeekDays.Resources.friday;
      case 5:
        return WeekDays.Resources.saturday;
      case 6:
        return WeekDays.Resources.sunday;
    }
  }

  render() {
    return (
      <div>
        <label>{this.props.additionalInofrmation}</label>
        <div>
          <button
            className="btn-text btn-schedule-copy"
            onClick={this.copyScheduleToAllDays}
          >
            Copy Monday’s schedule to all days
          </button>
        </div>

        {this.state?.schedule?.length > 0 &&
          this.state?.schedule?.map((e, i) => {
            return (
              <DayOfWeekForm
                key={i}
                index={i}
                name={this.getDayName(i)}
                isClosed={e.isClosed}
                startValue={e.openHour}
                endValue={e.closeHour}
                onChange={this.handleChange}
                errors={this.getFieldErrors(i.toString())}
              ></DayOfWeekForm>
            );
          })}

        {this.state.errors && Object.keys(this.state.errors).length ? (
          <div>
            <div>
              <span className="field-validation-error" style={{ color: "red" }}>
                {Validations.Resources.notAllFieldsAreValid}
              </span>
            </div>
            {Object.values(this.state.errors).map((errorValues) => {
              return (errorValues as any).map((err) => (
                <span key={err} className="field-validation-error">
                  {err}
                </span>
              ));
            })}
          </div>
        ) : (
          <></>
        )}

        <div className="modal-bottom-buttons">
          <button className="btn-big btn-primary" onClick={() => this.onSave()}>
            {General.Resources.save}
          </button>
          <button
            className="btn-big btn-secondary"
            onClick={() => this.onCancel()}
          >
            {General.Resources.cancelButtonLabel}
          </button>
        </div>
      </div>
    );
  }
}

interface IDayOfWeekProps {
  index: number;
  name: string;
  isClosed: boolean;
  startValue: any;
  endValue: any;
  errors: string[];
  onChange: (
    index: number,
    openHour: string,
    closeHour: string,
    isClosed: boolean
  ) => void;
}

class DayOfWeekForm extends React.PureComponent<IDayOfWeekProps, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="form-line">
        <div className="line-info">
          <div className="title in-modal">{this.props.name}</div>
        </div>

        <div className="line-action">
          <TimePicker
            placeholder={General.Resources.select}
            value={
              this.props.startValue
                ? dayjs(this.props.startValue, "HH:mm")
                : null
            }
            format={"HH:mm"}
            disabled={this.props.isClosed}
            onChange={(value, timeString) => {
              this.props.onChange(
                this.props.index,
                timeString,
                this.props.endValue,
                this.props.isClosed
              );
            }}
          />
          <TimePicker
            placeholder={General.Resources.select}
            value={
              this.props.endValue ? dayjs(this.props.endValue, "HH:mm") : null
            }
            format={"HH:mm"}
            disabled={this.props.isClosed}
            onChange={(value, timeString) => {
              this.props.onChange(
                this.props.index,
                this.props.startValue,
                timeString,
                this.props.isClosed
              );
            }}
          />
          <input
            type="checkbox"
            checked={this.props.isClosed}
            onChange={(event) => {
              this.props.onChange(
                this.props.index,
                this.props.startValue,
                this.props.endValue,
                event.target.checked
              );
            }}
          />
          <label>{AddOrEditWorkspaceForm.Resources.spaceIsClosed}</label>
          <div>
            {this.props.errors.map((err) => (
              <span key={err} className="field-validation-error">
                {err}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state: ApplicationState, ownProps: IProps) => {
  return {
    validator: ScheduleFormValidtor,
    ...ownProps,
  };
}, null)(ScheduleForm as any);
