import React from "react";
import { String_Format } from "../../../utils/utils";
import ClientDetails from "../../../resources/AdminUI/Clients/ClientDetails";
import { IClientDetails } from "../../../models/Clients/Interfaces/IClientDetails";
import ClientStateList from "../../../resources/AdminUI/Clients/ClientStateList";
import { formatDate } from "../../../utils/date";

interface IProps {
    client: IClientDetails
}

export default class ClientGeneralDetails extends React.PureComponent<IProps, any> {
    constructor(props) {
        super(props);
    }

    render() {
        return <div>
            <div className="item-general-info">
                <h2 className="item-name">{this.props.client.name}</h2>

                <div className="db_info">
                    <p className="status">{this.props.client.deactivated ? ClientStateList.Resources.deactivated : ClientStateList.Resources.active} </p>
                    <p>| {String_Format(ClientDetails.Resources.customerIdLabel, this.props.client.id)}</p>
                    <p>{this.props.client.countryName} | {String_Format(ClientDetails.Resources.createdOnLabel, formatDate(this.props.client.createdAt))}</p>
                </div>



                <div className="info-subsection">
                    <div className="header">{ClientDetails.Resources.planLabel}</div>
                    <div>{ClientDetails.Resources.enterprisePlanLabel}</div>
                </div>

                <div className="info-subsection">
                    <div className="header">{ClientDetails.Resources.emailDomainsLabel}</div>
                    {this.props.client.emailDomains.length > 0 ?
                        <div>
                            <ul>
                                {
                                    this.props.client.emailDomains.map(domain =>
                                        <li key={domain}>
                                            {domain}
                                        </li>
                                    )
                                }
                            </ul>
                        </div> :
                        <div> - </div>
                    }

                </div>
            </div>
        </div>;
    }
}