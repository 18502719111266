import bind from "bind-decorator";
import React from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ApplicationState } from "../../store";
import {
  String_Format,
  firstLetterToLowerCase,
  getPromiseFromAction,
} from "../../utils/utils";
import { DefaultFilterValues } from "../../enums/Common/DefaultFilterValues";
import { LookupStore } from "../../store/LookupStore";
import RegularExpressions from "../../resources/Common/RegularExpressions";
import { HotdeskBookingsGridStore } from "../../store/HotdeskBookingsGridStore";
import HotdeskBookingsGridPageTable from "../../components/HotdeskBookings/HotdeskBookingsTable";
import { HotdeskBookingStatuses } from "../../enums/HotdeskBookings/HotdeskBookingStatuses";
import HotdeskBookingStatusTypes from "../../resources/AdminUI/HotdeskBookings/HotdeskBookingStatusTypes";
import { DatePicker, Select } from "antd";
import HotdeskBookingsGrid from "../../resources/AdminUI/HotdeskBookings/HotdeskBookingsGrid";
import { ApplicationPaths } from "../../enums/Common/ApplicationPaths";
import HotdeskBookingsService from "../../services/HotdeskBookingsService";
import { LocalDateTime } from "@js-joda/core";
import General from "../../resources/AdminUI/General";
import { Link, NavigateFunction } from "react-router-dom";
import { IAntdSelectListItem } from "../../models/Common/IAntdSelectListItem";
import dayjs from "dayjs";
import { withRouter } from "../../components/Shared/withRouter";
import Loading from "../../components/Shared/Loading";
const { RangePicker } = DatePicker;

interface IProps {
  filters: string;
  searchTerm: string;
  singleSelectedStatus: string;
  singleSelectedCountry: string;
  singleSelectedCity: string;
  startDateSearchTemplate: number;
  endDateSearchTemplate: number;
  countries: IAntdSelectListItem[];
  cities: IAntdSelectListItem[];

  navigate: NavigateFunction;

  initialize: (defaultSelectedItemsPerPageOption: number) => void;
  reload: () => Promise<any>;
  changeCurrentPage: (currentPage: number) => void;
  setFilters: (filters: string) => void;
  setSearchTerm: (searchTerm: string) => void;
  setSingleSelectedStatus: (singleSelectedStatus: string) => void;
  setSingleSelectedCountry: (singleSelectedCountry: string) => void;
  setSingleSelectedCity: (singleSelectedCity: string) => void;
  setStartDateSearchTemplateAndEndDateSearchTemplate: (
    startDateSearchTemplate: number,
    endDateSearchTemplate: number
  ) => void;
}

class HotdeskBookingsGridPage extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    this.state = {};
    this.props.getCountriesWithCities(true);
    this.props.getCities(true);
  }

  nameSearchTemplate = `contains(tolower(UserCompanyName), tolower('{0}')) or contains(concat(concat(tolower(UserFirstName), ' '), tolower(UserLastName)), tolower('{0}')) or contains(tolower(WorkspaceName), tolower('{0}'))`;
  idSearchTemplate = ` or Id eq {0} or contains(tolower(BookingGroupId), tolower('{0}'))`;
  statusFilterTemplate = `StatusId eq {0}`;
  countryFilterTemplate = `WorkspaceCountryId eq {0}`;
  cityFilterTemplate = `WorkspaceCityId eq {0}`;
  startDateFilterTemplate = `UnixBookingAtWithTimezone eq {0}`;
  startAndEndDateFilterTemplate = `UnixBookingAtWithTimezone ge {0} and UnixBookingAtWithTimezone le {1}`;
  dateFormat = "DD-MM-YYYY";

  @bind
  rebuildODataAndGetData() {
    var filters = [];
    if (this.props.searchTerm) {
      var searchFilter = String_Format(
        this.nameSearchTemplate,
        this.props.searchTerm
      );
      var pattern = RegularExpressions.Resources.onlyNumbers;
      if (RegExp(pattern).test(this.props.searchTerm)) {
        searchFilter = searchFilter.concat(
          String_Format(this.idSearchTemplate, this.props.searchTerm)
        );
      }
      filters.push(`(${searchFilter})`);
    }
    if (
      this.props.singleSelectedStatus &&
      this.props.singleSelectedStatus != DefaultFilterValues.All.toString()
    ) {
      filters.push(
        `(${String_Format(
          this.statusFilterTemplate,
          this.props.singleSelectedStatus
        )})`
      );
    }

    if (
      this.props.singleSelectedCountry &&
      this.props.singleSelectedCountry != DefaultFilterValues.All.toString()
    ) {
      filters.push(
        `(${String_Format(
          this.countryFilterTemplate,
          this.props.singleSelectedCountry
        )})`
      );
    }

    if (
      this.props.singleSelectedCity &&
      this.props.singleSelectedCity != DefaultFilterValues.All.toString()
    ) {
      filters.push(
        `(${String_Format(
          this.cityFilterTemplate,
          this.props.singleSelectedCity
        )})`
      );
    }

    if (
      this.props.startDateSearchTemplate &&
      this.props.endDateSearchTemplate
    ) {
      if (
        this.props.startDateSearchTemplate == this.props.endDateSearchTemplate
      ) {
        var startDatefilter = String_Format(
          this.startDateFilterTemplate,
          this.props.startDateSearchTemplate
        );
        filters.push(`(${startDatefilter})`);
      } else {
        var startAndEndDatefilter = String_Format(
          this.startAndEndDateFilterTemplate,
          this.props.startDateSearchTemplate,
          this.props.endDateSearchTemplate
        );
        filters.push(`(${startAndEndDatefilter})`);
      }
    }

    var oDataFilter = filters.join(" and ");
    this.props.setFilters(oDataFilter);
    this.props.changeCurrentPage(1);
    getPromiseFromAction(this.props.reload()).then(() =>
      this.props.changeCurrentPage(1)
    );
  }

  @bind
  onSearchFilterChange(e: React.FormEvent<HTMLInputElement>) {
    let searchTerm = e.currentTarget.value;
    if (this.props.searchTerm != searchTerm) {
      getPromiseFromAction(this.props.setSearchTerm(searchTerm)).then(() => {
        this.rebuildODataAndGetData();
      });
    }
  }

  @bind
  onStatusFilterChange(value: string, option: any) {
    getPromiseFromAction(this.props.setSingleSelectedStatus(value)).then(() => {
      this.rebuildODataAndGetData();
    });
  }

  @bind
  onCountryFilterChange(value: string, option: any) {
    getPromiseFromAction(this.props.setSingleSelectedCountry(value)).then(
      () => {
        this.rebuildODataAndGetData();
      }
    );
  }

  @bind
  onCityFilterChange(value: string, values: any) {
    getPromiseFromAction(this.props.setSingleSelectedCity(value)).then(() => {
      this.rebuildODataAndGetData();
    });
  }

  @bind
  onDateChange(dates: [any, any], dateStrings: [string, string]) {
    if (dateStrings[0] == dateStrings[1]) {
      var startDate = dateStrings[0].split("-").reverse().join("-");
      var startDateSearchTemplate = parseInt(
        (new Date(startDate).getTime() / 1000).toFixed(0)
      );
      getPromiseFromAction(
        this.props.setStartDateSearchTemplateAndEndDateSearchTemplate(
          startDateSearchTemplate,
          startDateSearchTemplate
        )
      ).then(() => {
        this.rebuildODataAndGetData();
      });
    } else {
      var startDate = dateStrings[0].split("-").reverse().join("-");
      var endDate = dateStrings[1].split("-").reverse().join("-");
      var startDateSearchTemplate = parseInt(
        (new Date(startDate).getTime() / 1000).toFixed(0)
      );
      var endDateSearchTemplate = parseInt(
        (new Date(endDate).getTime() / 1000).toFixed(0)
      );
      getPromiseFromAction(
        this.props.setStartDateSearchTemplateAndEndDateSearchTemplate(
          startDateSearchTemplate,
          endDateSearchTemplate
        )
      ).then(() => {
        this.rebuildODataAndGetData();
      });
    }
  }

  @bind
  getAllHodeskStatuses() {
    var options = [];
    let keys = Object.keys(HotdeskBookingStatuses).filter(
      (k) => typeof HotdeskBookingStatuses[k as any] === "number"
    );

    keys.forEach((key) => {
      let value = HotdeskBookingStatuses[key as any].toString();
      options.push({
        value: value,
        label: HotdeskBookingStatusTypes.Resources[firstLetterToLowerCase(key)],
      });
    });
    return options;
  }

  @bind
  async exportLogs() {
    await HotdeskBookingsService.getHotdeskBookingsForExport(
      this.props.filters
    ).then((res) => {
      var entities = res.value
        ? res.value.entities
            .map((e) =>
              Object.entries(e)
                .filter(
                  ([key]) =>
                    key != "unixBookingAtWithTimezone" &&
                    key != "timezone" &&
                    key != "workspaceCountryFlag"
                )
                .map(([key, value]) =>
                  typeof value === "string" ? value.replaceAll(",", " ") : value
                )
                .join(",")
            )
            .join("\n")
        : "";
      const csvContent =
        HotdeskBookingsGrid.Resources.exportHotdeskBookingLogsHeader +
        "\n" +
        entities;
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        HotdeskBookingsGrid.Resources.desksLogs +
          "_" +
          LocalDateTime.now() +
          HotdeskBookingsGrid.Resources.csvExtension
      );
      document.body.appendChild(link);

      link.click();
    });
  }

  @bind
  addHotdeskLog() {
    this.props.navigate(ApplicationPaths.AddHotdeskBooking);
  }

  render() {
    if (!this.props.countries || !this.props.cities) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    return (
      <div>
        <Helmet title={HotdeskBookingsGrid.Resources.desksLogs} />

        <div className="page-top-container">
          <div className="page-title">
            {HotdeskBookingsGrid.Resources.desksLogs}
          </div>
          <div className="page-actions">
            <div className="btn-big btn-secondary" onClick={this.exportLogs}>
              <p className="btn-secondary-text">
                {HotdeskBookingsGrid.Resources.exportDesksLogsCsv}
              </p>
            </div>

            <Link
              className="btn-big btn-primary"
              to={ApplicationPaths.AddHotdeskBooking}
            >
              <span className="material-symbols-outlined">add_circle</span>
              <p className="btn-primary-text">
                {HotdeskBookingsGrid.Resources.addDeskLog}
              </p>
            </Link>
          </div>
        </div>

        <div className="filter-bar">
          <div className="filter-search">
            <div className="input-group">
              <span className="icon material-symbols-outlined">search</span>
              <input
                type="text"
                className="form-control"
                placeholder={
                  HotdeskBookingsGrid.Resources
                    .searchByLogIdUserNameOrClientName
                }
                onChange={this.onSearchFilterChange}
                name="searchTerm"
                value={this.props.searchTerm}
              />
            </div>
          </div>

          <div className="filter-item">
            <div className="input-group">
              <Select
                options={this.props.countries}
                onChange={this.onCountryFilterChange}
                value={this.props.singleSelectedCountry}
                showSearch
                optionFilterProp="label"
              />
            </div>
          </div>
          <div className="filter-item">
            <div className="input-group">
              <Select
                options={
                  this.props.singleSelectedCountry !=
                  DefaultFilterValues.All.toString()
                    ? this.props.cities.filter(
                        (e) =>
                          e.group == this.props.singleSelectedCountry ||
                          e.value == DefaultFilterValues.All.toString()
                      )
                    : this.props.cities
                }
                onChange={this.onCityFilterChange}
                value={this.props.singleSelectedCity.toString()}
                showSearch
                optionFilterProp="label"
              />
            </div>
          </div>
          <div className="filter-item">
            <div className="input-group">
              <Select
                options={this.getAllHodeskStatuses()}
                onChange={this.onStatusFilterChange}
                value={this.props.singleSelectedStatus}
              ></Select>
            </div>
          </div>

          <RangePicker
            value={[
              this.props.startDateSearchTemplate
                ? dayjs.unix(this.props.startDateSearchTemplate)
                : this.props.startDateSearchTemplate,
              this.props.endDateSearchTemplate
                ? dayjs.unix(this.props.endDateSearchTemplate)
                : this.props.endDateSearchTemplate,
            ]}
            format={this.dateFormat}
            onChange={this.onDateChange}
          />
        </div>

        <HotdeskBookingsGridPageTable
          hiddenColumns={[
            "userEmail",
            "userPhone",
            "statusName",
            "userFirstName",
            "userLastName",
            "userCompanyName",
            "workspaceName",
            "workspaceCategoryId",
            "workspaceCityId",
            "workspaceCityName",
            "workspaceCountryId",
            "workspaceCountryName",
            "workspaceCountryFlag",
            "timeSpent",
            "isFullDay",
            "createdAt",
            "checkinAt",
            "checkoutAt",
            "expiresAt",
            "cancelledAt",
            "bookingAt",
            "timezone",
            "isWalkin",
            "isAutomatedCheckout",
            "unixBookingAtWithTimezone",
            "bookingGroupId",
          ]}
          expandableColumns={["createdAt"]}
          filters={this.props.filters}
          showActionsColumn={true}
          componentId={componentId}
        />
      </div>
    );
  }
}

const componentId = "HotdeskBookingGridPage";

export default withRouter(
  connect(
    (state: ApplicationState) => {
      const compoentState = state.hotdeskBookingsGrid[componentId];
      return {
        filters: compoentState?.filters || "",
        searchTerm: compoentState?.searchTerm || "",
        singleSelectedStatus: compoentState?.singleSelectedStatus || "0",
        singleSelectedCountry: compoentState?.singleSelectedCountry || "0",
        singleSelectedCity: compoentState?.singleSelectedCity || "0",
        startDateSearchTemplate: compoentState?.startDateSearchTemplate || null,
        endDateSearchTemplate: compoentState?.endDateSearchTemplate || null,

        countries: state.lookup.countries,
        cities: state.lookup.cities,
      };
    },
    (dispatch) =>
      bindActionCreators(
        {
          ...HotdeskBookingsGridStore.getActionCreators(componentId),
          getCountriesWithCities:
            LookupStore.actionCreators.getCountriesWithCities,
          getCities: LookupStore.actionCreators.getCities,
        },
        dispatch
      )
  )(HotdeskBookingsGridPage)
);
