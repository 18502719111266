import React from "react";
import { connect } from "react-redux";
import bind from "bind-decorator";
import { bindActionCreators } from "redux";

import { TabStore } from "../../store/TabStore";
import { ApplicationState } from "../../store";
import TabsHeader from "./TabsHeader";
import { ITabItem, ITabTitleItem } from "../../utils/reactUtils";
import General from "../../resources/AdminUI/General";
import { Languages } from "../../enums/Common/Languages";
import { TextareaFormTab } from "./TextareaFormTab";

class DescriptionTextareaFormTabsComponent extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      descriptionEn: this.props.textEn,
      descriptionEs: this.props.textEs,
      descriptionPt: this.props.textPt,
    };

    var tabs = [
      {
        tabId: Languages.English.toString(),
        title: General.Resources.englishDefault,
        content: () => (
          <TextareaFormTab
            key={"description-en"}
            text={this.state.descriptionEn}
            name={this.props.nameEn}
            placeholder={this.props.placeholderEn}
            onChangeCallback={this.onHandleChange}
          />
        ),
      } as ITabItem,
      {
        tabId: Languages.Spanish.toString(),
        title: General.Resources.spanish,
        content: () => (
          <TextareaFormTab
            key={"description-sp"}
            text={this.state.descriptionEs}
            name={this.props.nameEs}
            placeholder={this.props.placeholderEs}
            onChangeCallback={this.onHandleChange}
          />
        ),
      } as ITabItem,
      {
        tabId: Languages.Portuguese.toString(),
        title: General.Resources.portuguese,
        content: () => (
          <TextareaFormTab
            key={"description-pt"}
            text={this.state.descriptionPt}
            name={this.props.namePt}
            placeholder={this.props.placeholderPt}
            onChangeCallback={this.onHandleChange}
          />
        ),
      } as ITabItem,
    ];

    this.props.initializeTabPage(tabs, Languages.English.toString(), null);
  }

  @bind
  onHandleChange(name, value) {
    this.setState({
      [name]: value,
    });
    this.props.onChangeCallback(name, value);
  }

  render() {
    {
      if (this.props.tabState == undefined) {
        return (
          <div>
            <h2 className="text-center">{General.Resources.loading}</h2>
          </div>
        );
      }
    }
    var activeTab = this.props.tabState.tabs.filter(
      (t) => t.tabId == this.props.tabState.activeTabId
    )[0];
    return (
      <div>
        <TabsHeader
          onTabClick={this.props.changeActiveTab}
          activeTabId={activeTab.tabId}
          className={"form-tabs"}
          tabs={this.props.tabState.tabs.map((t) => {
            return {
              title: t.title,
              tabId: t.tabId,
              class: t.class,
            } as ITabTitleItem;
          })}
        />
        <div className="form-item">{activeTab.content()}</div>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState, ownProps: any) => {
    return { tabState: state.tab[ownProps.componentId] };
  },
  (dispatch, ownProps: any) =>
    bindActionCreators(
      {
        ...TabStore.getActionCreators(ownProps.componentId),
      },
      dispatch
    )
)(DescriptionTextareaFormTabsComponent);
