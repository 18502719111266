import React, { useState } from "react";
import { Select } from "antd";
import { connect } from "react-redux";
import General from "../../resources/AdminUI/General";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import { ISelectListItem } from "../../models/Common/ISelectListItem";
import { ApplicationState } from "../../store";
import { DeclineRoomBookingConfirmationValidator } from "../FormValidations/Validators/DeclineRoomBookingConfirmationValidator";

import { useRoomBookingsQueries } from "../../hooks/RoomBookings/useRoomBookingsQueries";

interface IProps {
  reasons: ISelectListItem[];
  id: number;
  onCancelCallback: () => void;
}

const DeclineRoomBookingConfirmationModal: React.FC<IProps> = ({
  reasons,
  id,
  onCancelCallback,
}) => {
  const [reasonId, setReasonId] = useState<string | undefined>(undefined);

  const { rejectRoomBooking } = useRoomBookingsQueries();

  const { mutate: rejectBooking } = rejectRoomBooking();

  const handleSave = () => {
    if (reasonId) {
      rejectBooking({ id, reasonId });
      onCancelCallback();
    }
  };

  const handleInputChange = (value: string) => {
    setReasonId(value);
  };

  return (
    <div className="modal-container">
      <div className="modal-title">
        {RoomBookingsGrid.Resources.declineRoomRequestConfirmationMessage}
      </div>

      <div className="form-line">
        <div className="line-info">
          <div className="title in-modal">
            {RoomBookingsGrid.Resources.reasons}
            <span className="mandatory">*</span>
          </div>
        </div>
        <div className="line-action">
          <div className="input-group form-select">
            <Select
              options={reasons}
              onChange={(value) => handleInputChange(value as string)}
              value={reasonId?.toString()}
              placeholder={General.Resources.select}
            />
          </div>
        </div>
      </div>

      <div className="form-line form-bottom-actions">
        <button className="btn-big btn-secondary" onClick={onCancelCallback}>
          {General.Resources.cancelButtonLabel}
        </button>
        <button className="btn-big btn-primary" onClick={handleSave}>
          {RoomBookingsGrid.Resources.decline}
        </button>
      </div>
    </div>
  );
};

export default connect((state: ApplicationState, ownProps: IProps) => ({
  validator: DeclineRoomBookingConfirmationValidator,
  ...ownProps,
}))(DeclineRoomBookingConfirmationModal as any);
