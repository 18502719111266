import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import update from "immutability-helper";
import { getServerSideErrors } from "../utils/utils";
import { IWorkspaceSchedule } from "../models/Spaces/Interfaces/IWorkspaceSchedule";
import LookupService from "../services/LookupService";
import { ISelectListItemForAmenity } from "../models/Common/ISelectListItemForAmenity";
import RoomsService from "../services/RoomsService";
import SpacesService from "../services/SpacesService";

export namespace AddOrEditRoomStore {
  export interface IState {
    loading: boolean;

    id: number;
    name?: string;
    externalBookingProviderRefId?: string;
    roomTypeId?: string;
    workspaceId?: number;

    descriptionEn?: string;
    descriptionEs?: string;
    descriptionPt?: string;
    email?: string;
    phone?: string;

    schedule?: IWorkspaceSchedule[];
    workspaceSchedule?: IWorkspaceSchedule[];
    images: string[];
    amenities: ISelectListItemForAmenity[];
    isWorkspaceScheduleUsed?: boolean;

    maxGuests?: number;
    minHourlyBookingAmount?: number;
    minDailyBookingAmount?: number;

    hourPrice?: number;
    dayPrice?: number;
    monthPrice?: number;

    hasServerSideErrors: boolean;
    errors: string;
  }

  export enum Actions {
    ToggleLoading = "ADD_OR_EDIT_ROOM_TOGGLE_LOADING",
    Initialize = "ADD_OR_EDIT_ROOM_INITIALIZE",
    SetRoomDetails = "ADD_OR_EDIT_ROOM_SET_ROOM_DETAILS",
    SetRoomImages = "ADD_OR_EDIT_ROOM_SET_ROOM_IMAGES",
    SetRoomSchedule = "ADD_OR_EDIT_ROOM_SET_ROOM_SCHEDULE",
    SetRoomWorkspaceSchedule = "ADD_OR_EDIT_ROOM_SET_ROOM_WORKSPACE_SCHEDULE",
    SetRoomAmenities = "ADD_OR_EDIT_ROOM_SET_ROOM_AMENITIES",
    SaveRoomDetails = "ADD_OR_EDIT_ROOM_SAVE_ROOM_DETAILS",
    SetErrors = "ADD_OR_EDIT_ROOM_SET_ERRORS",
  }

  interface IInitialize {
    type: Actions.Initialize;
  }

  interface IToggleLoading {
    type: Actions.ToggleLoading;
    loading: boolean;
  }

  interface ISetRoomDetails {
    type: Actions.SetRoomDetails;
    id: number;
    roomDetails: any;
  }

  interface ISetRoomImages {
    type: Actions.SetRoomImages;
    images: string[];
  }

  interface ISetRoomSchedule {
    type: Actions.SetRoomSchedule;
    schedule: IWorkspaceSchedule[];
  }

  interface ISetRoomWorkspaceSchedule {
    type: Actions.SetRoomWorkspaceSchedule;
    workspaceSchedule: IWorkspaceSchedule[];
  }

  interface ISetRoomAmenities {
    type: Actions.SetRoomAmenities;
    amenities: ISelectListItemForAmenity[];
  }

  interface ISetErrors {
    type: Actions.SetErrors;
    hasServerSideErrors: boolean;
    errors: string;
  }

  type KnownAction =
    | IToggleLoading
    | IInitialize
    | ISetRoomDetails
    | ISetRoomImages
    | ISetRoomSchedule
    | ISetRoomWorkspaceSchedule
    | ISetErrors
    | ISetRoomAmenities;

  export const actionCreators = {
    initializeCreatePage:
      (workspaceId: number): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({ type: Actions.ToggleLoading, loading: true });
        dispatch({ type: Actions.Initialize });
        var workspaceSchedule = await SpacesService.getWorkspaceSchedule(
          workspaceId
        ).then((response) => response.value);
        dispatch({
          type: Actions.SetRoomWorkspaceSchedule,
          workspaceSchedule: workspaceSchedule,
        });
        dispatch({ type: Actions.ToggleLoading, loading: false });
      },

    initializeEditPage:
      (id: number): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({ type: Actions.ToggleLoading, loading: true });

        var details = await RoomsService.getRoomDetailsForEdit(id).then(
          (response) => response.value
        );

        dispatch({
          type: Actions.SetRoomDetails,
          id: id,
          roomDetails: details,
        });

        dispatch({
          type: Actions.SetRoomWorkspaceSchedule,
          workspaceSchedule: details.workspaceSchedule,
        });

        dispatch({ type: Actions.ToggleLoading, loading: false });
      },

    setRoomImages:
      (images: string[]): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({
          type: Actions.SetRoomImages,
          images: images,
        });
      },

    setRoomSchedule:
      (schedule: IWorkspaceSchedule[]): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({
          type: Actions.SetRoomSchedule,
          schedule: schedule,
        });
      },

    setWorkspaceSchedule:
      (workspaceSchedule: IWorkspaceSchedule[]): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({
          type: Actions.SetRoomWorkspaceSchedule,
          workspaceSchedule: workspaceSchedule,
        });
      },

    setRoomAmenities:
      (amenities: ISelectListItemForAmenity[]): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({
          type: Actions.SetRoomAmenities,
          amenities: amenities,
        });
      },

    getRoomAmenities:
      (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        var amenities = await LookupService.getAllAmenities().then(
          (res) => res.value
        );
        dispatch({
          type: Actions.SetRoomAmenities,
          amenities: amenities,
        });
      },

    saveDetails:
      (
        id: number,
        workspaceId: number,
        name?: string,
        externalBookingProviderRefId?: string,
        roomTypeId?: string,
        descriptionEn?: string,
        descriptionEs?: string,
        descriptionPt?: string,
        email?: string,
        phone?: string,
        maxGuests?: number,
        minHourlyBookingAmount?: number,
        minDailyBookingAmount?: number,
        hourPrice?: number,
        dayPrice?: number,
        monthPrice?: number,
        isWorkspaceScheduleUsed?: boolean
      ): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        var state = getState().addOrEditRoom;
        var schedule = state.schedule?.map((e, i) => {
          e = e.isClosed == true ? null : e;
          return e;
        });

        var details = {
          name: name,
          externalBookingProviderRefId: externalBookingProviderRefId,
          workspaceId: workspaceId,
          roomTypeId: roomTypeId,
          descriptionEn: !descriptionEn ? null : descriptionEn,
          descriptionEs: !descriptionEs ? null : descriptionEs,
          descriptionPt: !descriptionPt ? null : descriptionPt,
          email: !email ? null : email,
          phone: !phone ? null : phone,
          maxGuests: maxGuests,
          bookingRestrictions: {
            minHourlyBookingAmount: !minHourlyBookingAmount
              ? null
              : minHourlyBookingAmount,
            minDailyBookingAmount: !minDailyBookingAmount
              ? null
              : minDailyBookingAmount,
          },
          hourPrice: hourPrice,
          dayPrice: dayPrice,
          monthPrice: monthPrice,
          images: state.images,
          schedule: schedule,
          amenities: state.amenities,
          isWorkspaceScheduleUsed: isWorkspaceScheduleUsed,
        };
        dispatch({
          type: Actions.SetRoomDetails,
          id: id,
          roomDetails: details,
        });

        var serverResponse;
        if (id == null) {
          serverResponse = await RoomsService.addRoom(details).then(
            (response) => response
          );
        } else {
          serverResponse = await RoomsService.updateRoom(id, details).then(
            (response) => response
          );
        }

        var errors = getServerSideErrors(serverResponse);
        var hasServerSideErrors = !errors ? false : true;
        dispatch({
          type: Actions.SetErrors,
          hasServerSideErrors: hasServerSideErrors,
          errors: errors,
        });
      },
  };

  const initialState: IState = {
    loading: false,

    id: null,
    name: "",
    roomTypeId: "",
    externalBookingProviderRefId: "",
    descriptionEn: "",
    descriptionEs: "",
    descriptionPt: "",
    email: "",
    phone: "",

    schedule: new Array(7).fill(undefined).map((e, i) => {
      return {
        openHour: null,
        closeHour: null,
        isClosed: true,
      } as IWorkspaceSchedule;
    }),
    workspaceSchedule: new Array(7).fill(undefined).map((e, i) => {
      return {
        openHour: null,
        closeHour: null,
        isClosed: true,
      } as IWorkspaceSchedule;
    }),
    images: [],
    amenities: [],
    isWorkspaceScheduleUsed: true,

    maxGuests: null,
    minHourlyBookingAmount: null,
    minDailyBookingAmount: null,

    hourPrice: null,
    dayPrice: null,
    monthPrice: null,

    hasServerSideErrors: null,
    errors: null,
  };

  export const reducer: Reducer<IState> = (
    currentState: IState,
    incomingAction: Action
  ) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
      case Actions.Initialize:
        return initialState;
      case Actions.ToggleLoading:
        return { ...currentState, loading: action.loading };
      case Actions.SetRoomDetails:
        var isWorkspaceScheduleUsed = action.roomDetails?.schedule?.length == 0;
        var scheduleClone = action.roomDetails?.schedule?.map((e, i) => {
          e =
            e == null
              ? ({
                  openHour: null,
                  closeHour: null,
                  isClosed: true,
                } as IWorkspaceSchedule)
              : e;
          return e;
        });
        return update(currentState, {
          id: { $set: action.id },
          name: { $set: action.roomDetails.name },
          externalBookingProviderRefId: {
            $set: action.roomDetails.externalBookingProviderRefId,
          },
          roomTypeId: { $set: action.roomDetails.roomTypeId },
          workspaceId: { $set: action.roomDetails.workspaceId },

          descriptionEn: { $set: action.roomDetails.descriptionEn },
          descriptionEs: { $set: action.roomDetails.descriptionEs },
          descriptionPt: { $set: action.roomDetails.descriptionPt },
          email: { $set: action.roomDetails.email },
          phone: { $set: action.roomDetails.phone },

          schedule: {
            $set: isWorkspaceScheduleUsed
              ? currentState.schedule
              : scheduleClone,
          },
          isWorkspaceScheduleUsed: { $set: isWorkspaceScheduleUsed },
          amenities: { $set: action.roomDetails.amenities },
          images: { $set: action.roomDetails.images },

          maxGuests: { $set: action.roomDetails.maxGuests },
          minHourlyBookingAmount: {
            $set: action.roomDetails.bookingRestrictions.minHourlyBookingAmount,
          },
          minDailyBookingAmount: {
            $set: action.roomDetails.bookingRestrictions.minDailyBookingAmount,
          },

          hourPrice: { $set: action.roomDetails.hourPrice },
          dayPrice: { $set: action.roomDetails.dayPrice },
          monthPrice: { $set: action.roomDetails.monthPrice },
        });
      case Actions.SetRoomImages:
        return { ...currentState, images: action.images };
      case Actions.SetRoomSchedule:
        return { ...currentState, schedule: action.schedule };
      case Actions.SetRoomWorkspaceSchedule:
        var workspaceScheduleClone = action.workspaceSchedule.map((e, i) => {
          e =
            e == null
              ? ({
                  openHour: null,
                  closeHour: null,
                  isClosed: true,
                } as IWorkspaceSchedule)
              : e;
          return e;
        });
        return { ...currentState, workspaceSchedule: workspaceScheduleClone };
      case Actions.SetRoomAmenities:
        return { ...currentState, amenities: action.amenities };
      case Actions.SetErrors:
        return update(currentState, {
          hasServerSideErrors: { $set: action.hasServerSideErrors },
          errors: { $set: action.errors },
        });
      default:
        return currentState || initialState;
    }
  };
}
