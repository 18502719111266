import Globals from "./../../Globals";

export default class WeekDays {
       public friday: string = `Friday`;
       public monday: string = `Monday`;
       public saturday: string = `Saturday`;
       public sunday: string = `Sunday`;
       public thursday: string = `Thursday`;
       public tuesday: string = `Tuesday`;
       public wednesday: string = `Wednesday`;

		public static get Resources(): WeekDays{
			switch (Globals.currentActiveLanguage) {
				default:
				return new WeekDays();
		}
   	}
}
