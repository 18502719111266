import React from "react";
import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import ErrorMessages from "../resources/Common/ErrorMessages";
export namespace NotificationStore {
    
    export interface IState {
        children: JSX.Element,
        classes: string[],
        hidden: boolean
    }
    
    enum Actions {
        Initialize = "NOTIFICATION_INITIALIZE",
        ShowNotification = "NOTIFICATION_SHOW",
        HideNotification = "NOTIFICATION_HIDE",
    }
    
    interface IInitialize {
        type: Actions.Initialize;
    }
    interface IShowNotification {
        type: Actions.ShowNotification;
        children: JSX.Element;
        classes: string[];
    }
    interface IHideNotification {
        type: Actions.HideNotification
    }
    
    type KnownAction = IInitialize | IShowNotification | IHideNotification;
    
    export const actionCreators = {
        initialize: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.Initialize });
        },
        showNotification: (children: JSX.Element, classes: string[]): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ShowNotification, children, classes });
            setTimeout(() => dispatch({ type: Actions.HideNotification }), 3000);
        },
        showErrorNotification: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ShowNotification, children: React.createElement("span", null, ErrorMessages.Resources.generalErrorMessage), classes: ["notification-error"] });
            setTimeout(() => dispatch({ type: Actions.HideNotification }), 3000);
        }
    }

    export const boundActions = {
        ...actionCreators
    };

    export const initBoundActions = (dispatch, getState) => {
        Object.keys(actionCreators).forEach(action => {
            boundActions[action] = (...args: any[]) => actionCreators[action](...args)(dispatch, getState);
        });
    }
    
    const initialState: IState = {
        children: null,
        classes: [],
        hidden: true
    }
    
    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;
        
        switch (action.type) {
            case Actions.Initialize:
                return initialState;
            case Actions.ShowNotification:
                return { ...currentState, children: action.children, classes: action.classes, hidden: false };
            case Actions.HideNotification:
                return { ...currentState, hidden: true };
            default:
                const exhaustiveCheck: never = action;
        }
        
        return currentState || initialState;
        
    }
}