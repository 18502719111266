import bind from "bind-decorator";
import React from "react";
import Helmet from "react-helmet";
import SpacesTable from "../../components/Spaces/SpacesTable";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { SpaceGridStore } from "../../store/SpaceGridStore";
import {
  String_Format,
  firstLetterToLowerCase,
  getPromiseFromAction,
} from "../../utils/utils";
import { SpaceCategories } from "../../enums/Spaces/SpaceCategories";
import SpaceCategoryList from "../../resources/AdminUI/Spaces/SpaceCategoryList";
import Workspaces from "../../resources/AdminUI/Spaces/Workspaces";
import { DefaultFilterValues } from "../../enums/Common/DefaultFilterValues";
import { LookupStore } from "../../store/LookupStore";
import RegularExpressions from "../../resources/Common/RegularExpressions";
import { ApplicationPaths } from "../../enums/Common/ApplicationPaths";
import { Link } from "react-router-dom";
import { SpaceStatuses } from "../../enums/Spaces/SpaceStatuses";
import SpaceStatusesList from "../../resources/AdminUI/Spaces/SpaceStatusesList";
import SpacesService from "../../services/SpacesService";
import { LocalDateTime } from "@js-joda/core";
import { Select } from "antd";
import { IAntdSelectListItem } from "../../models/Common/IAntdSelectListItem";
import General from "../../resources/AdminUI/General";
import { withRouter } from "../../components/Shared/withRouter";
import Loading from "../../components/Shared/Loading";

interface IProps {
  filters: string;
  searchTerm: string;
  singleSelectedCategory: string;
  singleSelectedStatus: string;
  singleSelectedCountry: string;
  singleSelectedCity: string;
  countries: IAntdSelectListItem[];
  cities: IAntdSelectListItem[];
  initialize: (defaultSelectedItemsPerPageOption: number) => void;
  reload: () => Promise<any>;
  changeCurrentPage: (currentPage: number) => void;
  setFilter: (/*filters: string*/) => void;
  setSearchTerm: (searchTerm: string) => void;
  setSingleSelectedCategory: (singleSelectedCategory: string) => void;
  setSingleSelectedStatus: (singleSelectedStatus: string) => void;
  setSingleSelectedCountry: (singleSelectedCountry: string) => void;
  setSingleSelectedCity: (singleSelectedCity: string) => void;
}

interface IState {}

export class SpaceGridPage extends React.PureComponent<any, IState> {
  constructor(props) {
    super(props);
    this.props.getCountriesWithCities(true);
    this.props.getCities(true);
  }

  nameSearchTemplate = `contains(tolower(Name), tolower('{0}')) or contains(tolower(Address), tolower('{0}'))`;
  idSearchTemplate = ` or Id eq {0}`;
  categoryFilterTemplate = `CategoryId eq {0}`;
  statusFilterTemplate = `StatusId eq {0}`;
  countryFilterTemplate = `CountryId eq {0}`;
  cityFilterTemplate = `CityId eq {0}`;

  @bind
  rebuildODataAndGetData() {
    var filters = [];
    if (this.props.searchTerm) {
      var searchFilter = String_Format(
        this.nameSearchTemplate,
        this.props.searchTerm
      );
      var pattern = RegularExpressions.Resources.onlyNumbers;
      if (RegExp(pattern).test(this.props.searchTerm)) {
        searchFilter = searchFilter.concat(
          String_Format(this.idSearchTemplate, this.props.searchTerm)
        );
      }
      filters.push(`(${searchFilter})`);
    }

    if (
      this.props.singleSelectedCategory &&
      this.props.singleSelectedCategory != DefaultFilterValues.All.toString()
    ) {
      filters.push(
        `(${String_Format(
          this.categoryFilterTemplate,
          this.props.singleSelectedCategory
        )})`
      );
    }

    if (
      this.props.singleSelectedStatus &&
      this.props.singleSelectedStatus != DefaultFilterValues.All.toString()
    ) {
      filters.push(
        `(${String_Format(
          this.statusFilterTemplate,
          this.props.singleSelectedStatus
        )})`
      );
    }

    if (
      this.props.singleSelectedCountry &&
      this.props.singleSelectedCountry != DefaultFilterValues.All.toString()
    ) {
      filters.push(
        `(${String_Format(
          this.countryFilterTemplate,
          this.props.singleSelectedCountry
        )})`
      );
    }

    if (
      this.props.singleSelectedCity &&
      this.props.singleSelectedCity != DefaultFilterValues.All.toString()
    ) {
      filters.push(
        `(${String_Format(
          this.cityFilterTemplate,
          this.props.singleSelectedCity
        )})`
      );
    }

    var oDataFilter = filters.join(" and ");
    this.props.setFilter(oDataFilter);
    this.props.changeCurrentPage(1);
    getPromiseFromAction(this.props.reload()).then(() =>
      this.props.changeCurrentPage(1)
    );
  }

  @bind
  onSearchFilterChange(e: React.FormEvent<HTMLInputElement>) {
    let searchTerm = e.currentTarget.value;
    if (this.props.searchTerm != searchTerm) {
      getPromiseFromAction(this.props.setSearchTerm(searchTerm)).then(() => {
        this.rebuildODataAndGetData();
      });
    }
  }

  @bind
  onCategoryFilterChange(value: string, option: any) {
    getPromiseFromAction(this.props.setSingleSelectedCategory(value)).then(
      () => {
        this.rebuildODataAndGetData();
      }
    );
  }

  @bind
  onStatusFilterChange(value: string, option: any) {
    getPromiseFromAction(this.props.setSingleSelectedStatus(value)).then(() => {
      this.rebuildODataAndGetData();
    });
  }

  @bind
  onCountryFilterChange(value: string, option: any) {
    getPromiseFromAction(this.props.setSingleSelectedCountry(value)).then(
      () => {
        this.rebuildODataAndGetData();
      }
    );
  }

  @bind
  onCityFilterChange(value: string, option: any) {
    getPromiseFromAction(this.props.setSingleSelectedCity(value)).then(() => {
      this.rebuildODataAndGetData();
    });
  }

  @bind
  getAllSpaceCategories() {
    var options = [];
    let keys = Object.keys(SpaceCategories).filter(
      (k) => typeof SpaceCategories[k as any] === "number"
    );

    keys.forEach((key) => {
      let value = SpaceCategories[key as any].toString();
      options.push({
        value: value,
        label: SpaceCategoryList.Resources[firstLetterToLowerCase(key)],
      });
    });
    return options;
  }

  @bind
  getAllSpaceStatuses() {
    var options = [];
    let keys = Object.keys(SpaceStatuses).filter(
      (k) => typeof SpaceStatuses[k as any] === "number"
    );

    keys.forEach((key) => {
      let value = SpaceStatuses[key as any].toString();
      options.push({
        value: value,
        label: SpaceStatusesList.Resources[firstLetterToLowerCase(key)],
      });
    });
    return options;
  }

  @bind
  async exportWorkspaces() {
    await SpacesService.getSpacesForExport(this.props.filters).then((res) => {
      // remove commas from each string value, of every attribute, for evert element
      const entities = res.value
        ? res.value.entities
            .map((e) =>
              Object.values(e)
                .map((value) =>
                  typeof value == "string"
                    ? value.replaceAll(",", " ").replaceAll("\n", " ")
                    : value
                )
                .join(",")
            )
            .join("\n")
        : "";

      const csvContent =
        Workspaces.Resources.exportWorkspaceExcelHeader + "\n" + entities;
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);

      link.setAttribute(
        "download",
        Workspaces.Resources.worskpacesTitle +
          "_" +
          LocalDateTime.now() +
          Workspaces.Resources.csvExtension
      );
      document.body.appendChild(link);

      link.click();
    });
  }

  render() {
    if (!this.props.cities || !this.props.countries) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    return (
      <div className="space-list-page">
        <Helmet title={Workspaces.Resources.spaces} />

        <div className="page-top-container">
          <div className="page-title">{Workspaces.Resources.spaces}</div>

          <div className="page-actions">
            <div
              className="btn-big btn-secondary"
              onClick={this.exportWorkspaces}
            >
              <p className="btn-secondary-text">
                {Workspaces.Resources.exportWorkspacesCSV}
              </p>
            </div>

            <Link
              className="btn-big btn-primary"
              to={ApplicationPaths.AddSpace}
            >
              <span className="material-symbols-outlined">add_circle</span>
              <p className="btn-primary-text">
                {Workspaces.Resources.addSpace}
              </p>
            </Link>
          </div>
        </div>

        <div className="filter-bar">
          <div className="filter-search">
            <div className="input-group">
              <span className="icon material-symbols-outlined">search</span>
              <input
                type="text"
                className="form-control"
                placeholder={Workspaces.Resources.spacesGridSearchPlaceholder}
                onChange={this.onSearchFilterChange}
                name="searchTerm"
                value={this.props.searchTerm}
              />
            </div>
          </div>

          <div className="filter-item">
            <div className="input-group">
              <Select
                options={this.props.countries}
                onChange={this.onCountryFilterChange}
                value={this.props.singleSelectedCountry}
                showSearch
                optionFilterProp="label"
              />
            </div>
          </div>

          <div className="filter-item">
            <div className="input-group">
              <Select
                options={
                  this.props.singleSelectedCountry !=
                  DefaultFilterValues.All.toString()
                    ? this.props.cities.filter(
                        (e) =>
                          e.group == this.props.singleSelectedCountry ||
                          e.value == DefaultFilterValues.All.toString()
                      )
                    : this.props.cities
                }
                onChange={this.onCityFilterChange}
                value={this.props.singleSelectedCity}
                showSearch
                optionFilterProp="label"
              ></Select>
            </div>
          </div>

          <div className="filter-item">
            <div className="input-group">
              <Select
                options={this.getAllSpaceCategories()}
                onChange={this.onCategoryFilterChange}
                value={this.props.singleSelectedCategory}
              ></Select>
            </div>
          </div>

          <div className="filter-item">
            <div className="input-group">
              <Select
                options={this.getAllSpaceStatuses()}
                onChange={this.onStatusFilterChange}
                value={this.props.singleSelectedStatus}
              ></Select>
            </div>
          </div>
        </div>
        <SpacesTable></SpacesTable>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: ApplicationState) => {
      return {
        filters: state.spaceGrid.filters,
        searchTerm: state.spaceGrid.searchTerm,
        singleSelectedCategory: state.spaceGrid.singleSelectedCategory,
        singleSelectedStatus: state.spaceGrid.singleSelectedStatus,
        singleSelectedCountry: state.spaceGrid.singleSelectedCountry,
        singleSelectedCity: state.spaceGrid.singleSelectedCity,
        countries: state.lookup.countries,
        cities: state.lookup.cities,
      };
    },
    {
      initialize: SpaceGridStore.actionCreators.initialize,
      changeCurrentPage: SpaceGridStore.actionCreators.changeCurrentPage,
      reload: SpaceGridStore.actionCreators.reload,
      setFilter: SpaceGridStore.actionCreators.setFilter,
      setSearchTerm: SpaceGridStore.actionCreators.setSearchTerm,
      setSingleSelectedCategory:
        SpaceGridStore.actionCreators.setSingleSelectedCategory,
      setSingleSelectedStatus:
        SpaceGridStore.actionCreators.setSingleSelectedStatus,
      setSingleSelectedCountry:
        SpaceGridStore.actionCreators.setSingleSelectedCountry,
      setSingleSelectedCity:
        SpaceGridStore.actionCreators.setSingleSelectedCity,
      getCountriesWithCities: LookupStore.actionCreators.getCountriesWithCities,
      getCities: LookupStore.actionCreators.getCities,
    }
  )(SpaceGridPage as any)
);
