import bind from "bind-decorator";
import React from "react";
import RoomCard from "../../Rooms/RoomCard";

interface IProps {
  slides: any;
  currency: string;
}

interface IState {
  currentSlide: number;
}

export class RoomCardsSlider extends React.Component<IProps, IState>{

  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
    }
  }

  @bind
  nextSlide() {
    var newCurrentSlide = this.state.currentSlide === this.props.slides.length - 1 ? 0 : this.state.currentSlide + 1
    this.setState({ currentSlide: newCurrentSlide });
  };

  @bind
  prevSlide() {
    var newCurrentSlide = this.state.currentSlide === 0 ? this.props.slides.length - 1 : this.state.currentSlide - 1
    this.setState({ currentSlide: newCurrentSlide });
  };

  render() {

    return <div className="scrollable-list">
      {this.props.slides.map((slide, index) => {
        return (
          <RoomCard
            key={slide.id}
            slide={slide}
            currency={this.props.currency}
          />
        );
      })}
    </div>
  }
}



