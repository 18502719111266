import Globals from "./../../../Globals";

export default class AddOrEditHotdeskBooking {
       public addDeskLog: string = `Add Desk Log`;
       public areYouSureYouWantToDeleteThisHotdeskLog: string = `Are you sure you want to delete this desk log? By continuing the log will be deleted from the database and user’s history list from his/her account.`;
       public bookingDateAndTime: string = `Booking date & time`;
       public cancelledDateAndTime: string = `Cancelled date & time`;
       public checkinDateAndTime: string = `Check-in date & time`;
       public checkoutDateAndTime: string = `Check-out date & time`;
       public deleteDeskLog: string = `Delete Desk Log`;
       public editDeskLog: string = `Edit Desk Log`;
       public sameCheckinAndCheckoutDate: string = `Check in date and check out date must be the same`;
       public spaceId: string = `Space ID`;
       public status: string = `Status`;
       public userId: string = `User ID`;
       public youMustChooseBasedOnSpaceTimezone: string = `You must choose the date & hour based on the working space's timezone`;

		public static get Resources(): AddOrEditHotdeskBooking{
			switch (Globals.currentActiveLanguage) {
				default:
				return new AddOrEditHotdeskBooking();
		}
   	}
}
