import { NavigateFunction } from "react-router";

import { SpaceStatuses } from "../../enums/Spaces/SpaceStatuses";
import { SpaceCategories } from "../../enums/Spaces/SpaceCategories";
import SpaceStatusesList from "../../resources/AdminUI/Spaces/SpaceStatusesList";
import SpaceCategoryList from "../../resources/AdminUI/Spaces/SpaceCategoryList";
import { firstLetterToLowerCase } from "../../utils/utils";
import IDropdownButtonItem from "../../models/Common/IDropdownButtonItem";
import { ApplicationPathBuilder } from "../../enums/Common/ApplicationPaths";

export const getItemsForChangeStatusDropdownButton = (
  statusId: number,
  showDraftModal: () => void,
  showUnavailableModal: () => void,
  showActiveModal: () => void,
  showDeactivatedModal: () => void
): IDropdownButtonItem[] => {
  switch (statusId) {
    case SpaceStatuses.Available:
      return [
        {
          icon: "stylus_note",
          text: SpaceStatusesList.Resources.draft,
          action: showDraftModal,
        },
        {
          icon: "event_busy",
          text: SpaceStatusesList.Resources.unavailable,
          action: showUnavailableModal,
        },
        {
          icon: "toggle_off",
          text: SpaceStatusesList.Resources.deactivated,
          action: showDeactivatedModal,
        },
      ];
    case SpaceStatuses.Draft:
      return [
        {
          icon: "toggle_on",
          text: SpaceStatusesList.Resources.available,
          action: showActiveModal,
        },
        {
          icon: "event_busy",
          text: SpaceStatusesList.Resources.unavailable,
          action: showUnavailableModal,
        },
        {
          icon: "toggle_off",
          text: SpaceStatusesList.Resources.deactivated,
          action: showDeactivatedModal,
        },
      ];
    case SpaceStatuses.Unavailable:
      return [
        {
          icon: "stylus_note",
          text: SpaceStatusesList.Resources.draft,
          action: showDraftModal,
        },
        {
          icon: "toggle_on",
          text: SpaceStatusesList.Resources.available,
          action: showActiveModal,
        },
        {
          icon: "toggle_off",
          text: SpaceStatusesList.Resources.deactivated,
          action: showDeactivatedModal,
        },
      ];
    case SpaceStatuses.Deactivated:
      return [
        {
          icon: "stylus_note",
          text: SpaceStatusesList.Resources.draft,
          action: showDraftModal,
        },
        {
          icon: "toggle_on",
          text: SpaceStatusesList.Resources.available,
          action: showActiveModal,
        },
        {
          icon: "event_busy",
          text: SpaceStatusesList.Resources.unavailable,
          action: showUnavailableModal,
        },
      ];
    default:
      return [];
  }
};

export const getCategoryName = (categoryId: number): string => {
  const category = SpaceCategories[categoryId];
  return SpaceCategoryList.Resources[firstLetterToLowerCase(category)];
};

export const getTableFilterTemplate = (id: string): string => {
  return `WorkspaceId eq ${id}`;
};

export const goToAddRoom = (id: number, navigate: NavigateFunction) => {
  navigate(ApplicationPathBuilder.AddRoom(id.toString()));
};

export const goToEditSpace = (details: any, navigate: NavigateFunction) => {
  if (details.categoryId == SpaceCategories.CompanyOffice.toString()) {
    navigate(ApplicationPathBuilder.EditOffice(details.id));
  } else {
    navigate(ApplicationPathBuilder.EditSpace(details.id));
  }
};
