import Result from "../models/Common/Result";
import { PayloadTypes } from "../enums/Common/PayloadTypes";
import { AppSettings } from "../utils/settings";
import { ServiceBase } from "./ServiceBase";
import { ITeam } from "../models/Teams/Interfaces/ITeam";

export default class TeamsService extends ServiceBase {
    
    static addTeam = (team: any): Promise<Result<any>> => {
        var result = TeamsService.sendData<any>({
            method: "POST",
            url: `${AppSettings.CommonSettings.ApiUrl}/teams`,
            data: JSON.stringify(team)
        }, PayloadTypes.Json);

        return result;
    }

    static editTeam = (team: any): Promise<Result<any>> => {
        var result = TeamsService.sendData<any>({
            method: "PUT",
            url: `${AppSettings.CommonSettings.ApiUrl}/teams/${team.id}`,
            data: JSON.stringify(team)
        }, PayloadTypes.Json);

        return result;
    }

    static addUserToTeam = (teamId: number, userId: number): Promise<Result<any>> => {
        var result = TeamsService.sendData<any>({
            method: "PUT",
            url: `${AppSettings.CommonSettings.ApiUrl}/teams/${teamId}/users/${userId}`,
            data: null
        }, PayloadTypes.Json);

        return result;
    }

    static getTeamDetailsForEdit = (teamId: number): Promise<Result<ITeam>> => {
        var result = TeamsService.requestJsonFetch<ITeam>({
            method: "GET",
            url: `${AppSettings.CommonSettings.ApiUrl}/teams/${teamId}/form-details`
        });

        return result;
    }
}