// roomBookingsHelpers.ts

import { useCallback } from "react";
import buildQuery from "odata-query";

import { RoomBookingStatuses } from "../../enums/RoomBookings/RoomBookingStatuses";
import RoomBookingStatusTypes from "../../resources/AdminUI/RoomBookings/RoomBookingStatusTypes";
import { firstLetterToLowerCase } from "../../utils/utils";

export const useRoomBookingsHelpers = (
  itemsPerPage: number,
  numberOfRoomBookings: number,
  currentPage: number
) => {
  const recalculatePageArray = useCallback(() => {
    const totalPages =
      Math.floor((numberOfRoomBookings - 1) / itemsPerPage) + 1;
    const pageArray = [];
    for (let i = 1; i <= totalPages; i++) {
      pageArray.push({ pageNumber: i, isActive: currentPage === i });
    }
    return pageArray;
  }, [currentPage, numberOfRoomBookings, itemsPerPage]);

  const recalculateIndexes = useCallback(() => {
    const firstIndex = (currentPage - 1) * itemsPerPage + 1;
    const totalPages =
      Math.floor((numberOfRoomBookings - 1) / itemsPerPage) + 1;
    const lastIndex =
      currentPage === totalPages
        ? Math.min(numberOfRoomBookings, itemsPerPage * totalPages)
        : currentPage * itemsPerPage;
    return { firstIndex, lastIndex };
  }, [currentPage, numberOfRoomBookings, itemsPerPage]);

  const rebuildODataFilters = (
    toTake: number,
    toSkip: number,
    filters: any
  ): string => {
    const query = buildQuery({
      filter: filters,
      top: toTake,
      skip: toSkip,
      orderBy: "Id desc",
      count: true,
    });

    return query;
  };

  const getAllRoomStatuses = () => {
    let options = [];
    let keys = Object.keys(RoomBookingStatuses).filter(
      (k) => typeof RoomBookingStatuses[k as any] === "number"
    );

    keys.forEach((key) => {
      let value = RoomBookingStatuses[key as any].toString();
      options.push({
        value: value,
        label: RoomBookingStatusTypes.Resources[firstLetterToLowerCase(key)],
      });
    });
    return options;
  };

  return {
    recalculatePageArray,
    recalculateIndexes,
    rebuildODataFilters,
    getAllRoomStatuses,
  };
};
