import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthorizedLayoutComponent } from "./layouts/Layout";
import { ApplicationPaths } from "./enums/Common/ApplicationPaths";
import NotFoundPage from "./pages/NotFoundPage";
import UnauthorizedPage from "./pages/UnauthorizedPage";
import { RoomBookingsProvider } from "./providers/RoomBookingsProvider";
import { authorizedRoutes } from "./utils/authorizedRoutes";
import { PendingBookingsProvider } from "./providers/PendingBookingsProvider";

const RouteList: React.FC = () => {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 60 * 1000, // 1 minute
          },
        },
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <RoomBookingsProvider>
        <PendingBookingsProvider>
          <Routes>
            <Route
              path={ApplicationPaths.Unauthorized}
              element={<UnauthorizedPage />}
            />
            <Route
              path={ApplicationPaths.EmptyPath}
              element={<Navigate to={ApplicationPaths.Spaces} replace />}
            />
            {authorizedRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <AuthorizedLayoutComponent>
                    {route.component}
                  </AuthorizedLayoutComponent>
                }
              />
            ))}
            <Route
              path={ApplicationPaths.NotFound}
              element={<NotFoundPage />}
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </PendingBookingsProvider>
      </RoomBookingsProvider>
    </QueryClientProvider>
  );
};

export default RouteList;
