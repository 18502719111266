import Globals from "./../../../Globals";

export default class ClientDetails {
       public activateCustomerActionLabel: string = `Activate customer`;
       public activateCustomerDescLabel: string = `By continuing the users associated with this customer will be able to create bookings based on the available benefits.`;
       public addEditCustomersOfficeLabel: string = `Add/edit customer's office(s)`;
       public addUserActionLabel: string = `Add a new user`;
       public contactInfoTabLabel: string = `Contact info`;
       public createdOnLabel: string = `Created on {0}`;
       public cuiLabel: string = `CUI/Company number`;
       public customerContactInfoLabel: string = `Customer contact info`;
       public customerDetailsHeader: string = `Customer details`;
       public customerIdLabel: string = `Customer ID: #{0}`;
       public customerUsersSectionLabel: string = `Customer users`;
       public deactivateCustomerActionLabel: string = `Deactivate customer`;
       public deactivateCustomerDescLabel: string = `By continuing the customer's users will be deactivated (which means that users will still be able to access hic account, but will not be able to create bookings, check-in in any working space, and all his active bookings will be cancelled). The Customer Dashboard is still accessible by customer admin users.`;
       public detailsLabel: string = `Details`;
       public downloadTemplateForExport: string = `Download import users file template`;
       public editCustomerInfoButtonText: string = `Edit customer info`;
       public emailDomainsLabel: string = `Email domains for auth`;
       public enterprisePlanLabel: string = `Enterprise plan`;
       public exportButtonText: string = `Export Users.csv`;
       public importUsersFromFileActionLabel: string = `Import users from file`;
       public industryLabel: string = `Industry`;
       public legalNameLabel: string = `Legal name`;
       public moreTabLabel: string = `More`;
       public noEmployeesText: string = `{0} employees`;
       public noUsersAndEmployees: string = `No. of users / employees`;
       public planLabel: string = `Plan`;
       public pluriaAccountManagerLabel: string = `Pluria account manager`;
       public pluriaSalesPersonLabel: string = `Pluria sales person`;
       public priceLabel: string = `Price`;
       public renewalIntervalLabel: string = `Renewal interval: `;
       public teamsAndUsers: string = `Teams and users`;

		public static get Resources(): ClientDetails{
			switch (Globals.currentActiveLanguage) {
				default:
				return new ClientDetails();
		}
   	}
}
