export enum ApplicationPaths {
    Unauthorized = "/unauthorized",
    EmptyPath = "/",

    ViewSpaceDetails = "/spaces/details",
    AddSpace = "/spaces/add",
    EditSpace = "/spaces/edit",
    AddRoom = "/spaces/add-room",
    EditRoom = "/spaces/edit-room",
    Spaces = "/spaces",

    ViewUserDetails = "/users/details",
    Users = "/users",

    ViewCustomerDetails = "/customers/details",
    AddCustomer = "/customers/add",
    EditCustomer = "/customers/edit",
    AddTeam = "/customers/add-team",
    EditTeam = "/customers/edit-team",
    AddBenefit = "/customers/add-service",
    EditBenefit = "/customers/edit-service",
    AddOffice = "/customers/add-office",
    EditOffice = "/customers/edit-office",
    Customers = "/customers",

    ManageAccounts = "/manage-accounts",

    AddHotdeskBooking = "/desks-logs/add",
    EditHotdeskBooking = "/desks-logs/edit",
    DesksLogs = "/desks-logs",

    AddRoomBooking = "/rooms-logs/add",
    EditRoomBooking = "/rooms-logs/edit",
    RoomsLogs = "/rooms-logs",

    PendingRoomRequests = "/pending-room-requests",

    CountriesAndCities = "/countries-and-cities",

    AccountsAndLogs = "/accounts-and-logs",

    EditSpacePrice = "/edit-space-price",

    NotFound = "/not-found",
}

export class ApplicationPathBuilder {

    static ViewSpaceDetails = (id: string): string => {
        return `${ApplicationPaths.ViewSpaceDetails}?id=${id}`;
    }

    static EditSpace = (id: string): string => {
        return `${ApplicationPaths.EditSpace}?id=${id}`;
    }

    static EditRoom = (id: string): string => {
        return `${ApplicationPaths.EditRoom}?id=${id}`;
    }

    static AddRoom = (workspaceId: string): string => {
        return `${ApplicationPaths.AddRoom}?workspaceId=${workspaceId}`;
    }

    static ViewUserDetails = (id: number): string => {
        return `${ApplicationPaths.ViewUserDetails}?id=${id}`
    }

    static ViewCustomerDetails = (id: number): string => {
        return `${ApplicationPaths.ViewCustomerDetails}?id=${id}`
    }

    static EditCustomer = (id: number): string => {
        return `${ApplicationPaths.EditCustomer}?id=${id}`;
    }

    static AddTeam = (clientId: number): string => {
        return `${ApplicationPaths.AddTeam}?customerId=${clientId}`;
    }

    static EditTeam = (id: number): string => {
        return `${ApplicationPaths.EditTeam}?id=${id}`;
    }

    static AddService = (clientId: number, subscriptionId: number): string => {
        return `${ApplicationPaths.AddBenefit}?customerId=${clientId}&subscriptionId=${subscriptionId}`;
    }

    static EditService = (id: number): string => {
        return `${ApplicationPaths.EditBenefit}?id=${id}`;
    }

    static EditOffice = (id: number): string => {
        return `${ApplicationPaths.EditOffice}?id=${id}`;
    }

    static AddOffice = (id: number): string => {
        return `${ApplicationPaths.AddOffice}?customerId=${id}`;
    }

    static EditHotdeskBooking = (id: number): string => {
        return `${ApplicationPaths.EditHotdeskBooking}?id=${id}`;
    }

    static EditRoomBooking = (id: number): string => {
        return `${ApplicationPaths.EditRoomBooking}?id=${id}`;
    }
}
