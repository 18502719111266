import Globals from "../../../Globals";

export default class EditSpacePrice {
    public title: string = `Change hotdesk booking prices`;
    public startDateTitle: string = `Start date to change prices`;
    public spaceId: string = `Space ID`;
    public halfDayPrice: string = `Half day price`;
    public fullDayPrice: string = `Full day price`;
    public unredeemedPrice: string = `Unredeemed price`;
    public VAT: string = `VAT`;

		public static get Resources(): EditSpacePrice{
			switch (Globals.currentActiveLanguage) {
				default:
				return new EditSpacePrice();
		}
   	}
}
