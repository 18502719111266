import React from "react";
import { connect } from "react-redux";

import DnDGrid from "../../Shared/DnDGrid";
import Gallery from "../../../resources/AdminUI/Gallery";
import { AppSettings } from "../../../utils/settings";
import { useLogoUpload } from "./useLogoUpload";

interface LogoUploadProps {
  image?: string;
  onSaveCallback: (newImage?: string) => void;
}

const LogoUpload: React.FC<LogoUploadProps> = ({
  image,
  onSaveCallback,
}) => {
  const {
    currentImage,
    errors,
    uploadImageWithInput,
    uploadImageWithDragDrop,
    removeImage,
  } = useLogoUpload(
    onSaveCallback,
    image,
  );

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className="i-gallery" draggable="true">
      <div
        className="i-gallery-upload"
        onDrop={uploadImageWithDragDrop}
        onDragOver={handleDrag}
      >
        <div className="i-upload-label">
          {Gallery.Resources.dropYourImage}
          <span className="i-upload-btn">
            <span className="i-upload-btn-label">
              {Gallery.Resources.browse}
            </span>
            <input
              type="file"
              onChange={uploadImageWithInput}
              data-multiple-caption="{count} files selected"
              multiple={false}
              accept={"image/*"}
            />
          </span>
        </div>
        <div className="i-upload-info">
          {Gallery.Resources.supportsJPGandPNGfiles}
        </div>
      </div>

      <div style={{ color: "red" }}>{errors}</div>

      <div className="i-gallery-list">
        <DnDGrid
          items={currentImage ? [currentImage] : []}
          onChange={() => {}}
          getItemId={(img) => currentImage}
          getGridElementInnerContent={(imageUrl, propsForHandle) => (
            <div className="i-gallery-item">
              <div className="i-gallery-photo">
                <div {...propsForHandle}>
                  <img
                    src={AppSettings.CommonSettings.ImageBaseUrl + imageUrl}
                    alt=""
                  />
                </div>
                <a
                  className="i-gallery-delete-btn"
                  style={{ cursor: "pointer" }}
                  onClick={() => removeImage()}
                >
                  <span className="material-symbols-outlined">delete</span>
                </a>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default LogoUpload;


export const LogoUploadProps = connect(
  () => ({
    image: null,
  }),
  null,
  null,
  { forwardRef: true }
)(LogoUpload);
