import bind from "bind-decorator";
import React from "react";
import WeekDays from "../../resources/AdminUI/WeekDays";
import { IWorkspaceSchedule } from "../../models/Spaces/Interfaces/IWorkspaceSchedule";
import General from "../../resources/AdminUI/General";

interface IProps {
  schedule: IWorkspaceSchedule[];
}

interface IState {}

export class ScheduleTab extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
  }

  @bind
  getDayName(index: number) {
    switch (index) {
      case 0:
        return WeekDays.Resources.monday;
      case 1:
        return WeekDays.Resources.tuesday;
      case 2:
        return WeekDays.Resources.wednesday;
      case 3:
        return WeekDays.Resources.thursday;
      case 4:
        return WeekDays.Resources.friday;
      case 5:
        return WeekDays.Resources.saturday;
      case 6:
        return WeekDays.Resources.sunday;
    }
  }

  render() {
    return (
      <div>
        {this.props?.schedule?.map((e, i) => {
          return (
            <div key={i} className="schedule-grid-container">
              <div>
                <b>{this.getDayName(i)}</b>
              </div>
              <div>
                {!e || e.isClosed
                  ? General.Resources.closed
                  : e.openHour + "-" + e.closeHour}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
