import Globals from "./../../Globals";

export default class MenuItems {
       public accountsAndLogs: string = `Accounts & logs`;
       public activity: string = `Activity`;
       public companyAdmin: string = `Company Admin`;
       public customers: string = `Customers`;
       public dashboard: string = `Dashboard`;
       public desksLogs: string = `Desks logs`;
       public employees: string = `Employees`;
       public general: string = `General`;
       public logout: string = `Logout`;
       public manageAccounts: string = `Manage accounts`;
       public manageCities: string = `Manage cities`;
       public pendingRequests: string = `Pending requests`;
       public pi: string = `PI`;
       public pluriaInternational: string = `Pluria International`;
       public roomsLogs: string = `Rooms logs`;
       public settings: string = `Settings`;
       public spaces: string = `Spaces`;
       public superAdmin: string = `Super Admin`;
       public users: string = `Users`;
       public editSpacePrice = `Change hotdesk booking prices`;

		public static get Resources(): MenuItems{
			switch (Globals.currentActiveLanguage) {
				default:
				return new MenuItems();
		}
   	}
}
