import bind from "bind-decorator";
import Validations from "../../../resources/Common/Validations";
import { String_Format, isEmail, nameof } from "../../../utils/utils";
import { BaseValidator } from "../Base/BaseValidator";
import AddOrEditWorkspaceRoomForm from "../../../resources/AdminUI/Rooms/AddOrEditWorkspaceRoomForm";

export class AddOrEditRoomFormValidator extends BaseValidator {

    constructor() {
        super();
    }

    @bind
    validate(model: any, props: any) {

        this.check(() => model.name != '' && model.name != null, nameof(e => e.name), String_Format(Validations.Resources.required, AddOrEditWorkspaceRoomForm.Resources.roomName));
        // this.check(() => !model.name || model.name.length <= 50, nameof(form => form.name), String_Format(Validations.Resources.maxLength, 50));
        this.check(() => model.roomTypeId != '' && model.roomTypeId != null, nameof(e => e.roomTypeId), String_Format(Validations.Resources.required, AddOrEditWorkspaceRoomForm.Resources.typeOfRoom));
        this.check(() => !model.email || isEmail(model.email), nameof(e => e.email), Validations.Resources.emailNotValid);
        // this.check(() => !model.email || model.email.length <= 50, nameof(e => e.email), String_Format(Validations.Resources.maxLength, 50));
        // this.check(() => !model.phone || model.phone.length <= 50, nameof(e => e.phone), String_Format(Validations.Resources.maxLength, 50));
        // this.check(() => !model.hourPrice || model.hourPrice <= 999999999, nameof(e => e.hourPrice), String_Format(Validations.Resources.maxLength, 9));
        // this.check(() => !model.dayPrice || model.dayPrice <= 999999999, nameof(e => e.dayPrice), String_Format(Validations.Resources.maxLength, 9));
        // this.check(() => !model.monthPrice || model.monthPrice <= 999999999, nameof(e => e.monthPrice), String_Format(Validations.Resources.maxLength, 9));
        this.check(() => model.maxGuests, nameof(e => e.maxGuests), String_Format(Validations.Resources.required, AddOrEditWorkspaceRoomForm.Resources.maximumNoOfGuests));
        // this.check(() => !model.maxGuests || model.maxGuests <= 999999999, nameof(e => e.maxGuests), String_Format(Validations.Resources.maxLength, 9));
        this.check(() => model.minHourlyBookingAmount, nameof(e => e.minHourlyBookingAmount), String_Format(Validations.Resources.required, AddOrEditWorkspaceRoomForm.Resources.minimumHourlyBookingTime));
        // this.check(() => !model.minHourlyBookingAmount || model.minHourlyBookingAmount <= 999999999, nameof(e => e.minHourlyBookingAmount), String_Format(Validations.Resources.maxLength, 9));
        this.check(() => model.minDailyBookingAmount, nameof(e => e.minDailyBookingAmount), String_Format(Validations.Resources.required, AddOrEditWorkspaceRoomForm.Resources.minimumDailyBookingTime));
        // this.check(() => !model.minDailyBookingAmount || model.minDailyBookingAmount <= 999999999, nameof(e => e.minDailyBookingAmount), String_Format(Validations.Resources.maxLength, 9));

        return this.getErrorsDictionary();
    }
}