import React from "react";
import IDisplayAmenityDTO from "../../models/Common/IDisplayAmenityDTO";
import { AppSettings } from "../../utils/settings";
import General from "../../resources/AdminUI/General";

interface IProps {
    amenities: IDisplayAmenityDTO[];
}

interface IState {

}

export class AmenitiesTab extends React.Component<IProps, IState>{

    constructor(props) {
        super(props);
    }

    render() {

        return <div>
            {this.props.amenities && this.props.amenities.length > 0?
            this.props.amenities.map(e => {
                return <div key={e.name} className="space-details-tab-amenities">
                    <img src={AppSettings.CommonSettings.ImageBaseUrl + e.icon} />
                    <p>{e.name}</p>
                    </div>
            }) : General.Resources.notCompletedYet
            }
        </div>
    }
}
