import Globals from "./../../../Globals";

export default class UserStateTypes {
       public active: string = `Active`;
       public activeAndAccountCompleted: string = `Active & account completed`;
       public allUserStates: string = `All user states`;
       public deactivated: string = `Deactivated`;
       public deleted: string = `Deleted`;
       public notCompleted: string = `Not completed`;

		public static get Resources(): UserStateTypes{
			switch (Globals.currentActiveLanguage) {
				default:
				return new UserStateTypes();
		}
   	}
}
