import React from "react";
import { ApplicationPathBuilder } from "../../enums/Common/ApplicationPaths";
import { NavigateFunction } from "react-router-dom";
import Helmet from "react-helmet";
import ClientDetails from "../../resources/AdminUI/Clients/ClientDetails";
import ClientGeneralDetails from "../../components/Clients/Containers/ClientGeneralDetails";
import ClientAdditionalDetails from "../../components/Clients/Containers/ClientAdditionalDetails";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { ClientDetailsStore } from "../../store/ClientDetailsStore";
import { IClientDetails } from "../../models/Clients/Interfaces/IClientDetails";
import DropdownButton from "../../components/Shared/DropdownButton";
import bind from "bind-decorator";
import General from "../../resources/AdminUI/General";
import TeamsContainer from "../../components/Clients/Containers/TeamsContainer";
import SubscriptionContainer from "../../components/Clients/Containers/SubscriptionContainer";
import BenefitsContainer from "../../components/Clients/Containers/BenefitsContainer";
import UsersGrid from "../../resources/AdminUI/Users/UsersGrid";
import ClientOfficeContainer from "../../components/Clients/Containers/ClientOfficeContainer";
import UsersService from "../../services/UsersService";
import { LocalDateTime } from "@js-joda/core";
import ClientOffice from "../../resources/AdminUI/Clients/ClientOffice";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { withRouter } from "../../components/Shared/withRouter";
import ModalDialog from "../../components/Shared/ModalDialog";
import { BenefitsStore } from "../../store/BenefitsStore";
import { TeamsStore } from "../../store/TeamsStore";
import { SubscriptionStore } from "../../store/SubscriptionStore";
import Loading from "../../components/Shared/Loading";
const { confirm } = Modal;

interface IProps {
  navigate: NavigateFunction;
  params: URLSearchParams;

  client: IClientDetails;
  loading: boolean;

  initializeSubscription?: () => void;
  initializeBenefits?: () => void;
  initializeTeams?: () => void;
  setClientDetails: (id: number) => void;
  activateClient: (id: number) => void;
  deactivateClient: (id: number) => void;
}

class ClientsDetailsPage extends React.Component<IProps, any> {
  constructor(props) {
    super(props);

    this.state = {
      isClientOfficesModalOpen: false,
    };

    if (props.params.has("id")) {
      this.props.setClientDetails(parseInt(props.params.get("id")));

      this.props.initializeSubscription();
      this.props.initializeBenefits();
      this.props.initializeTeams();
    }
  }

  @bind
  showClientOfficesModal() {
    this.setState({ isClientOfficesModalOpen: true });
  }

  @bind
  hideClientOfficesModal() {
    this.setState({ isClientOfficesModalOpen: false });
  }

  @bind
  getAvailableActions() {
    var items = [];

    items.push(
      {
        icon: "download",
        text: ClientDetails.Resources.downloadTemplateForExport,
        action: this.downloadImportFileTemplate,
      },
      {
        icon: "domain",
        text: ClientDetails.Resources.addEditCustomersOfficeLabel,
        action: this.showClientOfficesModal,
      }
    );

    if (this.props.client.deactivated) {
      items.push({
        icon: "toggle_on",
        text: ClientDetails.Resources.activateCustomerActionLabel,
        action: this.showActivateClientModal,
      });
    } else {
      items.push({
        icon: "toggle_off",
        text: ClientDetails.Resources.deactivateCustomerActionLabel,
        action: this.showDeactivateClientModal,
      });
    }

    return items;
  }

  @bind
  downloadImportFileTemplate() {
    const csvContent =
      UsersGrid.Resources.exportFileForImportUsersHeader + "\n";

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      UsersGrid.Resources.users + UsersGrid.Resources.csvExtension
    );
    document.body.appendChild(link);

    link.click();
  }

  @bind
  async exportUsers() {
    var filter = `companyId eq ${this.props.client.id}`;
    await UsersService.getUsersForExport(filter).then((res) => {
      var entities = res?.value
        ? res?.value?.entities
            ?.map((e) =>
              Object.entries(e)
                ?.filter(([key]) => key != "marketingPreferences")
                ?.map(([key, value]) =>
                  typeof value === "string" ? value.replaceAll(",", " ") : value
                )
                ?.join(",")
            )
            ?.join("\n")
        : "";
      const csvContent =
        "data:text/csv;charset=utf-8," +
        UsersGrid.Resources.exportUsersHeader +
        "\n" +
        entities;

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        UsersGrid.Resources.users +
          "_" +
          LocalDateTime.now() +
          UsersGrid.Resources.csvExtension
      );
      document.body.appendChild(link);

      link.click();
    });
  }

  @bind
  showDeactivateClientModal() {
    confirm({
      title: General.Resources.areYouSureModalLabel,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>{ClientDetails.Resources.deactivateCustomerDescLabel}</p>
        </div>
      ),
      okText: General.Resources.deactivateLabel,
      okType: "danger",
      cancelText: General.Resources.cancelButtonLabel,
      onOk: () => {
        this.handleOnDeactivateClient();
      },
      onCancel() {},
    });
  }

  @bind
  showActivateClientModal() {
    confirm({
      title: General.Resources.areYouSureModalLabel,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>{ClientDetails.Resources.activateCustomerDescLabel}</p>
        </div>
      ),
      okText: General.Resources.activateLabel,
      okType: "primary",
      cancelText: General.Resources.cancelButtonLabel,
      onOk: () => {
        this.handleOnActivateClient();
      },
      onCancel() {},
    });
  }

  @bind
  handleOnDeactivateClient() {
    this.props.deactivateClient(this.props.client.id);
  }

  @bind
  handleOnActivateClient() {
    this.props.activateClient(this.props.client.id);
  }

  @bind
  goToEditClientPage() {
    this.props.navigate(
      ApplicationPathBuilder.EditCustomer(this.props.client.id)
    );
  }

  render() {
    if (this.props.loading || !this.props.client.id) {
      return (
        <div>
          <Loading />
        </div>
      );
    } else {
      return (
        <div>
          <Helmet to={ClientDetails.Resources.customerDetailsHeader}></Helmet>
          <ModalDialog
            title={ClientOffice.Resources.customerOfficeHeader}
            open={this.state.isClientOfficesModalOpen}
            onCancel={this.hideClientOfficesModal}
          >
            <ClientOfficeContainer
              clientId={this.props.client.id}
              clientName={this.props.client.name}
            />
          </ModalDialog>

          <div className="details-page-top">
            <div className="page-navigation">
              <span
                className="material-symbols-outlined"
                onClick={() => this.props.navigate(-1)}
              >
                arrow_back
              </span>
              <div className="page-title">
                {ClientDetails.Resources.customerDetailsHeader}
              </div>
            </div>

            <div className="page-actions">
              <DropdownButton
                text={General.Resources.moreActions}
                icon="arrow_drop_down"
                items={this.getAvailableActions()}
                className="btn-big btn-secondary"
              />

              <button
                className="btn-big btn-secondary"
                onClick={this.exportUsers}
              >
                <p className="btn-primary-text">
                  {ClientDetails.Resources.exportButtonText}
                </p>
              </button>

              <button
                className="btn-big btn-primary"
                onClick={this.goToEditClientPage}
              >
                <span className="material-symbols-outlined">edit</span>
                <p className="btn-primary-text">
                  {ClientDetails.Resources.editCustomerInfoButtonText}
                </p>
              </button>
            </div>
          </div>

          <div className="two-columns-container">
            <section className="container">
              <ClientGeneralDetails client={this.props.client} />
            </section>

            <section className="container">
              <ClientAdditionalDetails
                componentId={`client-details-${this.props.client.id}`}
                client={this.props.client}
              />
            </section>
          </div>

          <div className="container">
            <SubscriptionContainer clientId={this.props.client.id} />
          </div>

          <div className="container">
            <BenefitsContainer
              clientId={this.props.client.id}
              clientName={this.props.client.name}
            />
          </div>

          <div className="container">
            <TeamsContainer clientId={this.props.client.id} />
          </div>
        </div>
      );
    }
  }
}

export default withRouter(
  connect(
    (state: ApplicationState) => {
      return {
        ...state.clientDetails,
      };
    },
    {
      initializeSubscription:
        SubscriptionStore.actionCreators.initializeSubscription,
      initializeBenefits: BenefitsStore.actionCreators.inititalizeBenefits,
      initializeTeams: TeamsStore.actionCreators.initializeTeams,
      setClientDetails: ClientDetailsStore.actionCreators.setClientDetails,
      deactivateClient: ClientDetailsStore.actionCreators.deactivateClient,
      activateClient: ClientDetailsStore.actionCreators.activateClient,
    }
  )(ClientsDetailsPage)
);
