export enum ClientsIndustries {
    "Auto & Components",
    "Banking & Insurance",
    "Consulting & Professional Services",
    "E-commerce",
    "Energy & Utilities",
    "Food, Beverage & Tobacco",
    "Manufacturing",
    "Other",
    "Outsourcing",
    "Pharma & Healthcare",
    "Public sector",
    "Real Estate",
    "Retail",
    "Software & Services",
    "Telecom & Media",
    "Tourism",
    "Transportation & Logistics",
}