import { Link } from "react-router-dom";
import { FC } from "react";

import { ApplicationPaths } from "../../enums/Common/ApplicationPaths";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import { useRoomBookingsQueries } from "../../hooks/RoomBookings/useRoomBookingsQueries";

interface IActionsSectionProps {
  filters: string;
}

const ActionsSection: FC<IActionsSectionProps> = ({ filters }) => {
  const { getRoomBookingsForExport } = useRoomBookingsQueries();
  const { data, isLoading, error } = getRoomBookingsForExport(
    filters.split("&$count")[0]
  );

  const exportLogs = () => {
    if (isLoading) {
      console.log("Loading data...");
      return;
    }

    if (error) {
      console.error("Error fetching room bookings:", error);
      return;
    }

    if (data?.value) {
      const entities = data.value
        ? data.value.entities
            .map((e) =>
              Object.entries(e)
                .filter(
                  ([key]) =>
                    key != "unixStartsAtWithTimezone" &&
                    key != "timezone" &&
                    key != "workspaceCountryFlag"
                )
                .map(([key, value]) =>
                  typeof value === "string" ? value.replaceAll(",", " ") : value
                )
                .join(",")
            )
            .join("\n")
        : "";
      const csvContent =
        RoomBookingsGrid.Resources.exportRoomBookingLogsHeader +
        "\n" +
        entities;

      const blob = new Blob([csvContent], {
        type: "text/csv;charset=utf-8;",
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        RoomBookingsGrid.Resources.roomsLogs +
          "_" +
          new Date().toLocaleDateString() +
          RoomBookingsGrid.Resources.csvExtension
      );
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <div className="page-actions">
      <div className="btn-big btn-secondary" onClick={exportLogs}>
        <p className="btn-secondary-text">
          {RoomBookingsGrid.Resources.exportRoomsLogsCsv}
        </p>
      </div>

      <Link
        className="btn-big btn-primary"
        to={ApplicationPaths.AddRoomBooking}
      >
        <span className="material-symbols-outlined">add_circle</span>
        <p>{RoomBookingsGrid.Resources.addRoomLog}</p>
      </Link>
    </div>
  );
};

export default ActionsSection;
