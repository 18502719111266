import React, { useRef } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../store/index";
import Nav from "./Nav";
import LoginPage from "../pages/Login/LoginPage";

interface IProps {
  loginState: string;
  role: string;
  children?: React.ReactNode;
  loginRequest?: (path: string) => any;
}

const Layout: React.FC<IProps> = ({ children, role }) => {
  const pageRef = useRef<HTMLDivElement>(null);

  return (
    <div className="pluria">
      <div className="page" ref={pageRef}>
        <div className="sidebar">
          <Nav role={role} />
        </div>
        <div className="page-content">
          <div className="content">{children}</div>
        </div>
      </div>
    </div>
  );
};

const AuthorizedLayout: React.FC<IProps> = (props) => {
  const { loginState } = props;

  if (loginState !== "LOGGED_IN") {
    return <LoginPage />;
  }
  return <Layout {...props} />;
};

const mapStateToProps = (state: ApplicationState) => ({
  loginState: state.login.loginState,
  role: state.login.role,
});

export const AuthorizedLayoutComponent =
  connect(mapStateToProps)(AuthorizedLayout);
