import React from "react";
import { ITeam } from "../../../models/Teams/Interfaces/ITeam";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import General from "../../../resources/AdminUI/General";
import { TeamsStore } from "../../../store/TeamsStore";
import TeamsAndUsersTabs from "../TeamsAndUsers/TeamsAndUsersTabs";
import ClientDetails from "../../../resources/AdminUI/Clients/ClientDetails";
import AddOrEditTeam from "../../../resources/AdminUI/Teams/AddOrEditTeam";
import { withRouter } from "../../Shared/withRouter";
import { NavigateFunction } from "react-router";
import { ApplicationPathBuilder } from "../../../enums/Common/ApplicationPaths";

interface IProps {
    clientId: number,
    loading?: boolean,
    teams?: ITeam[],

    navigate?: NavigateFunction,

    getTeams?: (id: number) => void,
    addOrEditTeam?: (team: ITeam) => any,
}
interface IState {

}
class TeamsContainer extends React.Component<IProps, IState> {

    constructor(props) {
        super(props)
        this.state = {

        }
        this.props.getTeams(this.props.clientId);
    }



    render() {
        if (this.props.loading && this.props.teams.length == 0) {
            return <div>{General.Resources.loading}</div>
        } else {
            return <div className="teams-container">
                <div className="section-top">
                    <div className="section-title">{ClientDetails.Resources.teamsAndUsers}</div>
                    <button className="btn-big btn-primary" onClick={() => this.props.navigate(ApplicationPathBuilder.AddTeam(this.props.clientId))}>{AddOrEditTeam.Resources.addTeam}</button>
                </div>
                
                <TeamsAndUsersTabs
                    componentId={`teams-${this.props.clientId}`}
                    teams={this.props.teams}
                    editTeam={this.props.addOrEditTeam}
                />
            </div>
        }
    }
}

export default withRouter(connect(
    (state: ApplicationState, ownProps: IProps) => {
        return {
            ...ownProps,
            loading: state.teams.loading,
            teams: state.teams.teams
        }
    },
    {
        getTeams: TeamsStore.actionCreators.getTeams,
    }
)(TeamsContainer as any));