import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import update from "immutability-helper";
import { getAddOrEditBookingType, getDatabaseBookingType, getServerSideErrors } from "../utils/utils";
import RoomBookingsService from "../services/RoomBookingsService";

export namespace AddOrEditRoomBookingStore {

    export interface IState {
        id: number;
        loading: boolean;

        userId: number;
        roomId: number;
        statusId: string;
        bookingTypeId: string;

        confirmedAt?: string;
        startsAt?: string;
        endsAt?: string;
        cancelledAt?: string;

        confirmedAtTime?: string;
        startsAtTime?: string;
        endsAtTime?: string;
        cancelledAtTime?: string;

        hasServerSideErrors: boolean,
        errors: string,
    }

    export enum Actions {
        ToggleLoading = "ADD_OR_EDIT_ROOM_LOG_TOGGLE_LOADING",
        Initialize = "ADD_OR_EDIT_ROOM_LOG_INITIALIZE",
        SetDetails = "ADD_OR_EDIT_ROOM_LOG_SET_DETAILS",
        SaveDetails = "ADD_OR_EDIT_ROOM_LOG_SAVE_DETAILS",
        SetErrors = "ADD_OR_EDIT_ROOM_LOG_SET_ERRORS"
    }

    interface IInitialize {
        type: Actions.Initialize;
    }

    interface IToggleLoading {
        type: Actions.ToggleLoading;
        loading: boolean;
    }

    interface ISetDetails {
        type: Actions.SetDetails;
        id: number;
        details: any
    }

    interface ISetErrors {
        type: Actions.SetErrors,
        hasServerSideErrors: boolean,
        errors: string
    }

    type KnownAction = IToggleLoading | IInitialize | ISetDetails
        | ISetErrors;

    export const actionCreators = {

        initializeCreatePage: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.Initialize });
        },

        initializeEditPage: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ToggleLoading, loading: true });

            var details = await RoomBookingsService.getRoomBookingDetailsForEdit(id).then(response => response.value);

            dispatch({
                type: Actions.SetDetails,
                id: id,
                details: details
            });
            dispatch({ type: Actions.ToggleLoading, loading: false });
        },

        saveDetails: (id: number, userId: number, roomId: number, statusId: string, bookingTypeId: string, 
            confirmedAt?: string, startsAt?: string, endsAt?: string, cancelledAt?: string,
            confirmedAtTime?: string, startsAtTime?: string, endsAtTime?: string, cancelledAtTime?: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {

                var bookingTypeIdMapped = getDatabaseBookingType(bookingTypeId);
                var details = {
                    userId: userId,
                    roomId: roomId,
                    statusId: statusId,
                    bookingTypeId: parseInt(bookingTypeIdMapped),
                    confirmedAt: confirmedAt,
                    startsAt: startsAt,
                    endsAt: endsAt,
                    cancelledAt: cancelledAt,
                    confirmedAtTime: confirmedAtTime,
                    startsAtTime: startsAtTime,
                    endsAtTime: endsAtTime,
                    cancelledAtTime: cancelledAtTime
                };
                dispatch({
                    type: Actions.SetDetails,
                    id: id,
                    details: details
                });

                var serverResponse;
                if (id == null) {
                    serverResponse = await RoomBookingsService.addRoomBooking(details).then(response => response);
                } else {
                    serverResponse = await RoomBookingsService.updateRoomBooking(id, details).then(response => response);
                }

                var errors = getServerSideErrors(serverResponse);
                var hasServerSideErrors = !errors ? false : true;
                dispatch({
                    type: Actions.SetErrors,
                    hasServerSideErrors: hasServerSideErrors,
                    errors: errors
                });
            },
            deleteBooking: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
                var serverResponse = await RoomBookingsService.deleteRoomBooking(id).then(response => response);
                var errors = getServerSideErrors(serverResponse);
                var hasServerSideErrors = !errors ? false : true;
                dispatch({
                    type: Actions.SetErrors,
                    hasServerSideErrors: hasServerSideErrors,
                    errors: errors
                });
            },
    
    }

    const initialState: IState = {
        id: null,
        loading: false,

        userId: null,
        roomId: null,
        statusId: "",
        bookingTypeId: "",

        confirmedAt: null,
        startsAt: null,
        endsAt: null,
        cancelledAt: null,

        confirmedAtTime: null,
        startsAtTime: null,
        endsAtTime: null,
        cancelledAtTime: null,

        hasServerSideErrors: null,
        errors: null

    }

    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case Actions.Initialize:
                return initialState
            case Actions.ToggleLoading:
                return { ...currentState, loading: action.loading }
            case Actions.SetDetails:
                var bookingTypeId = getAddOrEditBookingType(action.details.bookingTypeId.toString(), action.details.startsAt, action.details.endsAt)
                return update(currentState,
                    {
                        id: { $set: action.id },
                        userId: { $set: action.details.userId },
                        roomId: { $set: action.details.roomId },
                        statusId: { $set: action.details.statusId },
                        bookingTypeId: { $set: bookingTypeId },
                        confirmedAt: { $set: action.details.confirmedAt },
                        startsAt: { $set: action.details.startsAt },
                        endsAt: { $set: action.details.endsAt },
                        cancelledAt: { $set: action.details.cancelledAt },
                        confirmedAtTime: { $set: action.details.confirmedAtTime },
                        startsAtTime: { $set: action.details.startsAtTime },
                        endsAtTime: { $set: action.details.endsAtTime },
                        cancelledAtTime: { $set: action.details.cancelledAtTime },
                    });
            case Actions.SetErrors:
                return update(currentState,
                    {
                        hasServerSideErrors: { $set: action.hasServerSideErrors },
                        errors: { $set: action.errors },
                    });
            default:
                return currentState || initialState;
        }


    }

}