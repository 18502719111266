// TextInput.tsx
import React, { useEffect, useRef } from "react";

import { IInputProps } from "./FormInput";

const TextInput: React.FC<IInputProps> = ({
  inputType,
  label,
  leadingText,
  placeholder,
  className,
  errors,
  name,
  value,
  readOnly,
  hidden,
  isFocused,
  cursorPosition,
  onKeyDown,
  onChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isFocused && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.selectionEnd = cursorPosition;
    }
  }, [isFocused, cursorPosition]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!readOnly && onChange) {
      onChange(e.target.name, e.target.value, inputRef.current?.selectionEnd);
    }
  };

  return (
    <div className="form-item">
      {label && <label>{label}</label>}
      <div className="input-group">
        {leadingText && <span className="input-group-text">{leadingText}</span>}
        <input
          type={inputType.toString()}
          ref={inputRef}
          placeholder={placeholder}
          className={`${className} ${
            errors.length > 0 ? "input-validation-error" : ""
          }`}
          onChange={handleChange}
          name={name}
          autoComplete={inputType === "password" ? "new-password" : ""}
          defaultValue={value}
          readOnly={readOnly}
          lang="en"
          hidden={hidden}
          onKeyDown={onKeyDown}
        />
      </div>
      {errors.map((err: string) => (
        <span key={err} className="field-validation-error">
          {err}
        </span>
      ))}
    </div>
  );
};

export default TextInput;
