import Globals from "./../../../Globals";

export default class StartDateOptions {
       public startImmediately: string = `Start immediately (from current renewal cycle)`;
       public startNextCycle: string = `Start from next renewal cycle`;

		public static get Resources(): StartDateOptions{
			switch (Globals.currentActiveLanguage) {
				default:
				return new StartDateOptions();
		}
   	}
}
