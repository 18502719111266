import AccountsAndLogs from "../../resources/AdminUI/AccountsAndLogs/AccountsAndLogs";
import { formatDate } from "../../utils/date";
import { BaseEntity } from "../../utils/reactUtils";
import { IAdminLogItem } from "./Interfaces/IAdminLogItem";
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import { defaultStyle as codeStyle } from 'react-syntax-highlighter/dist/esm/styles/hljs';

SyntaxHighlighter.registerLanguage('json', json);

export class AdminLogItem extends BaseEntity implements IAdminLogItem {
    id: number;
    adminUserId: number;
    adminUserName: string;
    createdAt: string;
    controller: string;
    action: string;
    actionArguments: string;
    actionPayload: string;

    constructor(e: IAdminLogItem) {
        super();
        this.additionalConstructor(this, e);

        this.ForProperty(p => "adminUserId")
            .SetDisplayName(AccountsAndLogs.Resources.userHeaderLabel)
            .Show(_self => <div className="column-xsmall">
                <p>{this.adminUserName}</p>
            </div>);

        this.ForProperty(p => "createdAt")
            .SetDisplayName(AccountsAndLogs.Resources.createdAtHeaderLabel)
            .Show(_self => <div className="column-small">
                <p>{formatDate(this.createdAt)}</p>
            </div>);

        this.ForProperty(p => "controller")
            .SetDisplayName(AccountsAndLogs.Resources.controllerHeaderLabel)
            .Show(_self => <div className="column-small">
                <p>{this.controller}</p>
            </div>);

        this.ForProperty(p => "action")
            .SetDisplayName(AccountsAndLogs.Resources.actionHeaderLabel)
            .Show(_self => <div className="column-medium">
                <p>{this.action}</p>
            </div>);

        this.ForProperty(p => "actionArguments")
            .SetDisplayName(AccountsAndLogs.Resources.argumentsHeaderLabel)
            .Show(_self => <div className="column-medium">
                <pre>
                    <SyntaxHighlighter language="json" style={codeStyle}>
                        {JSON.stringify(JSON.parse(this.actionArguments), null, 2)}
                    </SyntaxHighlighter>
                </pre>
            </div>);

        this.ForProperty(p => "actionPayload")
            .SetDisplayName("")
            .Show(_self => <div className="column-wide">
                <div className="span-details-row">
                    <pre>
                        <SyntaxHighlighter wrapLongLines showLineNumbers language="json" style={codeStyle}>
                            {JSON.stringify(JSON.parse(this.actionPayload), null, 2)}
                        </SyntaxHighlighter>
                    </pre>
                </div>
            </div>);
    }
}