import Globals from "./../../../Globals";

export default class Benefits {
  public addServiceText: string = `Add service`;
  public applyFromNextCycleText: string = `Apply from next renewal cycle`;
  public assignServiceToTeamOrUserHeader: string = `Assign service to team or user`;
  public assignText: string = `Assign`;
  public assignToTeamText: string = `Assign to team(s)`;
  public assignToUserText: string = `Assign to user(s)`;
  public chooseBeneficiariesLabel: string = `Choose beneficiares`;
  public completeDescText: string = `Complete description`;
  public completeRenewalValue: string = `Complete renewal value`;
  public completeServiceQuantityText: string = `Complete service quantity`;
  public completeTitleText: string = `Complete title`;
  public costPerEntry: string = `Cost per entry`;
  public createServiceHeader: string = `Create service for {0}`;
  public createServiceText: string = `Create service`;
  public deactivateService: string = `Deactivate service used`;
  public deactivateServiceText: string = `By deactivating this service, it will no longer be available for use.`;
  public deleteService: string = `Delete service used`;
  public deleteServiceConfirmation: string = `By deleting this service, it will no longer be available for use.`;
  public descLabel: string = `description`;
  public descriptionColumnHeader: string = `Description`;
  public entriesText: string = `entries`;
  public forWhomAssignLabel: string = `For whom do you assign?`;
  public limitedBugdet: string = `Limited budget`;
  public limitedEntries: string = `Limited entries`;
  public maxRenewalCyclelabel: string = `Maximum renewal cycles`;
  public maxUsePerUserLabel: string = `Maximum usage per user`;
  public noServicesText: string = `This customer does not have services`;
  public optionalText: string = `Optional`;
  public pricingPlanSource: string = `Pricing plan`;
  public quantityDescLabel: string = `For desks entries, complete with number of entries (ex. 100 for 100 entries offered to user). For room buget, select the currency and complete with budget pool`;
  public quantityLabel: string = `Quantity`;
  public renewalDetailsText: string = `Renewal details`;
  public renewalTypeLabel: string = `Renewal type`;
  public renewalTypeValueText: string = `Renewal type & value`;
  public renewalValueLabel: string = `Renewal value`;
  public renewDatesColumnHeader: string = `Renew Dates`;
  public restrictionsLabel: string = `Restrictions`;
  public selectCurrency: string = `Select service currency`;
  public selectRenewalType: string = `Select renewal type`;
  public serviceCurrency: string = `Currency`;
  public serviceIdText: string = `Service ID: #{0}`;
  public serviceQuanity: string = `Service quantity`;
  public servicesHeader: string = `Services used`;
  public serviceStartDateLabel: string = `Service start date`;
  public serviceTitle: string = `Service title`;
  public serviceTypeLabel: string = `Service type`;
  public promoTypeLabel: string = `Free/ Promo`;
  public sourceColumnHeader: string = `Source`;
  public startImmediatelyText: string = `Starts immediately (from current renewal cycle)`;
  public startNextCycleText: string = `Start from next renewal cycle`;
  public titleColumnHeader: string = `Title & ID`;
  public titleDescExplaination: string = `This information is for internal purposes & is not displayed in mobile app`;
  public titleLabel: string = `Title`;
  public typeAndQuatityColumnHeader: string = `Type & Quantity`;
  public unlimitedLabel: string = `unlimited`;
  public usageColumnHeader: string = `Usage`;
  public userLimitLabel: string = `User limit: {0}`;
  public viewServiceHistoryText: string = `View service used history`;
  public whenToApplyLabel: string = `Apply from when?`;
  public whenToDeactivateLabel: string = `Deactivate from when?`;

  public static get Resources(): Benefits {
    switch (Globals.currentActiveLanguage) {
      default:
        return new Benefits();
    }
  }
}
