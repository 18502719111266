import bind from "bind-decorator";
import Validations from "../../../resources/Common/Validations";
import { String_Format, nameof } from "../../../utils/utils";
import { BaseValidator } from "../Base/BaseValidator";
import AddOrEditRoomBooking from "../../../resources/AdminUI/RoomBookings/AddOrEditRoomBooking";
import { RoomBookingStatuses } from "../../../enums/RoomBookings/RoomBookingStatuses";
import { RoomBookingTypesForAddOrEdit } from "../../../enums/RoomBookings/RoomBookingTypesForAddOrEdit";

export class AddOrEditRoomBookingFormValidator extends BaseValidator {

    constructor() {
        super();
    }

    @bind
    validate(model: any, props: any) {

        this.check(() => model.userId != '' && model.userId != null, nameof(e => e.userId), String_Format(Validations.Resources.required, AddOrEditRoomBooking.Resources.userId));
        this.check(() => model.roomId != '' && model.roomId != null, nameof(e => e.roomId), String_Format(Validations.Resources.required, AddOrEditRoomBooking.Resources.roomId));
        this.check(() => model.statusId != '' && model.statusId != null, nameof(e => e.statusId), String_Format(Validations.Resources.required, AddOrEditRoomBooking.Resources.status));
        this.check(() => model.bookingTypeId != '' && model.bookingTypeId != null, nameof(e => e.bookingTypeId), String_Format(Validations.Resources.required, AddOrEditRoomBooking.Resources.bookingType));
        this.check(() => model.statusId == RoomBookingStatuses.Cancelled ? model.cancelledAt != null && model.cancelledAtTime != null : true, nameof(e => e.cancelledAtDateAndTime), String_Format(Validations.Resources.required, AddOrEditRoomBooking.Resources.cancelledDateAndTime));
        this.check(() => model.statusId == RoomBookingStatuses.Confirmed ? model.confirmedAt != null && model.confirmedAtTime != null : true, nameof(e => e.confirmedAtDateAndTime), String_Format(Validations.Resources.required, AddOrEditRoomBooking.Resources.confirmedDateAndTime));

        this.check(() => model.bookingTypeId != RoomBookingTypesForAddOrEdit.Hourly.toString()
            && (model.statusId == RoomBookingStatuses.Ended || model.statusId == RoomBookingStatuses.Pending || !props.isEditMode) ?
            model.startsAt != null && model.startsAtTime != null : true, nameof(e => e.startsAtDateAndTime), String_Format(Validations.Resources.required, AddOrEditRoomBooking.Resources.bookingStartsAt));

        this.check(() => model.bookingTypeId != RoomBookingTypesForAddOrEdit.Hourly.toString()
            && model.bookingTypeId != RoomBookingTypesForAddOrEdit.DailyOneDay.toString()
            && (model.statusId == RoomBookingStatuses.Ended || model.statusId == RoomBookingStatuses.Pending || !props.isEditMode) ? model.endsAt != null && model.endsAtTime != null : true, nameof(e => e.endsAtDateAndTime), String_Format(Validations.Resources.required, AddOrEditRoomBooking.Resources.bookingEndsAt));

        this.check(() => model.bookingTypeId != RoomBookingTypesForAddOrEdit.Monthly.toString()
            && model.bookingTypeId != RoomBookingTypesForAddOrEdit.DailyInterval.toString()
            && model.bookingTypeId != RoomBookingTypesForAddOrEdit.DailyOneDay.toString()
            && (model.statusId == RoomBookingStatuses.Ended || model.statusId == RoomBookingStatuses.Pending || !props.isEditMode)
            ? model.startsAt != null && model.startsAtTime != null && model.endsAt != null && model.endsAtTime != null : true, nameof(e => e.startsAndEndsAtDateAndTime), String_Format(Validations.Resources.required, AddOrEditRoomBooking.Resources.bookingInterval));

        return this.getErrorsDictionary();
    }
}