import { Modal } from "antd";
import { connect } from "react-redux";
import { NavigateFunction } from "react-router";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { ApplicationPathBuilder } from "../../../enums/Common/ApplicationPaths";
import { BenefitItem } from "../../../models/Benefits/BenefitItem";
import Benefits from "../../../resources/AdminUI/Benefits/Benefits";
import General from "../../../resources/AdminUI/General";
import ErrorMessages from "../../../resources/Common/ErrorMessages";
import { ApplicationState } from "../../../store";
import { BenefitsStore } from "../../../store/BenefitsStore";
import { getPromiseFromAction } from "../../../utils/utils";
import { DeactivateBenefitForm } from "../../Clients/Forms/DeactivateBenefitForm";
import DropdownButton from "../DropdownButton";
import ModalDialog from "../ModalDialog";
import { withRouter } from "../withRouter";
import { useBenefitsTable } from "./useBenefitsTable";
import AssignBenefitForm from "../../Clients/Forms/AssignBenefitForm";
import BenefitHistoryTable from "../../Clients/Tables/BenefitHistoryTable";

const { confirm } = Modal;

interface IProps {
  benefits?: BenefitItem[];
  clientId?: number;
  beneficiaryName: string;
  userId?: number;
  hiddenColumns?: string[];
  hasServerSideErrors?: boolean;
  errors?: string;
  navigate?: NavigateFunction;
  getClientBenefits?: (clientId: number) => any;
  deactivateBenefit?: (
    benefitId: number,
    deactivateFromCurrentCycle: boolean
  ) => any;
  deleteBenefit?: (benefitId: number) => any;
}

const BenefitsTable: React.FC<IProps> = (props) => {
  const {
    state,
    showAssignBenefitModal,
    hideAssignBenefitModal,
    showDeactivateBenefitModal,
    hideDeactivateBenefitModal,
    showBenefitsHistoryModal,
    hideBenefitsHistoryModal,
    getAllBenefitStartDateOptions,
    getAllBenefitBeneficiaryList,
  } = useBenefitsTable();

  const tableClass = "table";
  const benefitHistoryHiddenColumns = [
    "id",
    "name",
    "featureId",
    "maxValue",
    "currency",
    "hasUserLimit",
  ];

  const showOnDeleteBenefitModal = (benefit: BenefitItem) => {
    confirm({
      title: General.Resources.areYouSureModalLabel,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>{Benefits.Resources.deleteServiceConfirmation}</p>
        </div>
      ),
      okText: Benefits.Resources.deleteService,
      okType: "danger",
      cancelText: General.Resources.cancelButtonLabel,
      onOk: () => handleOnDeleteBenefit(benefit.id),
      onCancel() {},
    });
  };

  const handleOnDeactivateBenefit = (
    benefitId: number,
    deactivateFromCurrentCycle: boolean
  ) => {
    getPromiseFromAction(
      props.deactivateBenefit(benefitId, deactivateFromCurrentCycle)
    ).then(() => {
      if (props.hasServerSideErrors) {
        Modal.error({ title: ErrorMessages.Resources.error });
      }
    });
  };

  const handleOnDeleteBenefit = (benefitId: number) => {
    getPromiseFromAction(props.deleteBenefit(benefitId)).then(() => {
      if (props.hasServerSideErrors) {
        Modal.error({ title: ErrorMessages.Resources.generalErrorMessage });
      }
    });
  };

  return (
    <div className="table-wrapper">
      <table className={tableClass}>
        {/* Table header */}
        <thead>
          <tr>
            {props.hiddenColumns?.filter((c) => c === "actions").length ===
              0 && <th>{General.Resources.actions}</th>}
            {props?.benefits[0].TrueProperties.filter(
              (p) => props.hiddenColumns?.indexOf(p) < 0
            ).map((p) => (
              <th key={p}>{props?.benefits[0].GetDisplayNameForProperty(p)}</th>
            ))}
          </tr>
        </thead>
        {/* Table body */}
        <tbody>
          {props.benefits?.map((benefit) => (
            <tr key={benefit.id}>
              {props.hiddenColumns?.filter((c) => c === "actions").length ===
                0 && (
                <td className="">
                  <div className="client-benefits-actions">
                    {/* Action buttons */}
                    <button
                      className="btn-table-action client-benefit-table"
                      onClick={() =>
                        props.navigate(
                          ApplicationPathBuilder.EditService(benefit.id)
                        )
                      }
                    >
                      <span className="material-symbols-outlined">edit</span>
                    </button>
                    <DropdownButton
                      className="btn-table-action client-benefit-table"
                      items={[
                        {
                          icon: "toggle_off",
                          text: Benefits.Resources.deactivateService,
                          action: () => showDeactivateBenefitModal(benefit),
                        },
                        {
                          icon: "history",
                          text: Benefits.Resources.viewServiceHistoryText,
                          action: () => showBenefitsHistoryModal(benefit),
                        },
                        {
                          icon: "delete",
                          text: Benefits.Resources.deleteService,
                          action: () => showOnDeleteBenefitModal(benefit),
                        },
                      ]}
                      icon={
                        <span className="material-symbols-outlined">
                          more_vert
                        </span>
                      }
                    />
                    {/* Modals */}
                    <ModalDialog
                      title=""
                      open={
                        state.isDeactivateBenefitModalOpen &&
                        state.selectedBenefit === benefit
                      }
                      onCancel={hideDeactivateBenefitModal}
                    >
                      <DeactivateBenefitForm
                        benefitId={benefit.id}
                        contentText={Benefits.Resources.deactivateServiceText}
                        confirmButtonText={Benefits.Resources.deactivateService}
                        onCancelCallback={hideDeactivateBenefitModal}
                        onSaveCallback={handleOnDeactivateBenefit}
                      />
                    </ModalDialog>
                    <ModalDialog
                      title={Benefits.Resources.servicesHeader}
                      open={
                        state.isShowBenefitsHistoryModalOpen &&
                        state.selectedBenefit === benefit
                      }
                      onCancel={hideBenefitsHistoryModal}
                      width={700}
                    >
                      <BenefitHistoryTable
                        benefitId={benefit.id}
                        hiddenColumns={benefitHistoryHiddenColumns}
                      />
                    </ModalDialog>
                    <button
                      className="btn-big btn-primary"
                      onClick={() => showAssignBenefitModal(benefit)}
                    >
                      {Benefits.Resources.assignText}
                    </button>
                    <ModalDialog
                      title=""
                      open={
                        state.isAssignBenefitModalOpen &&
                        state.selectedBenefit === benefit
                      }
                      onCancel={hideAssignBenefitModal}
                    >
                      <AssignBenefitForm
                        clientId={props.clientId}
                        benefitId={benefit.id}
                        benefitBeneficiaryList={getAllBenefitBeneficiaryList()}
                        benefitStartDateOptions={getAllBenefitStartDateOptions()}
                        onCancelCallback={hideAssignBenefitModal}
                      />
                    </ModalDialog>
                  </div>
                </td>
              )}
              {benefit.TrueProperties.filter(
                (p) => props.hiddenColumns?.indexOf(p) < 0
              ).map((p) => (
                <td key={p}>{benefit.GetDisplayValueForProperty(p)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default withRouter(
  connect(
    (state: ApplicationState, ownProps: IProps) => ({
      benefits: state.benefits.benefits,
      hasServerSideErrors: state.benefits.hasServerSideErrors,
      errors: state.benefits.errors,
      ...ownProps,
    }),
    {
      getClientBenefits: BenefitsStore.actionCreators.getClientBenefits,
      deactivateBenefit: BenefitsStore.actionCreators.deactivateBenefit,
      deleteBenefit: BenefitsStore.actionCreators.deleteBenefit,
    }
  )(BenefitsTable)
);
