import React from "react";
import Helmet from 'react-helmet';
import AccountsAndLogs from "../../resources/AdminUI/AccountsAndLogs/AccountsAndLogs";
import AdminAccounts from "../../components/AccountsAndLogs/Containers/AdminAccounts";
import bind from "bind-decorator";
import AdminLogs from "../../components/AccountsAndLogs/Containers/AdminLogs";

interface IState {
    isAdminAccountsSelected: boolean
}

export class AccountsAndLogsPage extends React.Component<any, IState> {

    constructor(props) {
        super(props)

        this.state = {
            isAdminAccountsSelected: true
        }
    }

    @bind
    onSuperAdminAccountsClick() {
        this.setState({ isAdminAccountsSelected: true });
    }

    @bind
    onAccountsLogsClick() {
        this.setState({ isAdminAccountsSelected: false });
    }

    render() {
        return <div>
            <Helmet to={AccountsAndLogs.Resources.accountsAndLogsHeader}></Helmet>

            <div className="page-top-container">
                <div className="page-title">{AccountsAndLogs.Resources.accountsAndLogsHeader}</div>

                <div className="page-actions">

                    <div className="btn-big btn-primary" onClick={this.onSuperAdminAccountsClick}>
                        <p className="btn-secondary-text">{AccountsAndLogs.Resources.superAdminAccountsLabel}</p>
                    </div>

                    <div className="btn-big btn-primary" onClick={this.onAccountsLogsClick}>
                        <p className="btn-secondary-text">{AccountsAndLogs.Resources.adminLogsLabel}</p>
                    </div>
                </div>
            </div>
            <div>
                {
                    this.state.isAdminAccountsSelected ?
                        <AdminAccounts />
                        : <AdminLogs />
                }
            </div>
        </div>
    }
}