import { BenefitItem } from "../models/Benefits/BenefitItem";
import { PayloadTypes } from "../enums/Common/PayloadTypes";
import { IBenefit } from "../models/Benefits/Interfaces/IBenefit";
import { EntitiesList } from "../models/Common/IEntitiesList";
import Result from "../models/Common/Result";
import { AppSettings } from "../utils/settings";
import { ServiceBase } from "./ServiceBase";
import { IAddOrEditBenefit } from "../models/Benefits/Interfaces/IAddOrEditBenefit";
import { IBenefitHistory } from "../models/Benefits/Interfaces/IBenefitHistory";
import { BenefitHistoryItem } from "../models/Benefits/BenefitHistoryItem";

export default class BenefitService extends ServiceBase {

    static getCompanyTeamsAndUsersForAssignBenefit = (clientId: number, benefitId: number): Promise<Result<any>> => {
        try {
            var result = BenefitService.requestJsonFetch<any>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}/benefits/${benefitId}/companies/${clientId}`
            });
            return result;
        } catch (error) { }
    }
    
    static assignBenefitToUsers = (benefitId: number, beneficiaryIds: Number[], benefitStartDateOption: number): Promise<Result<[]>> => {
        var data = JSON.stringify({
            benefitId: benefitId,
            beneficiaryIds: beneficiaryIds,
            benefitStartDateOption: benefitStartDateOption
        });

        var result = BenefitService.sendData<[]>({
            method: "POST",
            url: `${AppSettings.CommonSettings.ApiUrl}/benefits/users/assign`,
            data: data
        }, PayloadTypes.Json);
        return result;
    }
    
    static assignBenefitToTeams = (benefitId: number, beneficiaryIds: Number[], benefitStartDateOption: number): Promise<Result<[]>> => {
        var data = JSON.stringify({
            benefitId: benefitId,
            beneficiaryIds: beneficiaryIds,
            benefitStartDateOption: benefitStartDateOption
        });

        var result = BenefitService.sendData<[]>({
            method: "POST",
            url: `${AppSettings.CommonSettings.ApiUrl}/benefits/teams/assign`,
            data: data
        }, PayloadTypes.Json);
        return result;
    }

    static mapBenefitsToBenefitItemsList = (benefitList: any): any => {
        var entities = new EntitiesList<IBenefit>();
        entities.entities = benefitList.map((e) => new BenefitItem(e));
        
        return entities.entities;
    }

    static addBenefit = (benefit: IAddOrEditBenefit): Promise<Result<IAddOrEditBenefit>> => {
        var result = BenefitService.sendData<IAddOrEditBenefit>({
            method: "POST",
            url: `${AppSettings.CommonSettings.ApiUrl}/benefits`,
            data: JSON.stringify(benefit)
        }, PayloadTypes.Json);

        return result;
    }

    static editBenefit = (benefit: IAddOrEditBenefit): Promise<Result<IAddOrEditBenefit>> => {

        var result = BenefitService.sendData<IAddOrEditBenefit>({
            method: "PUT",
            url: `${AppSettings.CommonSettings.ApiUrl}/benefits/${benefit.id}`,
            data: JSON.stringify(benefit)
        }, PayloadTypes.Json);

        return result;
    }

    static deactivateBenefit = (benefitId: number, deactivateFromCurrentCycle: boolean): Promise<Result<any>> => {

        var result = BenefitService.sendData<any>({
            method: "PUT",
            url: `${AppSettings.CommonSettings.ApiUrl}/benefits/${benefitId}/deactivate`,
            data: JSON.stringify(deactivateFromCurrentCycle)
        }, PayloadTypes.Json);

        return result;
    }

    static deleteBenefit = (benefitId: number): Promise<Result<any>> => {

        var result = BenefitService.sendData<any>({
            method: "DELETE",
            url: `${AppSettings.CommonSettings.ApiUrl}/benefits/${benefitId}/delete`,
            data: null,
        }, PayloadTypes.Json);

        return result;
    }

    static deleteTeamBenefit = (benefitId: number, teamId: number): Promise<Result<any>> => {
        var result = BenefitService.sendData<any>({
            method: "DELETE",
            url: `${AppSettings.CommonSettings.ApiUrl}/benefits/${benefitId}/teams/${teamId}`,
            data: null
        }, PayloadTypes.Json);

        return result;
    }

    static getBenefitHistory = (benefitId: number): Promise<Result<IBenefitHistory[]>> => {
        try {
            var result = BenefitService.requestJsonFetch<IBenefitHistory[]>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}/benefits/${benefitId}/history`
            });
            return result;
        } catch (error) { }
    }

    static mapBenefitHistoryToBenefitHistoryItemsList = (benefitItems: any): any => {
        var entities = new EntitiesList<IBenefit>();
        entities.entities = benefitItems.map((e) => new BenefitHistoryItem(e));
        
        return entities.entities;
    }

    static getBenefitDetailsForEdit = (benefitId: number): Promise<Result<IAddOrEditBenefit>> => {
        try {
            var result = BenefitService.requestJsonFetch<IAddOrEditBenefit>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}/benefits/${benefitId}/form-details`
            });
            return result;
        } catch (error) { }
    }
    
}