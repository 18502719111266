import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";

import { ApplicationState } from "../store";
import { ApplicationPaths } from "../enums/Common/ApplicationPaths";
import { NavLink } from "react-router-dom";
import { getPromiseFromAction } from "../utils/utils";
import RoomBookingsService from "../services/RoomBookingsService";
import MenuItems from "../resources/AdminUI/MenuItems";
import { LoginStore } from "../store/LoginStore";
import { AppSettings } from "../utils/settings";

interface IProps {
  loading?: boolean;
  logout?: () => void;
  role?: string;
}

const Nav: React.FC<IProps> = ({ logout, role }) => {
  const [arePendingRoomRequests, setArePendingRoomRequests] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    timerRef.current = setInterval(() => {
      getPromiseFromAction(
        RoomBookingsService.getNumberOfPendingRoomRequests() as any
      ).then((res) => {
        const hasPendingRequests = !res || (res as any).value > 0;
        setArePendingRoomRequests(hasPendingRequests);
      });
    }, 60000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <div className="mobile-menu-icon" onClick={toggleMenu}>
        <MenuOutlined />
      </div>
      <div className={`overlay ${isMenuOpen ? "open" : ""}`} />
      <section className={`nav-container ${isMenuOpen ? "open" : ""}`}>
        <div className="close-menu-icon" onClick={toggleMenu}>
          <CloseOutlined />
        </div>
        <div className="logo-container">
          <img src="/assets/images/logo_app.svg" className="logo" alt="Logo" />
          {AppSettings.CommonSettings.ApiUrl !==
            "https://pluria-admin-api-p.azurewebsites.net/api/admin" && (
            <div>Staging or development environment</div>
          )}
        </div>

        <div className="account-info">
          <div className="initials">{MenuItems.Resources.pi}</div>
          <div>
            <div className="dashboard-user">
              {MenuItems.Resources.pluriaInternational}
            </div>
            <div className="dashboard-name">
              {MenuItems.Resources.superAdmin}
            </div>
          </div>
        </div>

        <div className="menu">
          <div className="menu-section">
            <div className="menu-section-title">
              {MenuItems.Resources.general}
            </div>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active menu-item-container" : "menu-item-container"
              }
              to={ApplicationPaths.Spaces}
              onClick={closeMenu}
            >
              <div className="material-symbols-outlined">corporate_fare</div>
              <div className="title">{MenuItems.Resources.spaces}</div>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active menu-item-container" : "menu-item-container"
              }
              to={ApplicationPaths.Users}
              onClick={closeMenu}
            >
              <div className="material-symbols-outlined">group</div>
              <div className="title">{MenuItems.Resources.users}</div>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active menu-item-container" : "menu-item-container"
              }
              to={ApplicationPaths.Customers}
              onClick={closeMenu}
            >
              <div className="material-symbols-outlined">list</div>
              <div className="title">{MenuItems.Resources.customers}</div>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active menu-item-container" : "menu-item-container"
              }
              to={ApplicationPaths.ManageAccounts}
              onClick={closeMenu}
            >
              <div className="material-symbols-outlined">key</div>
              <div className="title">{MenuItems.Resources.manageAccounts}</div>
            </NavLink>
          </div>

          <div className="menu-section">
            <div className="menu-section-title">
              {MenuItems.Resources.activity}
            </div>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active menu-item-container" : "menu-item-container"
              }
              to={ApplicationPaths.DesksLogs}
              onClick={closeMenu}
            >
              <div className="material-symbols-outlined">calendar_month</div>
              <div className="title">{MenuItems.Resources.desksLogs}</div>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active menu-item-container" : "menu-item-container"
              }
              to={ApplicationPaths.RoomsLogs}
              onClick={closeMenu}
            >
              <div className="material-symbols-outlined">calendar_month</div>
              <div className="title">{MenuItems.Resources.roomsLogs}</div>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active menu-item-container" : "menu-item-container"
              }
              to={ApplicationPaths.PendingRoomRequests}
              onClick={closeMenu}
            >
              <div className="material-symbols-outlined">hourglass_bottom</div>
              <div className="title">{MenuItems.Resources.pendingRequests}</div>
            </NavLink>
          </div>

          {role && role === "superadmin" && (
            <div className="menu-section">
              <div className="menu-section-title">
                {MenuItems.Resources.settings}
              </div>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "active menu-item-container"
                    : "menu-item-container"
                }
                to={ApplicationPaths.CountriesAndCities}
                onClick={closeMenu}
              >
                <div className="material-symbols-outlined">public</div>
                <div className="title">{MenuItems.Resources.manageCities}</div>
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "active menu-item-container"
                    : "menu-item-container"
                }
                to={ApplicationPaths.AccountsAndLogs}
                onClick={closeMenu}
              >
                <div className="material-symbols-outlined">settings</div>
                <div className="title">
                  {MenuItems.Resources.accountsAndLogs}
                </div>
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "active menu-item-container"
                    : "menu-item-container"
                }
                to={ApplicationPaths.EditSpacePrice}
                onClick={closeMenu}
              >
                <div className="material-symbols-outlined">update</div>
                <div className="title">
                  {MenuItems.Resources.editSpacePrice}
                </div>
              </NavLink>
            </div>
          )}

          <div className="menu-section">
            <div className="menu-section-title">
              {MenuItems.Resources.settings}
            </div>
            <NavLink
              className="menu-item-container"
              to={ApplicationPaths.EmptyPath}
              onClick={() => {
                closeMenu();
                logout && logout();
              }}
            >
              {MenuItems.Resources.logout}
            </NavLink>
          </div>
        </div>
      </section>
    </div>
  );
};

export default connect((state: ApplicationState) => ({}), {
  logout: LoginStore.actionCreators.logout,
})(Nav);
