import React, { useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { LoginStore } from "../../store/LoginStore";
import { withRouter } from "../../components/Shared/withRouter";
import { Button, Input } from "antd";
import { ApplicationState } from "../../store";

const LoginPage = ({
  startLogin: startLoginReq,
  validateChallenge: validateChallengeReq,
  loginState,
  loginError,
}) => {
  const [email, setEmail] = useState("");
  const [challenge, setChallenge] = useState("");
  const [emailError, setEmailError] = useState("");
  const [challengeError, setChallengeError] = useState("");

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const startLogin = () => {
    if (!email) {
      setEmailError("Please enter an email address");
      return;
    }

    if (!isValidEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    }

    setEmailError("");
    startLoginReq(email);
  };

  const validateChallenge = () => {
    if (!challenge) {
      setChallengeError("Please enter the verification code");
      return;
    }

    setChallengeError("");
    validateChallengeReq(challenge);
  };

  const errorMessageStyle = {
    color: "#ff4d4f",
    fontSize: "14px",
    marginLeft: 0,
  };

  const renderLoginStep = () => {
    if (loginState === "AWAITING_CHALLENGE") {
      return (
        <div className="input-fields">
          <div className="description">
            Please enter the verification code sent to your email
          </div>
          <Input
            placeholder="Verification Code"
            value={challenge}
            onChange={(e) => {
              setChallenge(e.target.value);
              setChallengeError("");
            }}
            status={challengeError ? "error" : ""}
          />
          {challengeError && (
            <div style={errorMessageStyle}>{challengeError}</div>
          )}
          <div className="button">
            <Button
              className="login-ant-btn-default"
              onClick={validateChallenge}
            >
              Verify
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className="input-fields">
        <Input
          placeholder="Email address"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError("");
          }}
          type="email"
          status={emailError ? "error" : ""}
        />
        {emailError && <div style={errorMessageStyle}>{emailError}</div>}
        <div className="button">
          <Button className="login-ant-btn-default" onClick={startLogin}>
            Continue
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="page login">
      <Helmet title={"Login"} />

      <div className="auth-box-container">
        {loginState === "SENDING_CHALLENGE" && (
          <div>Sending verification code...</div>
        )}
        {loginState === "VALIDATING_CHALLENGE" && <div>Verifying...</div>}
        {loginState === "LOGIN_FAILED" && <div>{loginError}</div>}

        <div className="input-section">
          <div className="logo-container">
            <img
              src="/assets/images/logo_app_dark.svg"
              className="logo-auth"
              alt="logo"
            />
          </div>

          <div className="title">Super Admin</div>

          <div className="description">
            <span className="highlighted">Welcome!</span> Please complete the
            information below to sign in
          </div>

          {renderLoginStep()}
        </div>

        <div className="illustration">
          <div className="photo-container">
            <img
              src="/assets/images/bg_login.png"
              className="auth-illustration"
              alt="login illustration"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  connect(
    (state: ApplicationState) => ({
      ...state.login,
    }),
    LoginStore.actionCreators
  )(LoginPage)
);
