import bind from "bind-decorator";
import Validations from "../../../resources/Common/Validations";
import { String_Format, isEmail, nameof } from "../../../utils/utils";
import { BaseValidator } from "../Base/BaseValidator";
import AccountsAndLogs from "../../../resources/AdminUI/AccountsAndLogs/AccountsAndLogs";

export class AddAminCredentialsValidator extends BaseValidator {

    constructor() {
        super();
    }

    @bind
    validate(model: any, props: any) {
        
        this.check(() => model.name != '' && model.name != null, nameof(e => e.name), String_Format(Validations.Resources.required, AccountsAndLogs.Resources.nameLabel));
        // this.check(() => !model.name || model.name.length <= 50, nameof(e => e.name), String_Format(Validations.Resources.maxLength, 50));

        this.check(() => model.email != '' && model.email != null, nameof(e => e.email), String_Format(Validations.Resources.required, AccountsAndLogs.Resources.emailAddressLabel));
        this.check(() => !model.email || isEmail(model.email), nameof(e => e.email), Validations.Resources.emailNotValid);
        // this.check(() => !model.email || model.email.clength <= 50, nameof(e => e.email), String_Format(Validations.Resources.maxLength, 50));
        
        this.check(() => props.id || (model.password != '' && model.password != null), nameof(e => e.password), String_Format(Validations.Resources.required, AccountsAndLogs.Resources.passwordLabel));
        this.check(() => !model.password || model.password.length >= 8, nameof(e => e.password), Validations.Resources.passwordNotValid);

        return this.getErrorsDictionary();
    }
}