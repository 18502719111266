import { useState, useCallback } from "react";

import Validations from "../../../resources/Common/Validations";
import { String_Format } from "../../../utils/utils";
import ClientsService from "../../../services/ClientsService";

export const useLogoUpload = (
  onSaveCallback: (logo: string) => void,
  initialImage?: string,
) => {
  const [currentImage, setCurrentImage] = useState<string>(initialImage);
  const [errors, setErrors] = useState<string>("");

  const acceptedFormats = ["jpg", "jpeg", "png", "webp"];

  const getFileExtension = (filename: string) => {
    const parts = filename.split(".");
    return parts[parts.length - 1];
  };

  const hasFileExtension = (file: File, ...acceptedExtensions: string[]) => {
    const fileExtension = getFileExtension(file.name);
    return acceptedExtensions.includes(fileExtension);
  };

  const handleLogoUpload = useCallback(
    (img: File) => {
      let imageToBeSaved;
      const newErrors = [];

      if (hasFileExtension(img, ...acceptedFormats)) {
        if (img.size / 1024 ** 2 < 2) {
          imageToBeSaved = { id: img.name, file: img };
          ClientsService.uploadCompanyFile(
            imageToBeSaved
          )
            .then((result) => {
              const currentImage = result.value.path;
              setCurrentImage(currentImage);
              onSaveCallback(currentImage);
            })
            .catch((error) => {
              newErrors.push(error.message);
            });
        } else {
          newErrors.push(
            String_Format(Validations.Resources.fileIsTooLarge, img.name)
          );
        }
      } else {
        newErrors.push(
          String_Format(
            Validations.Resources.theFileSelectedIsNotTheCorrectFormat,
            img.name
          )
        );
      }

      setErrors(newErrors.join(", "));
    },
    [onSaveCallback, acceptedFormats]
  );

  const uploadImageWithInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        handleLogoUpload(Array.from(e.target.files)[0]);
      }
    },
    [handleLogoUpload]
  );

  const uploadImageWithDragDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      const files = e.dataTransfer.files;
      handleLogoUpload(files[0]);
    },
    [handleLogoUpload]
  );

  const removeImage = useCallback(() => {
    setCurrentImage("");
    onSaveCallback("");
  }
  , [onSaveCallback]);

  return {
    currentImage,
    errors,
    uploadImageWithInput,
    uploadImageWithDragDrop,
    removeImage,
  };
};
