import React from "react";
import ISpaceGroup from "../../../models/ManageAccounts/ISpaceGroup";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import SpaceGroupCard from "../Cards/SpaceGroupCard";
import General from "../../../resources/AdminUI/General";
import Loading from "../../Shared/Loading";

interface IProps {
  loading?: boolean;
  spaceGroups?: ISpaceGroup[];
}

class SpaceGroupGrid extends React.PureComponent<IProps, any> {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    if (!this.props.spaceGroups || this.props.spaceGroups.length == 0) {
      return <div>{General.Resources.noElements}</div>;
    } else {
      return (
        <div className="accounts-scrollable-list">
          {this.props.spaceGroups.map((spaceGroup) => (
            <SpaceGroupCard key={spaceGroup.id} spaceGroup={spaceGroup} />
          ))}
        </div>
      );
    }
  }
}

export default connect((state: ApplicationState, ownProps: IProps) => {
  return {
    loading: state.manageAccounts.loading,
    spaceGroups: state.manageAccounts.spaceGroups,
    ...ownProps,
  };
}, null)(SpaceGroupGrid as any);
