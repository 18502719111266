import bind from "bind-decorator"
import React from "react"
import AccountsAndLogs from "../../../resources/AdminUI/AccountsAndLogs/AccountsAndLogs"
import ModalDialog from "../../Shared/ModalDialog";
import General from "../../../resources/AdminUI/General";
import { Modal, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { AdminAccountsStore } from "../../../store/AdminAccountsStore";
import { getPromiseFromAction } from "../../../utils/utils";
import AddOrEditAdminCredentialsForm from "../Forms/AddOrEditAdminCredentialsForm";
import ErrorMessages from "../../../resources/Common/ErrorMessages";
const { confirm } = Modal;

interface IProps {
    id: number,
    name: string,
    email: string,
    isSuperAdmin: string,
    deactivated: boolean,

    hasServerSideErrors?: boolean,

    deactivateAdminUser: (id: number) => any,
    activateAdminUser: (id: number) => any,
    getAdminUsers: () => void,
}

interface IState {
    isChangeNameAndEmailModalOpen: boolean;
    isChangePasswordModalOpen: boolean;
}

class AdminUserCard extends React.PureComponent<IProps, IState> {

    constructor(props) {
        super(props)

        this.state = {
            isChangeNameAndEmailModalOpen: false,
            isChangePasswordModalOpen: false
        }
    }

    @bind
    showChangeNameAndEmailModal() {
        this.setState({ isChangeNameAndEmailModalOpen: true });
    }

    @bind
    hideChangeNameAndEmailModal() {
        this.setState({ isChangeNameAndEmailModalOpen: false });
    }

    @bind
    showChangePasswordModal() {
        this.setState({ isChangePasswordModalOpen: true });
    }

    @bind
    hideChangePasswordModal() {
        this.setState({ isChangePasswordModalOpen: false });
    }

    @bind
    showDeactivateModal() {
        confirm({
            title: General.Resources.areYouSureModalLabel,
            icon: <ExclamationCircleOutlined />,
            content: AccountsAndLogs.Resources.deactivateAdminDesc,
            okText: General.Resources.deactivateLabel,
            okType: "danger",
            cancelText: General.Resources.cancelButtonLabel,
            onOk: () => {
                this.handleDeactivateAdmin();
            },
            onCancel() {
            },
        });
    }

    @bind
    showActivateModal() {
        confirm({
            title: General.Resources.areYouSureModalLabel,
            icon: <ExclamationCircleOutlined />,
            content: AccountsAndLogs.Resources.activateAdminDesc,
            okText: General.Resources.activateLabel,
            cancelText: General.Resources.cancelButtonLabel,
            onOk: () => {
                this.handleActivateAdmin();
            },
            onCancel() {
            },
        });
    }

    @bind
    handleDeactivateAdmin() {
        getPromiseFromAction(this.props.deactivateAdminUser(this.props.id))
            .then(() => {
                if (this.props.hasServerSideErrors) {
                    Modal.error({
                        title: ErrorMessages.Resources.generalErrorMessage,
                    });
                } else {
                    this.props.getAdminUsers();
                }
            })
    }

    @bind
    handleActivateAdmin() {
        getPromiseFromAction(this.props.activateAdminUser(this.props.id))
            .then(() => {
                if (this.props.hasServerSideErrors) {
                    Modal.error({
                        title: ErrorMessages.Resources.generalErrorMessage,
                    });
                } else {
                    this.props.getAdminUsers();
                }
            })
    }

    render() {
        return <div className="account-item-container">

            <div className="top-section">
                <div className="account-user">{this.props.name}</div>
                <Tooltip title={this.props.deactivated ? AccountsAndLogs.Resources.activateUser : AccountsAndLogs.Resources.deactivateUser}>
                    <div className="material-symbols-outlined" onClick={this.props.deactivated ? this.showActivateModal : this.showDeactivateModal}>
                        {this.props.deactivated ? "visibility" : "visibility_off"}
                    </div>
                </Tooltip>
            </div>



            <div className="display-flex line-icon-text">
                <div className="material-symbols-outlined">email</div>
                <div className="text">{this.props.email}</div>
            </div>

            <div className="display-flex line-icon-text">
                <div className="material-symbols-outlined">account_circle</div>
                <div className="text"> {this.props.deactivated ? AccountsAndLogs.Resources.deactivatedLabel : AccountsAndLogs.Resources.activeLabel}</div>
            </div>

            <div>
                <div className="header">{AccountsAndLogs.Resources.roleLabel}</div>
                <p>{this.props.isSuperAdmin ? AccountsAndLogs.Resources.superAdminLabel : AccountsAndLogs.Resources.normalAdminLabel}</p>
            </div>

            <div className="buttons">

                <button
                    className="btn-big btn-secondary in-form"
                    onClick={this.showChangeNameAndEmailModal}>
                    {AccountsAndLogs.Resources.changeNameAndEmailLabel}
                </button>
                <ModalDialog
                    title={""}
                    open={this.state.isChangeNameAndEmailModalOpen}
                    onCancel={this.hideChangeNameAndEmailModal}>
                    <AddOrEditAdminCredentialsForm
                        title={AccountsAndLogs.Resources.changeNameAndEmailHeader}
                        description={AccountsAndLogs.Resources.changeNameAndEmailDesc}
                        isChangePassword={false}
                        id={this.props.id}
                        name={this.props.name}
                        email={this.props.email}
                        onCancelCallback={this.hideChangeNameAndEmailModal}
                    />
                </ModalDialog>

                <button
                    className="btn-big btn-secondary in-form"
                    onClick={this.showChangePasswordModal}>
                    {AccountsAndLogs.Resources.changePasswordLabel}
                </button>
                <ModalDialog
                    title={""}
                    open={this.state.isChangePasswordModalOpen}
                    onCancel={this.hideChangePasswordModal}>
                    <AddOrEditAdminCredentialsForm
                        title={AccountsAndLogs.Resources.changePasswordLabel}
                        description={AccountsAndLogs.Resources.changePasswordDesc}
                        id={this.props.id}
                        name={this.props.name}
                        email={this.props.email}
                        isChangePassword={true}
                        onCancelCallback={this.hideChangePasswordModal}
                    />
                </ModalDialog>

            </div>

        </div>
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            hasServerSideErrors: state.adminAccounts.hasServerSideErrors,
        }
    },
    {
        deactivateAdminUser: AdminAccountsStore.actionCreators.deactivateAdminAccount,
        activateAdminUser: AdminAccountsStore.actionCreators.activateAdminAccount,
        getAdminUsers: AdminAccountsStore.actionCreators.getAdminAccounts,
    }
)(AdminUserCard)