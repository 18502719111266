import Globals from "./../../../Globals";

export default class BenefitHistory {
       public cycleNumberLabel: string = `Cycle No.`;
       public endDateLabel: string = `To`;
       public servicesUsedHistoryHeader: string = `Services usage history`;
       public startDateLabel: string = `From`;
       public valueUsedLabel: string = `Value used`;

		public static get Resources(): BenefitHistory{
			switch (Globals.currentActiveLanguage) {
				default:
				return new BenefitHistory();
		}
   	}
}
