import React from "react";
import ManageAccounts from "../../../resources/AdminUI/ManageAccounts";
import { String_Format } from "../../../utils/utils";
import bind from "bind-decorator";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { ManageAccountStore } from "../../../store/ManageAccountsStore";
import ICompany from "../../../models/ManageAccounts/ICompany";

interface IProps {
    company: ICompany,
    changeSelectedCompany?: (selectedCompany: ICompany) => void
}

class CompanyCard extends React.PureComponent<IProps, any> {
    constructor(props) {
        super(props)
    }

    @bind
    loadCompanyAccounts() {
        this.props.changeSelectedCompany(this.props.company);
    }

    render() {
        return <div onClick={this.loadCompanyAccounts} className="accounts-list-item">
            <h2 className="title">{this.props.company.name}</h2>
            <div className="status">
                {
                    this.props.company.hasCredentials ?
                        <span className="status-affirmative">{ManageAccounts.Resources.hasCredentialsLabel}</span>
                        : <span className="status-negative">{ManageAccounts.Resources.noCredentialsLabel}</span>
                }
            </div>
            <div className="spaces-list">
                {
                    this.props.company.noOfPrivateOffices == 0 ?
                        <span>{ManageAccounts.Resources.noPrivateOfficesLabel}</span>
                        : <span className="spaces-number">
                            {this.props.company.noOfPrivateOffices > 1 ?
                                String_Format(ManageAccounts.Resources.privateOfficesLabel, this.props.company.noOfPrivateOffices)
                                : String_Format(ManageAccounts.Resources.privateOfficesLabel, this.props.company.noOfPrivateOffices)}
                        </span>
                }
                {
                    this.props.company.privateOfficesList?.join(', ')
                }
            </div>
        </div>
    }
}

export default connect(
    (state: ApplicationState, ownProps: IProps) => {
        return {
            ...ownProps
        }
    },
    {
        changeSelectedCompany: ManageAccountStore.actionCreators.changeSelectedCompany
    }
)(CompanyCard as any)