import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { useNavigate } from "react-router-dom";

import { String_Format } from "../../utils/utils";
import DropdownButton from "../../components/Shared/DropdownButton";
import { UserDetailsCard } from "../../components/Users/Cards/UserDetailsCard";
import { UserDetailsStore } from "../../store/UserDetailsStore";
import { ApplicationState } from "../../store";
import UserDetails from "../../resources/AdminUI/Users/UserDetails";
import General from "../../resources/AdminUI/General";
import AddOrEditUserForm from "../../components/Users/Forms/AddOrEditUserForm";
import { UserStates } from "../../enums/Users/UserStates";
import UsersBookingsTablesTabComponent from "../../components/Users/Tabs/UsersBookingsTablesTabComponent";
import UserBenefitsContainer from "../../components/Users/Containers/UserBenefitsContainer";
import { withRouter } from "../../components/Shared/withRouter";
import ModalDialog from "../../components/Shared/ModalDialog";
import { BenefitsStore } from "../../store/BenefitsStore";
import Loading from "../../components/Shared/Loading";
import { useUserDetailsPage } from "../../hooks/Users/useUserDetailsPage";
import { getRoomBookingsColumns } from "../../components/RoomBookings/RoomBookingsTableColumns";
import { useRoomBookingsQueries } from "../../hooks/RoomBookings/useRoomBookingsQueries";
import { IUserDetails } from "../../models/Users/Interfaces/IUserDetails";

interface IProps {
  params: URLSearchParams;
  user: IUserDetails;
  loading: boolean;
  initializeBenefits: () => void;
  getUserDetails: (userId: number) => void;
  activateUser: (userId: number) => void;
  deactivateUser: (userId: number) => void;
  deleteUser: (userId: number) => void;
}

const UserDetailsPage: React.FC<IProps> = (props) => {
  const tableFilterTemplate = `UserId eq {0}`;
  const navigate = useNavigate();

  const {
    isEditUserModalOpen,
    loading,
    numberOfRoomBookings,
    itemsPerPage,
    firstIndexFromPage,
    lastIndexFromPage,
    pageIndexArray,
    filters,
    skip,
    changeCurrentPage,
    reloadRoomBookingsData,
    showEditUserModal,
    hideEditUserModal,
    getAvailableActions,
  } = useUserDetailsPage(props);

  const { getRoomBookings } = useRoomBookingsQueries();
  const { data, isLoading } = getRoomBookings(itemsPerPage, skip, filters);

  useEffect(() => {
    if (props.params.has("id")) {
      const userId = parseInt(props.params.get("id")!);
      props.initializeBenefits();
      props.getUserDetails(userId);
    }
  }, []);

  const roomBookingsTableProps = useMemo(
    () => ({
      roomBookingsColumns: getRoomBookingsColumns(false),
      itemsPerPage,
      data,
      isLoading,
      pagination: {
        totalItems: numberOfRoomBookings,
        firstIndexFromPage,
        lastIndexFromPage,
        pageIndexArray,
        maxPagesDisplayed: 3,
        pageNeighbours: 1,
        reload: reloadRoomBookingsData,
        changeCurrentPage,
      },
    }),
    [
      itemsPerPage,
      data,
      isLoading,
      numberOfRoomBookings,
      firstIndexFromPage,
      lastIndexFromPage,
      pageIndexArray,
      reloadRoomBookingsData,
      changeCurrentPage,
      getRoomBookingsColumns,
    ]
  );

  if (loading || !props.user) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <Helmet title={UserDetails.Resources.userDetailsHeader}></Helmet>
      <div className="details-page-top">
        <div className="page-navigation">
          <span
            className="material-symbols-outlined"
            onClick={() => navigate(-1)}
          >
            arrow_back
          </span>
          <div className="page-title">
            {UserDetails.Resources.userDetailsHeader}
          </div>
        </div>

        <div className="page-actions">
          {props.user.status !== UserStates.Deleted && (
            <DropdownButton
              text={General.Resources.moreActions}
              items={getAvailableActions()}
              className="btn-big btn-secondary"
              icon="arrow_drop_down"
            />
          )}

          <button className="btn-big btn-primary" onClick={showEditUserModal}>
            <span className="material-symbols-outlined">edit</span>
            <p className="btn-primary-text">
              {UserDetails.Resources.editUserInfoLabel}
            </p>
          </button>
          <ModalDialog open={isEditUserModalOpen} onCancel={hideEditUserModal}>
            <AddOrEditUserForm
              id={props.user.id}
              firstName={props.user.firstName}
              lastName={props.user.lastName}
              phoneNo={props.user.phone}
              email={props.user.email}
              companyId={props.user.companyId}
              internalMetadata={props.user.internalMetadata}
              onCancelCallback={hideEditUserModal}
            />
          </ModalDialog>
        </div>
      </div>

      <UserDetailsCard user={props.user} />

      <section className="container">
        <UserBenefitsContainer
          userId={props.user.id}
          userName={`${props.user.lastName} ${props.user.firstName}`}
        />
      </section>

      <section className="container">
        <UsersBookingsTablesTabComponent
          componentId={`user-bookings-${props.user.id}`}
          userId={props.user.id}
          hotdeskBookingsTableFilters={String_Format(
            tableFilterTemplate,
            props.user.id
          )}
          hotdeskBookingsTableHiddenColumns={[
            "userEmail",
            "userPhone",
            "statusName",
            "userFirstName",
            "userLastName",
            "userCompanyName",
            "workspaceName",
            "workspaceCategoryId",
            "workspaceCityId",
            "workspaceCityName",
            "workspaceCountryId",
            "workspaceCountryName",
            "workspaceCountryFlag",
            "timeSpent",
            "isFullDay",
            "createdAt",
            "checkinAt",
            "checkoutAt",
            "expiresAt",
            "cancelledAt",
            "bookingAt",
            "timezone",
            "isWalkin",
            "isAutomatedCheckout",
            "unixBookingAtWithTimezone",
          ]}
          hotdeskBookingsTableExpandableColumns={["createdAt"]}
          showActionsColumn={true}
          roomBookingsTableProps={roomBookingsTableProps}
        />
      </section>
    </div>
  );
};

export default withRouter(
  connect(
    (state: ApplicationState) => ({
      user: state.userDetails.user,
      loading: state.userDetails.loading,
    }),
    {
      initializeBenefits: BenefitsStore.actionCreators.inititalizeBenefits,
      getUserDetails: UserDetailsStore.actionCreators.getUserDetails,
      activateUser: UserDetailsStore.actionCreators.activateUser,
      deactivateUser: UserDetailsStore.actionCreators.deactivateUser,
      deleteUser: UserDetailsStore.actionCreators.deleteUser,
    }
  )(UserDetailsPage)
);
