import bind from "bind-decorator";
import React from "react";
import Helmet from "react-helmet";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { firstLetterToLowerCase } from "../../utils/utils";
import { ApplicationPaths } from "../../enums/Common/ApplicationPaths";
import General from "../../resources/AdminUI/General";
import Workspaces from "../../resources/AdminUI/Spaces/Workspaces";
import AddOrEditRoomForm from "../../components/Rooms/AddOrEditRoomForm";
import { RoomCategories } from "../../enums/Rooms/RoomCategories";
import RoomTypes from "../../resources/AdminUI/Rooms/RoomTypes";
import { AddOrEditRoomStore } from "../../store/AddOrEditRoomStore";
import AddOrEditWorkspaceRoomForm from "../../resources/AdminUI/Rooms/AddOrEditWorkspaceRoomForm";
import ErrorMessages from "../../resources/Common/ErrorMessages";
import { IWorkspaceSchedule } from "../../models/Spaces/Interfaces/IWorkspaceSchedule";
import { Modal } from "antd";
import { NavigateFunction } from "react-router";
import { withRouter } from "../../components/Shared/withRouter";
import Loading from "../../components/Shared/Loading";

interface IProps {
  navigate?: NavigateFunction;
  params: URLSearchParams;

  loading: boolean;
  workspaceId: number;

  getRoomAmenities?: () => void;
  initializeCreatePage?: (workspaceId: number) => void;
  initializeEditPage?: (id: number) => void;
  setWorkspaceSchedule?: (workspaceSchedule: IWorkspaceSchedule[]) => void;
}

interface IState {
  isEditMode: boolean;
  roomId: number;
  workspaceId?: number;
}

class AddOrEditRoomPage extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);

    if (window.location.pathname == ApplicationPaths.AddRoom) {
      if (props.params.has("workspaceId")) {
        const workspaceId = parseInt(props.params.get("workspaceId"));
        this.state = {
          isEditMode: false,
          roomId: null,
          workspaceId: workspaceId,
        };
        this.props.initializeCreatePage(workspaceId);
        this.props.getRoomAmenities();
      } else {
        this.props.navigate(ApplicationPaths.NotFound);
      }
    } else if (window.location.pathname == ApplicationPaths.EditRoom) {
      if (props.params.has("id")) {
        const id = parseInt(props.params.get("id"));
        this.state = { isEditMode: true, roomId: id, workspaceId: null };
        this.props.initializeEditPage(id);
      } else {
        this.props.navigate(ApplicationPaths.NotFound);
      }
    }
  }

  @bind
  getAllRoomTypes() {
    var options = [];
    let keys = Object.keys(RoomCategories).filter(
      (k) => typeof RoomCategories[k as any] === "number"
    );

    keys.forEach((key) => {
      let value = RoomCategories[key as any].toString();
      options.push({
        value: value,
        text: RoomTypes.Resources[firstLetterToLowerCase(key)],
      });
    });
    return options;
  }

  @bind
  showInvalidArgumentModal() {
    Modal.error({
      title: ErrorMessages.Resources.error,
      content: ErrorMessages.Resources.invalidValueForArgumentId,
    });
  }

  render() {
    return (
      <div>
        <Helmet title={Workspaces.Resources.spaces} />

        <div className="details-page-top">
          <div className="page-navigation">
            <span
              className="material-symbols-outlined"
              onClick={() => this.props.navigate(-1)}
            >
              arrow_back
            </span>
            <div className="page-title">
              {this.state.isEditMode
                ? AddOrEditWorkspaceRoomForm.Resources.editRoom
                : AddOrEditWorkspaceRoomForm.Resources.addRoom}
            </div>
          </div>
        </div>

        {this.props.loading ? (
          <div>
            <Loading />
          </div>
        ) : (
          <div>
            <AddOrEditRoomForm
              workspaceId={this.state.workspaceId}
              roomTypes={this.getAllRoomTypes()}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: ApplicationState) => {
      return {
        loading: state.addOrEditRoom.loading,
      };
    },
    {
      getRoomAmenities: AddOrEditRoomStore.actionCreators.getRoomAmenities,
      initializeCreatePage:
        AddOrEditRoomStore.actionCreators.initializeCreatePage,
      initializeEditPage: AddOrEditRoomStore.actionCreators.initializeEditPage,
      setWorkspaceSchedule:
        AddOrEditRoomStore.actionCreators.setWorkspaceSchedule,
    }
  )(AddOrEditRoomPage as any)
);
